import {
    GET_PAZIENTI,
    SET_DENTE,
    SET_ISTANZA,
    GET_DETTAGLIO_PAZIENTE,
    SET_VISITA_DRAFT,
    DELTE_VISITA_DRAFT,
    UPDATE_DENTE,
    DELETE_VISITA,
    GET_PERSONALE,
    DO_LOGOUT,
    CREATE_PERSONA,
    DELETE_PERSONA,
    CREATE_PAZIENTE,
    SET_RISPOSTE_ANAMNESI,
    GET_LICENZE,
    GET_REPORT_SETTINGS,
    SAVE_VISITA,
    TOGGLE_RELATED_QUESTIONS,
    UPDATE_PERSONA,
    UPDATE_PAZIENTE,
    DELETE_PAZIENTE,
    SAVE_REPORT_SETTINGS,
    SET_REPORT_TOGGLE,
    DO_LOGOUT_REASON, SET_MARGINE_ENNE, SET_BIOTIPO, DISMISS_ONBOARDING, LOADING, GET_DITTA, GET_DETTAGLIO_VISITA
} from "./types";
import env from '../env';
import configureStore from '../store';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

let { st } = configureStore();
const axios = require('axios');
let get_user_token = () => { return st.getState().user.accessToken };


let handleError = (err) => {
    console.error(err);
    Sentry.captureException(err)
};


let handle_api_error = (dispatch, e) => {
    console.error(e);
    handleError(e);

    if(e.response && e.response.data && e.response.data.message) {
        return dispatch({type: DO_LOGOUT_REASON, error: e.response.data.message});
    }

    return dispatch({type: DO_LOGOUT});
}


let setLoading = (dispatch, isLoading) => {
    dispatch({
        type: LOADING,
        isLoading
    })
};



export const getListaPazienti = (searchParam) => async (dispatch, getState) => {
    try{
        let access_token = await getState().user.accessToken;
        let url = searchParam ? `${env.base_url}/api/patients/getPatients?search=${searchParam}` : `${env.base_url}/api/patients/getPatients`;
        let response = await axios.get(url, {headers: { "Authorization": `Bearer ${access_token}` }});

        dispatch({
            type: GET_PAZIENTI,
            pazienti: response.data
        });
    }
    catch(e){
        return handle_api_error(dispatch, e);
    }
};


export const getDettaglioPaziente = (id) => async (dispatch, getState) => {

    let access_token = await getState().user.accessToken;

    try {
        let response = await axios.get(`${env.base_url}/api/patients/${id}`, {
            headers: { "Authorization": `Bearer ${access_token}` }
        });

        dispatch({
            type: GET_DETTAGLIO_PAZIENTE,
            paziente: response.data,
        })
    }
    catch(e){
        return handle_api_error(dispatch, e);
    }
};


export const createPaziente = (paziente) => async (dispatch, getState) => {
    try {
        let p = {...paziente};
        let access_token = await getState().user.accessToken;

        p.id = 0;

        let response = await axios.post(`${env.base_url}/api/patients`, p, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: CREATE_PAZIENTE,
            paziente: response.data,
        });
    } catch (e){
        handleError(e);
    }
};


export const editPaziente = (paziente) => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;

        let response = await axios.put(`${env.base_url}/api/patients/${paziente.id}`, paziente, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: UPDATE_PAZIENTE,
            id_paziente: paziente.id,
            data: response.data
        })
    } catch (e){
        handleError(e);
    }
};


export const deletePaziente = (paziente) => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;

        await axios.delete(`${env.base_url}/api/patients/${paziente.id}`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: DELETE_PAZIENTE,
            id_paziente: paziente.id,
        })
    } catch (e){
        handleError(e);
    }
};


export const setCurrentDente = (data) => async dispatch => {
    dispatch({
        type: SET_DENTE,
        data
    })
};


export const updateDente = (dente_updated) => async (dispatch, getState) => {
    let {id_paziente, current_step, arcata_index, dente_index} = await getState().current_dente;

    dispatch({
        type: UPDATE_DENTE,
        data: {
            id_paziente,
            current_step,
            arcata_index,
            dente_index,
            dente_updated
        }
    })
};


export const setCurrentIstanza = (id, token) => async dispatch => {
    try {
        let response = await axios.post(`${env.base_url}/api/account/loginto`, {firm: parseInt(id)}, {
            headers: {"Authorization": `Bearer ${token}`},
        });

        dispatch({
            type: SET_ISTANZA,
            current_istanza: id,
            user: response.data,
            error: null
        })
    }
    catch (e) {
        dispatch({
            type: SET_ISTANZA,
            current_istanza: "",
            error: e.message
        });

        handleError(e);
    }
};


export const createVisitaDraft = (id_paziente, tipo_visita, modo_visita, note_visita, id_visita_rivalutazione) => async (dispatch, getState) => {

    try{
        await setLoading(dispatch, true);
        let access_token = await getState().user.accessToken;

        let response = await axios.get(`${env.base_url}/api/visits/model?type=${tipo_visita}&patient=${id_paziente}&fromLast=${modo_visita && modo_visita === 'rivalutazione'}&referVisitId=${id_visita_rivalutazione}`, {
            headers: { "Authorization": `Bearer ${access_token}` }
        });

        dispatch({
            type: SET_VISITA_DRAFT,
            data: {
                id_paziente,
                tipo_visita,
                modo_visita,
                note_visita,
                ...response.data
            }
        });
    }
    catch(e){
        return handle_api_error(dispatch, e);
    }
    finally
    {
        setLoading(dispatch, false);
    }
};



export const saveVisita = (visitaDraft) => async (dispatch, getState) => {

    try{
        await setLoading(dispatch, true);
        let access_token = await getState().user.accessToken;
        let response = await axios.post(`${env.base_url}/api/visits/save`, visitaDraft, {
            headers: { "Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: SAVE_VISITA,
            data: response.data
        });
    }
    catch(e){
        return handle_api_error(dispatch, e);
    }
    finally {
        await setLoading(dispatch, false);
    }
};







export const setRisposteAnamnesi = (id_paziente, current_step, id_domanda, risposta) => async (dispatch, getState) => {

    //console.log(risposta);

    if(risposta && risposta.showFields) {
        let domande = await getState().visite_drafts[id_paziente].steps[current_step].campi;
        let d = _.find(domande, d => d.id === id_domanda);
        _.forEach(d.options, o => o.showFields.map(id => dispatch({type: TOGGLE_RELATED_QUESTIONS, data: {id_domanda: id, hide: true, id_paziente, current_step}})));
        _.forEach(risposta.showFields, id => dispatch({type: TOGGLE_RELATED_QUESTIONS, data: {id_domanda: id, hide: false, id_paziente, current_step}}));
    }

    dispatch({
        type: SET_RISPOSTE_ANAMNESI,
        data: {
            id_paziente,
            current_step,
            id_domanda,
            risposta
        }
    })
};



export const getListaPersonale = () => async (dispatch, getState) => {

    try {
        let access_token = await getState().user.accessToken;
        let response = await axios.get(`${env.base_url}/api/firms/workers`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: GET_PERSONALE,
            personale: response.data
        })
    }
    catch(e){
        return handle_api_error(dispatch, e);
    }
};


export const createPersona = (persona) => async (dispatch, getState) => {
    try {
        let p = {...persona};
        p.id = 0;

        let access_token = await getState().user.accessToken;
        let response = await axios.post(`${env.base_url}/api/firms/saveworker`, p, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: CREATE_PERSONA,
            persona: response.data,
        });
    } catch (e){
        handleError(e);
    }
};


export const editPersona = (persona) => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;
        let response = await axios.put(`${env.base_url}/api/firms/workers/${persona.id}`, persona, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: UPDATE_PERSONA,
            id_persona: persona.id,
            data: response.data
        })
    } catch (e){
        handleError(e);
    }
};


export const deletePersona = (persona) => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;
        await axios.delete(`${env.base_url}/api/users/${persona.id}`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: DELETE_PERSONA,
            id_persona: persona.id
        })
    } catch (e){
        handleError(e);
    }
};


export const getListaLicenze = () => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;
        let response = await axios.get(`${env.base_url}/api/firms/licenses`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: GET_LICENZE,
            licenze: response.data
        });
    } catch (e){
        handleError(e);
    }
};



export function getOptionsLicenze () {
    return async function (dispatch, getState) {
        try {
            let access_token = await getState().user.accessToken;
            let response = await axios.get(`${env.base_url}/api/firms/licenses`, {
                headers: {"Authorization": `Bearer ${access_token}`}
            });

            return response.data.map(o => {
                console.log(o);

                return {
                    label: `${o.type} (${o.label.substr(-5)})`,
                    value: o.value,
                    userId: o.userId
                }
            })
        } catch (e){
            handleError(e);
        }
    }
}




export const getOptionsRuoli = async () => {
    try {
        let response = await axios.get(`${env.base_url}/api/roles`, {
            headers: {"Authorization": `Bearer ${await get_user_token()}`}
        });

        return response.data

    } catch (e){
        handleError(e);
    }
};


export const getReportSettings = () => async (dispatch, getState) => {
    try {
        let access_token = await getState().user ? await getState().user.accessToken : "";
        let response = await axios.get(`${env.base_url}/api/reportsettings`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: GET_REPORT_SETTINGS,
            data: response.data
        });
    } catch (e){
        handleError(e);
    }
};


export const saveReportSettings = () => async (dispatch, getState) => {
    try {
        let settings = await getState().settings;
        let access_token = await getState().user.accessToken;

        let response = await axios.post(`${env.base_url}/api/reportsettings`, settings, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: SAVE_REPORT_SETTINGS,
            data: response.data
        });
    } catch (e){
        handleError(e);
    }
};



export const setReportToggle = (module_id, option_id, data) => async dispatch => {
    dispatch({
        type: SET_REPORT_TOGGLE,
        module_id,
        option_id,
        data
    });
};



export const deleteVisitaDraft = (user_id) => async dispatch => {
    dispatch({
        type: DELTE_VISITA_DRAFT,
        user_id,
    });
};




export const setMargineEnne = (id_paziente, current_step, valore_margine) => async dispatch => {
    dispatch({
        type: SET_MARGINE_ENNE,
        data: {
            id_paziente,
            current_step,
            valore_margine
        }
    });
};



export const setBiotipo = (id_paziente, current_step, objAnswer) => async dispatch => {
    dispatch({
        type: SET_BIOTIPO,
        data: {
            id_paziente,
            current_step,
            objAnswer
        }
    });
};


export const dismissOnboarding = () => async (dispatch, getState) => {
    try {
        let access_token = await getState().user.accessToken;

        await axios.put(`${env.base_url}/api/users/onboarding`, {}, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: DISMISS_ONBOARDING
        });

    } catch (e){
        handleError(e);

        dispatch({
            type: DISMISS_ONBOARDING
        });
    }
};




export function getVisitaDettaglio (id) {
    return async function (dispatch, getState) {
        try {
            let access_token = await getState().user.accessToken;

            let response = await axios.get(`${env.base_url}/api/visits/${id}`, {
                headers: {"Authorization": `Bearer ${access_token}`}
            });

            return dispatch({
                type: GET_DETTAGLIO_VISITA,
                data: response.data
            });

        } catch (e){
            handleError(e);
        }
    }
}




export const getDitta = () => async (dispatch, getState) => {
    try{
        let access_token = await getState().user.accessToken;

        let response = await axios.get(`${env.base_url}/api/firms/details`, {
            headers: { "Authorization": `Bearer ${access_token}` }
        });

        return dispatch({
            type: GET_DITTA,
            data: response.data
        });

    } catch (e){
        handleError(e);
    }
};



export const editVisita = (id_paziente, id_visita) => async (dispatch, getState) => {

    let access_token = await getState().user.accessToken;

    let response = await axios.get(`${env.base_url}/api/visits/${id_visita}`, {
        headers: {"Authorization": `Bearer ${access_token}`}
    });


    dispatch({
        type: SET_VISITA_DRAFT,
        data: {
            id_paziente,
            ...response.data
        }
    });
};



export const deleteVisita = (id_paziente, id_visita) => async (dispatch, getState)  => {
    try {
        let access_token = await getState().user.accessToken;
        await axios.delete(`${env.base_url}/api/visits/${id_visita}`, {
            headers: {"Authorization": `Bearer ${access_token}`}
        });

        dispatch({
            type: DELETE_VISITA,
            id_paziente,
            id_visita
        })
    } catch (e){
        handleError(e);
    }
};
