import React, { Component } from 'react';
import './LayoutLogin.scss';


export class LayoutLogin extends Component {
  static displayName = LayoutLogin.name;

  render () {
    return (
      <div className={"layout-login"}>
          {this.props.children}
      </div>
    );
  }
}
