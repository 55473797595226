import {DO_LOGIN, DO_LOGOUT, DO_RECOVERY, DO_RESET, DO_COLLAPSE, DO_RESET_PASSWORD, DO_REFRESH_TOKEN} from "./types";
import env from "../env";
import * as Sentry from "@sentry/react";

const axios = require('axios');
let handleError = (err) => {Sentry.captureException(err)};


export const doLogin = (username, password) => async dispatch => {
    try {
        let response = await axios.post(`${env.base_url}/api/account/login`, {email: username, password});
        Sentry.setUser({ email: username, id: response.data.id });
        Sentry.configureScope(scope => scope.setUser({email: username, id: response.data.id}));
        dispatch({type: DO_LOGIN, user: response.data, error: null})
    }
    catch (err) {
        dispatch({type: DO_LOGIN, user: null, error: err.message});
        handleError(err);
    }
};


export const doRefreshToken = () => async (dispatch, getState) => {
    try {
        let state = await getState();
        let {accessToken, refreshToken} = state.user;
        let response = await axios.post(`${env.base_url}/api/account/refresh`, {accessToken, refreshToken});
        dispatch({type: DO_REFRESH_TOKEN, user: response.data, error: null})
    }
    catch (err) {
        dispatch({type: DO_LOGOUT, user: null, error: err.message});
        handleError(err);
    }
};


export const doRecovery = (username) => async dispatch => {

    try {
        await axios.post(`${env.base_url}/api/account/forgotpassword`, {username});

        dispatch({
            type: DO_RECOVERY,
            message: "Riceverai una mail con le istruzioni per resettare la tua password."
        })
    }
    catch(err){
        dispatch({
            type: DO_RECOVERY,
            message: "Si " +
                "è verificato un errore. Contatta l'assistenza per verificare la tua identità e resettare la password"
        });
        handleError(err);
    }
};


export const doResetPassword = (token, password, email) => async dispatch => {
    try {
        await axios.post(`${env.base_url}/api/account/resetpassword`, {token, password, email});
        dispatch({type: DO_RESET_PASSWORD, message: "Password modificata con successo! Clicca su accedi per effettuare il login con la tua nuova password"})
    }
    catch (err) {
        dispatch({type: DO_RESET_PASSWORD, error: "La password deve contenere almeno 6 caratteri. La password deve avere almeno un carattere speciale, un numero e almeno una lettera maiuscola"})
        handleError(err);
    }
};


export const doConfirmUser = (token, password, email, nome, cognome) => async dispatch => {
    try {
        await axios.post(`${env.base_url}/api/account/confirmuser`, {token, password, email, firstName: nome, lastName: cognome});
        dispatch({type: DO_RESET_PASSWORD, message: "Il tuo account è stato attivato, ora puoi accedere alla piattaforma"})
    }
    catch (err) {
        dispatch({type: DO_RESET_PASSWORD, error: "La password deve contenere almeno 6 caratteri. La password deve avere almeno un carattere speciale, un numero e almeno una lettera maiuscola"})
        handleError(err);
    }
};




export const doReset = () => async dispatch => {
    dispatch({
        type: DO_RESET,
    })
};


export const doLogout = () => async dispatch => {
    dispatch({
        type: DO_LOGOUT,
    });

    Sentry.configureScope(scope => scope.setUser(null));
};


export const doCollapse = (collapsed) => async dispatch => {
    dispatch({
        type: DO_COLLAPSE,
        collapsed
    })
};
