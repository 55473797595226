import React, { Component } from 'react';
import env from '../../env';
import _ from 'lodash';

export default class PathArea extends Component {
    static defaultProps = { multiplierX: 40, multiplierY: env.distanza_linee_dente };


    render() {
        const { x, y, yOsso, margine, profondita, multiplierY, dente_prev_margine_line, dente_next_margine_line, dente_prev_profondita_line, dente_next_profondita_line, offsets } = this.props;

        let y1_gen = y - (margine[0][1] * multiplierY);
        let y2_gen = y - (margine[1][1] * multiplierY);
        let y3_gen = y - (margine[2][1] * multiplierY);

        let y3_gen_pre = y - (dente_prev_margine_line[2][1] * multiplierY);   // gengiva-3 del dente prima
        let y1_gen_next = y - (dente_next_margine_line[0][1] * multiplierY); // gengiva-1 del dente dopo


        let y1_pro = yOsso - (profondita[0][1] * multiplierY);
        let y2_pro = yOsso - (profondita[1][1] * multiplierY);
        let y3_pro = yOsso - (profondita[2][1] * multiplierY);

        let y3_pro_pre = yOsso - (dente_prev_profondita_line[2][1] * multiplierY);   // osso-3 del dente prima
        let y1_pro_next = yOsso - (dente_next_profondita_line[0][1] * multiplierY); // osso-1 del dente dopo


        let getXStimata = ((x, y, key) => {
            let shift = _.find(offsets, s => y >= s.min && y <= s.max);
            return shift ? x - shift[key] : x;
        });


        return (
            <g>
                <path d={
                    `M 0 ${(y3_gen_pre + y1_gen) / 2}                                          
                     L ${getXStimata(x/4, y1_gen, 'left')} ${y1_gen}  
                     L ${getXStimata((2*x)/4, y2_gen, 'center')} ${y2_gen}  
                     L ${getXStimata((3*x)/4, y3_gen, 'right')} ${y3_gen}                       
                     L ${x} ${(y3_gen + y1_gen_next)/2}
                     
                     L ${x} ${(y1_pro_next + y3_pro)/2}
                                                                       
                     L ${x} ${(y3_pro + y1_pro_next)/2}
                     L ${getXStimata((3*x)/4, y3_pro, 'right')} ${y3_pro}
                     L ${getXStimata((2*x)/4, y2_pro, 'center')} ${y2_pro}
                     L ${getXStimata(x/4, y1_pro, 'left')} ${y1_pro}
                     L 0 ${(y1_pro + y3_pro_pre)/2}
  
                     Z`}
                     {...this.props}
                />
            </g>


            // <g>
            //     <path d={
            //         `M 0 ${(y3_gen_pre + y1_gen) / 2}
            //              L ${x/4} ${y1_gen}
            //              L ${(2*x)/4} ${y2_gen}
            //              L ${(3*x)/4} ${y3_gen}
            //              L ${x} ${(y3_gen + y1_gen_next)/2}
            //
            //              L ${x} ${(y1_pro_next + y3_pro)/2}
            //
            //              L ${x} ${(y3_pro + y1_pro_next)/2}
            //              L ${(3 * x) / 4} ${y3_pro}
            //              L ${(2 * x) / 4} ${y2_pro}
            //              L ${x/4} ${y1_pro}
            //              L 0 ${(y1_pro + y3_pro_pre)/2}
            //
            //              Z`}
            //           {...this.props}
            //     />
            // </g>
        )
    }
}
