import React from 'react';

export default () => {
    return (
        <g>
            <defs>
                <rect id="SVGID_1_" width="70.9" height="70.9"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_"/>
            </clipPath>
            <polyline className="st0" points="70.9,0 0,0 0,70.9 	"/>
            <rect className="st1" width="70.9" height="70.9"/>
            <path className="st2" d="M0,68.6c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.2,0.2,0.4,0.3,0.7C1,70,1.1,70.1,1.2,70.3c0,0.2-0.1,0.4-0.3,0.5h1.8
		c0-0.2,0-0.5,0.1-0.7c0.2-0.6,0.8-1,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.2,0.4,0.5,0.8,0.4,1.2h1.2
		c-0.3-1.5,2.9-3.4,2.5-0.4c0,0.2-0.1,0.3-0.1,0.4h1.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5
		c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.2H17c0.3-0.4,0.7-0.5,1.2-0.1c0,0,0.1,0.1,0.1,0.1h4
		c-0.5-0.3-1-0.8-1.3-1.4c-0.2-0.4-0.4-0.8-0.2-1.2c0.1-0.2,0.3-0.4,0.6-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.1,1,0.2,1.5,0.5
		c0.2,0.2,0.5,0.3,0.7,0.6c0.4,0.6,0.2,1.4-0.1,2c-0.1,0.2-0.2,0.4-0.3,0.5h0.7c0-0.4,0.3-0.8,0.5-1.1c0.1-0.1,0.2-0.3,0.3-0.3
		c0.1,0,0.2,0,0.3,0l-0.1-0.1c0.4,0.2,0.6,0.5,0.7,1c0,0.2,0,0.4,0,0.6h0.4c-0.1-0.7,0.3-1.5,1.1-1.4c0.5,0.1,1.4,0.9,1.1,1.4H33
		c0-0.4,0-0.8,0.2-1.1c0.1-0.2,0.3-0.5,0.6-0.6v0c0.3-0.2,0.6-0.3,0.9-0.4c0.2,0,0.3,0,0.5,0c0.3,0.1,0.5,0.3,0.6,0.6
		c0.2,0.4,0.2,0.9,0.1,1.3c0,0.1,0,0.1-0.1,0.2h0.7c0-0.4,0.1-0.8,0.1-1.1c0-0.3,0.1-0.6,0.2-0.8c0.2-0.4,0.6-0.6,1-0.5
		s0.8,0.3,1,0.7c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.2-0.1,0.4-0.3,0.5h1.8c0-0.2,0-0.5,0.1-0.7
		c0.2-0.6,0.8-1,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.2,0.4,0.5,0.8,0.4,1.2h1.2c-0.3-1.5,2.9-3.4,2.5-0.4
		c0,0.2-0.1,0.3-0.1,0.4h1.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.2h1.6c0.3-0.4,0.7-0.5,1.2-0.1c0,0,0.1,0.1,0.1,0.1h4c-0.5-0.3-1-0.8-1.3-1.4
		c-0.2-0.4-0.4-0.8-0.2-1.2c0.1-0.2,0.3-0.4,0.6-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.1,1,0.2,1.5,0.5c0.2,0.2,0.5,0.3,0.7,0.6
		c0.4,0.6,0.2,1.4-0.1,2c-0.1,0.2-0.2,0.4-0.3,0.5h0.7c0-0.4,0.3-0.8,0.5-1.1c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.2,0,0.3,0l-0.1-0.1
		c0.4,0.2,0.6,0.5,0.7,1c0,0.2,0,0.4,0,0.6h0.4c-0.1-0.7,0.3-1.5,1.1-1.4c0.5,0.1,1.4,0.9,1.1,1.4h1.8c0-0.1,0-0.2,0-0.2
		c0-0.1,0-0.2,0-0.4c0-0.2,0-0.5,0.1-0.7c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.1,0.1-0.1c0.2-0.3,0.6-0.5,1-0.5c0.1,0,0.3,0.1,0.4,0.1
		v-1c-0.1,0-0.1,0-0.2,0c-0.6-0.2-1-0.9-0.9-1.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1
		c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0v-1.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.5,0.7-0.9,0.7c-0.2,0-0.5-0.1-0.7-0.2
		c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8c0.3,0,0.5,0,0.8,0.1
		c0.2,0.1,0.3,0.2,0.3,0.4v-5.4c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5
		c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.4,0.6-0.6,1-0.5c0.1,0,0.3,0.1,0.4,0.1v-4.3
		c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-1v-0.1c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.3-0.5,0.7-0.9,0.7c-0.2,0-0.5-0.1-0.7-0.2
		c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8c0.3,0,0.5,0,0.8,0.1
		c0.2,0.1,0.3,0.3,0.4,0.4V41c0,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.7,0.3-0.7-0.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
		c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8
		c0.2-0.4,0.6-0.6,1-0.5c0.1,0,0.3,0.1,0.4,0.1v-2.7c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.3,0-0.4-0.1
		c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0.2-0.4,0.3-0.6C70,33.2,70,33,70.1,33c0.2-0.2,0.5-0.2,0.7-0.1c0,0,0,0,0,0v-2
		c0,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.2-0.4-0.3c-0.1-0.2,0-0.3,0-0.5c0-0.2,0-0.5,0.1-0.7c0-0.2,0-0.3,0.1-0.5
		c0.1-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.2,0.6,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0,0,0,0.1,0.1,0.1v-2.1c-0.1,0-0.1,0-0.2,0
		c-0.6-0.2-1-0.9-0.9-1.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0
		v-1.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.5,0.7-0.9,0.7c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5
		c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8c0.3,0,0.5-0.1,0.8,0.1c0.2,0.1,0.3,0.2,0.3,0.4v-5.4c-0.1,0.2-0.3,0.3-0.4,0.5
		c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8
		c0.2-0.4,0.6-0.6,1-0.5c0.1,0,0.3,0.1,0.4,0.1v-4.3c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-1V8c-0.1,0.2-0.2,0.5-0.3,0.7
		C70.3,9,70,9.4,69.7,9.4c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c-0.2-0.3-0.4-0.7-0.2-1.2
		c0.2-0.7,0.9-1.3,1.6-1.3c0.4,0,0.8,0.1,1,0.4c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.3,0.4,0.4V0.5c-0.2,0.2-0.3,0.3-0.4,0.5
		c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0,0,0,0h-1.8c0,0.1-0.1,0.2-0.2,0.2
		c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5C66.1,1,65.9,1.1,65.7,1c-0.3-0.2-0.4-0.6-0.5-1h-0.4c0,0.2-0.1,0.4-0.2,0.6
		C64.4,0.8,64.2,1,64,1c-0.5,0.1-0.8-0.4-0.8-0.9c0,0,0-0.1,0-0.1h-0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.5,0.2-0.7,0.1
		c-0.4,0-0.7-0.1-1-0.3h-4c0.2,0.3,0.2,0.8,0,1.2c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1c-0.3-0.4-0.1-0.9,0-1.3
		c0.1-0.3,0.3-0.7,0.5-1h-1.6c0,0,0,0.1,0,0.1c0.2,0.8-0.2,1.6-0.6,2.2c-0.2,0.3-0.5,0.7-0.8,0.7C52,3.2,51.7,3,51.4,2.8
		c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.2,0-0.4,0.1-0.6c0,0,0,0,0.1-0.1h-1.2
		c-0.1,0.2-0.3,0.5-0.5,0.6c-0.3,0.2-0.6,0.3-0.9,0.2c-0.2,0-0.4-0.1-0.6-0.2C46.1,0.4,46,0.2,45.9,0h-1.2c0,0,0,0.1,0,0.1
		c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6c-0.4,0.2-0.9,0.4-1.4,0.2c-0.5-0.2-0.7-0.7-0.8-1.2c0-0.2-0.1-0.5-0.1-0.7h-1.8
		c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.5,0.2-0.7,0.3C38.2,0.6,38,0.8,37.9,1c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5
		c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0,0,0,0h-0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.1-0.3,0.2
		c-0.3,0.2-0.5,0.4-0.5,0.8c0,0.2-0.2,0.5-0.4,0.5C34.2,2.3,34,2.2,34,2c-0.1-0.1-0.1-0.2-0.2-0.3v0c-0.3-0.4-0.5-0.8-0.6-1.2
		C33.1,0.4,33,0.2,33,0h-3.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5C27.8,1,27.6,1.1,27.4,1
		c-0.3-0.2-0.4-0.6-0.5-1h-0.4c0,0.2-0.1,0.4-0.2,0.6C26.1,0.8,25.9,1,25.7,1c-0.5,0.1-0.8-0.4-0.8-0.9c0,0,0-0.1,0-0.1h-0.7
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.5,0.2-0.7,0.1c-0.4,0-0.7-0.1-1-0.3h-4c0.2,0.3,0.2,0.8,0,1.2c-0.1,0.3-0.3,0.7-0.5,0.9
		c-0.3,0.3-1,0.5-1.4,0.1c-0.3-0.4-0.1-0.9,0-1.3c0.1-0.3,0.3-0.7,0.5-1h-1.6c0,0,0,0.1,0,0.1c0.2,0.8-0.2,1.6-0.6,2.2
		C14.6,2.7,14.4,3,14,3.1c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6C11.3,1.3,11.1,1,11,0.6
		c0-0.2,0-0.4,0.1-0.6c0,0,0,0,0.1-0.1H10C9.9,0.2,9.7,0.5,9.5,0.6C9.2,0.8,8.9,0.9,8.6,0.8C8.4,0.8,8.2,0.8,8,0.6
		C7.8,0.4,7.7,0.2,7.6,0H6.4c0,0,0,0.1,0,0.1c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6C4.4,1.9,3.9,2.1,3.5,1.9
		C3,1.7,2.8,1.2,2.7,0.7c0-0.2-0.1-0.5-0.1-0.7H0.8c0,0-0.1,0-0.1,0.1C0.5,0.2,0.3,0.3,0.1,0.4c0,0-0.1,0.1-0.1,0.1v5.5C0,6,0,6,0,6
		c0.3,0.6,0.2,1.3,0,2v6.3c0.2,0.1,0.5,0.3,0.6,0.6c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6
		c-0.2,0.1-0.5,0.2-0.7,0.3c0,0-0.1,0.1-0.1,0.1v5.4c0,0,0,0.1,0.1,0.1c0.3,0.7,0.2,1.4-0.1,2.1v5.6c0,0.1,0.1,0.2,0.1,0.2
		c0,0.1,0,0.2-0.1,0.2v6.3c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6
		c-0.2,0.1-0.5,0.2-0.7,0.3c0,0-0.1,0.1-0.1,0.1v5.5c0,0,0,0,0,0.1c0.3,0.6,0.2,1.3,0,2v6.3c0.2,0.1,0.5,0.3,0.6,0.6
		c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.2-0.7,0.3c0,0-0.1,0.1-0.1,0.1v5.4
		c0,0,0,0.1,0.1,0.1c0.3,0.7,0.2,1.4-0.1,2.1V68.6z M4.1,67C4,67.9,3.8,68.7,2.9,69c-0.8,0.2-1.7-0.2-2.1-0.9
		c-0.3-0.5-0.3-1-0.1-1.4h0c0.3-0.4,0.7-0.8,1.2-1c1.1-0.5,2.1-0.2,2.2,1h0C4.1,66.8,4.1,66.9,4.1,67z M1.3,46.1
		c0.2-0.2,0.4-0.3,0.6-0.4c0.3-0.1,0.7-0.2,1,0c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4C3.1,47,3,47.5,2.9,47.9
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7
		C1,47.2,0.9,46.5,1.3,46.1z M0.7,43.8C0.7,43.8,0.7,43.8,0.7,43.8L0.7,43.8C0.7,43.8,0.7,43.8,0.7,43.8c0-0.2,0.2-0.5,0.4-0.6
		C1.3,43,1.6,43,1.9,43c0.2,0,0.3,0,0.5,0.1c0.2,0.2,0.3,0.5,0.2,0.7c0,0.3-0.2,0.5-0.3,0.7C2.1,44.7,2,44.9,1.7,45
		c-0.1,0-0.3,0-0.4-0.1C1,44.6,0.7,44.2,0.7,43.8z M2.7,40.1c-0.1-0.5-0.1-1,0.1-1.4c0.2-0.6,0.8-1,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.2
		c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6c-0.4,0.2-0.9,0.4-1.4,0.2
		C3,41.1,2.8,40.6,2.7,40.1z M1.3,23.4c0.2-0.2,0.4-0.3,0.6-0.4c0.3-0.1,0.7-0.2,1,0c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4
		c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3,0.1-0.4,0
		c-0.2-0.1-0.3-0.4-0.4-0.7C1,24.5,0.9,23.8,1.3,23.4z M0.7,21.1C0.7,21.1,0.7,21.1,0.7,21.1L0.7,21.1C0.7,21,0.7,21.1,0.7,21.1
		c0-0.2,0.2-0.5,0.4-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.2,0.3,0.5,0.2,0.7c0,0.3-0.2,0.5-0.3,0.7
		C2.1,22,2,22.2,1.8,22.2c-0.1,0-0.3,0-0.4-0.1C1,21.9,0.8,21.5,0.7,21.1z M2.7,17.3c-0.1-0.5-0.1-1,0.1-1.4c0.2-0.6,0.8-1,1.4-1.3
		c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6
		c-0.4,0.2-0.9,0.4-1.4,0.2C3,18.4,2.8,17.8,2.7,17.3z M15.8,9.7c0.1,0.6,0.1,1.3,0,1.9c-0.1,0.2-0.1,0.4-0.3,0.5
		c-0.2,0.1-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.3,0.1-0.7,0.4-0.9c0.3-0.2,0.7-0.2,0.8,0.1
		L15.8,9.7z M20.8,7.8C20.9,7.9,21,8,21.1,8c0.1,0.1,0.2,0.2,0.3,0.3l-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.3,0,0.7-0.3,0.8
		c-0.3,0.1-0.6-0.1-0.8-0.3c-0.3-0.3-0.4-0.7-0.2-1C20.3,7.8,20.6,7.8,20.8,7.8c0.1,0.1,0.1,0.1,0.1,0.1L20.8,7.8z M38.9,14.9
		c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8
		c0.2-0.4,0.6-0.6,1-0.5C38.3,14.3,38.7,14.5,38.9,14.9z M39.1,9.9c0.4-0.2,0.8-0.3,1.2-0.3c0,0,0.1,0,0.1,0c0.3,0,0.7,0.1,0.7,0.3
		C41.3,11.3,37.7,10.7,39.1,9.9z M54.1,9.7c0.1,0.6,0.1,1.3,0,1.9c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.3,0.1-0.7,0.4-0.9c0.3-0.2,0.7-0.2,0.8,0.1L54.1,9.7z M59.1,7.8
		C59.2,7.9,59.3,8,59.4,8c0.1,0.1,0.2,0.2,0.3,0.3l-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.3,0,0.7-0.3,0.8
		c-0.3,0.1-0.6-0.1-0.8-0.3c-0.3-0.3-0.4-0.7-0.2-1C58.6,7.8,58.9,7.8,59.1,7.8c0.1,0.1,0.1,0.1,0.1,0.1L59.1,7.8z M68.7,36
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.3,0.2,0.5c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.1,0-0.1,0.1-0.2,0.1
		c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.3-0.1-0.7,0-1c0.1-0.2,0.2-0.3,0.3-0.5C67.9,35.9,68.4,35.8,68.7,36z
		 M68,34.3c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.1-0.5,0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.1
		C67.8,34.6,67.8,34.4,68,34.3z M67.2,39.6c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.5,0.2-0.6,0.1
		c-0.8-0.7-0.6-2.6,0.7-2.4C66.9,38.1,68,39,67.2,39.6z M67.2,54.5c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.1-0.2-0.3-0.2-0.4
		c-1-1.9,0.3-2.3,1.5-1c0.3,0.4,0.9,1,0.8,1.5C68.6,54.7,67.8,54.9,67.2,54.5z M68,56.2c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.2,0.1-0.5,0.2-0.6,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0c-0.1-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.7,0.4-0.9
		c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.5,0.2C68.2,55.9,68.1,56,68,56.2z M53.1,63.5c-0.2,0.2-0.5,0.3-0.7,0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.2-0.3-0.6c0-0.4,0.3-0.8,0.6-1c0.2-0.1,0.4-0.1,0.5,0.1c0.2,0.1,0.2,0.3,0.2,0.5
		C53.2,62.8,53.3,63.2,53.1,63.5z M51.4,58.8c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9
		c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.8-0.2,1.6-0.6,2.2c-0.2,0.3-0.5,0.7-0.8,0.7C52,59.2,51.7,59,51.4,58.8z
		 M51.5,61.9c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6
		c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.6,1.3-0.9c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1
		C51.4,61.3,51.5,61.6,51.5,61.9z M48.9,47.8c0.4-0.4,1-0.6,1.5-0.8c0.2,0,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.2,0.5
		c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8L48.9,47.8z M45.6,60.1
		c0.2-0.2,0.6-0.3,0.8-0.1c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1l0,0.1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2C45.5,60.3,45.5,60.2,45.6,60.1z M45.9,59.2c-0.5-0.1-0.8-0.6-1-1.1
		c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3
		c0.4,0.1,1.3-0.1,0.8,0.8C47.1,59.2,46.4,59.3,45.9,59.2z M33.7,61.9c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.2,0.1-0.3
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1v0c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0.2,0.8,0.4c0.1,0.1,0.2,0.2,0.2,0.4
		c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.5c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.4-0.5v0
		C33.7,62,33.7,62,33.7,61.9z M34.8,60.4c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.1
		c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0,0.3-0.1,0.4C35.1,60.5,35,60.5,34.8,60.4z M35,58.8
		c-0.2-0.5,0.2-1.8,0.9-1.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.3,0.3,0.5C37,59.3,35.4,59.9,35,58.8z
		 M14.8,63.5c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.2-0.3-0.6c0-0.4,0.3-0.8,0.6-1
		c0.2-0.1,0.4-0.1,0.5,0.1c0.2,0.1,0.2,0.3,0.2,0.5C14.9,62.8,15,63.2,14.8,63.5z M13.1,58.8c-0.2-0.2-0.4-0.5-0.7-0.7
		c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0
		c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.8-0.2,1.6-0.6,2.2
		c-0.2,0.3-0.5,0.7-0.8,0.7C13.7,59.2,13.4,59,13.1,58.8z M13.2,61.9c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.6,1.3-0.9
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1C13.1,61.3,13.3,61.6,13.2,61.9z M10.6,47.8c0.4-0.4,1-0.6,1.5-0.8c0.2,0,0.4-0.1,0.5,0
		c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8
		L10.6,47.8z M7.3,60.1c0.2-0.2,0.6-0.3,0.8-0.1c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5
		c0,0,0-0.1,0-0.1l0,0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2C7.2,60.3,7.2,60.2,7.3,60.1z M7.6,59.2
		c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1
		c0.2,0,0.4,0.1,0.4,0.3c0.4,0.1,1.3-0.1,0.8,0.8C8.9,59.2,8.1,59.3,7.6,59.2z M5,48.4c0.3-0.2,0.8-0.1,1.1,0.2
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.4,0.3-0.9,0.4-1.4,0.3l0.1,0C4.4,49,4.6,48.6,5,48.4z M5.3,50.8
		c0.2-0.4,0.7-0.7,1.1-0.5c0.4,0.2,0.5,0.7,0.4,1.1c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0
		C4.6,52.8,4.7,51.9,5.3,50.8z M4.4,33c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.3,0c0.2,0.1,0.2,0.3,0.2,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.3-0.1C4.4,33.2,4.4,33.1,4.4,33L4.4,33z M4.9,35.1L4.9,35.1c0.1-0.3,0.2-0.7,0.4-1
		c0.2-0.4,0.7-0.7,1.1-0.5c0.4,0.2,0.5,0.7,0.4,1.1c0,0.1-0.1,0.3-0.1,0.4h0c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2,0,0.4-0.2,0.5
		c-0.1,0.1-0.3,0-0.4,0C5,36.3,4.8,35.8,4.9,35.1z M5.3,26.7c0.2-0.4,0.7-0.7,1.1-0.5c0.4,0.2,0.5,0.7,0.4,1.1
		c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0C4.6,28.8,4.7,27.8,5.3,26.7z M4.7,25.4c-0.2,0-0.4,0-0.4-0.2
		c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6
		c0.1,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7C5,25.2,4.8,25.3,4.7,25.4z M7.3,20.8c0.2-0.2,0.6-0.3,0.8-0.1
		c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1l0,0.1c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.3,0.1-0.4l0,0.2C7.2,21,7.2,20.9,7.3,20.8z M7.4,23.3c0.3-0.3,0.7-0.5,1.1-0.7c0.1,0,0.2-0.1,0.3-0.1
		c0.2,0,0.3,0.1,0.4,0.2c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.7,0.7-1.2,0.8c-0.5,0.1-1-0.1-1.4-0.4
		C7.2,25.1,7,24.8,7,24.5C6.9,24,7.1,23.6,7.4,23.3z M45.6,20.8c0.2-0.2,0.6-0.3,0.8-0.1c0.2,0.2,0.2,0.5,0.1,0.8
		c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1l0,0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2
		C45.5,21,45.5,20.9,45.6,20.8z M45.7,23.3c0.3-0.3,0.7-0.5,1.1-0.7c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.2
		c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.7,0.7-1.2,0.8c-0.5,0.1-1-0.1-1.4-0.4c-0.2-0.2-0.4-0.5-0.4-0.8
		C45.2,24,45.4,23.6,45.7,23.3z M64.5,31.7c-0.5,0.1-0.8-0.4-0.8-0.9c0-0.5,0.3-0.9,0.5-1.3c0.1-0.1,0.2-0.3,0.3-0.3
		c0.1,0,0.2,0,0.3,0l-0.1-0.1c0.4,0.2,0.6,0.5,0.7,1c0.1,0.4,0,0.8-0.3,1.2C64.9,31.5,64.7,31.7,64.5,31.7z M64.8,32.6
		c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.2,0.6-0.2
		c0,0,0.1,0,0.1,0c0,0,0,0.1,0.1,0.1C64.8,32.5,64.8,32.6,64.8,32.6z M65,27.8c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3
		c-0.3,0-0.5-0.1-0.7-0.2c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7C65.1,27.2,65.1,27.5,65,27.8z M64.4,24
		c-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.1-1.6,0.1-2.4-0.1c-0.3-0.1-0.7-0.3-0.8-0.6c0-0.1,0-0.3,0.1-0.4c0.2-0.4,0.6-0.6,1-0.7
		c0.4-0.1,0.8-0.1,1.3-0.2c0.3,0,0.5-0.1,0.8-0.1c0.4,0,0.8,0.2,1,0.6C64.7,23.2,64.7,23.7,64.4,24z M63.8,20.4
		c-0.1,0.4-0.6,0.5-0.9,0.5c-0.7,0.1-1.4,0.2-2.1,0.2c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.6,0.4-1.1,0.9-1.3
		c0.5-0.3,1.1-0.3,1.6-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5C63.8,19.8,63.9,20.1,63.8,20.4z M60.4,25.7
		c0.4-0.3,0.9-0.3,1.3-0.1c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1
		c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.2-0.5-0.1-0.7C60.2,25.9,60.3,25.8,60.4,25.7z M60.7,28.3c0.5-0.1,1,0.2,1.5,0.5
		c0.2,0.2,0.5,0.3,0.7,0.6c0.4,0.6,0.2,1.4-0.1,2c-0.1,0.3-0.2,0.6-0.5,0.7c-0.2,0.1-0.5,0.2-0.7,0.1c-1-0.1-1.8-0.8-2.4-1.7
		c-0.2-0.4-0.4-0.8-0.2-1.2c0.1-0.2,0.3-0.4,0.6-0.6C60,28.6,60.3,28.4,60.7,28.3z M64.1,37.8c0.4,0.2,0.6,0.5,0.7,1
		c0.1,0.4,0,0.8-0.3,1.2c-0.1,0.2-0.3,0.4-0.6,0.5c-0.5,0.1-0.8-0.4-0.8-0.9c0-0.5,0.3-0.9,0.5-1.3c0.1-0.1,0.2-0.3,0.3-0.3
		C64,37.9,64.1,37.9,64.1,37.8L64.1,37.8z M65.7,42.9c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.3
		c0.1-0.4,0.4-1.6,0.9-1.7C65,41.4,65.5,42.6,65.7,42.9z M63.2,41.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
		c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.3,0.9-0.3,1.3-0.1
		c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0C63,41.3,63.2,41.5,63.2,41.8z M49.5,39.4c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0
		c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.8-0.2,1.6-0.6,2.2
		c-0.2,0.3-0.5,0.7-0.8,0.7c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9
		C49.3,39.8,49.3,39.6,49.5,39.4z M48.9,36.3c0-0.5,0.1-0.8,0.4-1.1h0c0.6-0.8,1.8-0.9,2.2,0h0c0.1,0.2,0.2,0.6,0.2,1
		C51.6,37.8,49,38.1,48.9,36.3z M57.8,41.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9
		c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.1,0.8,0c0.6,0.2,1.2,0.5,1.4,1c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.5,1
		c-0.2,0.1-0.4,0.1-0.6,0c-0.1-0.1-0.2-0.1-0.3-0.2C58.5,41.9,58.2,41.6,57.8,41.3z M58.7,45c-0.1,0.4-0.6,0.5-0.9,0.4
		c-0.4-0.1-0.6-0.5-0.7-0.8c-0.1-0.4-0.2-0.7-0.3-1.1c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0.1
		c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.3,0.3,0.7,0.3,1.1C58.7,44.6,58.8,44.8,58.7,45z M58.5,34.6
		c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.2,0.9-0.4,1.3-0.6c0.4-0.2,0.8-0.4,1.2-0.3
		c0.3,0,0.6,0.1,0.8,0.3c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.5,0.2-0.8,0.1
		s-0.5-0.2-0.8-0.2C59.7,34.3,59.1,34.8,58.5,34.6z M59.1,32.9c-0.2,0.3-0.5,0.4-0.8,0.5c-0.3,0.1-0.5,0.2-0.8,0.3
		c-0.1,0-0.3,0.1-0.4,0c-0.2,0-0.3-0.2-0.3-0.3c-0.2-0.7,0.2-1.5,1-1.6C58.4,31.7,59.7,32,59.1,32.9z M57.2,35.5
		c0.3-0.1,0.6-0.1,0.8,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.4,0.1,0.5c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5
		c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.7-0.8c-0.1-0.2,0-0.5-0.1-0.8c0-0.1-0.1-0.3-0.1-0.4C56.4,36.1,56.8,35.7,57.2,35.5z
		 M56.5,39.3c0.4,0.3,0.3,0.9,0.1,1.4c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1c-0.3-0.4-0.1-0.9,0-1.3
		C55,39.6,55.6,38.5,56.5,39.3z M55.7,37c0.2,0.5,0.1,1.2-0.3,1.5c-1,0.6-2.9-0.9-2.7-2C53,34.7,55.3,35.9,55.7,37z M53.9,33
		c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4-0.1,0.6,0c0.2,0,0.4,0.2,0.6,0.3c0.3,0.2,0.5,0.4,0.5,0.8
		c0,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0-0.4,0-0.6-0.1c-0.1-0.1-0.1-0.1-0.1-0.2C54.1,33.4,54,33.2,53.9,33z
		 M54.7,42.7c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.5-0.1,1.1-0.4,1.5c-1,1.3-2.6-1.5-0.3-2C54.2,42.4,54.6,42.4,54.7,42.7z M56.3,35.1
		C56.3,35.1,56.3,35.1,56.3,35.1L56.3,35.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.2,0.2-0.3,0.4-0.4c0,0,0.1,0,0.1,0
		C56.4,34,56.6,35,56.3,35.1z M55.8,31.4c-1,0.6-2.9-0.9-2.7-2c0.4-1.8,2.6-0.6,3,0.5C56.3,30.4,56.3,31.1,55.8,31.4z M53.3,33.6
		c0.2,0,0.3,0.1,0.4,0.2c0.1,0.2,0.1,0.4,0,0.6s-0.4,0.3-0.6,0.2c-0.3,0-0.5-0.2-0.7-0.4c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.1,0.1-0.1,0.1-0.1C52.7,33.6,53,33.6,53.3,33.6z M50.8,44.3c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1
		c0.2,0.3,0.4,0.6,0.3,0.9c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6
		c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2C50,44.9,50.4,44.6,50.8,44.3z M51.9,49.1c0.1,0.2,0.1,0.4,0,0.6
		c0,0.2-0.1,0.3-0.2,0.4c-0.3,0.3-0.8,0.1-1.2,0.2c-0.5,0.1-0.9,0.5-1.3,0.3c-0.3-0.1-0.4-0.6-0.3-0.9c0.1-0.3,0.4-0.6,0.7-0.7
		c0.4-0.2,0.8-0.3,1.2-0.3C51.3,48.7,51.7,48.8,51.9,49.1z M51.9,46.2c0-0.4,0.3-0.8,0.6-1l-0.2,0.2c0.4-0.2,0.8,0.2,0.8,0.6
		c0,0.4-0.4,0.8-0.8,0.8c-0.1,0-0.3,0-0.4-0.1C51.9,46.5,51.9,46.4,51.9,46.2z M52.9,50c0-0.3,0.1-0.7,0.4-0.9
		c0.3-0.2,0.7-0.2,0.8,0.1l0-0.1c0.1,0.6,0.1,1.3,0,1.9c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.1-0.1-0.2
		C52.9,50.9,52.9,50.5,52.9,50z M53.3,53c0.1-0.1,0.1-0.3,0.3-0.3c0.2-0.1,0.3,0,0.5,0.1c0.4,0.2,0.6,0.7,0.6,1.1l-0.1-0.3
		c0,0.4-0.6,0.7-1,0.5C53.3,54,53.1,53.4,53.3,53z M54.5,51c0-0.2,0-0.3,0.1-0.4c0.2-0.3,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.7,0.3
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.6,0.1,1.2,0.2,1.8c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.2,0.1c-0.4,0-0.8,0.1-1.1-0.1
		c-0.2-0.1-0.3-0.4-0.4-0.6C54.6,51.9,54.5,51.4,54.5,51z M56.6,56.8c-0.1,0.3-0.3,0.6-0.6,0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0
		c-0.5-0.3-1-0.6-1.2-1.1C53.7,54.5,57.2,54.3,56.6,56.8z M55,49c-0.2-0.1-0.3-0.3-0.4-0.5c-0.3-0.5-0.3-1.1-0.2-1.6
		c0.2-0.5,0.6-1,1-1.2c0.3-0.1,0.7-0.2,1,0c0.4,0.2,0.7,0.6,0.9,1.1c0.1,0.5,0.1,0.9,0.1,1.4c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.2-0.3,0.3-0.4,0.3C56.3,49.1,55.6,49.4,55,49z M56.9,50.6c0.1-0.3,0.3-0.5,0.6-0.6c1.8-1,3.5,2.8,1.4,2.8
		c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.3-0.4-0.4-0.7C56.8,51,56.8,50.8,56.9,50.6z M59.5,48.7
		c-0.3,0.1-0.6-0.1-0.8-0.3c-0.3-0.3-0.4-0.7-0.2-1c0.2-0.2,0.4-0.2,0.7-0.1c0,0,0.1,0,0.1,0l-0.1-0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3l-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3C59.9,48.2,59.8,48.6,59.5,48.7z M59.4,45.8c-0.2-0.5-0.2-1.1-0.2-1.7
		c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0,0.4,0.1,0.6,0.2c0.4,0.3,0.5,0.8,0.4,1.2
		c-0.1,0.5-0.3,0.9-0.4,1.3c-0.1,0.2-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.1-0.6,0C59.8,46.8,59.5,46.3,59.4,45.8z M60.5,48.7
		c0.3-0.4,1-0.5,1.5-0.5c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.2-0.3,0.4-0.5,0.2l0-0.1c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2-0.1-0.4-0.2-0.5-0.4C60.2,49.3,60.2,48.9,60.5,48.7z M62.2,50.9
		c0.1-0.2,0.3-0.4,0.5-0.5c2.2-1,2.9,4.8-0.1,2.7c-0.4-0.3-0.6-0.7-0.6-1.2C62,51.5,62,51.2,62.2,50.9z M62.8,38.8
		c-0.1,0.3-0.2,0.6-0.5,0.7c-0.2,0.1-0.5,0.2-0.7,0.1c-1-0.1-1.8-0.8-2.4-1.7c-0.2-0.4-0.4-0.8-0.2-1.2c0.1-0.2,0.3-0.4,0.6-0.6
		c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.1,1,0.2,1.5,0.5c0.2,0.2,0.5,0.3,0.7,0.6C63.2,37.4,63,38.2,62.8,38.8z M60,25.4
		c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.2-1-0.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.6
		c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8,0.1c0.3,0.2,0.4,0.6,0.5,0.9C60,25.1,60.1,25.3,60,25.4z
		 M59,23.2c-0.2,0.3-0.6,0.5-0.9,0.5c-0.2,0-0.3-0.1-0.5-0.2c-0.7-0.6-0.2-1.3,0.5-1.6C59,21.4,59.5,22.4,59,23.2z M58.1,28.1
		c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.4,0.1,0.5c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5c-0.1,0-0.2-0.1-0.3-0.2
		c-0.3-0.2-0.6-0.4-0.7-0.8c-0.1-0.2,0-0.5-0.1-0.8c0-0.1-0.1-0.3-0.1-0.4c0-0.5,0.3-1,0.8-1.1C57.5,28,57.8,28,58.1,28.1z
		 M56.8,27.6c-0.2,0.3-0.5,0.4-0.8,0.3c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0
		c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4C57,27.2,56.9,27.4,56.8,27.6z M56.6,24
		c0,0,0,0.1,0,0.1c-0.2,0.6-0.8,1.1-1.5,1.1c-1.8-0.1-0.9-3.5,0.6-3C56.3,22.5,56.7,23.3,56.6,24z M54.7,20.3
		C54.7,20.3,54.7,20.3,54.7,20.3c0,0.5-0.1,1.1-0.4,1.5c-1,1.3-2.6-1.5-0.3-2c0.3-0.1,0.6,0,0.7,0.2C54.7,20.1,54.7,20.2,54.7,20.3z
		 M53,22.6c0.2,0.1,0.2,0.3,0.2,0.5c0,0.4,0.1,0.8-0.2,1.1c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		c-0.2-0.2-0.4-0.2-0.3-0.6c0-0.4,0.3-0.8,0.6-1C52.7,22.4,52.9,22.4,53,22.6z M52.5,25.1c0.6,0.2,1.2,0.5,1.4,1
		c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.5,1c-0.2,0.1-0.4,0.1-0.6,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-0.6-1.1-0.9
		c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.3,0.2-0.4C51.8,25,52.2,25,52.5,25.1z M51.7,28.7
		c-0.1,1.7-2.7,2-2.7,0.2C48.9,26.9,51.8,26.3,51.7,28.7z M51.5,22.6c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7
		C50.1,23.9,50,24,49.8,24c-0.3,0-0.4-0.4-0.5-0.6c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.6,1.3-0.9
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1C51.4,21.9,51.5,22.2,51.5,22.6z M50.5,24.3c0.2,0,0.4-0.1,0.5,0
		c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8
		L48.9,25C49.4,24.7,49.9,24.4,50.5,24.3z M49.5,31c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.1,1.2-0.2,1.7-0.3c0.3-0.1,0.8-0.1,1,0.2
		c0.1,0.2,0.1,0.4,0.1,0.6c0,0.6-0.1,1.2-0.4,1.7c-0.4,0.4-0.9,0.6-1.5,0.7c-0.5,0.1-1.1,0.2-1.6,0.5c-0.2,0.1-0.3,0.2-0.5,0.2
		c-0.3,0-0.4-0.3-0.5-0.5c-0.2-0.7,0.1-1.6,0.5-2.2C49.1,31.4,49.3,31.1,49.5,31z M43.3,48.4c0.3-0.2,0.8-0.1,1.1,0.2
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.4,0.3-0.9,0.4-1.4,0.3l0.1,0C42.7,49,42.9,48.6,43.3,48.4z M44.1,47.1
		c-0.2,0.3-0.5,0.5-0.8,0.7c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4
		c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6C44.5,46.5,44.4,46.9,44.1,47.1z M44.1,44.7
		c-0.4,0.4-0.8,0.6-1.3,0.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.4,0.4-0.7,0.8-0.9c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5C44.7,43.9,44.4,44.4,44.1,44.7z
		 M32.9,44.3c-0.2-0.5-0.1-1.1,0.4-1.4c0.2-0.1,0.4-0.2,0.6-0.2l0,0c0.2,0,0.4,0,0.6,0.1c0.4,0.2,0.6,0.6,0.7,1
		c0.1,0.4,0,0.9-0.2,1.3c-0.1,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1-0.1v0C33.4,45.1,33,44.7,32.9,44.3z
		 M31.6,58.5c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.4,0.3-0.5,0.1c0,0-0.1-0.1-0.1-0.2c-0.2-0.7-0.3-1.4-0.3-2
		c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.5-0.2,0.7-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.5
		c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.4,0.5-0.7,0.6C31.5,58.3,31.5,58.4,31.6,58.5z M11.2,39.4c0.1-0.2,0.3-0.3,0.5-0.3
		c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4
		c0.2,0.8-0.2,1.6-0.6,2.2c-0.2,0.3-0.5,0.7-0.8,0.7c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6
		c-0.3-0.2-0.5-0.5-0.6-0.9C11,39.8,11,39.6,11.2,39.4z M10.7,36.3c0-0.5,0.1-0.8,0.4-1.1h0c0.6-0.8,1.8-0.9,2.2,0h0
		c0.1,0.2,0.2,0.6,0.2,1C13.3,37.8,10.7,38.1,10.7,36.3z M26.2,31.7c-0.5,0.1-0.8-0.4-0.8-0.9c0-0.5,0.3-0.9,0.5-1.3
		c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.2,0,0.3,0l-0.1-0.1c0.4,0.2,0.6,0.5,0.7,1c0.1,0.4,0,0.8-0.3,1.2C26.6,31.5,26.4,31.7,26.2,31.7z
		 M26.5,32.6c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.1,0.4-0.2,0.6-0.2c0,0,0.1,0,0.1,0c0,0,0,0.1,0.1,0.1C26.5,32.5,26.5,32.6,26.5,32.6z M26.7,27.8c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7
		C26.8,27.2,26.8,27.5,26.7,27.8z M25,34.1C25,34.1,25,34,25,34.1c0.8-0.4,1.7-0.8,2.5-0.5c0.3,0.1,0.5,0.2,0.8,0.3
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.1,0.5,0,0.7h0c-0.1,0.5-0.3,1.1-0.6,1.5c-0.2,0.3-0.5,0.7-0.8,0.7
		c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.4-0.5-0.7h0c0-0.1,0-0.1,0-0.2
		c0-0.2,0-0.4,0.1-0.6C24.6,34.2,24.8,34.1,25,34.1z M28.4,29.5c0.6,0.1,1.6,1.1,0.8,1.6c-0.1,0.1-0.2,0.1-0.4,0.2
		c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.5,0.2-0.6,0.1C26.9,31.2,27.1,29.3,28.4,29.5z M27.5,27.7c0.1-0.3,0.4-0.6,0.7-0.7
		c0.3-0.1,0.7,0.1,0.8,0.4c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.3-0.3,0.5-0.5,0.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.2-0.5C27.4,28.1,27.4,27.9,27.5,27.7z M32.4,28.2c-0.6-0.2-1-0.9-0.9-1.5
		c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.4,0.8,0.4,1.2
		c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2C32.5,28.3,32.5,28.2,32.4,28.2z M32.4,30.4c0,0.2-0.2,0.4-0.3,0.6
		c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.3-0.2-0.7-0.1-1.1c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0,0,0,0,0,0
		c0.1,0,0.2,0,0.2,0.1C32.4,30,32.4,30.2,32.4,30.4z M31.6,28.6c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.3
		c0.1-0.4,0.4-1.6,0.9-1.7C30.9,27.1,31.4,28.3,31.6,28.6z M30.1,31.3c0.3-0.1,0.7-0.1,1,0.1c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.2,0.2,0.4,0.1,0.6c0,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.3
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.1-0.6c0-0.1,0.1-0.2,0.2-0.4C29.5,31.7,29.7,31.5,30.1,31.3z M28.6,42.6
		c0-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6,0-0.8c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0,0.6,0.2,0.8,0.4c0.4,0.2,0.7,0.5,1.1,0.7
		c0.2,0.2,0.5,0.3,0.7,0.6c0.2,0.4,0.2,0.9-0.1,1.3c-0.3,0.4-0.7,0.6-1.1,0.6c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0
		c-0.2-0.1-0.3-0.4-0.4-0.7C28.6,43.1,28.7,42.8,28.6,42.6z M24.5,41.1L24.5,41.1C24.5,41.1,24.5,41.1,24.5,41.1
		c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7
		c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.1,0.2-0.3,0.3-0.3C23.6,41,24.1,41,24.5,41.1C24.5,41.1,24.5,41.1,24.5,41.1z M24.9,39.5
		c0-0.5,0.3-0.9,0.5-1.3c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.2,0,0.3,0l-0.1-0.1c0.4,0.2,0.6,0.5,0.7,1c0.1,0.4,0,0.8-0.3,1.2
		c-0.1,0.2-0.3,0.4-0.6,0.5C25.2,40.5,24.9,40,24.9,39.5z M25.4,43.1c0.1-0.4,0.4-1.6,0.9-1.7c0.4,0,0.9,1.2,1.1,1.5
		c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4C25.3,43.3,25.4,43.2,25.4,43.1z M27.4,40.3c-0.8-0.7-0.6-2.6,0.7-2.4
		c0.6,0.1,1.6,1.1,0.8,1.6c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5C27.8,40.4,27.6,40.5,27.4,40.3z M29.4,37.2
		c0.2-0.7,0.9-1.3,1.6-1.3c1.1-0.1,1.5,1,1.4,1.9c-0.1,1.3-0.9,1.9-2,1.2C29.7,38.5,29.2,38,29.4,37.2z M29.7,34.3
		c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.1-0.5,0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.1
		C29.5,34.6,29.6,34.4,29.7,34.3z M24.6,36.8c0.4,0.6,0.2,1.4-0.1,2c-0.1,0.3-0.2,0.6-0.5,0.7c-0.2,0.1-0.5,0.2-0.7,0.1
		c-1-0.1-1.8-0.8-2.4-1.7c-0.2-0.4-0.4-0.8-0.2-1.2c0.1-0.2,0.3-0.4,0.6-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.1,1,0.2,1.5,0.5
		C24.1,36.4,24.4,36.6,24.6,36.8z M19.5,41.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9
		c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.1,0.8,0c0.6,0.2,1.2,0.5,1.4,1c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.5,1
		c-0.2,0.1-0.4,0.1-0.6,0c-0.1-0.1-0.2-0.1-0.3-0.2C20.2,41.9,19.9,41.6,19.5,41.3z M20.4,45c-0.1,0.4-0.6,0.5-0.9,0.4
		c-0.4-0.1-0.6-0.5-0.7-0.8c-0.1-0.4-0.2-0.7-0.3-1.1c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0.1
		c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.3,0.3,0.7,0.3,1.1C20.4,44.6,20.5,44.8,20.4,45z M23.6,34.4
		c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3,0-0.5-0.2-0.8-0.2c-0.6,0-1.3,0.4-1.9,0.3c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3,0-0.4
		c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.2,0.9-0.4,1.3-0.6c0.4-0.2,0.8-0.4,1.2-0.3c0.3,0,0.6,0.1,0.8,0.3c0.1,0.1,0.2,0.1,0.2,0.2
		c0,0.1,0.1,0.2,0.1,0.3C23.9,34,23.8,34.2,23.6,34.4z M20.8,32.9c-0.2,0.3-0.5,0.4-0.8,0.5c-0.3,0.1-0.5,0.2-0.8,0.3
		c-0.1,0-0.3,0.1-0.4,0c-0.2,0-0.3-0.2-0.3-0.3c-0.2-0.7,0.2-1.5,1-1.6C20.1,31.7,21.4,32,20.8,32.9z M18.9,35.5
		c0.3-0.1,0.6-0.1,0.8,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.4,0.1,0.5c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5
		c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.7-0.8c-0.1-0.2,0-0.5-0.1-0.8c0-0.1-0.1-0.3-0.1-0.4C18.1,36.1,18.5,35.7,18.9,35.5z
		 M18.2,39.3c0.4,0.3,0.3,0.9,0.1,1.4c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1c-0.3-0.4-0.1-0.9,0-1.3
		C16.7,39.6,17.3,38.5,18.2,39.3z M17.4,37c0.2,0.5,0.1,1.2-0.3,1.5c-1,0.6-2.9-0.9-2.7-2C14.7,34.7,17,35.9,17.4,37z M15.6,33
		c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4-0.1,0.6,0c0.2,0,0.4,0.2,0.6,0.3c0.3,0.2,0.5,0.4,0.5,0.8
		c0,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0-0.4,0-0.6-0.1c-0.1-0.1-0.1-0.1-0.1-0.2C15.8,33.4,15.7,33.2,15.6,33z
		 M16.4,42.7c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.5-0.1,1.1-0.4,1.5c-1,1.3-2.6-1.5-0.3-2C15.9,42.4,16.3,42.4,16.4,42.7z M18,35.1
		C18,35.1,18,35.1,18,35.1L18,35.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.2,0.2-0.3,0.4-0.4c0,0,0.1,0,0.1,0
		C18.1,34,18.3,35,18,35.1z M17.5,31.4c-1,0.6-2.9-0.9-2.7-2c0.4-1.8,2.6-0.6,3,0.5C18,30.4,18,31.1,17.5,31.4z M15,33.6
		c0.2,0,0.3,0.1,0.4,0.2c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.4,0.3-0.6,0.2c-0.3,0-0.5-0.2-0.7-0.4C14,34.2,14,34.1,14,34
		c0-0.1,0.1-0.1,0.1-0.1C14.4,33.6,14.7,33.6,15,33.6z M12.5,44.3c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1
		c0.2,0.3,0.4,0.6,0.3,0.9c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6
		c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2C11.7,44.9,12.1,44.6,12.5,44.3z M13.6,49.1c0.1,0.2,0.1,0.4,0,0.6
		c0,0.2-0.1,0.3-0.2,0.4c-0.3,0.3-0.8,0.1-1.2,0.2c-0.5,0.1-0.9,0.5-1.3,0.3c-0.3-0.1-0.4-0.6-0.3-0.9c0.1-0.3,0.4-0.6,0.7-0.7
		c0.4-0.2,0.8-0.3,1.2-0.3C13,48.7,13.4,48.8,13.6,49.1z M13.6,46.2c0-0.4,0.3-0.8,0.6-1L14,45.4c0.4-0.2,0.8,0.2,0.8,0.6
		c0,0.4-0.4,0.8-0.8,0.8c-0.1,0-0.3,0-0.4-0.1C13.6,46.5,13.6,46.4,13.6,46.2z M14.6,50c0-0.3,0.1-0.7,0.4-0.9
		c0.3-0.2,0.7-0.2,0.8,0.1l0-0.1c0.1,0.6,0.1,1.3,0,1.9c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.1-0.1-0.2
		C14.6,50.9,14.6,50.5,14.6,50z M15,53c0.1-0.1,0.1-0.3,0.3-0.3c0.2-0.1,0.3,0,0.5,0.1c0.4,0.2,0.6,0.7,0.6,1.1l-0.1-0.3
		c0,0.4-0.6,0.7-1,0.5C15,54,14.8,53.4,15,53z M16.2,51c0-0.2,0-0.3,0.1-0.4c0.2-0.3,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.7,0.3
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.6,0.1,1.2,0.2,1.8c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.2,0.1c-0.4,0-0.8,0.1-1.1-0.1
		c-0.2-0.1-0.3-0.4-0.4-0.6C16.3,51.9,16.2,51.4,16.2,51z M18.3,56.8c-0.1,0.3-0.3,0.6-0.6,0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0
		c-0.5-0.3-1-0.6-1.2-1.1C15.4,54.5,18.9,54.3,18.3,56.8z M16.7,49c-0.2-0.1-0.3-0.3-0.4-0.5C16,48,16,47.4,16.1,46.9s0.6-1,1-1.2
		c0.3-0.1,0.7-0.2,1,0c0.4,0.2,0.7,0.6,0.9,1.1c0.1,0.5,0.1,0.9,0.1,1.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.4,0.3
		C18,49.1,17.3,49.4,16.7,49z M18.6,50.6c0.1-0.3,0.3-0.5,0.6-0.6c1.8-1,3.5,2.8,1.4,2.8c-0.3,0-0.5-0.1-0.8-0.2
		c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.3-0.4-0.4-0.7C18.5,51,18.5,50.8,18.6,50.6z M21.2,48.7c-0.3,0.1-0.6-0.1-0.8-0.3
		c-0.3-0.3-0.4-0.7-0.2-1c0.2-0.2,0.4-0.2,0.7-0.1c0,0,0.1,0,0.1,0l-0.1-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
		l-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3C21.6,48.2,21.5,48.6,21.2,48.7z M21.1,45.8c-0.2-0.5-0.2-1.1-0.2-1.7c0-0.1,0-0.3,0.1-0.4
		c0.1-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0,0.4,0.1,0.6,0.2c0.4,0.3,0.5,0.8,0.4,1.2c-0.1,0.5-0.3,0.9-0.4,1.3
		c-0.1,0.2-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.1-0.6,0C21.5,46.8,21.2,46.3,21.1,45.8z M22.2,48.7c0.3-0.4,1-0.5,1.5-0.5
		c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.5,0.2l0-0.1
		c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2-0.1-0.4-0.2-0.5-0.4C21.9,49.3,21.9,48.9,22.2,48.7z M23.9,50.9c0.1-0.2,0.3-0.4,0.5-0.5
		c2.2-1,2.9,4.8-0.1,2.7c-0.4-0.3-0.6-0.7-0.6-1.2C23.7,51.5,23.7,51.2,23.9,50.9z M25.7,49c-0.4-0.2-0.6-0.6-0.7-1.1
		c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.5,0.1-1.2,0.5-1.5c0.4-0.4,1-0.3,1.6-0.1c0.5,0.1,1,0.4,1.3,0.8c0.5,0.6,0.6,1.3,0.5,2.1
		c0,0.2,0,0.5-0.2,0.7c-0.3,0.5-0.9,0.7-1.5,0.7C26.7,49.3,26.2,49.3,25.7,49z M28.8,50c1-1.2,1.8-0.6,2.5,0.6
		c0.3,0.4,0.3,1-0.1,1.3c-0.4,0.3-0.9,0.2-1.2-0.1c-0.4-0.3-0.6-0.7-1-0.9c-0.2-0.1-0.4-0.3-0.4-0.5C28.6,50.3,28.7,50.1,28.8,50z
		 M32.2,54.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.6,0-0.5-1.1-0.3-1.4
		c0.2-0.3,0.9-1.2,1.2-0.5c0,0.1,0,0.3,0,0.5C32.3,53.9,32.3,54.2,32.2,54.5z M32.3,46.4c-0.1,1.3-0.9,1.9-2,1.2
		c-0.7-0.4-1.2-1-1-1.8c0.2-0.7,0.9-1.3,1.6-1.3C32,44.3,32.4,45.4,32.3,46.4z M31.7,41.4c-0.2-0.1-0.4-0.3-0.4-0.5
		c0-0.1-0.1-0.3-0.2-0.4c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		c0.3-0.1,0.6-0.1,0.9,0c0.3,0.1,0.6,0.3,0.7,0.6c0.1,0.3,0,0.7-0.2,0.9C32.2,41.4,32,41.5,31.7,41.4z M32.5,34.5
		c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.3
		c0.2-0.2,0.5-0.2,0.7-0.1c0.2,0.1,0.4,0.2,0.4,0.3c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.1,0.3-0.2,0.5C32.7,34.2,32.6,34.4,32.5,34.5z
		 M37.2,41.7c-0.2,1-1.8,1.6-2.2,0.5c-0.2-0.5,0.2-1.8,0.9-1.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2
		C37,41.3,37.2,41.5,37.2,41.7z M37.3,42.8c0.2,0,0.5,0.1,0.6,0.3c0.3,0.3,0,0.9-0.4,1.1c-0.4,0.2-0.8,0.2-1.2,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2,0-0.8,0.3-1C36.6,42.9,36.9,42.8,37.3,42.8z M33.1,39.9c-0.2-0.5-0.2-1.2,0.1-1.7
		c0.1-0.2,0.3-0.5,0.6-0.6v0c0.3-0.2,0.6-0.3,0.9-0.4c0.2,0,0.3,0,0.5,0c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.4,0.2,0.9,0.1,1.3
		c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.4-0.5,0.8c0,0.2-0.2,0.5-0.4,0.5
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.3v0C33.5,40.8,33.3,40.4,33.1,39.9z M34.3,33.5c0.3-0.5,1-0.7,1.5-0.5
		c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.6,0.1
		c-0.4,0-0.8-0.2-1-0.5C34.1,34.2,34.1,33.8,34.3,33.5z M34.4,31.5c0.2-0.2,0.6-0.1,0.9-0.1c0.2,0,0.5,0,0.6,0.2
		c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.3,0.6c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
		c-0.2-0.1-0.4-0.2-0.4-0.5S34.2,31.6,34.4,31.5z M36,37.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2-0.8-0.5-1.1-0.8
		c-0.1-0.3,0.1-0.7,0.4-0.8c0.2-0.1,0.5-0.1,0.7-0.1c0.7,0,1.4,0.2,2,0.4c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.3
		c-0.2,0.3-0.4,0.5-0.7,0.6C36.6,37,36.3,37.1,36,37.2z M37.3,34.5c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.3-0.5-0.5
		c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0c0.3,0.1,0.6,0.3,0.7,0.7
		c0.1,0.3,0.1,0.7,0,1c-0.1,0.1-0.1,0.2-0.2,0.3C37.7,34.5,37.5,34.5,37.3,34.5z M37.7,31.1c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.2-0.4-0.3c-0.1-0.2,0-0.3,0-0.5c0-0.2,0-0.5,0.1-0.7c0-0.2,0-0.3,0.1-0.5
		c0.1-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.2,0.6,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.4
		C38,31,37.9,31,37.7,31.1z M36.9,28.1c-0.2,1-1.8,1.6-2.2,0.5c-0.2-0.5,0.2-1.8,0.9-1.4c0.1,0.1,0.2,0.2,0.3,0.2
		c0.2,0.1,0.4,0.1,0.6,0.2C36.8,27.6,37,27.8,36.9,28.1z M34.8,21.8c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.2,0,0.4-0.1,0.6
		c-0.1,0.2-0.2,0.3-0.4,0.5c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.4-0.5v0c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1v0c0.1,0,0.2,0,0.2,0
		C34.3,21.3,34.6,21.5,34.8,21.8z M34.2,20.3c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.5,0c-0.2-0.1-0.3-0.1-0.4-0.3C34.3,20.7,34.2,20.5,34.2,20.3z
		 M33.6,24.2c0,0,0.1,0,0.1-0.1v0c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.5,0.3,0.6,0.6c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.2,0.1,0.5-0.1,0.7
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.1-0.6,0.3-1,0.4c0,0,0,0,0,0v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2
		c-0.1-0.3-0.2-0.7-0.3-1c0-0.3-0.1-0.5,0-0.8C33.1,24.7,33.4,24.4,33.6,24.2z M33.2,29.6c0.1-0.2,0.3-0.2,0.5-0.3v0
		c0.1,0,0.2,0,0.4,0c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.3,0.7c-0.1,0.3-0.4,0.4-0.6,0.4c-0.3,0-0.5,0-0.8,0c0,0-0.1,0-0.1,0
		v0c-0.2,0-0.4,0.1-0.6,0C33,30.9,33,30.6,33,30.4C33,30.1,33.1,29.9,33.2,29.6z M34.7,48.9c0.2-0.2,0.5-0.4,0.8-0.4
		c0.3,0,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.3,0,0.5c-0.1,0.4-0.2,0.7-0.4,1c-0.1,0.2-0.3,0.4-0.6,0.4
		c-0.3,0-0.6-0.2-0.8-0.5c-0.2-0.3-0.2-0.6-0.2-0.9C34.5,49.4,34.5,49.1,34.7,48.9z M36.9,50.5c0.5-0.8,1.2,0.4,1.4,0.9
		c0,0.2,0,0.4-0.1,0.5C38.1,52,38,52,37.9,52c-0.3,0.1-1.5,0.3-1.7,0C36,51.7,36.7,50.7,36.9,50.5z M38,48c-0.2,0.3-0.5,0.7-0.9,0.7
		c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8
		c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.3,0.3,0.4,0.5C38.7,46.2,38.5,47.3,38,48z M38.4,42.2c-0.2-0.3,0.6-1.2,0.7-1.5
		c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1C39.8,42.3,38.7,42.5,38.4,42.2z M40.2,34.2
		c1.1-0.5,2.1-0.2,2.2,1h0c0,0.1,0,0.2,0,0.4c-0.1,0.9-0.3,1.7-1.2,1.9c-0.8,0.2-1.7-0.2-2.1-0.9c-0.3-0.5-0.3-1-0.1-1.4h0
		C39.3,34.7,39.8,34.4,40.2,34.2z M39.1,32.3c0-0.4,0.1-0.8,0.4-1.1C39.7,31,40,31,40.3,31c0.5,0,1,0.1,1.2,0.4
		c0.2,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0-0.6,0.1-0.9,0.2
		c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C39.2,33,39.1,32.7,39.1,32.3z M43,29.9
		c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.4,0-0.6C42.5,30.2,42.8,30,43,29.9z M42.5,23.9
		c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6c0.1,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7
		c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7C42.5,24.2,42.5,24,42.5,23.9z M48,31.1
		c-0.2,0.3-0.6,0.4-0.9,0.4c-0.2,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.3-0.3-0.3-0.4C45.5,29.2,49.7,29,48,31.1z M45.8,27.5
		c0.1-0.4,0.4-1.1,0.9-1.4c0.6-0.3,1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.1-0.1,0.3-0.2,0.4C46.2,30,45.3,29,45.8,27.5z
		 M47.7,34.5c0,0.2-0.1,0.4-0.1,0.6h0c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-1,1.6-1.9,0.8-1.5-0.6h0
		c0-0.1,0-0.1,0.1-0.2c0.1-0.4,0.4-1.1,0.9-1.4C47.3,33.3,47.7,33.9,47.7,34.5z M46.2,40.9c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1
		c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.8,0.3c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3
		c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0C45.8,40.9,46,40.9,46.2,40.9z M45.6,43.5c0.2-0.2,0.6-0.3,0.8-0.1
		c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1l0,0.1c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.3,0.1-0.4l0,0.2C45.4,43.7,45.5,43.6,45.6,43.5z M45.1,34.7c0,0.1-0.1,0.3-0.1,0.4h0c-0.1,0.3-0.2,0.5-0.2,0.8
		c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0c-0.9-0.2-1.1-0.7-1-1.3h0c0.1-0.3,0.2-0.7,0.4-1c0.2-0.4,0.7-0.7,1.1-0.5
		C45.1,33.8,45.2,34.3,45.1,34.7z M42.7,33c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.3,0c0.2,0.1,0.2,0.3,0.2,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.3-0.1C42.7,33.2,42.7,33.1,42.7,33L42.7,33z M40.9,43.8c0,0.3-0.2,0.5-0.3,0.7
		c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0c0-0.2,0.2-0.5,0.4-0.6
		c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1C40.8,43.3,40.9,43.6,40.9,43.8z M39.1,49.3c0.4-0.2,0.8-0.3,1.2-0.3c0,0,0.1,0,0.1,0
		c0.3,0,0.7,0.1,0.7,0.3C41.3,50.7,37.7,50.1,39.1,49.3z M41.1,48.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.8,0.2
		c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7c-0.1-0.6-0.2-1.2,0.2-1.6c0.2-0.2,0.4-0.3,0.6-0.4c0.3-0.1,0.7-0.2,1,0
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.2C41.2,48,41.2,48.1,41.1,48.1z M41,40.1
		c-0.1-0.5-0.1-1,0.1-1.4c0.2-0.6,0.8-1,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4
		c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6c-0.4,0.2-0.9,0.4-1.4,0.2C41.3,41.1,41.1,40.6,41,40.1z M44.7,49.4
		c0-0.3,0.5-0.6,0.8-0.5c0.3-0.2,0.8-0.2,0.9,0.2c0.1,0.3-0.1,0.5-0.3,0.7C45.7,50.2,44.6,50.4,44.7,49.4z M45.8,51.6
		c0.2-0.4,0.4-1.1,0.9-1.4c0.6-0.3,1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.1-0.1,0.3-0.2,0.4C46.2,54,45.3,53,45.8,51.6z
		 M47,48.4c-0.5,0.1-1-0.1-1.4-0.4c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.1-0.7c0.1,0,0.2-0.1,0.3-0.1
		c0.2,0,0.3,0.1,0.4,0.2c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6C47.9,48,47.5,48.3,47,48.4z M46.9,40.2
		c-0.2,0-0.4-0.1-0.6-0.2c-1.7-1.4,2.6-4.4,2.2-1c-0.1,0.4-0.3,0.8-0.7,1C47.5,40.1,47.2,40.2,46.9,40.2z M46.8,32.4
		c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.1-0.6,0.2-0.8,0c-0.5-0.4-1.3-1.8-0.2-2
		C45.7,31.4,46.3,32.1,46.8,32.4z M45.1,27.3c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0
		c-1.4-0.3-1.2-1.2-0.6-2.3c0.2-0.4,0.7-0.7,1.1-0.5C45.1,26.4,45.2,26.9,45.1,27.3z M44.1,22c-0.4,0.4-0.8,0.6-1.3,0.8
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.4-0.5C42.1,22.2,42,22,42,21.8c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.4-0.7,0.8-0.9
		c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5C44.7,21.2,44.5,21.6,44.1,22z M42.4,28.1
		c-0.1,0.9-0.3,1.7-1.2,1.9c-0.8,0.2-1.7-0.2-2.1-0.9c-0.7-1.1,0.2-2,1.1-2.4C41.5,26.2,42.5,26.7,42.4,28.1z M38.9,37.6
		c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8
		c0.2-0.4,0.6-0.6,1-0.5S38.7,37.3,38.9,37.6z M38,25.3c-0.2,0.3-0.5,0.7-0.9,0.7c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7
		c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8c0.3,0,0.5-0.1,0.8,0.1c0.2,0.1,0.3,0.3,0.4,0.5
		C38.7,23.5,38.5,24.5,38,25.3z M37.6,21.5c-0.4,0.2-0.8,0.2-1.2,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0,0.1
		c0-0.4,0.2-0.7,0.5-1c0.3-0.2,0.7-0.3,1-0.3c0.2,0,0.5,0.1,0.6,0.3C38.2,20.7,37.9,21.3,37.6,21.5z M37.2,19
		c-0.2,1-1.8,1.6-2.2,0.5c-0.2-0.5,0.2-1.8,0.9-1.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2C37,18.6,37.2,18.8,37.2,19z
		 M36.2,14.6c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.3-0.1-0.6-0.2-0.9-0.4c-0.2-0.1-0.5-0.3-0.7-0.5
		c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.6,0.5-0.6c0.2,0,0.4,0,0.6,0.1c0.4,0.2,0.7,0.4,1,0.8c0.2,0.2,0.3,0.4,0.4,0.6
		C36.4,14.1,36.3,14.4,36.2,14.6z M34.7,15c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.5,0.1,1-0.1,1.5c-0.2,0.5-0.5,0.9-0.6,1.4
		c-0.1,0.2-0.1,0.5-0.3,0.7c-0.1,0.2-0.4,0.3-0.6,0.3v0c0,0,0,0,0,0c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.5-0.5-1.1-0.5-1.7
		c-0.1-0.6,0-1.2,0.4-1.7c0.2-0.2,0.4-0.4,0.6-0.5v0C34.1,14.9,34.4,14.9,34.7,15z M32.8,23.5c0,0.4,0,0.7-0.3,1s-0.6,0.4-0.9,0.2
		c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.4-0.1-0.8,0.1-1.2c0.2-0.3,0.7-0.5,1.1-0.3c0.1,0.1,0.2,0.1,0.2,0.2
		C32.8,23.3,32.8,23.4,32.8,23.5z M32.2,18.3c-0.1,0.3-0.4,0.5-0.7,0.6c-0.1,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3
		c-0.1,0.2-0.4,0.3-0.5,0.1c0,0-0.1-0.1-0.1-0.2c-0.2-0.7-0.3-1.4-0.3-2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2
		c0.2-0.1,0.5-0.2,0.7-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.5C32.4,17.7,32.4,18,32.2,18.3z M31.5,20.6
		c0.4,0.3,0.3,0.9,0.1,1.4c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1c-0.3-0.4-0.1-0.9,0-1.3C30,20.9,30.6,19.8,31.5,20.6z
		 M30.1,19.9C30,20,30,20.1,29.9,20.2c-0.2,0.2-0.4,0.4-0.6,0.4c-0.2,0.1-0.5,0-0.7-0.2c-0.8-1,0.5-3.3,1.6-2.3
		c0.2,0.1,0.2,0.4,0.2,0.6C30.4,19.2,30.3,19.6,30.1,19.9z M29.7,16.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.5,0.2-0.6,0.4
		c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0c-0.1-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.7,0.4-0.9c0.1-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.4,0,0.5,0.2C29.9,16.5,29.8,16.7,29.7,16.8z M28.7,23.1c0.4,0.2,0.7,0.5,1.1,0.7c0.2,0.2,0.5,0.3,0.7,0.6
		c0.2,0.4,0.2,0.9-0.1,1.3c-0.3,0.4-0.7,0.6-1.1,0.6c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0c-0.2-0.1-0.3-0.4-0.4-0.7
		c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6,0-0.8c0.1-0.3,0.5-0.4,0.8-0.3C28.2,22.7,28.5,22.9,28.7,23.1z
		 M27.7,20.6c0,0.2-0.2,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.2-0.4-0.3-1,0-1.3c0.3-0.3,0.7-0.4,1.1-0.1C27.7,19.8,27.8,20.3,27.7,20.6z M26.2,22.9c0.2,0.4,0.2,0.8-0.1,1.1
		c-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.1-1.6,0.1-2.4-0.1c-0.3-0.1-0.7-0.3-0.8-0.6c0-0.1,0-0.3,0.1-0.4c0.2-0.4,0.6-0.6,1-0.7
		c0.4-0.1,0.8-0.1,1.3-0.2c0.3,0,0.5-0.1,0.8-0.1C25.6,22.3,26,22.5,26.2,22.9z M25.5,20.4c-0.1,0.4-0.6,0.5-0.9,0.5
		c-0.7,0.1-1.4,0.2-2.1,0.2c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.6,0.4-1.1,0.9-1.3c0.5-0.3,1.1-0.3,1.6-0.2
		c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5C25.5,19.8,25.6,20.1,25.5,20.4z M22.1,25.7c0.4-0.3,0.9-0.3,1.3-0.1
		c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7
		c-0.1-0.2-0.2-0.5-0.1-0.7C21.9,25.9,22,25.8,22.1,25.7z M22.4,28.3c0.5-0.1,1,0.2,1.5,0.5c0.2,0.2,0.5,0.3,0.7,0.6
		c0.4,0.6,0.2,1.4-0.1,2c-0.1,0.3-0.2,0.6-0.5,0.7c-0.2,0.1-0.5,0.2-0.7,0.1c-1-0.1-1.8-0.8-2.4-1.7c-0.2-0.4-0.4-0.8-0.2-1.2
		c0.1-0.2,0.3-0.4,0.6-0.6C21.7,28.6,22,28.4,22.4,28.3z M21.7,25.4c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.6,0.4-1,0.4
		c-0.4,0-0.7-0.2-1-0.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.7-0.4
		c0.3-0.1,0.5-0.1,0.8,0.1c0.3,0.2,0.4,0.6,0.5,0.9C21.7,25.1,21.8,25.3,21.7,25.4z M20.7,23.2c-0.2,0.3-0.6,0.5-0.9,0.5
		c-0.2,0-0.3-0.1-0.5-0.2c-0.7-0.6-0.2-1.3,0.5-1.6C20.7,21.4,21.2,22.4,20.7,23.2z M19.8,28.1c0.1,0,0.2,0.1,0.3,0.2
		c0.1,0.2,0.1,0.4,0.1,0.5c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.7-0.8
		c-0.1-0.2,0-0.5-0.1-0.8c0-0.1-0.1-0.3-0.1-0.4c0-0.5,0.3-1,0.8-1.1C19.2,28,19.5,28,19.8,28.1z M18.5,27.6
		c-0.2,0.3-0.5,0.4-0.8,0.3c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0
		c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4C18.7,27.2,18.6,27.4,18.5,27.6z M18.3,24
		c0,0,0,0.1,0,0.1c-0.2,0.6-0.8,1.1-1.5,1.1c-1.8-0.1-0.9-3.5,0.6-3C18,22.5,18.4,23.3,18.3,24z M16.4,20.3
		C16.4,20.3,16.4,20.3,16.4,20.3c0,0.5-0.1,1.1-0.4,1.5c-1,1.3-2.6-1.5-0.3-2c0.3-0.1,0.6,0,0.7,0.2C16.4,20.1,16.4,20.2,16.4,20.3z
		 M14.7,22.6c0.2,0.1,0.2,0.3,0.2,0.5c0,0.4,0.1,0.8-0.2,1.1c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		c-0.2-0.2-0.4-0.2-0.3-0.6c0-0.4,0.3-0.8,0.6-1C14.4,22.4,14.6,22.4,14.7,22.6z M14.2,25.1c0.6,0.2,1.2,0.5,1.4,1
		c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.5,1c-0.2,0.1-0.4,0.1-0.6,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-0.6-1.1-0.9
		c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.3,0.2-0.4C13.5,25,13.9,25,14.2,25.1z M13.4,28.7
		c-0.1,1.7-2.7,2-2.7,0.2C10.6,26.9,13.5,26.3,13.4,28.7z M13.2,22.6c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.6,1.3-0.9
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1C13.1,21.9,13.3,22.2,13.2,22.6z M12.2,24.3c0.2,0,0.4-0.1,0.5,0
		c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8
		L10.6,25C11.1,24.7,11.6,24.4,12.2,24.3z M11.2,31c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.1,1.2-0.2,1.7-0.3c0.3-0.1,0.8-0.1,1,0.2
		c0.1,0.2,0.1,0.4,0.1,0.6c0,0.6-0.1,1.2-0.4,1.7c-0.4,0.4-0.9,0.6-1.5,0.7c-0.5,0.1-1.1,0.2-1.6,0.5c-0.2,0.1-0.3,0.2-0.5,0.2
		c-0.3,0-0.4-0.3-0.5-0.5c-0.2-0.7,0.1-1.6,0.5-2.2C10.8,31.4,11,31.1,11.2,31z M8.7,48.4c-0.5,0.1-1-0.1-1.4-0.4
		c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.1-0.7c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.2
		c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6C9.6,48,9.2,48.3,8.7,48.4z M9.5,51.2c0,0.3-0.1,0.6-0.2,0.8
		c-0.1,0.1-0.1,0.3-0.2,0.4C7.9,54,7,53,7.5,51.6c0.1-0.4,0.4-1.1,0.9-1.4C9,49.9,9.5,50.5,9.5,51.2z M7.3,43.5
		c0.2-0.2,0.6-0.3,0.8-0.1c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1l0,0.1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2C7.2,43.7,7.2,43.6,7.3,43.5z M8.6,40.2c-0.2,0-0.4-0.1-0.6-0.2
		c-1.7-1.4,2.6-4.4,2.2-1c-0.1,0.4-0.3,0.8-0.7,1C9.2,40.1,8.9,40.2,8.6,40.2z M9.7,31.1c-0.2,0.3-0.6,0.4-0.9,0.4
		c-0.2,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.3-0.3-0.3-0.4C7.2,29.2,11.4,29,9.7,31.1z M7.5,27.5c0.1-0.4,0.4-1.1,0.9-1.4
		c0.6-0.3,1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.2,0.8C9.1,28.1,9,28.2,9,28.3C7.9,30,7,29,7.5,27.5z M9.4,34.5c0,0.2-0.1,0.4-0.1,0.6h0
		c0,0.1-0.1,0.1-0.1,0.2C9.1,35.5,9,35.6,9,35.7c-1,1.6-1.9,0.8-1.5-0.6h0c0-0.1,0-0.1,0.1-0.2c0.1-0.4,0.4-1.1,0.9-1.4
		C9,33.3,9.4,33.9,9.4,34.5z M8.5,32.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.2-0.4,0.4-0.6,0.6
		c-0.2,0.1-0.6,0.2-0.8,0c-0.5-0.4-1.3-1.8-0.2-2C7.4,31.4,8,32.1,8.5,32.4z M6.8,40.9c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1
		c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.8,0.3c-0.5-0.1-0.8-0.6-1-1.1
		c0-0.1-0.1-0.2-0.1-0.3C6.5,41.1,6.7,40.9,6.8,40.9z M7.2,48.9c0.3-0.2,0.8-0.2,0.9,0.2c0.1,0.3-0.1,0.5-0.3,0.7
		c-0.4,0.4-1.6,0.6-1.5-0.4C6.4,49.1,6.9,48.8,7.2,48.9z M8.7,56.9c-0.2,0-0.4-0.1-0.6-0.2c-1.7-1.4,2.6-4.4,2.2-1
		c-0.1,0.4-0.3,0.8-0.7,1C9.3,56.8,9,56.9,8.7,56.9z M9.9,44.6c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3C10.4,44.8,10.1,44.7,9.9,44.6z M13.4,52.8c-0.1,1.7-2.7,2-2.7,0.2
		C10.6,51,13.5,50.3,13.4,52.8z M15.7,59.1c0.3-0.1,0.6,0,0.7,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.5-0.1,1.1-0.4,1.5
		C15.1,62.4,13.5,59.6,15.7,59.1z M17.4,61.6c0.7,0.3,1.1,1,0.9,1.7c0,0,0,0.1,0,0.1c-0.2,0.6-0.8,1.1-1.5,1.1
		C15,64.5,15.9,61.1,17.4,61.6z M17.6,60.9c-0.3-0.1-0.4-0.4-0.4-0.7c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.8,0.9-1.5,1.7-1.6
		c0.2,0,0.4-0.1,0.6,0c0.4,0.2,0.5,0.7,0.5,1.2c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.5,0.4-0.8,0.6
		C18.3,60.8,17.9,61,17.6,60.9z M19.8,61.2c0.9-0.4,1.4,0.6,0.9,1.4c-0.2,0.3-0.6,0.5-0.9,0.5c-0.2,0-0.3-0.1-0.5-0.2
		C18.6,62.2,19,61.5,19.8,61.2z M19.4,56.3c-0.2-0.4-0.4-0.8-0.3-1.2c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7-0.2,1-0.3
		c0.3-0.1,0.7-0.4,1-0.5s0.7-0.3,1.1-0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0,0,0.1,0,0.1c0,0.9-0.1,1.9-0.7,2.6
		c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0C20.4,57.8,19.8,57.1,19.4,56.3z M21.9,59.9
		c0-0.6,0.4-1.1,0.9-1.3c0.5-0.3,1.1-0.3,1.6-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.3,0.2,0.6,0.1,0.8
		c-0.1,0.4-0.6,0.5-0.9,0.5c-0.7,0.1-1.4,0.2-2.1,0.2c-0.1,0-0.2,0-0.3,0C21.9,60.4,21.9,60.1,21.9,59.9z M25.4,57.7
		c-1.6-0.9-0.5-3.7,1.2-2.8C28.5,55.8,27.7,59,25.4,57.7z M29.5,52.4c0.3,0.4,0.9,1,0.8,1.5c0,0.8-0.8,0.9-1.4,0.6
		c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.1-0.2-0.3-0.2-0.4C27,51.5,28.3,51.2,29.5,52.4z M28.4,56.9c0.1-0.3,0.2-0.7,0.4-0.9
		c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.5,0.2c0.1,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.5,0.2-0.6,0.4
		c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0C28.3,57.1,28.3,56.9,28.4,56.9z M30.2,57.5c0.2,0.1,0.2,0.4,0.2,0.6
		c0,0.4-0.1,0.8-0.3,1.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.4-0.6,0.4c-0.2,0.1-0.5,0-0.7-0.2C27.8,58.8,29.1,56.4,30.2,57.5z
		 M29.7,61c0.3-0.8,0.9-1.8,1.8-1.1c0.4,0.3,0.3,0.9,0.1,1.4c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1
		C29.4,61.9,29.6,61.4,29.7,61z M31.5,62.6c0.2-0.3,0.7-0.5,1.1-0.3c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4
		c0,0.4,0,0.7-0.3,1s-0.6,0.4-0.9,0.2c-0.1-0.1-0.2-0.2-0.3-0.4C31.2,63.4,31.2,62.9,31.5,62.6z M32.4,48.5c0-0.8,0.4-1.7,1.2-1.9
		c0.1,0,0.2,0,0.2-0.1v0c0,0,0.1,0,0.1,0c0.2,0,0.5,0.2,0.6,0.5c0.1,0.2,0.1,0.5,0.1,0.8c0,0.5-0.2,1-0.4,1.5
		c-0.1,0.4-0.3,0.8-0.4,1.2v0c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.2-0.1C32.8,50.1,32.4,49.3,32.4,48.5z M34.3,51.8
		c0.2,0,0.4,0,0.6,0.1c0.4,0.2,0.7,0.4,1,0.8c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5-0.1,0.7c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0-0.2,0c-0.3-0.1-0.6-0.2-0.9-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.8C33.9,52.1,34.1,51.8,34.3,51.8z
		 M36.3,59.7c0.3-0.2,0.7-0.3,1-0.3c0.2,0,0.5,0.1,0.6,0.3c0.3,0.3,0,0.9-0.4,1.1c-0.4,0.2-0.8,0.2-1.2,0.1c-0.1,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2l0,0.1C35.8,60.3,36,59.9,36.3,59.7z M37.9,57c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.5
		c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.4,0.1-0.8,0.1-1.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.4,0.6-0.6,1-0.5c0.4,0.1,0.8,0.3,1,0.7
		c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.2-0.7,0.3C38.2,56.6,38.1,56.8,37.9,57z
		 M39.1,53.2c-0.7-1.1,0.2-2,1.1-2.4c1.3-0.5,2.3-0.1,2.2,1.4c-0.1,0.9-0.3,1.7-1.2,1.9C40.4,54.3,39.5,53.9,39.1,53.2z M40.9,60.5
		c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0
		c0-0.2,0.2-0.5,0.4-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1C40.9,59.9,40.9,60.2,40.9,60.5z M39.6,62.8
		c0.2-0.2,0.4-0.3,0.6-0.4c0.3-0.1,0.7-0.2,1,0c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.2
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7
		C39.3,63.8,39.2,63.2,39.6,62.8z M41,56.7c-0.1-0.5-0.1-1,0.1-1.4c0.2-0.6,0.8-1,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.2
		c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6c-0.4,0.2-0.9,0.4-1.4,0.2
		C41.3,57.7,41.1,57.2,41,56.7z M44.6,53.1c-0.1,0.1-0.3,0-0.4,0c-1.4-0.3-1.2-1.2-0.6-2.3c0.2-0.4,0.7-0.7,1.1-0.5
		c0.4,0.2,0.5,0.7,0.4,1.1c-0.1,0.4-0.3,0.8-0.3,1.2C44.8,52.8,44.8,53,44.6,53.1z M47,56.9c-0.2,0-0.4-0.1-0.6-0.2
		c-1.7-1.4,2.6-4.4,2.2-1c-0.1,0.4-0.3,0.8-0.7,1C47.6,56.8,47.3,56.9,47,56.9z M48.2,44.6c-1.5-1.1,0.8-4.1,1.6-2.4
		c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3C48.7,44.8,48.4,44.7,48.2,44.6z
		 M51.7,52.8c-0.1,1.7-2.7,2-2.7,0.2C48.9,51,51.8,50.3,51.7,52.8z M54,59.1c0.3-0.1,0.6,0,0.7,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0
		c0,0.5-0.1,1.1-0.4,1.5C53.4,62.4,51.8,59.6,54,59.1z M55.7,61.6c0.7,0.3,1.1,1,0.9,1.7c0,0,0,0.1,0,0.1c-0.2,0.6-0.8,1.1-1.5,1.1
		C53.3,64.5,54.2,61.1,55.7,61.6z M55.9,60.9c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.8,0.9-1.5,1.7-1.6
		c0.2,0,0.4-0.1,0.6,0c0.4,0.2,0.5,0.7,0.5,1.2c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.5,0.4-0.8,0.6
		C56.6,60.8,56.2,61,55.9,60.9z M58.1,61.2c0.9-0.4,1.4,0.6,0.9,1.4c-0.2,0.3-0.6,0.5-0.9,0.5c-0.2,0-0.3-0.1-0.5-0.2
		C56.9,62.2,57.3,61.5,58.1,61.2z M57.7,56.3c-0.2-0.4-0.4-0.8-0.3-1.2c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7-0.2,1-0.3
		c0.3-0.1,0.7-0.4,1-0.5c0.3-0.2,0.7-0.3,1.1-0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0,0,0.1,0,0.1
		c0,0.9-0.1,1.9-0.7,2.6c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0C58.7,57.8,58.1,57.1,57.7,56.3z
		 M60.2,59.9c0-0.6,0.4-1.1,0.9-1.3c0.5-0.3,1.1-0.3,1.6-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.3,0.2,0.6,0.1,0.8
		c-0.1,0.4-0.6,0.5-0.9,0.5c-0.7,0.1-1.4,0.2-2.1,0.2c-0.1,0-0.2,0-0.3,0C60.2,60.4,60.2,60.1,60.2,59.9z M63.7,57.7
		c-1.6-0.9-0.5-3.7,1.2-2.8C66.8,55.8,66,59,63.7,57.7z M65.5,49.3C65,49.3,64.5,49.3,64,49c-0.4-0.2-0.6-0.6-0.7-1.1
		c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.5,0.1-1.2,0.5-1.5c0.4-0.4,1-0.3,1.6-0.1c0.5,0.1,1,0.4,1.3,0.8c0.5,0.6,0.6,1.3,0.5,2.1
		c0,0.2,0,0.5-0.2,0.7C66.7,49.1,66.1,49.2,65.5,49.3z M67,35.1L67,35.1c-0.1,0.5-0.3,1.1-0.6,1.5c-0.2,0.3-0.5,0.7-0.8,0.7
		c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.4-0.5-0.7h0c0-0.1,0-0.1,0-0.2
		c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5c0.3,0.1,0.5,0.2,0.8,0.3
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4C67.1,34.7,67.1,34.9,67,35.1z M66.6,31.8c-0.2,0.2-0.5,0.2-0.6,0.1
		c-0.8-0.7-0.6-2.6,0.7-2.4c0.6,0.1,1.6,1.1,0.8,1.6c-0.1,0.1-0.2,0.1-0.4,0.2C66.9,31.4,66.8,31.6,66.6,31.8z M65.8,27.7
		c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7,0.1,0.8,0.4c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.3-0.3,0.5-0.5,0.6c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.2-0.5C65.7,28.1,65.7,27.9,65.8,27.7z M67.5,25.7
		c-0.1,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.4-0.4-1-0.2-1.3c0.4-0.5,1.1,0.1,1.5,0.3
		c0.1,0.1,0.3,0.2,0.3,0.3C67.6,25.3,67.6,25.6,67.5,25.7z M67.1,23.3c-0.1,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.2-0.4s0-0.3,0-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.2,0.4-0.2
		c0.2,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.3,0.2,0.3,0.3C67.3,22.8,67.3,23.1,67.1,23.3z M66,20.6
		c0,0.2-0.2,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.4-0.3-1,0-1.3
		c0.3-0.3,0.7-0.4,1.1-0.1C66,19.8,66.1,20.3,66,20.6z M63.7,18.3c-1.6-0.9-0.5-3.7,1.2-2.8C66.8,16.5,66,19.6,63.7,18.3z
		 M62.6,13.7C62.3,13.4,62,13,62,12.5c0-0.3,0-0.7,0.2-1c0.1-0.2,0.3-0.4,0.5-0.5C64.9,10,65.6,15.9,62.6,13.7z M62.5,9.4
		c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.5,0.2l0-0.1c-0.1,0.2-0.4,0.2-0.6,0.2
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.3-0.1-0.7,0.1-1c0.3-0.4,1-0.5,1.5-0.5c0.1,0,0.2,0,0.3,0.1C62.5,9,62.5,9.2,62.5,9.4z M61.2,14
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0,0,0.1,0,0.1c0,0.9-0.1,1.9-0.7,2.6c-0.2,0.2-0.5,0.4-0.7,0.6
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0c-0.8-0.2-1.4-0.9-1.8-1.7c-0.2-0.4-0.4-0.8-0.3-1.2c0.1-0.3,0.4-0.6,0.7-0.7
		c0.3-0.1,0.7-0.2,1-0.3c0.3-0.1,0.7-0.4,1-0.5C60.5,14,60.9,13.9,61.2,14z M58.9,13.5c-0.3,0-0.5-0.1-0.8-0.2
		c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.3,0.3-0.5,0.6-0.6C59.2,9.6,60.9,13.5,58.9,13.5
		z M57.1,18.3c0.2,0,0.4-0.1,0.6,0c0.4,0.2,0.5,0.7,0.5,1.2c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.5,0.4-0.8,0.6
		c-0.3,0.2-0.7,0.5-1,0.3c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1-0.3-0.1-0.6,0-0.8C55.5,19.1,56.3,18.5,57.1,18.3z M56.6,17.4
		C56.5,17.7,56.3,18,56,18c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.5-0.3-1-0.6-1.2-1.1C53.7,15.1,57.2,14.9,56.6,17.4z M56.3,13.6
		c0,0-0.1,0-0.2,0.1c-0.4,0-0.8,0.1-1.1-0.1c-0.2-0.1-0.3-0.4-0.4-0.6c-0.2-0.4-0.2-0.9-0.2-1.3c0-0.2,0-0.3,0.1-0.4
		c0.2-0.3,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.7,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.6,0.1,1.2,0.2,1.8C56.4,13.5,56.4,13.6,56.3,13.6z
		 M54.7,14.6l-0.1-0.3c0,0.4-0.6,0.7-1,0.5c-0.4-0.2-0.5-0.7-0.4-1.2c0.1-0.1,0.1-0.3,0.3-0.3c0.2-0.1,0.3,0,0.5,0.1
		C54.4,13.7,54.7,14.1,54.7,14.6z M53.2,16.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.8-0.2,1.6-0.6,2.2
		c-0.2,0.3-0.5,0.7-0.8,0.7c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9
		c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0c0.8-0.4,1.6-0.7,2.4-0.5C52.7,15.9,53,16,53.2,16.2z M51.7,13.4
		c-0.1,1.7-2.7,2-2.7,0.2C48.9,11.6,51.8,11,51.7,13.4z M48.9,8.4c0.4-0.4,1-0.6,1.5-0.8c0.2,0,0.4-0.1,0.5,0
		c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1C49,9,48.8,8.6,49,8.3L48.9,8.4z
		 M49.9,19.5C50,19.7,50,20,50,20.2c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2
		C46.7,20.8,49,17.8,49.9,19.5z M48.5,16.2c-0.1,0.4-0.3,0.8-0.7,1c-0.3,0.2-0.6,0.3-0.9,0.2c-0.2,0-0.4-0.1-0.6-0.2
		C44.6,15.8,49,12.8,48.5,16.2z M47.5,12.6c-0.1,0.1-0.1,0.3-0.2,0.4c-1.1,1.6-2,0.7-1.5-0.8c0.2-0.4,0.4-1.1,0.9-1.4
		c0.6-0.3,1.1,0.3,1.1,1C47.8,12.1,47.7,12.4,47.5,12.6z M46.2,18.2c0.2,0,0.4,0.1,0.4,0.3c0.4,0.1,1.3-0.1,0.8,0.8
		c-0.3,0.5-1,0.7-1.5,0.6c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0
		C45.8,18.2,46,18.2,46.2,18.2z M45.2,12c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0
		c-1.4-0.3-1.2-1.2-0.6-2.3c0.2-0.4,0.7-0.7,1.1-0.5C45.1,11.1,45.2,11.6,45.2,12z M43.3,9c0.3-0.2,0.8-0.1,1.1,0.2
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.4,0.3-0.9,0.4-1.4,0.3l0.1,0C42.7,9.7,42.9,9.2,43.3,9z M44.1,7.8
		c-0.2,0.3-0.5,0.5-0.8,0.7c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4
		c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6C44.5,7.2,44.4,7.5,44.1,7.8z M42.5,14.6
		c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6
		c-0.4,0.2-0.9,0.4-1.4,0.2c-0.5-0.2-0.7-0.7-0.8-1.2c-0.1-0.5-0.1-1,0.1-1.4C41.4,15.3,41.9,14.9,42.5,14.6z M41.2,23.1
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.2,0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7c-0.1-0.6-0.2-1.2,0.2-1.6c0.2-0.2,0.4-0.3,0.6-0.4
		C40.6,22.9,41,22.9,41.2,23.1z M40.9,21.1c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0-0.3,0-0.4-0.1
		c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0c0-0.2,0.2-0.5,0.4-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1
		C40.9,20.6,40.9,20.9,40.9,21.1z M40.4,19.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-1.5,0.3-1.7,0c-0.2-0.3,0.6-1.2,0.7-1.5
		c0.5-0.8,1.2,0.4,1.4,0.9C40.6,19,40.6,19.3,40.4,19.4z M38.1,12.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-1.5,0.3-1.7,0
		c-0.2-0.3,0.6-1.2,0.7-1.5c0.5-0.8,1.2,0.4,1.4,0.9C38.3,12.2,38.3,12.4,38.1,12.6z M36.5,10.4c-0.1,0.4-0.2,0.7-0.4,1
		c-0.1,0.2-0.3,0.4-0.6,0.4c-0.3,0-0.6-0.2-0.8-0.5c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.4,0.8-0.4
		c0.3,0,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.2,0.3,0.4C36.5,10.1,36.5,10.2,36.5,10.4z M34.6,8.4c0,0.5-0.2,1-0.4,1.5
		c-0.1,0.4-0.3,0.8-0.4,1.2v0c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c-0.6-0.6-1-1.3-1.1-2.1
		c0-0.8,0.4-1.7,1.2-1.9c0.1,0,0.2,0,0.2-0.1v0c0,0,0.1,0,0.1,0c0.2,0,0.5,0.2,0.6,0.5C34.6,7.8,34.6,8.1,34.6,8.4z M32.4,13.8
		c0,0.1,0,0.3,0,0.5c0,0.3-0.1,0.6-0.2,0.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.6,0-0.5-1.1-0.3-1.4C31.4,13.9,32.1,13,32.4,13.8z M32.3,7c-0.1,1.3-0.9,1.9-2,1.2c-0.7-0.4-1.2-1-1-1.8
		c0.2-0.7,0.9-1.3,1.6-1.3C32,4.9,32.4,6,32.3,7z M31.3,11.2c0.3,0.4,0.3,1-0.1,1.3c-0.4,0.3-0.9,0.2-1.2-0.1
		c-0.4-0.3-0.6-0.7-1-0.9c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0.1-0.3,0.2-0.4C29.8,9.5,30.6,10.1,31.3,11.2z M29.5,13
		c0.3,0.4,0.9,1,0.8,1.5c0,0.8-0.8,0.9-1.4,0.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.1-0.2-0.3-0.2-0.4C27,12.2,28.3,11.8,29.5,13z
		 M25.4,18.3c-1.6-0.9-0.5-3.7,1.2-2.8C28.5,16.5,27.7,19.6,25.4,18.3z M24.4,13.7c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.3,0-0.7,0.2-1
		c0.1-0.2,0.3-0.4,0.5-0.5C26.6,10,27.3,15.9,24.4,13.7z M24.2,9.4c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.2-0.3,0.4-0.5,0.2l0-0.1c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.3-0.1-0.7,0.1-1c0.3-0.4,1-0.5,1.5-0.5
		c0.1,0,0.2,0,0.3,0.1C24.2,9,24.2,9.2,24.2,9.4z M22.9,14c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0,0,0.1,0,0.1
		c0,0.9-0.1,1.9-0.7,2.6c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0c-0.8-0.2-1.4-0.9-1.8-1.7
		c-0.2-0.4-0.4-0.8-0.3-1.2c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7-0.2,1-0.3c0.3-0.1,0.7-0.4,1-0.5S22.6,13.9,22.9,14z M20.6,13.5
		c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.3,0.3-0.5,0.6-0.6
		C20.9,9.6,22.6,13.5,20.6,13.5z M18.8,18.3c0.2,0,0.4-0.1,0.6,0c0.4,0.2,0.5,0.7,0.5,1.2c0,0.3,0,0.5-0.1,0.7
		c-0.1,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.7,0.5-1,0.3c-0.3-0.1-0.4-0.4-0.4-0.7c-0.1-0.3-0.1-0.6,0-0.8
		C17.2,19.1,18,18.5,18.8,18.3z M18.3,17.4C18.2,17.7,18,18,17.7,18c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.5-0.3-1-0.6-1.2-1.1
		C15.4,15.1,18.9,14.9,18.3,17.4z M18,13.6c0,0-0.1,0-0.2,0.1c-0.4,0-0.8,0.1-1.1-0.1c-0.2-0.1-0.3-0.4-0.4-0.6
		c-0.2-0.4-0.2-0.9-0.2-1.3c0-0.2,0-0.3,0.1-0.4c0.2-0.3,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.7,0.3c0,0.1,0.1,0.2,0.1,0.3
		c0.1,0.6,0.1,1.2,0.2,1.8C18.1,13.5,18.1,13.6,18,13.6z M16.4,14.6l-0.1-0.3c0,0.4-0.6,0.7-1,0.5c-0.4-0.2-0.5-0.7-0.4-1.2
		c0.1-0.1,0.1-0.3,0.3-0.3c0.2-0.1,0.3,0,0.5,0.1C16.1,13.7,16.4,14.1,16.4,14.6z M14.9,16.2c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.8-0.2,1.6-0.6,2.2c-0.2,0.3-0.5,0.7-0.8,0.7c-0.3,0.1-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.7-0.7
		c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0.1,0,0.1,0
		c0.8-0.4,1.6-0.7,2.4-0.5C14.4,15.9,14.7,16,14.9,16.2z M13.4,13.4c-0.1,1.7-2.7,2-2.7,0.2C10.6,11.6,13.5,11,13.4,13.4z M10.6,8.4
		c0.4-0.4,1-0.6,1.5-0.8c0.2,0,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7
		c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8L10.6,8.4z M11.6,19.5c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2C8.4,20.8,10.7,17.8,11.6,19.5z M10.2,16.2
		c-0.1,0.4-0.3,0.8-0.7,1c-0.3,0.2-0.6,0.3-0.9,0.2c-0.2,0-0.4-0.1-0.6-0.2C6.3,15.8,10.7,12.8,10.2,16.2z M9.2,12.6
		C9.1,12.8,9.1,12.9,9,13c-1.1,1.6-2,0.7-1.5-0.8c0.1-0.4,0.4-1.1,0.9-1.4c0.6-0.3,1.1,0.3,1.1,1C9.5,12.1,9.4,12.4,9.2,12.6z
		 M7.9,18.2c0.2,0,0.4,0.1,0.4,0.3c0.4,0.1,1.3-0.1,0.8,0.8c-0.3,0.5-1,0.7-1.5,0.6c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3
		c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0C7.5,18.2,7.7,18.2,7.9,18.2z M6.9,12c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5
		c-0.1,0.1-0.3,0-0.4,0c-1.4-0.3-1.2-1.2-0.6-2.3C5.6,11,6,10.7,6.4,10.9C6.8,11.1,6.9,11.6,6.9,12z M5,9C5.3,8.8,5.8,8.9,6,9.2
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.4,0.3-0.9,0.4-1.4,0.3l0.1,0C4.4,9.7,4.6,9.2,5,9z M5.9,7.8
		C5.6,8,5.3,8.2,5.1,8.5C4.9,8.6,4.8,8.7,4.6,8.8c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4
		c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6C6.2,7.2,6.1,7.5,5.9,7.8z M3.6,21.2
		c0-0.4,0.4-0.7,0.8-0.9c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.5-0.3,1-0.6,1.3
		c-0.4,0.4-0.8,0.6-1.3,0.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.2-0.7C3.6,21.6,3.6,21.4,3.6,21.2z
		 M4.7,29.9c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.4,0-0.6C4.2,30.2,4.5,30,4.7,29.9z M3.6,43.9c0-0.4,0.4-0.7,0.8-0.9
		c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.5-0.3,1-0.6,1.3c-0.4,0.4-0.8,0.6-1.3,0.8
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.2-0.7C3.6,44.3,3.5,44.1,3.6,43.9z M4.2,46.6
		c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6c0.1,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7
		c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7C4.2,46.9,4.2,46.7,4.2,46.6z M4.2,54
		c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.4,1.4c0,0.4-0.3,0.8-0.6,1c-0.3,0.3-0.6,0.4-1,0.6
		c-0.4,0.2-0.9,0.4-1.4,0.2c-0.5-0.2-0.7-0.7-0.8-1.2c-0.1-0.5-0.1-1,0.1-1.4C3.1,54.7,3.6,54.3,4.2,54z M2.9,62.4
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
		C2.4,65,2.1,65,1.9,65.1c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7c-0.1-0.6-0.2-1.2,0.2-1.6c0.2-0.2,0.4-0.3,0.6-0.4
		C2.3,62.3,2.7,62.2,2.9,62.4z M2.6,60.5c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0-0.3,0-0.4-0.1
		c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0c0-0.2,0.2-0.5,0.4-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1
		C2.6,59.9,2.6,60.2,2.6,60.5z M3.9,61.8c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.4-0.7,0.8-0.9
		c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.5-0.3,1-0.6,1.3C5.4,61.7,5,62,4.5,62.2
		c-0.1,0.1-0.2,0.1-0.3,0.1C4,62.2,3.9,62,3.9,61.8z M4.7,64.8c-0.2,0-0.4,0-0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7
		c0-0.1,0-0.3,0-0.4c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6c0.1,0.3-0.1,0.7-0.3,1
		c-0.2,0.3-0.5,0.5-0.8,0.7C5,64.6,4.8,64.7,4.7,64.8z M6.8,66.7c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.2,0,0.4-0.2,0.5
		c-0.1,0.1-0.3,0-0.4,0c-1.4-0.3-1.2-1.2-0.6-2.3c0.2-0.4,0.7-0.7,1.1-0.5C6.8,65.8,6.9,66.3,6.8,66.7z M9.2,67.3
		C9.1,67.5,9,67.6,9,67.7c-1.1,1.6-2,0.7-1.5-0.8c0.1-0.4,0.4-1.1,0.9-1.4c0.6-0.3,1.1,0.3,1.1,1C9.4,66.8,9.3,67.1,9.2,67.3z
		 M9.9,64.2C9.7,64.7,9.2,65,8.7,65c-0.5,0.1-1-0.1-1.4-0.4C7.2,64.4,7,64.1,7,63.8c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.1-0.7
		c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.2c0.5,0.4,1,0.9,0.9,1.6C10.1,63.9,10.1,64.1,9.9,64.2z M9.9,61.2
		c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3
		C10.4,61.5,10.2,61.4,9.9,61.2z M12.2,63.6c0.2,0,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2
		c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.5-0.2-0.8l-0.1,0.1C11.1,64,11.6,63.8,12.2,63.6z M10.7,67.8
		c0-0.5,0.1-0.8,0.4-1.1h0c0.6-0.8,1.8-0.9,2.2,0h0c0.1,0.2,0.2,0.6,0.2,1C13.3,69.3,10.7,69.6,10.7,67.8z M17.1,70
		c-1,0.6-2.9-0.9-2.7-2c0-0.2,0.1-0.4,0.2-0.6c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-0.6-1.1-0.9
		c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.1,0.8,0
		c0.6,0.2,1.2,0.5,1.4,1c0.1,0.3,0.1,0.5,0,0.8c0,0.2-0.1,0.5-0.2,0.7c0.8,0,1.7,0.7,2,1.5C17.6,69,17.5,69.7,17.1,70z M17.6,67.3
		c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1
		c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0,0.4-0.1,0.6C18.3,67.2,18,67.3,17.6,67.3z M20.2,67.8
		c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.7-0.8c-0.1-0.2,0-0.5-0.1-0.8
		c0-0.1-0.1-0.3-0.1-0.4c0-0.5,0.3-1,0.8-1.1c0.3-0.1,0.6-0.1,0.8,0c0.1,0,0.2,0.1,0.3,0.2C20.2,67.4,20.2,67.6,20.2,67.8z
		 M21.7,64.8c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.2-1-0.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.6
		c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8,0.1c0.3,0.2,0.4,0.6,0.5,0.9C21.7,64.5,21.8,64.6,21.7,64.8
		z M23.6,66.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.2-0.5-0.1-0.7
		c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.3,0.9-0.3,1.3-0.1c0.2,0.1,0.3,0.4,0.4,0.7C23.9,65.9,23.8,66.2,23.6,66.5z M22.8,63.6
		c-0.3-0.1-0.7-0.3-0.8-0.6c0-0.1,0-0.3,0.1-0.4c0.2-0.4,0.6-0.6,1-0.7c0.4-0.1,0.8-0.1,1.3-0.2c0.3,0,0.5-0.1,0.8-0.1
		c0.4,0,0.8,0.2,1,0.6c0.2,0.4,0.2,0.8-0.1,1.1c-0.2,0.2-0.5,0.3-0.8,0.4C24.5,63.9,23.6,63.8,22.8,63.6z M26.7,67.2
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7
		C26.8,66.5,26.8,66.9,26.7,67.2z M26.7,60.8c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.4-0.3-1,0-1.3
		c0.3-0.3,0.7-0.4,1.1-0.1c0.3,0.2,0.4,0.6,0.3,1c0,0.2-0.2,0.4-0.3,0.6C27.2,60.7,27,60.8,26.7,60.8z M29,67.5
		c-0.1,0.3-0.3,0.5-0.5,0.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-0.2,0-0.4,0-0.6c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7,0.1,0.8,0.4C29.1,67,29.1,67.3,29,67.5z M28.5,65.6c-0.2,0-0.5,0.1-0.7,0
		c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6,0-0.8c0.1-0.3,0.5-0.4,0.8-0.3
		c0.3,0,0.6,0.2,0.8,0.4c0.4,0.2,0.7,0.5,1.1,0.7c0.2,0.2,0.5,0.3,0.7,0.6c0.2,0.4,0.2,0.9-0.1,1.3c-0.3,0.4-0.7,0.6-1.1,0.6
		C29,65.6,28.8,65.6,28.5,65.6z M30.3,66.5c-0.2,0.1-0.5,0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1,0-0.3,0.1-0.4
		c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2C30.6,66,30.5,66.3,30.3,66.5z M32.3,69.2c-0.1,1.3-0.9,1.9-2,1.2
		c-0.7-0.4-1.2-1-1-1.8c0.2-0.7,0.9-1.3,1.6-1.3C32,67.2,32.4,68.3,32.3,69.2z M32.9,67.1c0,0.1,0,0.2-0.1,0.3s-0.1,0.2-0.2,0.2
		c-0.1,0-0.2,0-0.2,0c-0.6-0.2-1-0.9-0.9-1.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1
		c0.1,0.1,0.2,0.1,0.3,0.2C32.9,66.1,32.9,66.6,32.9,67.1z M32.8,56.6c-0.1-0.6,0-1.2,0.4-1.7c0.2-0.2,0.4-0.4,0.6-0.5v0
		c0.3-0.1,0.6-0.2,0.9,0c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.5,0.1,1-0.1,1.5c-0.2,0.5-0.5,0.9-0.6,1.4c-0.1,0.2-0.1,0.5-0.3,0.7
		c-0.1,0.2-0.4,0.3-0.6,0.3v0c0,0,0,0,0,0c-0.2-0.1-0.3-0.3-0.4-0.5C33.1,57.7,32.9,57.2,32.8,56.6z M33.8,66.4
		C33.8,66.4,33.8,66.4,33.8,66.4L33.8,66.4c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.3-0.2-0.7-0.3-1
		c0-0.3-0.1-0.5,0-0.8c0.1-0.3,0.3-0.7,0.6-0.8c0,0,0.1,0,0.1-0.1v0c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.5,0.3,0.6,0.6
		c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.2,0.1,0.5-0.1,0.7c-0.1,0.1-0.2,0.1-0.3,0.2C34.5,66.1,34.1,66.2,33.8,66.4z M36.9,67.4
		c-0.2,1-1.8,1.6-2.2,0.5c-0.2-0.5,0.2-1.8,0.9-1.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2C36.8,67,37,67.2,36.9,67.4z
		 M38,64.7c-0.2,0.3-0.5,0.7-0.9,0.7c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8
		c0.3-0.6,1-0.7,1.6-0.8c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.3,0.3,0.4,0.5C38.7,62.9,38.5,63.9,38,64.7z M38.5,58.8
		c-0.2-0.3,0.6-1.2,0.7-1.5c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1C39.8,58.9,38.7,59.2,38.5,58.8z
		 M42.4,67c-0.1,0.9-0.3,1.7-1.2,1.9c-0.8,0.2-1.7-0.2-2.1-0.9c-0.3-0.5-0.3-1-0.1-1.4h0c0.3-0.4,0.7-0.8,1.2-1
		c1.1-0.5,2.1-0.2,2.2,1h0C42.4,66.8,42.4,66.9,42.4,67z M42.2,61.8c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.4,0.4-0.7,0.8-0.9c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.5-0.3,1-0.6,1.3
		c-0.4,0.4-0.8,0.6-1.3,0.8c-0.1,0.1-0.2,0.1-0.3,0.1C42.3,62.2,42.2,62,42.2,61.8z M43,64.8c-0.2,0-0.4,0-0.4-0.2
		c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4c0.1-0.3,0.5-0.5,0.7-0.8c0.2-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7,0.3,0.7,0.6
		c0.1,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7C43.3,64.6,43.1,64.7,43,64.8z M45.1,66.7c-0.1,0.4-0.3,0.8-0.3,1.2
		c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4,0c-0.9-0.2-1.1-0.7-1-1.3c0-0.2,0-0.3,0-0.5h0c0.1-0.3,0.2-0.7,0.4-1
		c0.2-0.4,0.7-0.7,1.1-0.5c0.4,0.2,0.5,0.7,0.4,1.1c0,0,0,0,0,0C45.2,66.4,45.2,66.5,45.1,66.7z M48.2,64.2
		c-0.3,0.4-0.6,0.7-1.1,0.8c0.4,0.1,0.6,0.5,0.6,1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.8
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.9,1.4-1.7,0.9-1.6-0.3c0-0.2,0-0.5,0.1-0.8h0c0-0.1,0-0.1,0.1-0.2c0.1-0.4,0.4-1.1,0.9-1.4
		c0,0,0,0,0,0c-0.4,0-0.7-0.2-1-0.4c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.1-0.7c0.1,0,0.2-0.1,0.3-0.1
		c0.2,0,0.3,0.1,0.4,0.2c0.5,0.4,1,0.9,0.9,1.6C48.4,63.9,48.3,64.1,48.2,64.2z M48.2,61.2c-1.5-1.1,0.8-4.1,1.6-2.4
		c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3C48.7,61.5,48.5,61.4,48.2,61.2z
		 M50.5,63.6c0.2,0,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.1
		c-0.3-0.1-0.4-0.5-0.2-0.8l-0.1,0.1C49.4,64,49.9,63.8,50.5,63.6z M48.9,67.8c0-0.5,0.1-0.8,0.4-1.1h0c0.6-0.8,1.8-0.9,2.2,0h0
		c0.1,0.2,0.2,0.6,0.2,1C51.6,69.3,49,69.6,48.9,67.8z M55.4,70c-1,0.6-2.9-0.9-2.7-2c0-0.2,0.1-0.4,0.2-0.6c0,0-0.1,0-0.1,0
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-0.6-1.1-0.9c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9
		c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.1,0.8,0c0.6,0.2,1.2,0.5,1.4,1c0.1,0.3,0.1,0.5,0,0.8c0,0.2-0.1,0.5-0.2,0.7
		c0.8,0,1.7,0.7,2,1.5C55.9,69,55.8,69.7,55.4,70z M55.9,67.3c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4
		c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0,0.4-0.1,0.6
		C56.6,67.2,56.3,67.3,55.9,67.3z M58.5,67.8c0,0.7-0.2,1.4-0.4,2c-0.1,0.2-0.2,0.5-0.5,0.5c-0.1,0-0.2-0.1-0.3-0.2
		c-0.3-0.2-0.6-0.4-0.7-0.8c-0.1-0.2,0-0.5-0.1-0.8c0-0.1-0.1-0.3-0.1-0.4c0-0.5,0.3-1,0.8-1.1c0.3-0.1,0.6-0.1,0.8,0
		c0.1,0,0.2,0.1,0.3,0.2C58.5,67.4,58.5,67.6,58.5,67.8z M60,64.8c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.6,0.4-1,0.4
		c-0.4,0-0.7-0.2-1-0.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.7-0.4
		c0.3-0.1,0.5-0.1,0.8,0.1c0.3,0.2,0.4,0.6,0.5,0.9C60,64.5,60.1,64.6,60,64.8z M61.9,66.5c-0.1,0.2-0.2,0.3-0.4,0.4
		c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.3,0.9-0.3,1.3-0.1
		c0.2,0.1,0.3,0.4,0.4,0.7C62.2,65.9,62.1,66.2,61.9,66.5z M61.1,63.6c-0.3-0.1-0.7-0.3-0.8-0.6c0-0.1,0-0.3,0.1-0.4
		c0.2-0.4,0.6-0.6,1-0.7c0.4-0.1,0.8-0.1,1.3-0.2c0.3,0,0.5-0.1,0.8-0.1c0.4,0,0.8,0.2,1,0.6c0.2,0.4,0.2,0.8-0.1,1.1
		c-0.2,0.2-0.5,0.3-0.8,0.4C62.8,63.9,61.9,63.8,61.1,63.6z M65,67.2c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3
		c-0.3,0-0.5-0.1-0.7-0.2c-1.5-1.1,0.8-4.1,1.6-2.4c0.1,0.2,0.1,0.5,0.1,0.7C65.1,66.5,65.1,66.9,65,67.2z M65,60.8
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.4-0.3-1,0-1.3c0.3-0.3,0.7-0.4,1.1-0.1c0.3,0.2,0.4,0.6,0.3,1
		c0,0.2-0.2,0.4-0.3,0.6C65.5,60.7,65.3,60.8,65,60.8z M67.2,67.5c-0.1,0.3-0.3,0.5-0.5,0.6c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0-0.6c0.1-0.3,0.4-0.6,0.7-0.7
		c0.3-0.1,0.7,0.1,0.8,0.4C67.4,67,67.4,67.3,67.2,67.5z M67,65.6c-0.2,0.1-0.7,0.1-0.9,0c-0.2-0.1-0.3-0.4-0.4-0.7
		c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6,0-0.8c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0,0.5,0.2,0.7,0.3
		c0.2,0.1,0.3,0.2,0.4,0.5c0.2,0.5,0.3,1,0.4,1.6c0,0.2,0,0.5-0.1,0.7C67.5,65.3,67.2,65.5,67,65.6z M68.6,66c0.4,0,0.9,1.2,1.1,1.5
		c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.3C67.8,67.3,68.1,66,68.6,66z M68.2,59.6
		c-0.2,0.2-0.4,0.4-0.6,0.4c-0.2,0.1-0.5,0-0.7-0.2c-0.8-1,0.5-3.3,1.6-2.3c0.2,0.1,0.2,0.4,0.2,0.6c0,0.4-0.1,0.8-0.3,1.2
		C68.3,59.4,68.3,59.5,68.2,59.6z M70.4,58.6c0.4,0.3,0.3,0.9,0.1,1.4c-0.1,0.3-0.3,0.7-0.5,0.9c-0.3,0.3-1,0.5-1.4,0.1
		c-0.3-0.4-0.1-0.9,0-1.3C68.9,58.9,69.5,57.9,70.4,58.6z M69.6,50.6c0.3,0.4,0.3,1-0.1,1.3c-0.4,0.3-0.9,0.2-1.2-0.1
		c-0.4-0.3-0.6-0.7-1-0.9c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0.1-0.3,0.2-0.4C68.1,48.8,68.9,49.4,69.6,50.6z M69.2,41.6
		c0.2,0.2,0.5,0.3,0.7,0.6c0.2,0.4,0.2,0.9-0.1,1.3s-0.7,0.6-1.1,0.6c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0
		c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8s-0.2-0.6,0-0.8c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0,0.6,0.2,0.8,0.4
		C68.5,41.1,68.9,41.4,69.2,41.6z M69.2,32.1c0.1,0.2,0.2,0.4,0.1,0.6c0,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.5
		c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.1-0.6c0-0.1,0.1-0.2,0.2-0.4
		c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.1,0.7-0.1,1,0.1C69,31.8,69.1,31.9,69.2,32.1z M68.8,27.1c0.4,0,0.9,1.2,1.1,1.5
		c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.3C68,28.4,68.4,27.2,68.8,27.1z M68.4,19.9
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.4-0.6,0.4c-0.2,0.1-0.5,0-0.7-0.2c-0.8-1,0.5-3.3,1.6-2.3c0.2,0.1,0.2,0.4,0.2,0.6
		C68.7,19.2,68.6,19.6,68.4,19.9z M66.7,17.5c0.1-0.3,0.2-0.7,0.4-0.9c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.5,0.2
		c0.1,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.5,0.2-0.6,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0
		C66.6,17.7,66.6,17.6,66.7,17.5z M69.9,20.3c0.2,0.2,0.1,0.4,0.1,0.7c-0.1,0.2-0.1,0.3-0.3,0.5c-0.2,0.2-0.5,0.2-0.7,0
		c-0.2-0.2-0.1-0.4,0-0.6C69.2,20.4,69.5,19.9,69.9,20.3z M69.4,11.1c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-1.5,0.3-1.7,0C68.5,12.3,69.3,11.4,69.4,11.1z M67.8,13c0.3,0.4,0.9,1,0.8,1.5
		c0,0.8-0.8,0.9-1.4,0.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.1-0.2-0.3-0.2-0.4C65.3,12.2,66.6,11.8,67.8,13z M66.5,1.5
		c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0,0.6,0.2,0.8,0.4c0.4,0.2,0.7,0.5,1.1,0.7c0.2,0.2,0.5,0.3,0.7,0.6c0.2,0.4,0.2,0.9-0.1,1.3
		s-0.7,0.6-1.1,0.6c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0C67,4.6,66.9,4.3,66.9,4c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8
		S66.4,1.8,66.5,1.5z M63.7,3.7c0.1-0.4,0.4-1.6,0.9-1.7c0.4,0,0.9,1.2,1.1,1.5c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4
		C63.6,3.9,63.7,3.8,63.7,3.7z M63.3,7.3c0-0.5,0.1-1.2,0.5-1.5c0.4-0.4,1-0.3,1.6-0.1c0.5,0.1,1,0.4,1.3,0.8
		c0.5,0.6,0.6,1.3,0.5,2.1c0,0.2,0,0.5-0.2,0.7c-0.3,0.5-0.9,0.7-1.5,0.7C65,10,64.5,10,64,9.7c-0.4-0.2-0.6-0.6-0.7-1.1
		C63.2,8.2,63.2,7.7,63.3,7.3z M61.2,2.2c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.3,0.9-0.3,1.3-0.1c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0
		c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7
		C61.2,2.7,61.1,2.5,61.2,2.2z M60.7,4.4c0.2,0,0.4,0.1,0.6,0.2c0.4,0.3,0.5,0.8,0.4,1.2c-0.1,0.5-0.3,0.9-0.4,1.3
		c-0.1,0.2-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.1-0.6,0c-0.5-0.3-0.8-0.8-1-1.3c-0.2-0.5-0.2-1.1-0.2-1.7c0-0.1,0-0.3,0.1-0.4
		c0.1-0.2,0.4-0.2,0.6-0.1C60.2,4.3,60.4,4.4,60.7,4.4z M57.6,1.5c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.3,0.2-0.4
		c0.2-0.2,0.6-0.1,0.8,0c0.6,0.2,1.2,0.5,1.4,1c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.5,1c-0.2,0.1-0.4,0.1-0.6,0
		C59.1,3,59,2.9,58.9,2.8c-0.4-0.3-0.7-0.6-1.1-0.9c-0.1-0.1-0.2-0.1-0.2-0.2C57.6,1.7,57.6,1.6,57.6,1.5z M56.7,3.6
		c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.3,0.3,0.7,0.3,1.1
		c0,0.2,0.1,0.5,0,0.7c-0.1,0.4-0.6,0.5-0.9,0.4C57.4,6,57.2,5.6,57,5.3c-0.1-0.4-0.2-0.7-0.3-1.1C56.7,4,56.7,3.8,56.7,3.6z
		 M55.5,6.4c0.3-0.1,0.7-0.2,1,0c0.4,0.2,0.7,0.6,0.9,1.1c0.1,0.5,0.1,0.9,0.1,1.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.4,0.3
		C56.3,9.8,55.6,10,55,9.6c-0.2-0.1-0.3-0.3-0.4-0.5c-0.3-0.5-0.3-1.1-0.2-1.6C54.6,7,55,6.6,55.5,6.4z M54,3.1
		c0.3-0.1,0.6,0,0.7,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.5-0.1,1.1-0.4,1.5C53.3,6.4,51.7,3.6,54,3.1z M52.5,5.8L52.3,6
		c0.4-0.2,0.8,0.2,0.8,0.6c0,0.4-0.4,0.8-0.8,0.8c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4C51.9,6.4,52.1,6,52.5,5.8z
		 M51.9,9.7c0.1,0.2,0.1,0.4,0,0.6c0,0.2-0.1,0.3-0.2,0.4c-0.3,0.3-0.8,0.1-1.2,0.2c-0.5,0.1-0.9,0.5-1.3,0.3
		c-0.3-0.1-0.4-0.6-0.3-0.9c0.1-0.3,0.4-0.6,0.7-0.7c0.4-0.2,0.8-0.3,1.2-0.3C51.3,9.3,51.7,9.4,51.9,9.7z M50.8,4.9
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.4,0.6,0.3,0.9c0,0.2-0.2,0.4-0.3,0.6c-0.3,0.3-0.6,0.6-1,0.7
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2C50,5.5,50.4,5.2,50.8,4.9z
		 M49.9,2.8C50,3,50,3.3,50,3.6c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2
		C46.7,4.2,49,1.1,49.9,2.8z M47.1,5.9c0.2,0,0.3,0.1,0.4,0.2c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6C47.9,8.7,47.5,9,47,9
		c-0.5,0.1-1-0.1-1.4-0.4c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.4,0.2-0.9,0.5-1.2C46,6.4,46.4,6.2,46.8,6C46.9,5.9,47,5.9,47.1,5.9z
		 M45.6,4.1c0.2-0.2,0.6-0.3,0.8-0.1c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.7-0.7,0.6c-0.2-0.1-0.4-0.3-0.4-0.5c0,0,0-0.1,0-0.1
		l0,0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2C45.4,4.3,45.5,4.2,45.6,4.1z M45.1,1.5c0.2-0.1,0.3,0,0.5,0
		c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.8,0.3
		c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3C44.8,1.7,44.9,1.6,45.1,1.5z M45.5,9.5c0.3-0.2,0.8-0.2,0.9,0.2
		c0.1,0.3-0.1,0.5-0.3,0.7c-0.4,0.4-1.6,0.6-1.5-0.4C44.7,9.7,45.2,9.5,45.5,9.5z M41.9,4.5c0-0.4,0.4-0.7,0.8-0.9
		c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.5-0.3,1-0.6,1.3c-0.4,0.4-0.8,0.6-1.3,0.8
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.2-0.7C41.9,4.9,41.8,4.7,41.9,4.5z M42.4,12.8
		c-0.1,0.9-0.3,1.7-1.2,1.9c-0.8,0.2-1.7-0.2-2.1-0.9c-0.7-1.1,0.2-2,1.1-2.4C41.5,10.9,42.5,11.4,42.4,12.8z M41.2,6.4
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
		C40.7,9,40.4,9,40.1,9.1c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.3-0.4-0.4-0.7c-0.1-0.6-0.2-1.2,0.2-1.6c0.2-0.2,0.4-0.3,0.6-0.4
		C40.5,6.3,40.9,6.2,41.2,6.4z M40.9,4.5c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0-0.3,0-0.4-0.1
		C39.3,5.3,39,4.9,39,4.4c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0c0-0.2,0.2-0.5,0.4-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1
		C40.8,3.9,40.9,4.2,40.9,4.5z M39.2,1.3c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.3,0.1-1.5,0.3-1.7,0C38.3,2.5,39,1.6,39.2,1.3z M38.3,6c0.4,0.9,0.1,1.9-0.4,2.7c-0.2,0.3-0.5,0.7-0.9,0.7
		c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.4-0.2-1-0.2-1.5c0-0.3,0-0.5,0.2-0.8c0.3-0.6,1-0.7,1.6-0.8
		c0.3,0,0.5,0,0.8,0.1C38.1,5.6,38.2,5.8,38.3,6z M37.3,3.5c0.2,0,0.5,0.1,0.6,0.3c0.3,0.3,0,0.9-0.4,1.1c-0.4,0.2-0.8,0.2-1.2,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2,0-0.8,0.3-1C36.6,3.5,36.9,3.4,37.3,3.5z M35.9,1.4c0.1,0.1,0.2,0.2,0.3,0.2
		c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.3,0.3,0.5c-0.2,1-1.8,1.6-2.2,0.5C34.8,2.3,35.2,1,35.9,1.4z M33.2,3.5
		c0.2-0.1,0.4-0.2,0.6-0.2v0c0.2,0,0.4,0,0.6,0.1c0.4,0.2,0.6,0.6,0.7,1c0.1,0.4,0,0.9-0.2,1.3c-0.1,0.3-0.3,0.5-0.6,0.5
		c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1-0.1v0c-0.4-0.3-0.8-0.6-0.9-1.1C32.7,4.4,32.8,3.8,33.2,3.5z M30.7,0.5
		c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.1,0.9,0c0.3,0.1,0.6,0.3,0.7,0.6s0,0.7-0.2,0.9C32.2,2.1,32,2.1,31.7,2
		c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1-0.1-0.3-0.2-0.4c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2
		C30.6,0.7,30.6,0.6,30.7,0.5z M28.3,1.5c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0,0.6,0.2,0.8,0.4c0.4,0.2,0.7,0.5,1.1,0.7
		c0.2,0.2,0.5,0.3,0.7,0.6c0.2,0.4,0.2,0.9-0.1,1.3c-0.3,0.4-0.7,0.6-1.1,0.6c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0
		c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0-0.6,0-0.8c0-0.3-0.2-0.5-0.3-0.8C28.2,2.1,28.1,1.8,28.3,1.5z M25.4,3.7
		c0.1-0.4,0.4-1.6,0.9-1.7c0.4,0,0.9,1.2,1.1,1.5c0.6,0.9-1,1.1-1.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.4C25.3,3.9,25.4,3.8,25.4,3.7z
		 M25,7.3c0-0.5,0.1-1.2,0.5-1.5c0.4-0.4,1-0.3,1.6-0.1c0.5,0.1,1,0.4,1.3,0.8c0.5,0.6,0.6,1.3,0.5,2.1c0,0.2,0,0.5-0.2,0.7
		c-0.3,0.5-0.9,0.7-1.5,0.7c-0.5,0.1-1.1,0.1-1.5-0.2C25.4,9.4,25.1,9,25,8.6C24.9,8.2,24.9,7.7,25,7.3z M22.9,2.2
		C22.9,2.1,23,2,23.2,1.9c0.4-0.3,0.9-0.3,1.3-0.1c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3-0.1,0.6-0.2,0.9
		c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.4-0.4-0.5-0.7C22.9,2.7,22.8,2.5,22.9,2.2z M22.4,4.4
		c0.2,0,0.4,0.1,0.6,0.2c0.4,0.3,0.5,0.8,0.4,1.2c-0.1,0.5-0.3,0.9-0.4,1.3c-0.1,0.2-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.1-0.6,0
		c-0.5-0.3-0.8-0.8-1-1.3c-0.2-0.5-0.2-1.1-0.2-1.7c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.4-0.2,0.6-0.1C21.9,4.3,22.1,4.4,22.4,4.4z
		 M19.3,1.5c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.1,0.8,0c0.6,0.2,1.2,0.5,1.4,1C22.1,1.5,22,1.7,22,2
		c-0.1,0.4-0.2,0.8-0.5,1c-0.2,0.1-0.4,0.1-0.6,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-0.6-1.1-0.9c-0.1-0.1-0.2-0.1-0.2-0.2
		C19.3,1.7,19.3,1.6,19.3,1.5z M18.4,3.6c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.5,0.2,0.7,0.3
		c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.3,0.3,0.7,0.3,1.1c0,0.2,0.1,0.5,0,0.7c-0.1,0.4-0.6,0.5-0.9,0.4c-0.4-0.1-0.6-0.5-0.7-0.8
		c-0.1-0.4-0.2-0.7-0.3-1.1C18.4,4,18.4,3.8,18.4,3.6z M17.2,6.4c0.3-0.1,0.7-0.2,1,0c0.4,0.2,0.7,0.6,0.9,1.1
		c0.1,0.5,0.1,0.9,0.1,1.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.4,0.3C18,9.8,17.3,10,16.7,9.6c-0.2-0.1-0.3-0.3-0.4-0.5
		C16,8.6,16,8,16.1,7.5C16.3,7,16.7,6.6,17.2,6.4z M15.7,3.1c0.3-0.1,0.6,0,0.7,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0
		c0,0.5-0.1,1.1-0.4,1.5C15,6.4,13.4,3.6,15.7,3.1z M14.2,5.8L14,6c0.4-0.2,0.8,0.2,0.8,0.6c0,0.4-0.4,0.8-0.8,0.8
		c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4C13.6,6.4,13.8,6,14.2,5.8z M13.6,9.7c0.1,0.2,0.1,0.4,0,0.6c0,0.2-0.1,0.3-0.2,0.4
		c-0.3,0.3-0.8,0.1-1.2,0.2c-0.5,0.1-0.9,0.5-1.3,0.3c-0.3-0.1-0.4-0.6-0.3-0.9c0.1-0.3,0.4-0.6,0.7-0.7c0.4-0.2,0.8-0.3,1.2-0.3
		C13,9.3,13.4,9.4,13.6,9.7z M12.5,4.9c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.4,0.6,0.3,0.9c0,0.2-0.2,0.4-0.3,0.6
		c-0.3,0.3-0.6,0.6-1,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5,0-0.7c0.1-0.1,0.1-0.1,0.2-0.2
		C11.7,5.5,12.1,5.2,12.5,4.9z M11.6,2.8c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.5-0.1-0.7-0.2C8.4,4.2,10.7,1.1,11.6,2.8z M8.8,5.9c0.2,0,0.3,0.1,0.4,0.2
		c0.5,0.4,1,0.9,0.9,1.6c0,0.2-0.1,0.4-0.2,0.6C9.6,8.7,9.2,9,8.7,9C8.2,9.1,7.7,9,7.4,8.6C7.1,8.4,7,8.1,6.9,7.8
		c0-0.4,0.2-0.9,0.5-1.2C7.7,6.4,8.1,6.2,8.5,6C8.6,5.9,8.7,5.9,8.8,5.9z M7.3,4.1C7.5,3.9,7.9,3.8,8.1,4c0.2,0.2,0.2,0.5,0.1,0.8
		C8.1,5.2,7.8,5.5,7.4,5.4C7.2,5.4,7.1,5.2,7.1,4.9c0,0,0-0.1,0-0.1L7,5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4l0,0.2
		C7.2,4.3,7.2,4.2,7.3,4.1z M6.8,1.5c0.2-0.1,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.4,0.3L8.2,1.9c0.2,0,0.4,0.2,0.4,0.4
		c0,0.2,0,0.4-0.1,0.6C8.3,3.2,7.9,3.3,7.6,3.2c-0.5-0.1-0.8-0.6-1-1.1c0-0.1-0.1-0.2-0.1-0.3C6.5,1.7,6.7,1.6,6.8,1.5z M7.2,9.5
		C7.5,9.3,8,9.3,8.1,9.7c0.1,0.3-0.1,0.5-0.3,0.7C7.4,10.9,6.3,11,6.4,10C6.4,9.7,6.9,9.5,7.2,9.5z M3.6,4.5c0-0.4,0.4-0.7,0.8-0.9
		s0.8-0.1,1.2-0.2c0.2,0,0.4-0.1,0.6,0C6.3,3.6,6.4,3.8,6.4,4c0,0.5-0.3,1-0.6,1.3C5.4,5.7,5,5.9,4.5,6.2C4.4,6.2,4.3,6.3,4.2,6.3
		C4,6.2,3.9,6,3.8,5.8C3.8,5.6,3.7,5.3,3.7,5.1C3.6,4.9,3.5,4.7,3.6,4.5z M2.9,6.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4
		C3.1,7.7,3,8.1,2.9,8.5c0,0.1,0,0.2-0.1,0.3C2.8,8.8,2.7,8.9,2.6,8.9C2.4,9,2.1,9,1.9,9.1c-0.1,0-0.3,0.1-0.4,0
		C1.2,9,1.1,8.7,1.1,8.4C1,7.8,0.9,7.2,1.3,6.8c0.2-0.2,0.4-0.3,0.6-0.4C2.2,6.3,2.6,6.2,2.9,6.4z M2.6,4.5c0,0.3-0.2,0.5-0.3,0.7
		C2.1,5.4,2,5.6,1.7,5.6c-0.1,0-0.3,0-0.4-0.1C1,5.3,0.7,4.9,0.7,4.4c0,0,0,0,0,0.1l0-0.1c0,0,0,0,0,0c0-0.2,0.2-0.5,0.4-0.6
		c0.2-0.2,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1C2.6,3.9,2.6,4.2,2.6,4.5z M0.2,2.8C0,2.5,0.7,1.6,0.9,1.3c0.5-0.8,1.2,0.4,1.4,0.9
		c0,0.2,0,0.4-0.1,0.5C2,2.8,1.9,2.8,1.8,2.8C1.5,2.9,0.4,3.2,0.2,2.8z M0.8,9.9C1.2,9.8,1.6,9.7,2,9.7c0,0,0.1,0,0.1,0
		c0.3,0,0.7,0.1,0.7,0.3C3,11.3-0.6,10.7,0.8,9.9z M0.8,13.9c-0.7-1.1,0.2-2,1.1-2.4c1.3-0.5,2.3-0.1,2.2,1.4
		c-0.1,0.9-0.3,1.7-1.2,1.9C2.1,14.9,1.2,14.5,0.8,13.9z M0.2,19.4C0,19.2,0.7,18.2,0.9,18c0.5-0.8,1.2,0.4,1.4,0.9
		c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1C1.5,19.6,0.4,19.8,0.2,19.4z M1.9,26.8c1.3-0.5,2.3-0.1,2.2,1.4
		C4,29,3.8,29.9,2.9,30.1c-0.8,0.2-1.7-0.2-2.1-0.9C0.1,28.1,1,27.2,1.9,26.8z M3.5,32.2c0,0.3-0.1,0.5-0.1,0.8
		c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
		C1,33.6,1,33.4,0.9,33.3c-0.1-0.3-0.1-0.7-0.1-1c0-0.4,0.1-0.8,0.4-1.1C1.4,31,1.7,31,2,31c0.5,0,1,0.1,1.2,0.4
		C3.4,31.6,3.5,31.9,3.5,32.2z M0.8,36.6c-0.3-0.5-0.3-1-0.1-1.4h0c0.3-0.4,0.7-0.8,1.2-1c1.1-0.5,2.1-0.2,2.2,1h0
		c0,0.1,0,0.2,0,0.4c-0.1,0.9-0.3,1.7-1.2,1.9C2.1,37.7,1.2,37.2,0.8,36.6z M0.2,42.2c-0.2-0.3,0.6-1.2,0.7-1.5
		c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1C1.5,42.3,0.4,42.5,0.2,42.2z M0.8,49.3
		C1.2,49.1,1.6,49,2,49c0,0,0.1,0,0.1,0c0.3,0,0.7,0.1,0.7,0.3C3,50.7-0.6,50.1,0.8,49.3z M0.8,53.2c-0.7-1.1,0.2-2,1.1-2.4
		c1.3-0.5,2.3-0.1,2.2,1.4c-0.1,0.9-0.3,1.7-1.2,1.9C2.1,54.3,1.2,53.9,0.8,53.2z M0.2,58.8c-0.2-0.3,0.6-1.2,0.7-1.5
		c0.5-0.8,1.2,0.4,1.4,0.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1C1.5,58.9,0.4,59.2,0.2,58.8z"/>
            <path className="st3" d="M2.3,0C2.2,0.2,2,0.4,1.7,0.6C1.5,0.7,1.3,0.8,1.1,0.9C0.9,1,0.7,1.1,0.5,1.2C0.3,1.2,0.1,1,0,0.8v1.8
		c0.2,0,0.5,0,0.7,0.1C1.3,3,1.7,3.6,2,4.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2C0.9,6.2,0.4,6.4,0,6.4v1.2
		c1.5-0.3,3.4,2.9,0.4,2.5c-0.2,0-0.3-0.1-0.4-0.1v1.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4
		c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.2,0.1V17c0.4,0.3,0.5,0.7,0.1,1.2c0,0-0.1,0.1-0.1,0.1v4
		c0.3-0.5,0.8-1,1.4-1.3c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.5-0.2,1-0.5,1.5
		c-0.2,0.2-0.3,0.5-0.6,0.7c-0.6,0.4-1.4,0.2-2-0.1c-0.2-0.1-0.4-0.2-0.5-0.3v0.7c0.4,0,0.8,0.3,1.1,0.5c0.1,0.1,0.3,0.2,0.3,0.3
		c0,0.1,0,0.2,0,0.3l0.1-0.1c-0.2,0.4-0.5,0.6-1,0.7c-0.2,0-0.4,0-0.6,0v0.4c0.7-0.1,1.5,0.3,1.4,1.1c-0.1,0.5-0.9,1.4-1.4,1.1V33
		c0.4,0,0.8,0,1.1,0.2c0.2,0.1,0.5,0.3,0.6,0.6h0C1.9,34,2,34.4,2.1,34.7c0,0.2,0,0.3,0,0.5c-0.1,0.3-0.3,0.5-0.6,0.6
		C1.1,36,0.6,36,0.2,35.9c-0.1,0-0.1,0-0.2-0.1v0.7c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1
		c-0.1,0.4-0.3,0.8-0.7,1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.5-0.3v1.8c0.2,0,0.5,0,0.7,0.1
		c0.6,0.2,1,0.8,1.3,1.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.2-0.8,0.5-1.2,0.4v1.2c1.5-0.3,3.4,2.9,0.4,2.5
		c-0.2,0-0.3-0.1-0.4-0.1v1.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.2,0.1v1.6c0.4,0.3,0.5,0.7,0.1,1.2c0,0-0.1,0.1-0.1,0.1v4c0.3-0.5,0.8-1,1.4-1.3
		c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.5-0.2,1-0.5,1.5c-0.2,0.2-0.3,0.5-0.6,0.7
		c-0.6,0.4-1.4,0.2-2-0.1c-0.2-0.1-0.4-0.2-0.5-0.3v0.7c0.4,0,0.8,0.3,1.1,0.5c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3l0.1-0.1
		c-0.2,0.4-0.5,0.6-1,0.7c-0.2,0-0.4,0-0.6,0v0.4c0.7-0.1,1.5,0.3,1.4,1.1c-0.1,0.5-0.9,1.4-1.4,1.1v1.8c0.1,0,0.2,0,0.2,0
		c0.1,0,0.2,0,0.4,0c0.2,0,0.5,0,0.7,0.1c0.2,0,0.3,0,0.5,0.1c0.1,0,0.1,0.1,0.1,0.1c0.3,0.2,0.5,0.6,0.5,1c0,0.1-0.1,0.3-0.1,0.4h1
		c0-0.1,0-0.1,0-0.2c0.2-0.6,0.9-1,1.5-0.9c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.2
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0h1.8c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7
		c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2c0.6,0.3,0.7,1,0.8,1.6c0,0.3,0,0.5-0.1,0.8
		c-0.1,0.2-0.2,0.3-0.4,0.3h5.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6c0.3-0.1,0.6-0.1,0.8-0.1
		c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1c0,0.1-0.1,0.3-0.1,0.4h4.3c0.3-0.1,0.6-0.2,0.9-0.2
		c0.3,0,0.7,0.1,1,0.2h0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8
		c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2c0.6,0.3,0.7,1,0.8,1.6c0,0.3,0.1,0.5-0.1,0.8c-0.1,0.2-0.3,0.3-0.4,0.4h4.3
		c-0.1,0-0.2-0.1-0.2-0.2c-0.2-0.2-0.3-0.7,0.1-0.7c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-0.3,0.2-0.5,0.5-0.6c0.3-0.1,0.6-0.1,0.8-0.1c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1
		c0,0.1-0.1,0.3-0.1,0.4h2.7c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.4-0.2,0.6-0.2
		c0.2,0,0.4,0.2,0.6,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.2,0.5,0.1,0.7c0,0,0,0,0,0h2c-0.1,0-0.1-0.1-0.1-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.4
		c0-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0c0.2,0,0.5,0,0.7,0.1c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.4,0.3,0.7
		c0,0.3-0.2,0.5-0.4,0.6c-0.1,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0.1h2.1c0-0.1,0-0.1,0-0.2c0.2-0.6,0.9-1,1.5-0.9
		c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0h1.8
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2
		c0.3,0,0.5,0,0.8,0.2c0.6,0.3,0.7,1,0.8,1.6c0,0.3,0,0.5-0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.3h5.4c-0.2-0.1-0.3-0.3-0.5-0.4
		c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6c0.3-0.1,0.6-0.1,0.8-0.1c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2
		c0.4,0.2,0.6,0.6,0.5,1c0,0.1-0.1,0.3-0.1,0.4h4.3c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7,0.1,1,0.2h0.1c-0.2-0.1-0.5-0.2-0.7-0.3
		c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.6-0.2,0.9-0.2c0.3-0.2,0.7-0.4,1.2-0.2
		c0.7,0.2,1.3,0.9,1.3,1.6c0,0.4-0.1,0.8-0.4,1c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.4,0.4h5.5c-0.2-0.2-0.3-0.3-0.5-0.4
		c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6c0.3-0.1,0.6-0.1,0.8-0.1c0,0,0,0,0,0v-1.8c-0.1,0-0.2-0.1-0.2-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.2-0.5-0.1-0.6c0.2-0.3,0.6-0.4,1-0.5v-0.4c-0.2,0-0.4-0.1-0.6-0.2
		c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.5,0.4-0.8,0.9-0.8c0,0,0.1,0,0.1,0v-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.2-0.5-0.1-0.7
		c0-0.4,0.1-0.7,0.3-1v-4c-0.3,0.2-0.8,0.2-1.2,0c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0
		c0.3,0.1,0.7,0.3,1,0.5v-1.6c0,0-0.1,0-0.1,0c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9
		c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.2,0,0.4,0,0.6,0.1c0,0,0,0,0.1,0.1v-1.2
		c-0.2-0.1-0.5-0.3-0.6-0.5c-0.2-0.3-0.3-0.6-0.2-0.9c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.4v-1.2c0,0-0.1,0-0.1,0
		c-0.4,0-0.8-0.3-1-0.6s-0.4-0.6-0.6-1c-0.2-0.4-0.4-0.9-0.2-1.4c0.2-0.5,0.7-0.7,1.2-0.8c0.2,0,0.5-0.1,0.7-0.1v-1.8
		c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6
		c0.3-0.1,0.6-0.1,0.8-0.1c0,0,0,0,0,0v-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.3-0.4-0.5-0.8-0.5c-0.2,0-0.5-0.2-0.5-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.2h0c0.4-0.3,0.8-0.5,1.2-0.6
		c0.2-0.1,0.4-0.1,0.6-0.1v-3.9c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5
		c-0.2-0.2-0.2-0.5-0.1-0.6c0.2-0.3,0.6-0.4,1-0.5v-0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.5,0.4-0.8,0.9-0.8
		c0,0,0.1,0,0.1,0v-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.2-0.5-0.1-0.7c0-0.4,0.1-0.7,0.3-1v-4c-0.3,0.2-0.8,0.2-1.2,0
		c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0c0.3,0.1,0.7,0.3,1,0.5v-1.6c0,0-0.1,0-0.1,0
		c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8
		c0.2-0.3,0.5-0.5,0.9-0.6c0.2,0,0.4,0,0.6,0.1c0,0,0,0,0.1,0.1V10c-0.2-0.1-0.5-0.3-0.6-0.5C70.1,9.2,70,8.9,70,8.6
		c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.4V6.4c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1
		c-0.2-0.4-0.4-0.9-0.2-1.4c0.2-0.5,0.7-0.7,1.2-0.8c0.2,0,0.5-0.1,0.7-0.1V0.8c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7
		c0,0-0.1-0.1-0.1-0.1h-5.5c0,0,0,0-0.1,0c-0.6,0.3-1.3,0.2-2,0h-6.3c-0.1,0.2-0.3,0.5-0.6,0.6c-0.2,0.1-0.4,0.2-0.7,0.3
		C55.1,1,55,1.2,54.7,1.2c-0.3,0-0.5-0.2-0.6-0.4C54,0.6,54,0.3,53.8,0.1c0,0-0.1-0.1-0.1-0.1h-5.4c0,0-0.1,0-0.1,0.1
		c-0.7,0.3-1.4,0.2-2.1-0.1h-5.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1h-6.3c-0.1,0.2-0.3,0.4-0.6,0.6
		c-0.2,0.1-0.4,0.2-0.7,0.3C32.4,1,32.2,1.1,32,1.2c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7C31.1,0.1,31,0,31,0h-5.5
		c0,0,0,0-0.1,0c-0.6,0.3-1.3,0.2-2,0h-6.3c-0.1,0.2-0.3,0.5-0.6,0.6c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.6,0.3
		c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7c0,0-0.1-0.1-0.1-0.1H9c0,0-0.1,0-0.1,0.1C8.2,0.3,7.5,0.3,6.8,0H2.3z M3.8,4.1
		C3,4,2.1,3.8,1.9,2.9C1.7,2.1,2.1,1.2,2.8,0.8c0.5-0.3,1-0.3,1.4-0.1v0C4.6,1,5,1.5,5.2,1.9c0.5,1.1,0.2,2.1-1,2.2v0
		C4.1,4.1,4,4.1,3.8,4.1z M24.7,1.3c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0
		C23.8,3.1,23.4,3,23,2.9c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4
		c0.1-0.2,0.4-0.3,0.7-0.4C23.7,1,24.3,0.9,24.7,1.3z M27.1,0.7C27.1,0.7,27.1,0.7,27.1,0.7L27.1,0.7C27.1,0.7,27.1,0.7,27.1,0.7
		c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5c-0.2,0.2-0.5,0.3-0.7,0.2c-0.3,0-0.5-0.2-0.7-0.3
		c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4C26.2,1,26.7,0.7,27.1,0.7z M30.8,2.7c0.5-0.1,1-0.1,1.4,0.1c0.6,0.2,1,0.8,1.3,1.4
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1
		c-0.2-0.4-0.4-0.9-0.2-1.4C29.8,3,30.3,2.8,30.8,2.7z M47.5,1.3c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.7,0,1
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.2-1.2-0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4C46.4,1,47,0.9,47.5,1.3z M49.8,0.7
		C49.8,0.7,49.8,0.7,49.8,0.7L49.8,0.7C49.8,0.7,49.8,0.7,49.8,0.7c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8
		c0,0.2,0,0.3-0.1,0.5c-0.2,0.2-0.5,0.3-0.7,0.2c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4
		C49,1,49.4,0.8,49.8,0.7z M53.5,2.7c0.5-0.1,1-0.1,1.4,0.1c0.6,0.2,1,0.8,1.3,1.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2
		c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1c-0.2-0.4-0.4-0.9-0.2-1.4C52.5,3,53,2.8,53.5,2.7z
		 M61.1,15.8c-0.6,0.1-1.3,0.1-1.9,0c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4,0-0.6c0.1-0.1,0.1-0.1,0.2-0.1
		c0.4-0.1,0.9-0.2,1.3-0.2c0.3,0,0.7,0.1,0.9,0.4c0.2,0.3,0.2,0.7-0.1,0.8L61.1,15.8z M63,20.8c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.3l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.1-0.7,0-0.8-0.3c-0.1-0.3,0.1-0.6,0.3-0.8
		c0.3-0.3,0.7-0.4,1-0.2C63,20.3,63,20.6,63,20.8c-0.1,0.1-0.1,0.1-0.1,0.1L63,20.8z M56,38.9c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.2,0.1-0.3,0.3-0.6,0.3c-0.3,0-0.5-0.2-0.6-0.4s-0.2-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.4-0.7
		c0-0.3,0.2-0.5,0.5-0.6c0.3-0.1,0.6-0.1,0.8-0.1c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1
		C56.6,38.3,56.3,38.7,56,38.9z M60.9,39.1c0.2,0.4,0.3,0.8,0.3,1.2c0,0,0,0.1,0,0.1c0,0.3-0.1,0.7-0.3,0.7
		C59.5,41.3,60.2,37.7,60.9,39.1z M61.1,54.1c-0.6,0.1-1.3,0.1-1.9,0c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4,0-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.2c0.3,0,0.7,0.1,0.9,0.4c0.2,0.3,0.2,0.7-0.1,0.8L61.1,54.1z M63,59.1
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.1-0.7,0-0.8-0.3
		c-0.1-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.7-0.4,1-0.2C63,58.6,63,58.9,63,59.1c-0.1,0.1-0.1,0.1-0.1,0.1L63,59.1z M34.9,68.7
		c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.5-0.3-0.7-0.5c0-0.1-0.1-0.1-0.1-0.2
		c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.3-0.4,0.4-0.5c0.3-0.2,0.7-0.1,1,0c0.2,0.1,0.3,0.2,0.5,0.3C35,67.9,35,68.4,34.9,68.7z M36.5,68
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.5,0-0.6-0.2c-0.1-0.2-0.1-0.5,0.1-0.7c0,0,0.1-0.1,0.1-0.1
		C36.2,67.8,36.4,67.8,36.5,68z M31.3,67.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.2-0.5-0.1-0.6
		c0.7-0.8,2.6-0.6,2.4,0.7C32.8,66.9,31.8,68,31.3,67.2z M16.3,67.2c0.2-0.3,0.4-0.5,0.7-0.7c0.1-0.1,0.3-0.2,0.4-0.2
		c1.9-1,2.3,0.3,1,1.5c-0.4,0.3-1,0.9-1.5,0.8C16.2,68.6,16,67.8,16.3,67.2z M14.7,68c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.2-0.2-0.5-0.4-0.6c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.3,0c0.3,0.1,0.7,0.2,0.9,0.4
		c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0,0.4-0.2,0.5C15,68.2,14.8,68.1,14.7,68z M7.3,53.1c-0.2-0.2-0.3-0.5-0.1-0.7
		c0.1-0.1,0.1-0.1,0.2-0.1C7.6,52,7.7,51.9,8,51.9c0.4,0,0.8,0.3,1,0.6c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0.2-0.3,0.2-0.5,0.2
		C8,53.2,7.6,53.3,7.3,53.1z M12.1,51.4c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6
		c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8
		C11.7,52,11.9,51.7,12.1,51.4z M8.9,51.5c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1c-0.1-0.1-0.1-0.3-0.1-0.4
		c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.1,0.7,0C8.9,49.4,9,49.4,9,49.5c0.3,0.4,0.6,0.9,0.9,1.3c0,0.1,0.1,0.1,0.1,0.2
		c0,0-0.1,0.1-0.1,0.1C9.6,51.4,9.3,51.5,8.9,51.5z M23.1,48.9c0.4,0.4,0.6,1,0.8,1.5c0,0.2,0.1,0.4,0,0.5c-0.1,0.2-0.3,0.3-0.5,0.2
		c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2L23.1,48.9z M10.7,45.6
		c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0
		c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0C10.5,45.5,10.6,45.5,10.7,45.6z M11.6,45.9c0.1-0.5,0.6-0.8,1.1-1
		c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4
		c-0.1,0.4,0.1,1.3-0.8,0.8C11.7,47.1,11.5,46.4,11.6,45.9z M9,33.7c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0.1
		c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1h0c0,0.1,0,0.2,0,0.2c0,0.3-0.2,0.6-0.4,0.8C9.6,34.9,9.5,35,9.4,35
		c-0.2,0.1-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.5-0.4h0
		C8.8,33.7,8.9,33.7,9,33.7z M10.4,34.8c0.1-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1,0,0.1,0
		c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0-0.4-0.1C10.3,35.1,10.4,35,10.4,34.8z
		 M12.1,35c0.5-0.2,1.8,0.2,1.4,0.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.4-0.5,0.3
		C11.6,37,11,35.4,12.1,35z M7.3,14.8c-0.2-0.2-0.3-0.5-0.1-0.7C7.3,14,7.4,14,7.4,13.9c0.2-0.2,0.2-0.4,0.6-0.3
		c0.4,0,0.8,0.3,1,0.6c0.1,0.2,0.1,0.4-0.1,0.5C8.8,14.9,8.6,15,8.4,15C8,14.9,7.6,15,7.3,14.8z M12.1,13.1c0.2-0.2,0.5-0.4,0.7-0.7
		c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1
		c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-1.6-0.2-2.2-0.6
		c-0.3-0.2-0.7-0.5-0.7-0.8C11.7,13.7,11.9,13.4,12.1,13.1z M8.9,13.2c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7,0C8.9,11.1,9,11.2,9,11.2c0.3,0.4,0.6,0.9,0.9,1.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1C9.6,13.1,9.3,13.3,8.9,13.2z M23.1,10.6c0.4,0.4,0.6,1,0.8,1.5c0,0.2,0.1,0.4,0,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2
		L23.1,10.6z M10.7,7.3c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1C9.7,8.1,9.3,7.8,9.4,7.5c0.1-0.2,0.3-0.4,0.5-0.4
		c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0C10.5,7.2,10.6,7.2,10.7,7.3z M11.6,7.6
		c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6
		c0,0.2-0.1,0.4-0.3,0.4c-0.1,0.4,0.1,1.3-0.8,0.8C11.7,8.9,11.5,8.1,11.6,7.6z M22.5,5c0.2,0.3,0.1,0.8-0.2,1.1
		c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.4-0.4-0.9-0.3-1.4l0,0.1C21.8,4.4,22.3,4.6,22.5,5z M20.1,5.3
		c0.4,0.2,0.7,0.7,0.5,1.1c-0.2,0.4-0.7,0.5-1.1,0.4c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4
		C18,4.6,19,4.7,20.1,5.3z M37.9,4.4C38,4.5,38,4.6,38.1,4.7c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.2-0.3,0.2-0.4,0.2
		c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0.1-0.3C37.6,4.4,37.8,4.4,37.9,4.4L37.9,4.4z M35.7,4.9L35.7,4.9c0.3,0.1,0.7,0.2,1,0.4
		c0.4,0.2,0.7,0.7,0.5,1.1c-0.2,0.4-0.7,0.5-1.1,0.4c-0.1,0-0.3-0.1-0.4-0.1v0c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.4,0-0.5-0.2
		c-0.1-0.1,0-0.3,0-0.4C34.6,5,35.1,4.8,35.7,4.9z M44.1,5.3c0.4,0.2,0.7,0.7,0.5,1.1c-0.2,0.4-0.7,0.5-1.1,0.4
		c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4C42.1,4.6,43,4.7,44.1,5.3z M45.5,4.7c0-0.2,0-0.4,0.2-0.4
		c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0c0.3,0.1,0.5,0.5,0.8,0.7C47.8,5.1,48,5.3,48,5.5c0.1,0.3-0.3,0.7-0.6,0.7
		c-0.3,0.1-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.7-0.8C45.6,5,45.5,4.8,45.5,4.7z M50.1,7.3c0.2,0.2,0.3,0.6,0.1,0.8
		c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0
		c0.1,0,0.3,0,0.4,0.1l-0.2,0C49.9,7.2,50,7.2,50.1,7.3z M47.6,7.4c0.3,0.3,0.5,0.7,0.7,1.1c0,0.1,0.1,0.2,0.1,0.3
		c0,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.9,1-1.6,0.9c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0.1-1,0.4-1.4
		C45.8,7.2,46.1,7,46.4,7C46.8,6.9,47.3,7.1,47.6,7.4z M50.1,45.6c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1
		c-0.3-0.1-0.7-0.4-0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0
		C49.9,45.5,50,45.5,50.1,45.6z M47.6,45.7c0.3,0.3,0.5,0.7,0.7,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4
		c-0.4,0.5-0.9,1-1.6,0.9c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0.1-1,0.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4
		C46.8,45.2,47.3,45.4,47.6,45.7z M39.1,64.5c-0.1-0.5,0.4-0.8,0.9-0.8c0.5,0,0.9,0.3,1.3,0.5c0.1,0.1,0.3,0.2,0.3,0.3
		c0,0.1,0,0.2,0,0.3l0.1-0.1c-0.2,0.4-0.5,0.6-1,0.7c-0.4,0.1-0.8,0-1.2-0.3C39.4,64.9,39.2,64.7,39.1,64.5z M38.2,64.8
		c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.2,0.4,0.2,0.6
		c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1C38.4,64.8,38.3,64.8,38.2,64.8z M43.1,65c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5
		c0-0.3,0.1-0.5,0.2-0.7c1.1-1.5,4.1,0.8,2.4,1.6c-0.2,0.1-0.5,0.1-0.7,0.1C43.7,65.1,43.4,65.1,43.1,65z M46.8,64.4
		c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.8-0.1-1.6,0.1-2.4c0.1-0.3,0.3-0.7,0.6-0.8c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1
		c0.1,0.4,0.1,0.8,0.2,1.3c0,0.3,0.1,0.5,0.1,0.8c0,0.4-0.2,0.8-0.6,1C47.6,64.7,47.1,64.7,46.8,64.4z M50.5,63.8
		c-0.4-0.1-0.5-0.6-0.5-0.9c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.1,0-0.2,0-0.3c0.1-0.2,0.4-0.3,0.6-0.3c0.6,0,1.1,0.4,1.3,0.9
		c0.3,0.5,0.3,1.1,0.2,1.6c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.4C51.1,63.8,50.8,63.9,50.5,63.8z M45.2,60.4
		c0.3,0.4,0.3,0.9,0.1,1.3c-0.1,0.2-0.4,0.3-0.7,0.4c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.5-0.2,0.7-0.1C45,60.2,45.1,60.3,45.2,60.4z M42.5,60.7c0.1,0.5-0.2,1-0.5,1.5
		c-0.2,0.2-0.3,0.5-0.6,0.7c-0.6,0.4-1.4,0.2-2-0.1c-0.3-0.1-0.6-0.2-0.7-0.5c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-1,0.8-1.8,1.7-2.4
		c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6C42.3,60,42.5,60.3,42.5,60.7z M33,64.1c-0.2,0.4-0.5,0.6-1,0.7
		c-0.4,0.1-0.8,0-1.2-0.3c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.5,0.4-0.8,0.9-0.8c0.5,0,0.9,0.3,1.3,0.5c0.1,0.1,0.3,0.2,0.3,0.3
		C33,64,33,64.1,33,64.1L33,64.1z M28,65.7c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.3,0
		c0.4,0.1,1.6,0.4,1.7,0.9C29.5,65,28.3,65.5,28,65.7z M29.1,63.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
		c-0.1-0.3-0.1-0.6,0.1-0.8c0.2-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.5-0.2,0.7-0.1c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.4,0.3,0.9,0.1,1.3
		c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0C29.6,63,29.3,63.2,29.1,63.2z M31.4,49.5c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1
		c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-1.6-0.2-2.2-0.6
		c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6
		C31.1,49.3,31.3,49.3,31.4,49.5z M34.6,48.9c0.5,0,0.8,0.1,1.1,0.4v0c0.8,0.6,0.9,1.8,0,2.2v0c-0.2,0.1-0.6,0.2-1,0.2
		C33.1,51.6,32.8,49,34.6,48.9z M29.5,57.8c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.2
		c0.2,0.2,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5c-0.1-0.2-0.1-0.4,0-0.6
		c0.1-0.1,0.1-0.2,0.2-0.3C29,58.5,29.3,58.2,29.5,57.8z M25.8,58.7c-0.4-0.1-0.5-0.6-0.4-0.9c0.1-0.4,0.5-0.6,0.8-0.7
		c0.4-0.1,0.7-0.2,1.1-0.3c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.3-0.7,0.3-1.1,0.3C26.3,58.7,26,58.8,25.8,58.7z M36.2,58.5c0-0.1,0-0.1,0.1-0.2
		c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.6-0.3,0.8
		c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.2-0.5-0.1-0.8s0.2-0.5,0.2-0.8
		C36.5,59.7,36.1,59.1,36.2,58.5z M37.9,59.1c-0.3-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1-0.1-0.3,0-0.4
		c0-0.2,0.2-0.3,0.3-0.3c0.7-0.2,1.5,0.2,1.6,1C39.1,58.4,38.9,59.7,37.9,59.1z M35.4,57.2c0.1,0.3,0.1,0.6,0,0.8
		c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1C34.7,56.4,35.2,56.8,35.4,57.2z M31.6,56.5
		c-0.3,0.4-0.9,0.3-1.4,0.1c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0C31.3,55,32.4,55.6,31.6,56.5z
		 M33.9,55.7c-0.5,0.2-1.2,0.1-1.5-0.3c-0.6-1,0.9-2.9,2-2.7C36.2,53,35,55.3,33.9,55.7z M37.9,53.9c0.2-0.1,0.5-0.2,0.7-0.1
		c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0.1,0.4,0,0.6c0,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.4,0.5-0.8,0.5c-0.2,0-0.4-0.2-0.6-0.4
		c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.1-0.1,0.2-0.1C37.4,54.1,37.6,54,37.9,53.9z M28.2,54.7
		c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4c-1.3-1,1.5-2.6,2-0.3C28.5,54.2,28.4,54.6,28.2,54.7z M35.7,56.3
		C35.7,56.3,35.7,56.3,35.7,56.3L35.7,56.3c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.1-0.1,0.1-0.1
		c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0.2,0.4,0.4c0,0,0,0.1,0,0.1
		C36.9,56.4,35.9,56.6,35.7,56.3z M39.5,55.8c-0.6-1,0.9-2.9,2-2.7c1.8,0.4,0.6,2.6-0.5,3C40.4,56.3,39.8,56.3,39.5,55.8z
		 M37.3,53.3c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.4-0.2-0.6c0-0.3,0.2-0.5,0.4-0.7
		c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.1C37.2,52.7,37.3,53,37.3,53.3z M26.6,50.8c0,0.1,0.1,0.1,0.1,0.2
		c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.4-0.9,0.3c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1c-0.1-0.1-0.1-0.3-0.1-0.4
		c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2C26,50,26.3,50.4,26.6,50.8z M21.8,51.9
		c-0.2,0.1-0.4,0.1-0.6,0c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.1-0.8-0.2-1.2c-0.1-0.5-0.5-0.9-0.3-1.3c0.1-0.3,0.6-0.4,0.9-0.3
		c0.3,0.1,0.6,0.4,0.7,0.7c0.2,0.4,0.3,0.8,0.3,1.2C22.2,51.3,22.1,51.7,21.8,51.9z M24.7,51.9c0.4,0,0.8,0.3,1,0.6l-0.2-0.2
		c0.2,0.4-0.2,0.8-0.6,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.1,0-0.3,0.1-0.4C24.3,51.9,24.5,51.9,24.7,51.9z M20.9,52.9
		c0.3,0,0.7,0.1,0.9,0.4c0.2,0.3,0.2,0.7-0.1,0.8l0.1,0c-0.6,0.1-1.3,0.1-1.9,0c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4,0-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1C20,52.9,20.4,52.9,20.9,52.9z M17.8,53.3c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.5
		c-0.2,0.4-0.7,0.6-1.1,0.6l0.3-0.1c-0.4,0-0.7-0.6-0.5-1C16.9,53.3,17.4,53.1,17.8,53.3z M19.9,54.5c0.2,0,0.3,0,0.4,0.1
		c0.3,0.2,0.2,0.6,0.2,0.9c0,0.3-0.1,0.5-0.3,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.1-1.2,0.1-1.8,0.2c-0.1,0-0.2,0-0.2,0
		c0,0,0-0.1-0.1-0.2c0-0.4-0.1-0.8,0.1-1.1c0.1-0.2,0.4-0.3,0.6-0.4C19,54.6,19.4,54.5,19.9,54.5z M14.1,56.6
		c-0.3-0.1-0.6-0.3-0.6-0.6c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.3-0.5,0.6-1,1.1-1.2C16.4,53.7,16.6,57.2,14.1,56.6z M21.9,55
		c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.3,1.1-0.3,1.6-0.2c0.5,0.2,1,0.6,1.2,1c0.1,0.3,0.2,0.7,0,1c-0.2,0.4-0.6,0.7-1.1,0.9
		c-0.5,0.1-0.9,0.1-1.4,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.4C21.7,56.3,21.5,55.6,21.9,55z M20.3,56.9
		c0.3,0.1,0.5,0.3,0.6,0.6c1,1.8-2.8,3.5-2.8,1.4c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.4-0.3,0.7-0.4
		C19.8,56.8,20.1,56.8,20.3,56.9z M22.1,59.5c-0.1-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.7-0.4,1-0.2c0.2,0.2,0.2,0.4,0.1,0.7
		c0,0,0,0.1,0,0.1l0.1-0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2
		C22.7,59.9,22.3,59.8,22.1,59.5z M25.1,59.4c0.5-0.2,1.1-0.2,1.7-0.2c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.1,0.6
		c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.8,0.5-1.2,0.4c-0.5-0.1-0.9-0.3-1.3-0.4c-0.2-0.1-0.5-0.1-0.6-0.3
		c-0.1-0.2-0.1-0.4,0-0.6C24,59.8,24.6,59.5,25.1,59.4z M22.2,60.5c0.4,0.3,0.5,1,0.5,1.5c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.3-0.2-0.5l0.1,0
		c-0.2-0.1-0.2-0.4-0.2-0.6c0.1-0.2,0.2-0.4,0.4-0.5C21.5,60.2,21.9,60.2,22.2,60.5z M20,62.2c0.2,0.1,0.4,0.3,0.5,0.5
		c1,2.2-4.8,2.9-2.7-0.1c0.3-0.4,0.7-0.6,1.2-0.6C19.3,62,19.7,62,20,62.2z M32,62.8c-0.3-0.1-0.6-0.2-0.7-0.5
		c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-1,0.8-1.8,1.7-2.4c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.4,0.7,0.5,1.1
		c0.1,0.5-0.2,1-0.5,1.5c-0.2,0.2-0.3,0.5-0.6,0.7C33.5,63.2,32.7,63,32,62.8z M45.5,60c-0.1,0-0.2-0.1-0.3-0.2
		c-0.3-0.2-0.4-0.6-0.4-1c0-0.4,0.2-0.7,0.4-1c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.4
		c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5-0.1,0.8c-0.2,0.3-0.6,0.4-0.9,0.5C45.7,60,45.6,60.1,45.5,60z M47.6,59
		c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.2,0.1-0.3,0.2-0.5c0.6-0.7,1.3-0.2,1.6,0.5C49.5,59,48.5,59.5,47.6,59z M42.7,58.1
		c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1c0.5,0,1,0.3,1.1,0.8C42.8,57.5,42.8,57.8,42.7,58.1z
		 M43.3,56.8c-0.3-0.2-0.4-0.5-0.3-0.8c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4C43.7,57,43.5,56.9,43.3,56.8z M46.9,56.6
		c0,0-0.1,0-0.1,0c-0.6-0.2-1.1-0.8-1.1-1.5c0.1-1.8,3.5-0.9,3,0.6C48.3,56.3,47.6,56.7,46.9,56.6z M50.6,54.7
		C50.6,54.7,50.6,54.7,50.6,54.7c-0.5,0-1.1-0.1-1.5-0.4c-1.3-1,1.5-2.6,2-0.3c0.1,0.3,0,0.6-0.2,0.7C50.8,54.7,50.7,54.7,50.6,54.7
		z M48.3,53c-0.1,0.2-0.3,0.2-0.5,0.2c-0.4,0-0.8,0.1-1.1-0.2c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.1,0.1-0.1,0.2-0.1
		c0.2-0.2,0.2-0.4,0.6-0.3c0.4,0,0.8,0.3,1,0.6C48.5,52.7,48.4,52.9,48.3,53z M45.7,52.5c-0.2,0.6-0.5,1.2-1,1.4
		c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.7,0.9-1.1
		c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.2C45.9,51.8,45.8,52.2,45.7,52.5z
		 M42.1,51.7c-1.7-0.1-2-2.7-0.2-2.7C43.9,48.9,44.6,51.8,42.1,51.7z M48.3,51.5c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.1,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.4,0.6,0.9,0.9,1.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1C48.9,51.4,48.6,51.5,48.3,51.5z M46.6,50.5c0,0.2,0.1,0.4,0,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2
		l-0.1-0.1C46.2,49.4,46.5,49.9,46.6,50.5z M39.9,49.5c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.6,0.2,1.2,0.3,1.7c0.1,0.3,0.1,0.8-0.2,1
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.6,0-1.2-0.1-1.7-0.4c-0.4-0.4-0.6-0.9-0.7-1.5c-0.1-0.5-0.2-1.1-0.5-1.6c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-0.3,0.3-0.4,0.5-0.5c0.7-0.2,1.6,0.1,2.2,0.5C39.5,49.1,39.7,49.3,39.9,49.5z M22.5,43.3c0.2,0.3,0.1,0.8-0.2,1.1
		c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.4-0.4-0.9-0.3-1.4l0,0.1C21.8,42.7,22.3,42.9,22.5,43.3z M23.7,44.1
		c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0
		c0.3,0.1,0.5,0.5,0.8,0.7c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.3-0.3,0.7-0.6,0.7C24.3,44.5,24,44.4,23.7,44.1z M26.2,44.1
		c-0.4-0.4-0.6-0.8-0.8-1.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.1
		c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2C27,44.7,26.5,44.4,26.2,44.1z
		 M26.6,32.9c0.5-0.2,1.1-0.1,1.4,0.4c0.1,0.2,0.2,0.4,0.2,0.6l0,0c0,0.2,0,0.4-0.1,0.6c-0.2,0.4-0.6,0.6-1,0.7
		c-0.4,0.1-0.9,0-1.3-0.2c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.2,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1h0C25.8,33.4,26.1,33,26.6,32.9z
		 M12.4,31.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.4-0.1-0.5c0,0,0.1-0.1,0.2-0.1c0.7-0.2,1.4-0.3,2-0.3
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.2,0.5,0.1,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.5,0.2
		c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.5-0.4-0.6-0.7C12.6,31.5,12.5,31.5,12.4,31.6z M31.4,11.2c0.2,0.1,0.3,0.3,0.3,0.5
		c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
		c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8
		c0.2-0.3,0.5-0.5,0.9-0.6C31.1,11,31.3,11,31.4,11.2z M34.6,10.7c0.5,0,0.8,0.1,1.1,0.4v0c0.8,0.6,0.9,1.8,0,2.2v0
		c-0.2,0.1-0.6,0.2-1,0.2C33.1,13.3,32.8,10.7,34.6,10.7z M39.1,26.2c-0.1-0.5,0.4-0.8,0.9-0.8c0.5,0,0.9,0.3,1.3,0.5
		c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3l0.1-0.1c-0.2,0.4-0.5,0.6-1,0.7c-0.4,0.1-0.8,0-1.2-0.3C39.4,26.6,39.2,26.4,39.1,26.2
		z M38.2,26.5c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.2c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1C38.4,26.5,38.3,26.5,38.2,26.5z M43.1,26.7c-0.2-0.1-0.4-0.2-0.6-0.4
		c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7c1.1-1.5,4.1,0.8,2.4,1.6c-0.2,0.1-0.5,0.1-0.7,0.1C43.7,26.8,43.4,26.8,43.1,26.7
		z M36.8,25C36.8,25,36.8,25,36.8,25c0.4,0.8,0.8,1.7,0.5,2.5c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.5,0.1-0.7,0v0c-0.5-0.1-1.1-0.3-1.5-0.6c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9
		c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.4-0.4,0.7-0.5v0c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0.1
		C36.6,24.6,36.7,24.8,36.8,25z M41.4,28.4c-0.1,0.6-1.1,1.6-1.6,0.8c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5
		c-0.2-0.2-0.2-0.5-0.1-0.6C39.6,26.9,41.6,27.1,41.4,28.4z M43.2,27.5c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3-0.1,0.7-0.4,0.8
		c-0.3,0.1-0.6,0.1-0.8,0c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.1,0.3-0.2,0.5-0.2C42.8,27.4,43,27.4,43.2,27.5z M42.6,32.4c0.2-0.6,0.9-1,1.5-0.9c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.3-0.8,0.4-1.2,0.4c-0.1,0-0.2,0-0.3-0.1
		c-0.1,0-0.2-0.1-0.2-0.2C42.6,32.5,42.6,32.5,42.6,32.4z M40.4,32.4c-0.2,0-0.4-0.2-0.6-0.3c-0.1,0-0.1-0.1-0.1-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0.3-0.2,0.7-0.2,1.1-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.2
		C40.9,32.4,40.7,32.4,40.4,32.4z M42.3,31.6c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.3,0
		c0.4,0.1,1.6,0.4,1.7,0.9C43.8,30.9,42.6,31.4,42.3,31.6z M39.5,30.1c0.1,0.3,0.1,0.7-0.1,1c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.2,0.1-0.4,0.2-0.6,0.1c-0.3,0-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.3-0.5
		c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.6-0.1c0.1,0,0.2,0.1,0.4,0.2C39.1,29.5,39.4,29.7,39.5,30.1z M28.3,28.6
		c0.3,0,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8,0c0.3,0.1,0.4,0.5,0.3,0.8c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1
		c-0.2,0.2-0.3,0.5-0.6,0.7c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.5,0-0.7
		c0.1-0.2,0.4-0.3,0.7-0.4C27.8,28.6,28,28.7,28.3,28.6z M29.8,24.5L29.8,24.5C29.7,24.5,29.7,24.5,29.8,24.5
		c-0.2,0.3-0.4,0.4-0.7,0.4c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8c0.2-0.2,0.4-0.4,0.7-0.5
		c0.2-0.1,0.5-0.2,0.7-0.1c0.1,0.1,0.3,0.2,0.3,0.3C29.9,23.6,29.9,24.1,29.8,24.5C29.7,24.5,29.7,24.5,29.8,24.5z M31.4,24.9
		c0.5,0,0.9,0.3,1.3,0.5c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3l0.1-0.1c-0.2,0.4-0.5,0.6-1,0.7c-0.4,0.1-0.8,0-1.2-0.3
		c-0.2-0.1-0.4-0.3-0.5-0.6C30.4,25.2,30.9,24.9,31.4,24.9z M27.8,25.4c0.4,0.1,1.6,0.4,1.7,0.9c0,0.4-1.2,0.9-1.5,1.1
		c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5C27.6,25.3,27.7,25.4,27.8,25.4z M30.5,27.4c0.7-0.8,2.6-0.6,2.4,0.7
		c-0.1,0.6-1.1,1.6-1.6,0.8c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5C30.5,27.8,30.4,27.6,30.5,27.4z M33.7,29.4
		c0.7,0.2,1.3,0.9,1.3,1.6c0.1,1.1-1,1.5-1.9,1.4c-1.3-0.1-1.9-0.9-1.2-2C32.3,29.7,32.9,29.2,33.7,29.4z M36.5,29.7
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.5,0-0.6-0.2c-0.1-0.2-0.1-0.5,0.1-0.7c0,0,0.1-0.1,0.1-0.1
		C36.2,29.5,36.4,29.6,36.5,29.7z M34.1,24.6c-0.6,0.4-1.4,0.2-2-0.1c-0.3-0.1-0.6-0.2-0.7-0.5c-0.1-0.2-0.2-0.5-0.1-0.7
		c0.1-1,0.8-1.8,1.7-2.4c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.5-0.2,1-0.5,1.5
		C34.5,24.1,34.3,24.4,34.1,24.6z M29.5,19.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2
		c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5
		c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3C29,20.2,29.3,19.9,29.5,19.5z M25.8,20.4c-0.4-0.1-0.5-0.6-0.4-0.9
		c0.1-0.4,0.5-0.6,0.8-0.7c0.4-0.1,0.7-0.2,1.1-0.3c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1-0.1,0.2
		c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.3-0.7,0.3-1.1,0.3C26.3,20.4,26,20.5,25.8,20.4z M36.5,23.6
		c-0.2-0.2-0.2-0.5-0.1-0.8c0-0.3,0.2-0.5,0.2-0.8c0-0.6-0.4-1.3-0.3-1.9c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.3-0.1,0.4,0
		c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.1C36.9,23.9,36.6,23.8,36.5,23.6z M37.9,20.8c-0.3-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.5-0.3-0.8
		c0-0.1-0.1-0.3,0-0.4c0-0.2,0.2-0.3,0.3-0.3c0.7-0.2,1.5,0.2,1.6,1C39.1,20.1,38.9,21.4,37.9,20.8z M35.4,18.9
		c0.1,0.3,0.1,0.6,0,0.8c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5
		c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1C34.7,18.1,35.2,18.5,35.4,18.9z
		 M31.6,18.2c-0.3,0.4-0.9,0.3-1.4,0.1c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0
		C31.3,16.7,32.4,17.3,31.6,18.2z M33.9,17.4c-0.5,0.2-1.2,0.1-1.5-0.3c-0.6-1,0.9-2.9,2-2.7C36.2,14.7,35,17,33.9,17.4z M37.9,15.6
		c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0.1,0.4,0,0.6c0,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.4,0.5-0.8,0.5
		c-0.2,0-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.1-0.1,0.2-0.1C37.4,15.8,37.6,15.7,37.9,15.6z
		 M28.2,16.4c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4c-1.3-1,1.5-2.6,2-0.3C28.5,15.9,28.4,16.3,28.2,16.4z M35.7,18
		C35.7,18,35.7,18,35.7,18L35.7,18c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.1-0.1,0.1-0.1
		c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0.2,0.4,0.4c0,0,0,0.1,0,0.1
		C36.9,18.1,35.9,18.3,35.7,18z M39.5,17.5c-0.6-1,0.9-2.9,2-2.7c1.8,0.4,0.6,2.6-0.5,3C40.4,18,39.8,18,39.5,17.5z M37.3,15
		c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.4-0.2-0.6c0-0.3,0.2-0.5,0.4-0.7c0.1-0.1,0.2-0.1,0.3-0.1
		c0.1,0,0.1,0.1,0.1,0.1C37.2,14.4,37.3,14.7,37.3,15z M26.6,12.5c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1
		c-0.3,0.2-0.6,0.4-0.9,0.3c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5
		c0.2-0.1,0.5-0.2,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2C26,11.7,26.3,12.1,26.6,12.5z M21.8,13.6c-0.2,0.1-0.4,0.1-0.6,0
		c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.1-0.8-0.2-1.2c-0.1-0.5-0.5-0.9-0.3-1.3c0.1-0.3,0.6-0.4,0.9-0.3c0.3,0.1,0.6,0.4,0.7,0.7
		c0.2,0.4,0.3,0.8,0.3,1.2C22.2,13,22.1,13.4,21.8,13.6z M24.7,13.6c0.4,0,0.8,0.3,1,0.6L25.5,14c0.2,0.4-0.2,0.8-0.6,0.8
		c-0.4,0-0.8-0.4-0.8-0.8c0-0.1,0-0.3,0.1-0.4C24.3,13.6,24.5,13.6,24.7,13.6z M20.9,14.6c0.3,0,0.7,0.1,0.9,0.4
		c0.2,0.3,0.2,0.7-0.1,0.8l0.1,0c-0.6,0.1-1.3,0.1-1.9,0c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4,0-0.6c0.1-0.1,0.1-0.1,0.2-0.1
		C20,14.6,20.4,14.6,20.9,14.6z M17.8,15c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.5c-0.2,0.4-0.7,0.6-1.1,0.6l0.3-0.1
		c-0.4,0-0.7-0.6-0.5-1C16.9,15,17.4,14.8,17.8,15z M19.9,16.2c0.2,0,0.3,0,0.4,0.1c0.3,0.2,0.2,0.6,0.2,0.9c0,0.3-0.1,0.5-0.3,0.7
		c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.1-1.2,0.1-1.8,0.2c-0.1,0-0.2,0-0.2,0c0,0,0-0.1-0.1-0.2c0-0.4-0.1-0.8,0.1-1.1
		c0.1-0.2,0.4-0.3,0.6-0.4C19,16.3,19.4,16.2,19.9,16.2z M14.1,18.3c-0.3-0.1-0.6-0.3-0.6-0.6c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0
		c0.3-0.5,0.6-1,1.1-1.2C16.4,15.4,16.6,18.9,14.1,18.3z M21.9,16.7c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.3,1.1-0.3,1.6-0.2
		c0.5,0.2,1,0.6,1.2,1c0.1,0.3,0.2,0.7,0,1c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.1-0.9,0.1-1.4,0.1c-0.1,0-0.2,0-0.3-0.1
		c-0.2-0.1-0.3-0.3-0.3-0.4C21.7,18,21.5,17.3,21.9,16.7z M20.3,18.6c0.3,0.1,0.5,0.3,0.6,0.6c1,1.8-2.8,3.5-2.8,1.4
		c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.4-0.3,0.7-0.4C19.8,18.5,20.1,18.5,20.3,18.6z M22.1,21.2
		c-0.1-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.7-0.4,1-0.2c0.2,0.2,0.2,0.4,0.1,0.7c0,0,0,0.1,0,0.1l0.1-0.1c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.3l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2C22.7,21.6,22.3,21.5,22.1,21.2z M25.1,21.1c0.5-0.2,1.1-0.2,1.7-0.2
		c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.1,0.6c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.8,0.5-1.2,0.4
		c-0.5-0.1-0.9-0.3-1.3-0.4c-0.2-0.1-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.4,0-0.6C24,21.5,24.6,21.2,25.1,21.1z M22.2,22.2
		c0.4,0.3,0.5,1,0.5,1.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.4-0.3-0.2-0.5l0.1,0c-0.2-0.1-0.2-0.4-0.2-0.6c0.1-0.2,0.2-0.4,0.4-0.5C21.5,21.9,21.9,21.9,22.2,22.2z M20,23.9
		c0.2,0.1,0.4,0.3,0.5,0.5c1,2.2-4.8,2.9-2.7-0.1c0.3-0.4,0.7-0.6,1.2-0.6C19.3,23.7,19.7,23.7,20,23.9z M21.8,25.7
		c0.2-0.4,0.6-0.6,1.1-0.7c0.4-0.1,0.9-0.1,1.3-0.1c0.5,0,1.2,0.1,1.5,0.5c0.4,0.4,0.3,1,0.1,1.6c-0.1,0.5-0.4,1-0.8,1.3
		c-0.6,0.5-1.3,0.6-2.1,0.5c-0.2,0-0.5,0-0.7-0.2c-0.5-0.3-0.7-0.9-0.7-1.5C21.5,26.7,21.5,26.2,21.8,25.7z M20.8,28.8
		c1.2,1,0.6,1.8-0.6,2.5c-0.4,0.3-1,0.3-1.3-0.1c-0.3-0.4-0.2-0.9,0.1-1.2c0.3-0.4,0.7-0.6,0.9-1c0.1-0.2,0.3-0.4,0.5-0.4
		C20.6,28.6,20.7,28.7,20.8,28.8z M16.4,32.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.6,1.1-0.5,1.4-0.3c0.3,0.2,1.2,0.9,0.5,1.2c-0.1,0-0.3,0-0.5,0C17,32.3,16.7,32.3,16.4,32.2z M24.5,32.3
		c-1.3-0.1-1.9-0.9-1.2-2c0.4-0.7,1-1.2,1.8-1c0.7,0.2,1.3,0.9,1.3,1.6C26.6,32,25.5,32.4,24.5,32.3z M29.5,31.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3-0.1,0.4-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1
		c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.3-0.3,0.6-0.6,0.7c-0.3,0.1-0.7,0-0.9-0.2C29.4,32.2,29.4,32,29.5,31.7z
		 M36.4,32.5c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.2,0.6,0.3c0.1,0.1,0.2,0.1,0.3,0.2
		c0.2,0.2,0.2,0.5,0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.4c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.3-0.1-0.5-0.2C36.6,32.7,36.5,32.6,36.4,32.5z
		 M29.2,37.2c-1-0.2-1.6-1.8-0.5-2.2c0.5-0.2,1.8,0.2,1.4,0.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.2,0.6
		C29.6,37,29.4,37.2,29.2,37.2z M28.1,37.3c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.3-0.9,0-1.1-0.4c-0.2-0.4-0.2-0.8,0-1.2
		c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.8,0,1,0.3C28,36.6,28.1,36.9,28.1,37.3z M30.9,33.1c0.5-0.2,1.2-0.2,1.7,0.1
		c0.2,0.1,0.5,0.3,0.6,0.6h0c0.2,0.3,0.3,0.6,0.4,0.9c0,0.2,0,0.3,0,0.5c-0.1,0.3-0.3,0.5-0.6,0.6c-0.4,0.2-0.9,0.2-1.3,0.1
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.4-0.5-0.8-0.5c-0.2,0-0.5-0.2-0.5-0.4
		c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.2h0C30.1,33.5,30.5,33.3,30.9,33.1z M37.3,34.3c0.5,0.3,0.7,1,0.5,1.5
		c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.4,0.2-0.8,0.5-1C36.7,34.1,37.1,34.1,37.3,34.3z M39.4,34.4c0.2,0.2,0.1,0.6,0.1,0.9c0,0.2,0,0.5-0.2,0.6
		C39.2,35.9,39,36,38.9,36c-0.3,0-0.5-0.1-0.6-0.3c-0.1-0.1-0.1-0.3,0-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3
		c0.1-0.2,0.2-0.4,0.5-0.4C39.1,34.1,39.3,34.2,39.4,34.4z M33.6,36c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3
		c0.2-0.4,0.5-0.8,0.8-1.1c0.3-0.1,0.7,0.1,0.8,0.4c0.1,0.2,0.1,0.5,0.1,0.7c0,0.7-0.2,1.4-0.4,2c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.4-0.6-0.7C33.8,36.6,33.7,36.3,33.6,36z M36.4,37.3c0-0.2,0.1-0.3,0.2-0.4
		c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.1,0.7,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.3,0.6-0.7,0.7c-0.3,0.1-0.7,0.1-1,0c-0.1-0.1-0.2-0.1-0.3-0.2C36.4,37.7,36.4,37.5,36.4,37.3z M39.7,37.7
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0c0.2,0,0.5,0,0.7,0.1
		c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.4,0.3,0.7c0,0.3-0.2,0.5-0.4,0.6c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.2,0-0.3-0.1-0.4-0.3C39.9,38,39.8,37.9,39.7,37.7z M42.8,36.9c-1-0.2-1.6-1.8-0.5-2.2c0.5-0.2,1.8,0.2,1.4,0.9
		c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.2,0.6C43.2,36.8,43,37,42.8,36.9z M49.1,34.8C49,34.9,48.9,35,48.7,35
		c-0.2,0.1-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.5-0.4h0
		c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1h0c0,0.1,0,0.2,0,0.2
		C49.5,34.3,49.3,34.6,49.1,34.8z M50.5,34.2c0.1,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3,0-0.5c0.1-0.2,0.1-0.3,0.3-0.4C50.2,34.3,50.4,34.2,50.5,34.2z
		 M46.7,33.6c0,0,0,0.1,0.1,0.1h0c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.3,0.5-0.6,0.6c-0.3,0.2-0.7,0.3-1.1,0.4c-0.2,0.1-0.5,0.1-0.7-0.1
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.3-0.6-0.4-1c0,0,0,0,0,0h0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.2c0.3-0.1,0.7-0.2,1-0.3
		c0.3,0,0.5-0.1,0.8,0C46.2,33.1,46.5,33.4,46.7,33.6z M41.2,33.2c0.2,0.1,0.2,0.3,0.3,0.5h0c0,0.1,0,0.2,0,0.4
		c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.4-0.7,0.3c-0.3-0.1-0.4-0.4-0.4-0.6c0-0.3,0-0.5,0-0.8c0,0,0-0.1,0-0.1h0
		c0-0.2-0.1-0.4,0-0.6C40,33,40.2,33,40.5,33C40.7,33,41,33.1,41.2,33.2z M22,34.7c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.2,0.5-0.3,0.7
		c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0-0.3,0-0.5,0c-0.4-0.1-0.7-0.2-1-0.4c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.3,0.2-0.6,0.5-0.8
		c0.3-0.2,0.6-0.2,0.9-0.2C21.4,34.5,21.7,34.5,22,34.7z M20.4,36.9c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.3-1.5,0-1.7C19.2,36,20.1,36.7,20.4,36.9z M22.8,38c-0.3-0.2-0.7-0.5-0.7-0.9
		c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2c0.6,0.3,0.7,1,0.8,1.6
		c0,0.3,0.1,0.5-0.1,0.8c-0.1,0.2-0.3,0.3-0.5,0.4C24.6,38.7,23.6,38.5,22.8,38z M28.7,38.4c0.3-0.2,1.2,0.6,1.5,0.7
		c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C28.6,39.8,28.3,38.7,28.7,38.4z M36.7,40.2
		c0.5,1.1,0.2,2.1-1,2.2l0,0c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.7-0.3-1.9-1.2c-0.2-0.8,0.2-1.7,0.9-2.1c0.5-0.3,1-0.3,1.4-0.1v0
		C36.1,39.3,36.5,39.8,36.7,40.2z M38.5,39.1c0.4,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.5-0.1,1-0.4,1.2
		c-0.2,0.2-0.5,0.2-0.8,0.2c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.3-0.1-0.6-0.2-0.9
		c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1C37.9,39.2,38.2,39.1,38.5,39.1z M41,43c0,0.1,0.1,0.2,0.1,0.3
		c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.1-0.6
		c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0,0.6,0C40.7,42.5,40.9,42.8,41,43z M47,42.5c0.3,0.1,0.5,0.5,0.8,0.7c0.1,0.2,0.3,0.3,0.3,0.5
		c0.1,0.3-0.3,0.7-0.6,0.7c-0.3,0.1-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0-0.4,0.2-0.4
		c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0C46.7,42.5,46.8,42.5,47,42.5z M39.7,48c-0.3-0.2-0.4-0.6-0.4-0.9c0-0.2,0.1-0.5,0.2-0.7
		c0.1-0.1,0.3-0.3,0.4-0.3C41.7,45.5,41.9,49.7,39.7,48z M43.3,45.8c0.4,0.1,1.1,0.4,1.4,0.9c0.3,0.6-0.3,1.1-1,1.1
		c-0.3,0-0.6-0.1-0.8-0.2c-0.1-0.1-0.3-0.1-0.4-0.2C40.9,46.2,41.8,45.3,43.3,45.8z M36.3,47.7c-0.2,0-0.4-0.1-0.6-0.1v0
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-1.6-1-0.8-1.9,0.6-1.5v0c0.1,0,0.1,0,0.2,0.1c0.4,0.1,1.1,0.4,1.4,0.9
		C37.6,47.3,37,47.7,36.3,47.7z M30,46.2c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1
		c-0.3-0.2-0.4-0.5-0.3-0.8c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		C30,45.8,30,46,30,46.2z M27.4,45.6c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0C27.2,45.4,27.3,45.5,27.4,45.6z
		 M36.1,45.1c-0.1,0-0.3-0.1-0.4-0.1v0c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.7-1.1,1.3-1
		v0c0.3,0.1,0.7,0.2,1,0.4c0.4,0.2,0.7,0.7,0.5,1.1C37.1,45.1,36.6,45.2,36.1,45.1z M37.9,42.7c0.1,0.1,0.2,0.2,0.2,0.3
		c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.2-0.3,0.2-0.4,0.2c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0.1-0.3C37.6,42.7,37.8,42.7,37.9,42.7
		L37.9,42.7z M27,40.9c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4c0.2-0.4,0.6-0.6,1.1-0.7c0,0,0,0-0.1,0
		l0.1,0c0,0,0,0,0,0c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5C27.6,40.8,27.3,40.9,27,40.9z M21.6,39.1
		c0.2,0.4,0.3,0.8,0.3,1.2c0,0,0,0.1,0,0.1c0,0.3-0.1,0.7-0.3,0.7C20.2,41.3,20.8,37.7,21.6,39.1z M22.7,41.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4c0.6-0.1,1.2-0.2,1.6,0.2
		c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.2-1.2-0.2
		C22.9,41.2,22.8,41.2,22.7,41.1z M30.8,41c0.5-0.1,1-0.1,1.4,0.1c0.6,0.2,1,0.8,1.3,1.4c0.1,0.2,0.2,0.4,0.2,0.6
		c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1c-0.2-0.4-0.4-0.9-0.2-1.4
		C29.8,41.3,30.3,41.1,30.8,41z M21.5,44.7c0.3,0,0.6,0.5,0.5,0.8c0.2,0.3,0.2,0.8-0.2,0.9c-0.3,0.1-0.5-0.1-0.7-0.3
		C20.7,45.7,20.5,44.6,21.5,44.7z M19.3,45.8c0.4,0.2,1.1,0.4,1.4,0.9c0.3,0.6-0.3,1.1-1,1.1c-0.3,0-0.6-0.1-0.8-0.2
		c-0.1-0.1-0.3-0.1-0.4-0.2C16.8,46.2,17.8,45.3,19.3,45.8z M22.5,47c-0.1-0.5,0.1-1,0.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4
		c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.9,1-1.6,0.9
		c-0.2,0-0.4-0.1-0.6-0.2C22.9,47.9,22.6,47.5,22.5,47z M30.7,46.9c0-0.2,0.1-0.4,0.2-0.6c1.4-1.7,4.4,2.6,1,2.2
		c-0.4-0.1-0.8-0.3-1-0.7C30.7,47.5,30.6,47.2,30.7,46.9z M38.5,46.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.1
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.2-0.6,0-0.8c0.4-0.5,1.8-1.3,2-0.2C39.5,45.7,38.8,46.3,38.5,46.8z M43.5,45.1
		c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4c0.3-1.4,1.2-1.2,2.3-0.6c0.4,0.2,0.7,0.7,0.5,1.1
		C44.4,45.1,43.9,45.2,43.5,45.1z M48.9,44.1c-0.4-0.4-0.6-0.8-0.8-1.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6
		c-0.1,0.2-0.3,0.2-0.5,0.2C49.7,44.7,49.2,44.5,48.9,44.1z M42.7,42.4c-0.9-0.1-1.7-0.3-1.9-1.2c-0.2-0.8,0.2-1.7,0.9-2.1
		c1.1-0.7,2,0.2,2.4,1.1C44.6,41.5,44.2,42.5,42.7,42.4z M33.2,38.9c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.6,0.3
		c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6
		c0.3-0.1,0.6-0.1,0.8-0.1c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1S33.6,38.7,33.2,38.9z M45.5,38
		c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2
		c0.6,0.3,0.7,1,0.8,1.6c0,0.3,0,0.5-0.1,0.8c-0.1,0.2-0.3,0.3-0.5,0.4C47.3,38.7,46.3,38.5,45.5,38z M49.4,37.6
		c-0.2-0.4-0.2-0.8-0.1-1.2c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.1,0.2-0.1l-0.1,0c0.4,0,0.7,0.2,1,0.5c0.2,0.3,0.3,0.7,0.3,1
		c0,0.2-0.1,0.5-0.3,0.6C50.1,38.2,49.6,37.9,49.4,37.6z M51.9,37.2c-1-0.2-1.6-1.8-0.5-2.2c0.5-0.2,1.8,0.2,1.4,0.9
		c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.2,0.6C52.3,37,52.1,37.2,51.9,37.2z M56.3,36.2c-0.1,0-0.1-0.1-0.2-0.2
		c0-0.1,0-0.2,0-0.2c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.2,0.6,0.5
		c0,0.2,0,0.4-0.1,0.6c-0.2,0.4-0.4,0.7-0.8,1c-0.2,0.2-0.4,0.3-0.6,0.4C56.7,36.4,56.5,36.3,56.3,36.2z M55.9,34.7
		c-0.1,0.3-0.3,0.5-0.6,0.6c-0.5,0.2-1,0.1-1.5-0.1c-0.5-0.2-0.9-0.5-1.4-0.6c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.3-0.4-0.3-0.6h0
		c0,0,0,0,0,0c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.2,1.1-0.5,1.7-0.5c0.6-0.1,1.2,0,1.7,0.4c0.2,0.2,0.4,0.4,0.5,0.6h0
		C56,34.1,56,34.4,55.9,34.7z M47.3,32.8c-0.4,0-0.7,0-1-0.3s-0.4-0.6-0.2-0.9c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.2,0.8-0.1,1.2,0.1
		c0.3,0.2,0.5,0.7,0.3,1.1c-0.1,0.1-0.1,0.2-0.2,0.2C47.6,32.8,47.5,32.8,47.3,32.8z M52.6,32.2c-0.3-0.1-0.5-0.4-0.6-0.7
		c0-0.1-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.4-0.1-0.5c0,0,0.1-0.1,0.2-0.1
		c0.7-0.2,1.4-0.3,2-0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.2,0.5,0.1,0.7c0,0.1-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.3,0.2-0.5,0.2C53.2,32.4,52.8,32.4,52.6,32.2z M50.3,31.5c-0.3,0.4-0.9,0.3-1.4,0.1c-0.3-0.1-0.7-0.3-0.9-0.5
		c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0C50,30,51.1,30.6,50.3,31.5z M50.9,30.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.2,0-0.5,0.2-0.7c1-0.8,3.3,0.5,2.3,1.6c-0.1,0.2-0.4,0.2-0.6,0.2C51.7,30.4,51.3,30.3,50.9,30.1z
		 M54.1,29.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.5-0.4-0.6c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.2,0-0.2
		c0.1-0.1,0.2,0,0.3,0c0.3,0.1,0.7,0.2,0.9,0.4c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0,0.4-0.2,0.5C54.4,29.9,54.2,29.8,54.1,29.7z
		 M47.8,28.7c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.2-0.3,0.5-0.6,0.7c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1
		c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.5,0-0.7c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8,0
		c0.3,0.1,0.4,0.5,0.3,0.8C48.1,28.2,48,28.5,47.8,28.7z M50.2,27.7c-0.2,0-0.4-0.2-0.6-0.3c-0.1-0.2-0.2-0.4-0.2-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.2,1-0.3,1.3,0c0.3,0.3,0.4,0.7,0.1,1.1C51,27.7,50.6,27.8,50.2,27.7z
		 M48,26.2c-0.4,0.2-0.8,0.2-1.1-0.1c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.8-0.1-1.6,0.1-2.4c0.1-0.3,0.3-0.7,0.6-0.8
		c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1s0.1,0.8,0.2,1.3c0,0.3,0.1,0.5,0.1,0.8C48.6,25.6,48.3,26,48,26.2z M50.5,25.5
		c-0.4-0.1-0.5-0.6-0.5-0.9c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.1,0-0.2,0-0.3c0.1-0.2,0.4-0.3,0.6-0.3c0.6,0,1.1,0.4,1.3,0.9
		c0.3,0.5,0.3,1.1,0.2,1.6c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.4C51.1,25.5,50.8,25.6,50.5,25.5z M45.2,22.1
		c0.3,0.4,0.3,0.9,0.1,1.3c-0.1,0.2-0.4,0.3-0.7,0.4c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.5-0.2,0.7-0.1C45,21.9,45.1,22,45.2,22.1z M42.5,22.4c0.1,0.5-0.2,1-0.5,1.5
		c-0.2,0.2-0.3,0.5-0.6,0.7c-0.6,0.4-1.4,0.2-2-0.1c-0.3-0.1-0.6-0.2-0.7-0.5c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-1,0.8-1.8,1.7-2.4
		c0.4-0.2,0.8-0.4,1.2-0.2c0.2,0.1,0.4,0.3,0.6,0.6C42.3,21.7,42.5,22,42.5,22.4z M45.5,21.7c-0.1,0-0.2-0.1-0.3-0.2
		c-0.3-0.2-0.4-0.6-0.4-1c0-0.4,0.2-0.7,0.4-1c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.4
		c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5-0.1,0.8c-0.2,0.3-0.6,0.4-0.9,0.5C45.7,21.7,45.6,21.8,45.5,21.7z M47.6,20.7
		c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.2,0.1-0.3,0.2-0.5c0.6-0.7,1.3-0.2,1.6,0.5C49.5,20.7,48.5,21.2,47.6,20.7z M42.7,19.8
		c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1c0.5,0,1,0.3,1.1,0.8C42.8,19.2,42.8,19.5,42.7,19.8z
		 M43.3,18.5C43,18.3,42.9,18,43,17.6c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4C43.7,18.7,43.5,18.6,43.3,18.5z M46.9,18.3
		c0,0-0.1,0-0.1,0c-0.6-0.2-1.1-0.8-1.1-1.5c0.1-1.8,3.5-0.9,3,0.6C48.3,18,47.6,18.4,46.9,18.3z M50.6,16.4
		C50.6,16.4,50.6,16.4,50.6,16.4c-0.5,0-1.1-0.1-1.5-0.4c-1.3-1,1.5-2.6,2-0.3c0.1,0.3,0,0.6-0.2,0.7C50.8,16.4,50.7,16.4,50.6,16.4
		z M48.3,14.7C48.2,14.9,48,15,47.8,15c-0.4,0-0.8,0.1-1.1-0.2c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.1,0.1-0.1,0.2-0.1
		c0.2-0.2,0.2-0.4,0.6-0.3c0.4,0,0.8,0.3,1,0.6C48.5,14.4,48.4,14.6,48.3,14.7z M45.7,14.2c-0.2,0.6-0.5,1.2-1,1.4
		c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.7,0.9-1.1
		c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.2C45.9,13.5,45.8,13.9,45.7,14.2z
		 M42.1,13.4c-1.7-0.1-2-2.7-0.2-2.7C43.9,10.6,44.6,13.5,42.1,13.4z M48.3,13.2c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.4,0.6,0.9,0.9,1.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1C48.9,13.1,48.6,13.3,48.3,13.2z M46.6,12.2c0,0.2,0.1,0.4,0,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2
		l-0.1-0.1C46.2,11.1,46.5,11.6,46.6,12.2z M39.9,11.2c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.6,0.2,1.2,0.3,1.7c0.1,0.3,0.1,0.8-0.2,1
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.6,0-1.2-0.1-1.7-0.4c-0.4-0.4-0.6-0.9-0.7-1.5c-0.1-0.5-0.2-1.1-0.5-1.6c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-0.3,0.3-0.4,0.5-0.5c0.7-0.2,1.6,0.1,2.2,0.5C39.5,10.8,39.7,11,39.9,11.2z M22.5,8.7c-0.1-0.5,0.1-1,0.4-1.4
		c0.2-0.2,0.5-0.4,0.8-0.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4
		c-0.4,0.5-0.9,1-1.6,0.9c-0.2,0-0.4-0.1-0.6-0.2C22.9,9.6,22.6,9.2,22.5,8.7z M19.7,9.5c-0.3,0-0.6-0.1-0.8-0.2
		c-0.1-0.1-0.3-0.1-0.4-0.2c-1.6-1.1-0.7-2,0.8-1.5c0.4,0.1,1.1,0.4,1.4,0.9C21,9,20.3,9.5,19.7,9.5z M27.4,7.3
		c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0
		c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0C27.2,7.2,27.3,7.2,27.4,7.3z M30.7,8.6c0-0.2,0.1-0.4,0.2-0.6
		c1.4-1.7,4.4,2.6,1,2.2c-0.4-0.1-0.8-0.3-1-0.7C30.7,9.2,30.6,8.9,30.7,8.6z M39.7,9.7c-0.3-0.2-0.4-0.6-0.4-0.9
		c0-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.3,0.4-0.3C41.7,7.2,41.9,11.4,39.7,9.7z M43.3,7.5c0.4,0.1,1.1,0.4,1.4,0.9
		c0.3,0.6-0.3,1.1-1,1.1c-0.3,0-0.6-0.1-0.8-0.2C42.8,9.1,42.6,9,42.5,9C40.9,7.9,41.8,7,43.3,7.5z M36.3,9.4
		c-0.2,0-0.4-0.1-0.6-0.1v0c-0.1,0-0.1-0.1-0.2-0.1C35.4,9.1,35.3,9,35.1,9c-1.6-1-0.8-1.9,0.6-1.5v0c0.1,0,0.1,0,0.2,0.1
		c0.4,0.1,1.1,0.4,1.4,0.9C37.6,9,37,9.4,36.3,9.4z M38.5,8.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.1
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.2-0.6,0-0.8c0.4-0.5,1.8-1.3,2-0.2C39.5,7.4,38.8,8,38.5,8.5z M30,6.8C30,7,30,7.1,30,7.3
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.4-0.5-0.3-0.8
		c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1C29.8,6.5,29.9,6.7,30,6.8z M22,7.2c0.2,0.3,0.2,0.8-0.2,0.9
		c-0.3,0.1-0.5-0.1-0.7-0.3c-0.4-0.4-0.6-1.6,0.4-1.5C21.8,6.4,22,6.9,22,7.2z M14,8.7c0-0.2,0.1-0.4,0.2-0.6c1.4-1.7,4.4,2.6,1,2.2
		c-0.4-0.1-0.8-0.3-1-0.7C14.1,9.3,14,9,14,8.7z M26.3,9.9c1.1-1.5,4.1,0.8,2.4,1.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.7,0-1-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5C26,10.4,26.1,10.1,26.3,9.9z M18.1,13.4c-1.7-0.1-2-2.7-0.2-2.7
		C19.9,10.6,20.5,13.5,18.1,13.4z M11.8,15.7c0.1,0.3,0,0.6-0.2,0.7c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4
		C8.4,15.1,11.2,13.5,11.8,15.7z M9.2,17.4c-0.3,0.7-1,1.1-1.7,0.9c0,0-0.1,0-0.1,0c-0.6-0.2-1.1-0.8-1.1-1.5
		C6.3,15,9.8,15.9,9.2,17.4z M10,17.6c0.1-0.3,0.4-0.4,0.7-0.4c0.3-0.1,0.6-0.1,0.8,0c0.8,0.1,1.5,0.9,1.6,1.7c0,0.2,0.1,0.4,0,0.6
		c-0.2,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.3-0.4-0.5-0.6-0.8C10.1,18.3,9.8,17.9,10,17.6z
		 M9.7,19.8c0.4,0.9-0.6,1.4-1.4,0.9c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.2,0.1-0.3,0.2-0.5C8.7,18.6,9.4,19,9.7,19.8z M14.5,19.4
		c0.4-0.2,0.8-0.4,1.2-0.3c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.4,0.7,0.5,1s0.3,0.7,0.1,1.1
		c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.7,0.1c0,0-0.1,0-0.1,0c-0.9,0-1.9-0.1-2.6-0.7c-0.2-0.2-0.4-0.5-0.6-0.7
		c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4C13.1,20.4,13.8,19.8,14.5,19.4z M11,21.9c0.6,0,1.1,0.4,1.3,0.9
		c0.3,0.5,0.3,1.1,0.2,1.6c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.1-0.6,0.2-0.8,0.1c-0.4-0.1-0.5-0.6-0.5-0.9
		c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.1,0-0.2,0-0.3C10.5,21.9,10.8,21.9,11,21.9z M13.2,25.4c0.9-1.6,3.7-0.5,2.8,1.2
		C15,28.5,11.9,27.7,13.2,25.4z M18.5,29.5c-0.4,0.3-1,0.9-1.5,0.8c-0.8,0-0.9-0.8-0.6-1.4c0.2-0.3,0.4-0.5,0.7-0.7
		c0.1-0.1,0.3-0.2,0.4-0.2C19.3,27,19.7,28.3,18.5,29.5z M14,28.4c0.3,0.1,0.7,0.2,0.9,0.4c0.2,0.1,0.3,0.3,0.4,0.5
		c0.1,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.5-0.4-0.6c-0.1,0-0.2-0.1-0.2-0.1
		c-0.1-0.1-0.1-0.2,0-0.2C13.8,28.3,13.9,28.3,14,28.4z M13.4,30.2c-0.1,0.2-0.4,0.2-0.6,0.2c-0.4,0-0.8-0.1-1.2-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.2,0-0.5,0.2-0.7C12.1,27.8,14.4,29.1,13.4,30.2z M9.9,29.7
		c0.8,0.3,1.8,0.9,1.1,1.8c-0.3,0.4-0.9,0.3-1.4,0.1c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.3-0.5-1-0.1-1.4C9,29.4,9.5,29.6,9.9,29.7z
		 M8.3,31.5c0.3,0.2,0.5,0.7,0.3,1.1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.4,0-0.7,0-1-0.3
		c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.1,0.2-0.2,0.4-0.3C7.5,31.2,8,31.2,8.3,31.5z M22.4,32.4c0.8,0,1.7,0.4,1.9,1.2
		c0,0.1,0,0.2,0.1,0.2h0c0,0,0,0.1,0,0.1c0,0.2-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.1-0.8,0.1c-0.5,0-1-0.2-1.5-0.4
		c-0.4-0.1-0.8-0.3-1.2-0.4h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2C20.8,32.8,21.6,32.4,22.4,32.4z M19.1,34.3
		c0,0.2,0,0.4-0.1,0.6c-0.2,0.4-0.4,0.7-0.8,1c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7-0.1c-0.1,0-0.1-0.1-0.2-0.2
		c0-0.1,0-0.2,0-0.2c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.3,0.8-0.3C18.7,33.9,19,34.1,19.1,34.3z
		 M11.1,36.3c0.2,0.3,0.3,0.7,0.3,1c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.3-0.9,0-1.1-0.4c-0.2-0.4-0.2-0.8-0.1-1.2c0-0.1,0-0.3,0.1-0.4
		c0.1-0.1,0.1-0.1,0.2-0.1l-0.1,0C10.5,35.8,10.9,36,11.1,36.3z M13.8,37.9c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.5-0.6
		c0.3-0.1,0.6-0.1,0.8-0.1c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.6,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1c-0.1,0.4-0.3,0.8-0.7,1
		c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.6,0.3c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7
		C14.3,38.2,14.1,38.1,13.8,37.9z M17.6,39.1c1.1-0.7,2,0.2,2.4,1.1c0.5,1.3,0.1,2.3-1.4,2.2c-0.9-0.1-1.7-0.3-1.9-1.2
		C16.6,40.4,17,39.5,17.6,39.1z M10.4,40.9c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4
		c0.2-0.4,0.6-0.6,1.1-0.7c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5
		C10.9,40.9,10.6,40.9,10.4,40.9z M8.1,39.6c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.3
		c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.2-1.2-0.2c-0.1,0-0.2,0-0.3-0.1C6,41.1,6,41,6,40.9c-0.1-0.2-0.2-0.5-0.2-0.8
		c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4C7,39.3,7.7,39.2,8.1,39.6z M14.2,41c0.5-0.1,1-0.1,1.4,0.1c0.6,0.2,1,0.8,1.3,1.4
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1
		c-0.2-0.4-0.4-0.9-0.2-1.4C13.1,41.3,13.7,41.1,14.2,41z M17.8,44.6c-0.1-0.1,0-0.3,0-0.4c0.3-1.4,1.2-1.2,2.3-0.6
		c0.4,0.2,0.7,0.7,0.5,1.1c-0.2,0.4-0.7,0.5-1.1,0.4c-0.4-0.1-0.8-0.3-1.2-0.3C18.1,44.8,17.9,44.8,17.8,44.6z M14,47
		c0-0.2,0.1-0.4,0.2-0.6c1.4-1.7,4.4,2.6,1,2.2c-0.4-0.1-0.8-0.3-1-0.7C14.1,47.6,14,47.3,14,47z M26.3,48.2
		c1.1-1.5,4.1,0.8,2.4,1.6C28.5,50,28.2,50,27.9,50c-0.3,0-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5
		C26,48.7,26.1,48.4,26.3,48.2z M18.1,51.7c-1.7-0.1-2-2.7-0.2-2.7C19.9,48.9,20.5,51.8,18.1,51.7z M11.8,54c0.1,0.3,0,0.6-0.2,0.7
		c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4C8.4,53.4,11.2,51.8,11.8,54z M9.2,55.7c-0.3,0.7-1,1.1-1.7,0.9
		c0,0-0.1,0-0.1,0c-0.6-0.2-1.1-0.8-1.1-1.5C6.3,53.3,9.8,54.2,9.2,55.7z M10,55.9c0.1-0.3,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.1,0.8,0
		c0.8,0.1,1.5,0.9,1.6,1.7c0,0.2,0.1,0.4,0,0.6c-0.2,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.5
		c-0.2-0.3-0.4-0.5-0.6-0.8C10.1,56.6,9.8,56.2,10,55.9z M9.7,58.1c0.4,0.9-0.6,1.4-1.4,0.9C8,58.8,7.8,58.4,7.8,58
		c0-0.2,0.1-0.3,0.2-0.5C8.7,56.9,9.4,57.3,9.7,58.1z M14.5,57.7c0.4-0.2,0.8-0.4,1.2-0.3c0.3,0.1,0.6,0.4,0.7,0.7
		c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.4,0.7,0.5,1c0.2,0.3,0.3,0.7,0.1,1.1c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.7,0.1
		c0,0-0.1,0-0.1,0c-0.9,0-1.9-0.1-2.6-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4
		C13.1,58.7,13.8,58.1,14.5,57.7z M11,60.2c0.6,0,1.1,0.4,1.3,0.9c0.3,0.5,0.3,1.1,0.2,1.6c0,0.2,0,0.3-0.1,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.1-0.6,0.2-0.8,0.1c-0.4-0.1-0.5-0.6-0.5-0.9c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.1,0-0.2,0-0.3
		C10.5,60.2,10.8,60.2,11,60.2z M13.2,63.7c0.9-1.6,3.7-0.5,2.8,1.2C15,66.8,11.9,66,13.2,63.7z M21.6,65.5
		c-0.1-0.5-0.1-1.1,0.2-1.5c0.2-0.4,0.6-0.6,1.1-0.7c0.4-0.1,0.9-0.1,1.3-0.1c0.5,0,1.2,0.1,1.5,0.5c0.4,0.4,0.3,1,0.1,1.6
		c-0.1,0.5-0.4,1-0.8,1.3c-0.6,0.5-1.3,0.6-2.1,0.5c-0.2,0-0.5,0-0.7-0.2C21.8,66.7,21.7,66.1,21.6,65.5z M35.7,67L35.7,67
		c-0.5-0.1-1.1-0.3-1.5-0.6c-0.3-0.2-0.7-0.5-0.7-0.8c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5v0c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1
		c0.4,0.8,0.7,1.6,0.5,2.4c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
		C36.2,67.1,35.9,67.1,35.7,67z M39.1,66.6c-0.2-0.2-0.2-0.5-0.1-0.6c0.7-0.8,2.6-0.6,2.4,0.7c-0.1,0.6-1.1,1.6-1.6,0.8
		c-0.1-0.1-0.1-0.2-0.2-0.4C39.4,66.9,39.2,66.8,39.1,66.6z M43.2,65.8c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3-0.1,0.7-0.4,0.8
		c-0.3,0.1-0.6,0.1-0.8,0c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.1,0.3-0.2,0.5-0.2C42.8,65.7,43,65.7,43.2,65.8z M45.1,67.5c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.1,0-0.3,0-0.4
		c0-0.2,0.1-0.4,0.3-0.6c0.4-0.3,1-0.4,1.3-0.2c0.5,0.4-0.1,1.1-0.3,1.5c-0.1,0.1-0.2,0.3-0.3,0.3C45.6,67.6,45.3,67.6,45.1,67.5z
		 M47.6,67.1c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0
		c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.1,0.1-0.2,0.3-0.3,0.3C48.1,67.3,47.8,67.3,47.6,67.1z M50.2,66c-0.2,0-0.4-0.2-0.6-0.3c-0.1-0.2-0.2-0.4-0.2-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.2,1-0.3,1.3,0c0.3,0.3,0.4,0.7,0.1,1.1C51,66,50.6,66.1,50.2,66z M52.5,63.7
		c0.9-1.6,3.7-0.5,2.8,1.2C54.4,66.8,51.2,66,52.5,63.7z M57.2,62.6c0.3-0.4,0.7-0.6,1.2-0.6c0.3,0,0.7,0,1,0.2
		c0.2,0.1,0.4,0.3,0.5,0.5C60.8,64.9,55,65.6,57.2,62.6z M61.5,62.5c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.4-0.3-0.2-0.5l0.1,0c-0.2-0.1-0.2-0.4-0.2-0.6s0.2-0.4,0.4-0.5c0.3-0.1,0.7-0.1,1,0.1c0.4,0.3,0.5,1,0.5,1.5
		c0,0.1,0,0.2-0.1,0.3C61.9,62.5,61.7,62.5,61.5,62.5z M56.8,61.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.7,0.1
		c0,0-0.1,0-0.1,0c-0.9,0-1.9-0.1-2.6-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4
		c0.2-0.8,0.9-1.4,1.7-1.8c0.4-0.2,0.8-0.4,1.2-0.3c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.4,0.7,0.5,1
		C56.9,60.5,57,60.9,56.8,61.2z M57.4,58.9c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.4-0.3,0.7-0.4
		c0.2-0.1,0.4-0.1,0.7,0c0.3,0.1,0.5,0.3,0.6,0.6C61.2,59.2,57.4,60.9,57.4,58.9z M52.6,57.1c0,0.2,0.1,0.4,0,0.6
		c-0.2,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.3-0.5-0.7-0.3-1
		c0.1-0.3,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.1,0.8,0C51.8,55.5,52.4,56.3,52.6,57.1z M53.4,56.6c-0.3-0.1-0.6-0.3-0.6-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.3-0.5,0.6-1,1.1-1.2C55.7,53.7,55.9,57.2,53.4,56.6z M57.3,56.3c0,0,0-0.1-0.1-0.2
		c0-0.4-0.1-0.8,0.1-1.1c0.1-0.2,0.4-0.3,0.6-0.4c0.4-0.2,0.9-0.2,1.3-0.2c0.2,0,0.3,0,0.4,0.1c0.3,0.2,0.2,0.6,0.2,0.9
		c0,0.3-0.1,0.5-0.3,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.1-1.2,0.1-1.8,0.2C57.4,56.4,57.3,56.4,57.3,56.3z M56.3,54.7l0.3-0.1
		c-0.4,0-0.7-0.6-0.5-1c0.2-0.4,0.7-0.5,1.2-0.4c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.5C57.2,54.4,56.7,54.7,56.3,54.7z
		 M54.7,53.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8
		c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4C55,52.7,54.8,53,54.7,53.2z M57.5,51.7c-1.7-0.1-2-2.7-0.2-2.7
		C59.3,48.9,59.9,51.8,57.5,51.7z M62.5,48.9c0.4,0.4,0.6,1,0.8,1.5c0,0.2,0.1,0.4,0,0.5c-0.1,0.2-0.3,0.3-0.5,0.2
		c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2L62.5,48.9z M51.4,49.9
		C51.2,50,50.9,50,50.7,50c-0.3,0-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7
		C50.1,46.7,53.1,49,51.4,49.9z M54.6,48.5c-0.4-0.1-0.8-0.3-1-0.7c-0.2-0.3-0.3-0.6-0.2-0.9c0-0.2,0.1-0.4,0.2-0.6
		C55,44.6,58,49,54.6,48.5z M58.2,47.5c-0.1-0.1-0.3-0.1-0.4-0.2c-1.6-1.1-0.7-2,0.8-1.5c0.4,0.2,1.1,0.4,1.4,0.9
		c0.3,0.6-0.3,1.1-1,1.1C58.8,47.8,58.5,47.7,58.2,47.5z M52.7,46.2c0,0.2-0.1,0.4-0.3,0.4c-0.1,0.4,0.1,1.3-0.8,0.8
		c-0.5-0.3-0.7-1-0.6-1.5c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		C52.7,45.8,52.7,46,52.7,46.2z M58.9,45.2c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4
		c0.3-1.4,1.2-1.2,2.3-0.6c0.4,0.2,0.7,0.7,0.5,1.1C59.8,45.1,59.3,45.2,58.9,45.2z M61.9,43.3c0.2,0.3,0.1,0.8-0.2,1.1
		c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.4-0.4-0.9-0.3-1.4l0,0.1C61.2,42.7,61.6,42.9,61.9,43.3z M63.1,44.1
		c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0
		c0.3,0.1,0.5,0.5,0.8,0.7c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.3-0.3,0.7-0.6,0.7C63.7,44.5,63.4,44.4,63.1,44.1z M56.2,42.5
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1
		c-0.2-0.4-0.4-0.9-0.2-1.4c0.2-0.5,0.7-0.7,1.2-0.8c0.5-0.1,1-0.1,1.4,0.1C55.5,41.4,56,41.9,56.2,42.5z M47.8,41.2
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.2-1.2-0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4c0.6-0.1,1.2-0.2,1.6,0.2c0.2,0.2,0.3,0.4,0.4,0.6
		C47.9,40.6,48,41,47.8,41.2z M49.7,40.9c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4
		c0.2-0.4,0.6-0.6,1.1-0.7c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5
		C50.3,40.9,50,40.9,49.7,40.9z M51.5,40.4c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.3-1.5,0-1.7c0.3-0.2,1.2,0.6,1.5,0.7
		c0.8,0.5-0.4,1.2-0.9,1.4C51.8,40.6,51.6,40.6,51.5,40.4z M58.3,38.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.3-1.5,0-1.7
		c0.3-0.2,1.2,0.6,1.5,0.7c0.8,0.5-0.4,1.2-0.9,1.4C58.7,38.3,58.4,38.3,58.3,38.1z M60.5,36.5c-0.4-0.1-0.7-0.2-1-0.4
		c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.3,0.2-0.6,0.5-0.8c0.3-0.2,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.5,0.4,0.8
		c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.1-0.2,0.3-0.4,0.3C60.8,36.5,60.6,36.5,60.5,36.5z M62.5,34.6c-0.5,0-1-0.2-1.5-0.4
		c-0.4-0.1-0.8-0.3-1.2-0.4h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2c0.6-0.6,1.3-1,2.1-1.1
		c0.8,0,1.7,0.4,1.9,1.2c0,0.1,0,0.2,0.1,0.2h0c0,0,0,0.1,0,0.1c0,0.2-0.2,0.5-0.5,0.6C63,34.6,62.8,34.6,62.5,34.6z M57.1,32.4
		c-0.1,0-0.3,0-0.5,0c-0.3,0-0.6-0.1-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.6,1.1-0.5,1.4-0.3C56.9,31.4,57.8,32.1,57.1,32.4z M63.9,32.3c-1.3-0.1-1.9-0.9-1.2-2c0.4-0.7,1-1.2,1.8-1
		c0.7,0.2,1.3,0.9,1.3,1.6C65.9,32,64.8,32.4,63.9,32.3z M59.6,31.3c-0.4,0.3-1,0.3-1.3-0.1c-0.3-0.4-0.2-0.9,0.1-1.2s0.7-0.6,0.9-1
		c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0.1,0.4,0.2C61.4,29.8,60.8,30.6,59.6,31.3z M57.8,29.5c-0.4,0.3-1,0.9-1.5,0.8
		c-0.8,0-0.9-0.8-0.6-1.4c0.2-0.3,0.4-0.5,0.7-0.7c0.1-0.1,0.3-0.2,0.4-0.2C58.7,27,59.1,28.3,57.8,29.5z M52.5,25.4
		c0.9-1.6,3.7-0.5,2.8,1.2C54.4,28.5,51.2,27.7,52.5,25.4z M57.2,24.4c0.3-0.4,0.7-0.6,1.2-0.6c0.3,0,0.7,0,1,0.2
		c0.2,0.1,0.4,0.3,0.5,0.5C60.8,26.6,55,27.3,57.2,24.4z M61.5,24.2c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.4-0.3-0.2-0.5l0.1,0c-0.2-0.1-0.2-0.4-0.2-0.6s0.2-0.4,0.4-0.5c0.3-0.1,0.7-0.1,1,0.1c0.4,0.3,0.5,1,0.5,1.5
		c0,0.1,0,0.2-0.1,0.3C61.9,24.2,61.7,24.2,61.5,24.2z M56.8,22.9c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.7,0.1
		c0,0-0.1,0-0.1,0c-0.9,0-1.9-0.1-2.6-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4
		c0.2-0.8,0.9-1.4,1.7-1.8c0.4-0.2,0.8-0.4,1.2-0.3c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.4,0.7,0.5,1
		C56.9,22.2,57,22.6,56.8,22.9z M57.4,20.6c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.4-0.3,0.7-0.4
		c0.2-0.1,0.4-0.1,0.7,0c0.3,0.1,0.5,0.3,0.6,0.6C61.2,20.9,57.4,22.6,57.4,20.6z M52.6,18.8c0,0.2,0.1,0.4,0,0.6
		c-0.2,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.3-0.5-0.7-0.3-1
		c0.1-0.3,0.4-0.4,0.7-0.4c0.3-0.1,0.6-0.1,0.8,0C51.8,17.2,52.4,18,52.6,18.8z M53.4,18.3c-0.3-0.1-0.6-0.3-0.6-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.3-0.5,0.6-1,1.1-1.2C55.7,15.4,55.9,18.9,53.4,18.3z M57.3,18c0,0,0-0.1-0.1-0.2
		c0-0.4-0.1-0.8,0.1-1.1c0.1-0.2,0.4-0.3,0.6-0.4c0.4-0.2,0.9-0.2,1.3-0.2c0.2,0,0.3,0,0.4,0.1c0.3,0.2,0.2,0.6,0.2,0.9
		c0,0.3-0.1,0.5-0.3,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.1-1.2,0.1-1.8,0.2C57.4,18.1,57.3,18.1,57.3,18z M56.3,16.4l0.3-0.1
		c-0.4,0-0.7-0.6-0.5-1c0.2-0.4,0.7-0.5,1.2-0.4c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.5C57.2,16.1,56.7,16.4,56.3,16.4z
		 M54.7,14.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-1.6-0.2-2.2-0.6c-0.3-0.2-0.7-0.5-0.7-0.8
		c-0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,0.1,0,0.1c0.4,0.8,0.7,1.6,0.5,2.4C55,14.4,54.8,14.7,54.7,14.9z M57.5,13.4
		c-1.7-0.1-2-2.7-0.2-2.7C59.3,10.6,59.9,13.5,57.5,13.4z M62.5,10.6c0.4,0.4,0.6,1,0.8,1.5c0,0.2,0.1,0.4,0,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2
		L62.5,10.6z M51.4,11.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5
		c0-0.3,0.1-0.5,0.2-0.7C50.1,8.4,53.1,10.7,51.4,11.6z M54.6,10.2c-0.4-0.1-0.8-0.3-1-0.7c-0.2-0.3-0.3-0.6-0.2-0.9
		c0-0.2,0.1-0.4,0.2-0.6C55,6.3,58,10.7,54.6,10.2z M58.2,9.2C58.1,9.1,58,9.1,57.8,9c-1.6-1.1-0.7-2,0.8-1.5
		C59.1,7.7,59.8,8,60,8.4c0.3,0.6-0.3,1.1-1,1.1C58.8,9.5,58.5,9.4,58.2,9.2z M52.7,7.9c0,0.2-0.1,0.4-0.3,0.4
		c-0.1,0.4,0.1,1.3-0.8,0.8c-0.5-0.3-0.7-1-0.6-1.5c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3
		c0.1,0.2,0,0.3,0,0.5C52.7,7.5,52.7,7.7,52.7,7.9z M58.9,6.9c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4
		c0.3-1.4,1.2-1.2,2.3-0.6c0.4,0.2,0.7,0.7,0.5,1.1C59.8,6.8,59.3,6.9,58.9,6.9z M61.9,5C62.1,5.3,62,5.8,61.7,6
		c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.4-0.4-0.9-0.3-1.4l0,0.1C61.2,4.4,61.6,4.6,61.9,5z M63.1,5.9
		c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0
		c0.3,0.1,0.5,0.5,0.8,0.7c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.3-0.3,0.7-0.6,0.7C63.7,6.2,63.4,6.1,63.1,5.9z M49.7,3.6
		c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6
		C48.5,5.4,48.3,5,48,4.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2C49.3,3.6,49.5,3.6,49.7,3.6z
		 M41,4.7c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0,0.6,0C40.7,4.2,40.9,4.5,41,4.7z M27,3.6c0.4,0,0.7,0.4,0.9,0.8
		C28,4.8,28,5.2,28.1,5.6c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6c-0.4-0.4-0.6-0.8-0.8-1.3
		c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2C26.6,3.6,26.8,3.5,27,3.6z M24.2,4.2
		c0.3,0.1,0.5,0.5,0.8,0.7c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.3-0.3,0.7-0.6,0.7c-0.3,0.1-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.7-0.8
		c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0C24,4.2,24.1,4.2,24.2,4.2z M16.9,4.2
		C17,4.4,17,4.6,17,4.8c0,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.4c-0.4,0-0.8-0.3-1-0.6c-0.3-0.3-0.4-0.6-0.6-1
		c-0.2-0.4-0.4-0.9-0.2-1.4c0.2-0.5,0.7-0.7,1.2-0.8c0.5-0.1,1-0.1,1.4,0.1C16.2,3.1,16.6,3.6,16.9,4.2z M8.4,2.9
		C8.3,3.1,8.2,3.2,8,3.2c-0.1,0-0.3,0-0.4,0C7.2,3.1,6.8,3,6.3,2.9c-0.1,0-0.2,0-0.3-0.1C6,2.8,6,2.7,6,2.6C5.9,2.4,5.8,2.1,5.8,1.9
		c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4C7,1,7.7,0.9,8.1,1.3c0.2,0.2,0.3,0.4,0.4,0.6C8.6,2.3,8.6,2.7,8.4,2.9z M10.4,2.6
		c-0.3,0-0.5-0.2-0.7-0.3C9.5,2.1,9.3,2,9.3,1.8c0-0.1,0-0.3,0.1-0.4C9.6,1,10,0.8,10.4,0.7c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0
		c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5C10.9,2.6,10.6,2.6,10.4,2.6z M9.1,3.9
		c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6
		c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6C9.2,5.4,8.9,5,8.7,4.5C8.6,4.4,8.6,4.3,8.6,4.2C8.6,4,8.9,3.9,9.1,3.9z M6.1,4.7
		c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0C8,4.3,8.1,4.7,8.4,5c0.1,0.2,0.3,0.3,0.3,0.5
		c0.1,0.3-0.3,0.7-0.6,0.7c-0.3,0.1-0.7-0.1-1-0.3C6.8,5.7,6.6,5.4,6.4,5.1C6.3,5,6.2,4.8,6.1,4.7z M4.2,6.8C3.8,6.8,3.4,6.6,3,6.5
		c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0-0.4c0.3-1.4,1.2-1.2,2.3-0.6C5.2,5.5,5.4,6,5.3,6.4C5.1,6.8,4.6,6.9,4.2,6.8z M3.5,9.2
		C3.4,9.1,3.3,9,3.2,9C1.5,7.9,2.5,7,4,7.5c0.4,0.1,1.1,0.4,1.4,0.9C5.6,9,5,9.4,4.4,9.4C4.1,9.4,3.8,9.3,3.5,9.2z M6.6,9.9
		C6.2,9.7,5.9,9.2,5.8,8.7c-0.1-0.5,0.1-1,0.4-1.4C6.4,7.2,6.7,7,7,7c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1
		C8.9,8.6,9,8.7,9,8.8C9,9,8.9,9.1,8.8,9.2c-0.4,0.5-0.9,1-1.6,0.9C7,10.1,6.8,10.1,6.6,9.9z M9.6,9.9c1.1-1.5,4.1,0.8,2.4,1.6
		c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5C9.4,10.4,9.5,10.2,9.6,9.9z
		 M7.2,12.2c0,0.2,0.1,0.4,0,0.5C7.1,12.9,6.9,13,6.8,13c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9
		c0.1-0.3,0.5-0.4,0.8-0.2l-0.1-0.1C6.8,11.1,7.1,11.6,7.2,12.2z M3.1,10.7c0.5,0,0.8,0.1,1.1,0.4v0c0.8,0.6,0.9,1.8,0,2.2v0
		c-0.2,0.1-0.6,0.2-1,0.2C1.6,13.3,1.3,10.7,3.1,10.7z M0.9,17.1c-0.6-1,0.9-2.9,2-2.7c0.2,0,0.4,0.1,0.6,0.2c0,0,0-0.1,0-0.1
		c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.7,0.9-1.1C4.6,13,4.7,13,4.8,12.9c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2
		c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.2,0-0.5-0.1-0.7-0.2
		c0,0.8-0.7,1.7-1.5,2C1.8,17.6,1.2,17.5,0.9,17.1z M3.6,17.6c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3
		c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1
		C3.7,18.3,3.5,18,3.6,17.6z M3.1,20.2c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1c0.5,0,1,0.3,1.1,0.8c0.1,0.3,0.1,0.6,0,0.8
		c0,0.1-0.1,0.2-0.2,0.3C3.5,20.2,3.3,20.2,3.1,20.2z M6.1,21.7c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.4-0.6-0.4-1c0-0.4,0.2-0.7,0.4-1
		c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5-0.1,0.8
		c-0.2,0.3-0.6,0.4-0.9,0.5C6.4,21.7,6.2,21.8,6.1,21.7z M4.4,23.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.5-0.2,0.7-0.1c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.4,0.3,0.9,0.1,1.3c-0.1,0.2-0.4,0.3-0.7,0.4
		C5,23.9,4.6,23.8,4.4,23.6z M7.2,22.8c0.1-0.3,0.3-0.7,0.6-0.8c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1c0.1,0.4,0.1,0.8,0.2,1.3
		c0,0.3,0.1,0.5,0.1,0.8c0,0.4-0.2,0.8-0.6,1c-0.4,0.2-0.8,0.2-1.1-0.1c-0.2-0.2-0.3-0.5-0.4-0.8C7,24.5,7,23.6,7.2,22.8z M3.7,26.7
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7c1.1-1.5,4.1,0.8,2.4,1.6c-0.2,0.1-0.5,0.1-0.7,0.1
		C4.3,26.8,4,26.8,3.7,26.7z M10.1,26.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.2,1-0.3,1.3,0
		c0.3,0.3,0.4,0.7,0.1,1.1c-0.2,0.3-0.6,0.4-1,0.3c-0.2,0-0.4-0.2-0.6-0.3C10.1,27.2,10.1,27,10.1,26.7z M3.3,29
		c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.2,0.5-0.2
		c0.2,0,0.4,0,0.6,0c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3-0.1,0.7-0.4,0.8C3.9,29.1,3.6,29.1,3.3,29z M5.3,28.5c0-0.2-0.1-0.5,0-0.7
		c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8,0c0.3,0.1,0.4,0.5,0.3,0.8
		c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.2-0.3,0.5-0.6,0.7c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1
		C5.2,29,5.3,28.8,5.3,28.5z M4.4,30.3c-0.1-0.2-0.1-0.5,0.1-0.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3,0,0.4,0.1
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4C4.8,30.6,4.5,30.5,4.4,30.3z M1.6,32.3c-1.3-0.1-1.9-0.9-1.2-2
		c0.4-0.7,1-1.2,1.8-1c0.7,0.2,1.3,0.9,1.3,1.6C3.7,32,2.6,32.4,1.6,32.3z M3.8,32.9c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2
		c0-0.1,0-0.2,0-0.2c0.2-0.6,0.9-1,1.5-0.9c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.2
		c-0.1,0.1-0.1,0.2-0.2,0.3C4.8,32.9,4.2,32.9,3.8,32.9z M14.3,32.8c0.6-0.1,1.2,0,1.7,0.4c0.2,0.2,0.4,0.4,0.5,0.6h0
		c0.1,0.3,0.2,0.6,0,0.9c-0.1,0.3-0.3,0.5-0.6,0.6c-0.5,0.2-1,0.1-1.5-0.1c-0.5-0.2-0.9-0.5-1.4-0.6c-0.2-0.1-0.5-0.1-0.7-0.3
		c-0.2-0.1-0.3-0.4-0.3-0.6h0c0,0,0,0,0,0c0.1-0.2,0.3-0.3,0.5-0.4C13.2,33.1,13.7,32.9,14.3,32.8z M4.5,33.8
		C4.5,33.8,4.5,33.8,4.5,33.8L4.5,33.8c0-0.1-0.1-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.2c0.3-0.1,0.7-0.2,1-0.3c0.3,0,0.5-0.1,0.8,0
		c0.3,0.1,0.7,0.3,0.8,0.6c0,0,0,0.1,0.1,0.1h0c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.3,0.5-0.6,0.6c-0.3,0.2-0.7,0.3-1.1,0.4
		c-0.2,0.1-0.5,0.1-0.7-0.1C5,35,4.9,34.9,4.9,34.8C4.8,34.5,4.6,34.1,4.5,33.8z M3.5,36.9c-1-0.2-1.6-1.8-0.5-2.2
		c0.5-0.2,1.8,0.2,1.4,0.9c-0.1,0.1-0.2,0.2-0.2,0.3C4,36.2,4,36.4,3.9,36.6C3.9,36.8,3.7,37,3.5,36.9z M6.2,38
		c-0.3-0.2-0.7-0.5-0.7-0.9c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2
		c0.6,0.3,0.7,1,0.8,1.6c0,0.3,0,0.5-0.1,0.8c-0.1,0.2-0.3,0.3-0.5,0.4C8,38.7,7,38.5,6.2,38z M12.1,38.5c0.3-0.2,1.2,0.6,1.5,0.7
		c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1C12,40.4,12,40.3,12,40.2C12,39.8,11.7,38.7,12.1,38.5z M3.8,42.4
		c-0.9-0.1-1.7-0.3-1.9-1.2c-0.2-0.8,0.2-1.7,0.9-2.1c0.5-0.3,1-0.3,1.4-0.1v0c0.4,0.3,0.8,0.7,1,1.2c0.5,1.1,0.2,2.1-1,2.2l0,0
		C4.1,42.4,4,42.4,3.8,42.4z M9.1,42.2C9.3,42.1,9.5,42,9.8,42c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.7,0.4,0.9,0.8
		c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6c-0.4-0.4-0.6-0.8-0.8-1.3
		c-0.1-0.1-0.1-0.2-0.1-0.3C8.6,42.3,8.9,42.2,9.1,42.2z M6.1,43c0-0.2,0-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0
		c0.1,0,0.3,0,0.4,0C8,42.6,8.1,43,8.4,43.3c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.3-0.3,0.7-0.6,0.7c-0.3,0.1-0.7-0.1-1-0.3
		c-0.3-0.2-0.5-0.5-0.7-0.8C6.3,43.3,6.2,43.1,6.1,43z M4.2,45.1c-0.4-0.1-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.5-0.2
		c-0.1-0.1,0-0.3,0-0.4c0.2-0.9,0.7-1.1,1.3-1c0.2,0,0.3,0,0.5,0v0c0.3,0.1,0.7,0.2,1,0.4c0.4,0.2,0.7,0.7,0.5,1.1
		c-0.2,0.4-0.7,0.5-1.1,0.4c0,0,0,0,0,0C4.5,45.2,4.3,45.2,4.2,45.1z M6.6,48.2C6.2,48,6,47.6,5.8,47.1c-0.1,0.4-0.5,0.6-1,0.6
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.8-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-1.4-0.9-0.9-1.7,0.3-1.6
		c0.2,0,0.5,0,0.8,0.1v0c0.1,0,0.1,0,0.2,0.1c0.4,0.1,1.1,0.4,1.4,0.9c0,0,0,0,0,0c0-0.4,0.2-0.7,0.4-1c0.2-0.2,0.5-0.4,0.8-0.4
		c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1C8.9,46.9,9,47,9,47.1c0,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.9,1-1.6,0.9
		C7,48.4,6.8,48.3,6.6,48.2z M9.6,48.2c1.1-1.5,4.1,0.8,2.4,1.6C11.8,50,11.6,50,11.3,50c-0.3,0-0.7,0-1-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5C9.4,48.7,9.5,48.5,9.6,48.2z M7.2,50.5c0,0.2,0.1,0.4,0,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.4,0.8-0.2
		l-0.1-0.1C6.8,49.4,7.1,49.9,7.2,50.5z M3.1,48.9c0.5,0,0.8,0.1,1.1,0.4v0c0.8,0.6,0.9,1.8,0,2.2v0c-0.2,0.1-0.6,0.2-1,0.2
		C1.6,51.6,1.3,49,3.1,48.9z M0.9,55.4c-0.6-1,0.9-2.9,2-2.7c0.2,0,0.4,0.1,0.6,0.2c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3
		c0.3-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.2
		c0.2,0.2,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.2,0-0.5-0.1-0.7-0.2c0,0.8-0.7,1.7-1.5,2
		C1.8,55.9,1.2,55.8,0.9,55.4z M3.6,55.9c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4l-0.1-0.1c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1C3.7,56.6,3.5,56.3,3.6,55.9z
		 M3.1,58.5c-0.7,0-1.4-0.2-2-0.4c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3C1,57,1.2,56.7,1.5,56.6c0.2-0.1,0.5,0,0.8-0.1
		c0.1,0,0.3-0.1,0.4-0.1c0.5,0,1,0.3,1.1,0.8c0.1,0.3,0.1,0.6,0,0.8c0,0.1-0.1,0.2-0.2,0.3C3.5,58.5,3.3,58.5,3.1,58.5z M6.1,60
		C6,60,5.9,60,5.8,59.9c-0.3-0.2-0.4-0.6-0.4-1c0-0.4,0.2-0.7,0.4-1c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1
		C6.8,57.7,7,57.9,7.1,58c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5-0.1,0.8c-0.2,0.3-0.6,0.4-0.9,0.5C6.4,60,6.2,60.1,6.1,60z
		 M4.4,61.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8c0.2-0.2,0.4-0.4,0.7-0.5C5,60.2,5.2,60,5.5,60.1
		c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.4,0.3,0.9,0.1,1.3c-0.1,0.2-0.4,0.3-0.7,0.4C5,62.2,4.6,62.1,4.4,61.9z M7.2,61.1
		c0.1-0.3,0.3-0.7,0.6-0.8c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1c0.1,0.4,0.1,0.8,0.2,1.3c0,0.3,0.1,0.5,0.1,0.8
		c0,0.4-0.2,0.8-0.6,1c-0.4,0.2-0.8,0.2-1.1-0.1c-0.2-0.2-0.3-0.5-0.4-0.8C7,62.8,7,61.9,7.2,61.1z M3.7,65
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7c1.1-1.5,4.1,0.8,2.4,1.6c-0.2,0.1-0.5,0.1-0.7,0.1
		C4.3,65.1,4,65.1,3.7,65z M10.1,65c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.2,1-0.3,1.3,0c0.3,0.3,0.4,0.7,0.1,1.1
		c-0.2,0.3-0.6,0.4-1,0.3c-0.2,0-0.4-0.2-0.6-0.3C10.1,65.5,10.1,65.3,10.1,65z M3.3,67.2c-0.3-0.1-0.5-0.3-0.6-0.5
		c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.6,0
		c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3-0.1,0.7-0.4,0.8C3.9,67.4,3.6,67.4,3.3,67.2z M5.3,67c-0.1-0.2-0.1-0.7,0-0.9
		c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8,0c0.3,0.1,0.4,0.5,0.3,0.8
		c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.3-0.5,0.4c-0.5,0.2-1,0.3-1.6,0.4c-0.2,0-0.5,0-0.7-0.1C5.6,67.5,5.4,67.2,5.3,67z
		 M4.9,68.6c0,0.4-1.2,0.9-1.5,1.1c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.3,0C3.6,67.8,4.9,68.1,4.9,68.6z
		 M11.3,68.2c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.2,0-0.5,0.2-0.7c1-0.8,3.3,0.5,2.3,1.6c-0.1,0.2-0.4,0.2-0.6,0.2
		c-0.4,0-0.8-0.1-1.2-0.3C11.5,68.3,11.4,68.3,11.3,68.2z M12.2,70.4c-0.3,0.4-0.9,0.3-1.4,0.1c-0.3-0.1-0.7-0.3-0.9-0.5
		c-0.3-0.3-0.5-1-0.1-1.4c0.4-0.3,0.9-0.1,1.3,0C11.9,68.9,13,69.5,12.2,70.4z M20.3,69.6c-0.4,0.3-1,0.3-1.3-0.1
		c-0.3-0.4-0.2-0.9,0.1-1.2c0.3-0.4,0.7-0.6,0.9-1c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0.1,0.4,0.2C22.1,68.1,21.4,68.9,20.3,69.6z
		 M29.3,69.2c-0.2,0.2-0.3,0.5-0.6,0.7c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.5,0-0.7
		c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8,0c0.3,0.1,0.4,0.5,0.3,0.8
		c0,0.3-0.2,0.6-0.4,0.8C29.7,68.5,29.5,68.9,29.3,69.2z M38.8,69.2c-0.2,0.1-0.4,0.2-0.6,0.1c-0.3,0-0.5-0.3-0.7-0.5
		c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.6-0.1
		c0.1,0,0.2,0.1,0.4,0.2c0.3,0.2,0.5,0.5,0.7,0.8c0.1,0.3,0.1,0.7-0.1,1C39,69,38.9,69.1,38.8,69.2z M43.7,68.8
		c0,0.4-1.2,0.9-1.5,1.1c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.3,0C42.4,68,43.7,68.4,43.7,68.8z
		 M50.9,68.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.2,0-0.5,0.2-0.7c1-0.8,3.3,0.5,2.3,1.6
		c-0.1,0.2-0.4,0.2-0.6,0.2C51.7,68.7,51.3,68.6,50.9,68.4z M53.4,66.7c0.3,0.1,0.7,0.2,0.9,0.4c0.2,0.1,0.3,0.3,0.4,0.5
		c0.1,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.5-0.4-0.6c-0.1,0-0.2-0.1-0.2-0.1
		c-0.1-0.1-0.1-0.2,0-0.2C53.2,66.6,53.3,66.6,53.4,66.7z M50.6,69.9c-0.2,0.2-0.4,0.1-0.7,0.1c-0.2-0.1-0.3-0.1-0.5-0.3
		c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.4-0.1,0.6,0C50.4,69.2,51,69.5,50.6,69.9z M59.7,69.4c0.8,0.5-0.4,1.2-0.9,1.4
		c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.3-1.5,0-1.7C58.5,68.5,59.5,69.3,59.7,69.4z M57.8,67.8
		c-0.4,0.3-1,0.9-1.5,0.8c-0.8,0-0.9-0.8-0.6-1.4c0.2-0.3,0.4-0.5,0.7-0.7c0.1-0.1,0.3-0.2,0.4-0.2C58.7,65.3,59.1,66.6,57.8,67.8z
		 M69.3,66.5c0.3,0.1,0.4,0.5,0.3,0.8c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.2-0.3,0.5-0.6,0.7
		c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.5,0-0.7c0.1-0.2,0.4-0.3,0.7-0.4
		c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3C68.8,66.5,69.1,66.4,69.3,66.5z M67.1,63.7c0.4,0.1,1.6,0.4,1.7,0.9
		c0,0.4-1.2,0.9-1.5,1.1c-0.9,0.6-1.1-1-0.9-1.5c0.1-0.2,0.2-0.4,0.4-0.5C66.9,63.6,67,63.7,67.1,63.7z M63.6,63.3
		c0.5,0,1.2,0.1,1.5,0.5c0.4,0.4,0.3,1,0.1,1.6c-0.1,0.5-0.4,1-0.8,1.3c-0.6,0.5-1.3,0.6-2.1,0.5c-0.2,0-0.5,0-0.7-0.2
		c-0.5-0.3-0.7-0.9-0.7-1.5c-0.1-0.5-0.1-1.1,0.2-1.5c0.2-0.4,0.6-0.6,1.1-0.7S63.1,63.2,63.6,63.3z M68.6,61.2
		c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.4,0.3,0.9,0.1,1.3c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c-0.1,0.2-0.4,0.3-0.7,0.4
		c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4C67,62.3,67,62,67.2,61.8c0.2-0.2,0.4-0.4,0.7-0.5C68.1,61.2,68.4,61.1,68.6,61.2
		z M66.5,60.7c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.8,0.5-1.2,0.4c-0.5-0.1-0.9-0.3-1.3-0.4c-0.2-0.1-0.5-0.1-0.6-0.3
		c-0.1-0.2-0.1-0.4,0-0.6c0.3-0.5,0.8-0.8,1.3-1c0.5-0.2,1.1-0.2,1.7-0.2c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.1,0.6
		C66.6,60.2,66.5,60.4,66.5,60.7z M69.4,57.6c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.1,0.6,0,0.8
		c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3
		c0.3-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2C69.2,57.6,69.3,57.6,69.4,57.6z M67.3,56.7c0.2,0,0.4,0.2,0.4,0.4
		c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.3-0.7,0.3-1.1,0.3c-0.2,0-0.5,0.1-0.7,0
		c-0.4-0.1-0.5-0.6-0.4-0.9c0.1-0.4,0.5-0.6,0.8-0.7c0.4-0.1,0.7-0.2,1.1-0.3C66.9,56.7,67.1,56.7,67.3,56.7z M64.5,55.5
		c0.1,0.3,0.2,0.7,0,1c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.1-0.9,0.1-1.4,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.4
		c-0.3-0.6-0.5-1.4-0.1-1.9c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.3,1.1-0.3,1.6-0.2C63.9,54.6,64.3,55,64.5,55.5z M67.8,54
		c0.1,0.3,0,0.6-0.2,0.7c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4C64.4,53.3,67.2,51.7,67.8,54z M65,52.5l-0.2-0.2
		c0.2,0.4-0.2,0.8-0.6,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1C64.4,51.9,64.8,52.1,65,52.5z
		 M61.1,51.9c-0.2,0.1-0.4,0.1-0.6,0c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.1-0.8-0.2-1.2c-0.1-0.5-0.5-0.9-0.3-1.3
		c0.1-0.3,0.6-0.4,0.9-0.3c0.3,0.1,0.6,0.4,0.7,0.7c0.2,0.4,0.3,0.8,0.3,1.2C61.5,51.3,61.5,51.7,61.1,51.9z M65.9,50.8
		C66,50.9,66,51,66,51c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.4-0.9,0.3c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2C65.3,50,65.6,50.4,65.9,50.8z
		 M68,49.9C67.8,50,67.6,50,67.3,50c-0.3,0-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7
		C66.7,46.7,69.7,49,68,49.9z M65,47.1c0,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.9,1-1.6,0.9c-0.2,0-0.4-0.1-0.6-0.2
		c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0.1-1,0.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1
		C64.9,46.9,65,47,65,47.1z M66.7,45.6c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l-0.2,0C66.5,45.4,66.6,45.5,66.7,45.6z
		 M69.3,45.1c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.3,0.4L69,46.5c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1
		c-0.3-0.2-0.4-0.5-0.3-0.8c0.1-0.5,0.6-0.8,1.1-1c0.1,0,0.2-0.1,0.3-0.1C69.2,44.8,69.3,44.9,69.3,45.1z M61.4,45.5
		c0.2,0.3,0.2,0.8-0.2,0.9c-0.3,0.1-0.5-0.1-0.7-0.3c-0.4-0.4-0.6-1.6,0.4-1.5C61.1,44.7,61.4,45.2,61.4,45.5z M66.3,41.9
		c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6
		c-0.4-0.4-0.6-0.8-0.8-1.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2C66,41.9,66.1,41.8,66.3,41.9z
		 M58.1,42.4c-0.9-0.1-1.7-0.3-1.9-1.2c-0.2-0.8,0.2-1.7,0.9-2.1c1.1-0.7,2,0.2,2.4,1.1C60,41.5,59.5,42.5,58.1,42.4z M64.4,41.2
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.2-1.2-0.2c-0.1,0-0.2,0-0.3-0.1C62,41.1,62,41,62,40.9
		c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4c0.1-0.2,0.4-0.3,0.7-0.4c0.6-0.1,1.2-0.2,1.6,0.2c0.2,0.2,0.3,0.4,0.4,0.6
		C64.6,40.5,64.6,40.9,64.4,41.2z M66.4,40.9c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4
		c0.2-0.4,0.6-0.6,1.1-0.7c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5
		C66.9,40.8,66.6,40.9,66.4,40.9z M69.6,39.2c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
		c-0.1-0.3-0.3-1.5,0-1.7C68.4,38.3,69.3,39,69.6,39.2z M64.8,38.3C64,38.7,63,38.5,62.2,38c-0.3-0.2-0.7-0.5-0.7-0.9
		c0-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,1-0.2,1.5-0.2c0.3,0,0.5,0,0.8,0.2c0.6,0.3,0.7,1,0.8,1.6
		c0,0.3,0.1,0.5-0.1,0.8C65.3,38.1,65.1,38.2,64.8,38.3z M67.4,37.3c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.3-0.9,0-1.1-0.4
		c-0.2-0.4-0.2-0.8,0-1.2c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.8,0,1,0.3C67.4,36.6,67.5,36.9,67.4,37.3z M69.4,35.9
		c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.4-0.5,0.3c-1-0.2-1.6-1.8-0.5-2.2
		C68.6,34.8,69.9,35.2,69.4,35.9z M67.4,33.2c0.1,0.2,0.2,0.4,0.2,0.6l0,0c0,0.2,0,0.4-0.1,0.6c-0.2,0.4-0.6,0.6-1,0.7
		c-0.4,0.1-0.9,0-1.3-0.2c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.2,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1h0c0.3-0.4,0.6-0.8,1.1-0.9
		C66.5,32.7,67.1,32.8,67.4,33.2z M70.3,30.7c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.3-0.3,0.6-0.6,0.7
		c-0.3,0.1-0.7,0-0.9-0.2c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3-0.1,0.4-0.2c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.1,0.2-0.1C70.2,30.6,70.3,30.6,70.3,30.7z M69.3,28.3c0.3,0.1,0.4,0.5,0.3,0.8
		c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.2-0.3,0.5-0.6,0.7c-0.4,0.2-0.9,0.2-1.3-0.1c-0.4-0.3-0.6-0.7-0.6-1.1
		c0-0.3,0-0.5,0-0.8c0-0.2-0.1-0.5,0-0.7c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.2,0.8-0.3
		C68.8,28.2,69.1,28.1,69.3,28.3z M67.1,25.4c0.4,0.1,1.6,0.4,1.7,0.9c0,0.4-1.2,0.9-1.5,1.1c-0.9,0.6-1.1-1-0.9-1.5
		c0.1-0.2,0.2-0.4,0.4-0.5C66.9,25.3,67,25.4,67.1,25.4z M63.6,25c0.5,0,1.2,0.1,1.5,0.5c0.4,0.4,0.3,1,0.1,1.6
		c-0.1,0.5-0.4,1-0.8,1.3c-0.6,0.5-1.3,0.6-2.1,0.5c-0.2,0-0.5,0-0.7-0.2c-0.5-0.3-0.7-0.9-0.7-1.5c-0.1-0.5-0.1-1.1,0.2-1.5
		c0.2-0.4,0.6-0.6,1.1-0.7C62.7,24.9,63.1,24.9,63.6,25z M68.6,22.9c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.4,0.3,0.9,0.1,1.3c0,0,0,0,0-0.1
		l0,0.1c0,0,0,0,0,0c-0.1,0.2-0.4,0.3-0.7,0.4c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.1-0.6,0.1-0.8
		c0.2-0.2,0.4-0.4,0.7-0.5C68.1,22.9,68.4,22.8,68.6,22.9z M66.5,22.4c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.8,0.5-1.2,0.4
		c-0.5-0.1-0.9-0.3-1.3-0.4c-0.2-0.1-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.4,0-0.6c0.3-0.5,0.8-0.8,1.3-1c0.5-0.2,1.1-0.2,1.7-0.2
		c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.1,0.6C66.6,21.9,66.5,22.1,66.5,22.4z M69.4,19.3c0.3,0,0.6,0.1,0.9,0.2
		c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.2-1,1.4c-0.3,0.1-0.5,0.1-0.8,0c-0.4-0.1-0.8-0.2-1-0.5
		c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2C69.2,19.3,69.3,19.3,69.4,19.3z
		 M67.3,18.4c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.4
		c-0.3,0.3-0.7,0.3-1.1,0.3c-0.2,0-0.5,0.1-0.7,0c-0.4-0.1-0.5-0.6-0.4-0.9c0.1-0.4,0.5-0.6,0.8-0.7c0.4-0.1,0.7-0.2,1.1-0.3
		C66.9,18.4,67.1,18.4,67.3,18.4z M64.5,17.2c0.1,0.3,0.2,0.7,0,1c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.1-0.9,0.1-1.4,0.1
		c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.4c-0.3-0.6-0.5-1.4-0.1-1.9c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.3,1.1-0.3,1.6-0.2
		C63.9,16.3,64.3,16.7,64.5,17.2z M67.8,15.7c0.1,0.3,0,0.6-0.2,0.7c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4
		C64.4,15,67.2,13.4,67.8,15.7z M65,14.2L64.9,14c0.2,0.4-0.2,0.8-0.6,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.1,0-0.3,0.1-0.4
		c0.1-0.1,0.3-0.1,0.4-0.1C64.4,13.6,64.8,13.8,65,14.2z M61.1,13.6c-0.2,0.1-0.4,0.1-0.6,0c-0.2,0-0.3-0.1-0.4-0.2
		c-0.3-0.3-0.1-0.8-0.2-1.2c-0.1-0.5-0.5-0.9-0.3-1.3c0.1-0.3,0.6-0.4,0.9-0.3c0.3,0.1,0.6,0.4,0.7,0.7c0.2,0.4,0.3,0.8,0.3,1.2
		C61.5,13,61.5,13.4,61.1,13.6z M65.9,12.5c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.4-0.9,0.3
		c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.6-0.7-1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7,0
		c0.1,0.1,0.1,0.1,0.2,0.2C65.3,11.7,65.6,12.1,65.9,12.5z M68,11.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.7,0-1-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.3,0.1-0.5,0.2-0.7C66.7,8.4,69.7,10.7,68,11.6z M65,8.8
		c0,0.2-0.1,0.3-0.2,0.4c-0.4,0.5-0.9,1-1.6,0.9c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0.1-1,0.4-1.4
		C62.4,7.1,62.7,7,63,6.9c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.7,1.1C64.9,8.6,65,8.7,65,8.8z M66.7,7.3C67,7.5,67,7.9,66.8,8.1
		c-0.2,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.7-0.4-0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l-0.1,0c0.1,0,0.2,0,0.3,0
		c0.1,0,0.3,0,0.4,0.1l-0.2,0C66.5,7.2,66.6,7.2,66.7,7.3z M69.3,6.8c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.6
		c0,0.2-0.1,0.4-0.3,0.4L69,8.2c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.4-0.5-0.3-0.8c0.1-0.5,0.6-0.8,1.1-1
		c0.1,0,0.2-0.1,0.3-0.1C69.2,6.5,69.3,6.7,69.3,6.8z M61.4,7.2c0.2,0.3,0.2,0.8-0.2,0.9c-0.3,0.1-0.5-0.1-0.7-0.3
		c-0.4-0.4-0.6-1.6,0.4-1.5C61.1,6.4,61.4,6.9,61.4,7.2z M66.3,3.6c0.4,0,0.7,0.4,0.9,0.8c0.1,0.4,0.1,0.8,0.2,1.2
		c0,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.2-0.5,0.2c-0.5,0-1-0.3-1.3-0.6c-0.4-0.4-0.6-0.8-0.8-1.3c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2C66,3.6,66.1,3.5,66.3,3.6z M64.4,2.9c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.4,0
		c-0.4-0.1-0.8-0.2-1.2-0.2c-0.1,0-0.2,0-0.3-0.1C62,2.8,62,2.7,62,2.6c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3,0-0.4
		c0.1-0.2,0.4-0.3,0.7-0.4C63,1,63.7,0.9,64.1,1.3c0.2,0.2,0.3,0.4,0.4,0.6C64.6,2.2,64.6,2.6,64.4,2.9z M66.4,2.6
		c-0.3,0-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.1,0-0.3,0.1-0.4C65.6,1,66,0.7,66.5,0.7c0,0,0,0-0.1,0l0.1,0c0,0,0,0,0,0
		c0.2,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5C66.9,2.6,66.6,2.6,66.4,2.6z M68.1,0.2
		c0.3-0.2,1.2,0.6,1.5,0.7c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1C68.1,2,68,1.9,68,1.8C68,1.5,67.7,0.4,68.1,0.2z M60.9,0.8
		c0.2,0.4,0.3,0.8,0.3,1.2c0,0,0,0.1,0,0.1c0,0.3-0.1,0.7-0.3,0.7C59.5,3,60.2-0.6,60.9,0.8z M57,0.8C58.1,0.1,59,1,59.4,2
		c0.5,1.3,0.1,2.3-1.4,2.2c-0.9-0.1-1.7-0.3-1.9-1.2C55.9,2.1,56.3,1.2,57,0.8z M51.4,0.2c0.3-0.2,1.2,0.6,1.5,0.7
		c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C51.3,1.5,51.1,0.4,51.4,0.2z M44.1,1.9
		c0.5,1.3,0.1,2.3-1.4,2.2C41.9,4,41,3.8,40.8,2.9c-0.2-0.8,0.2-1.7,0.9-2.1C42.7,0.1,43.7,1,44.1,1.9z M38.7,3.5
		c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.3-0.1-0.6-0.2-0.9c0-0.1-0.1-0.3-0.1-0.4
		c0-0.1,0-0.3,0.1-0.4C37.3,1,37.4,1,37.5,0.9c0.3-0.1,0.7-0.1,1-0.1c0.4,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.5,0.3,0.8
		c0,0.5-0.1,1-0.4,1.2C39.3,3.4,39,3.5,38.7,3.5z M34.3,0.8c0.5-0.3,1-0.3,1.4-0.1v0c0.4,0.3,0.8,0.7,1,1.2c0.5,1.1,0.2,2.1-1,2.2v0
		c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.7-0.3-1.9-1.2C33.2,2.1,33.6,1.2,34.3,0.8z M28.7,0.2C29,0,29.9,0.7,30.2,0.9
		c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2-0.1-0.3C28.6,1.5,28.3,0.4,28.7,0.2z M21.6,0.8
		c0.2,0.4,0.3,0.8,0.3,1.2c0,0,0,0.1,0,0.1c0,0.3-0.1,0.7-0.3,0.7C20.2,3,20.8-0.6,21.6,0.8z M17.6,0.8c1.1-0.7,2,0.2,2.4,1.1
		c0.5,1.3,0.1,2.3-1.4,2.2c-0.9-0.1-1.7-0.3-1.9-1.2C16.6,2.1,17,1.2,17.6,0.8z M12.1,0.2c0.3-0.2,1.2,0.6,1.5,0.7
		c0.8,0.5-0.4,1.2-0.9,1.4c-0.2,0-0.4,0-0.5-0.1C12,2.1,12,2,12,1.9C12,1.5,11.7,0.4,12.1,0.2z"/>
            <path className="st4" d="M70.9,2.3c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.5
		h-1.8c0,0.2,0,0.5-0.1,0.7c-0.2,0.6-0.8,1-1.4,1.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1.2-0.9c-0.2-0.4-0.5-0.8-0.4-1.2h-1.2
		c0.3,1.5-2.9,3.4-2.5,0.4c0-0.2,0.1-0.3,0.1-0.4h-1.2c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5
		c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.2h-1.6c-0.3,0.4-0.7,0.5-1.2,0.1c0,0-0.1-0.1-0.1-0.1
		h-4c0.5,0.3,1,0.8,1.3,1.4c0.2,0.4,0.4,0.8,0.2,1.2C50,2.8,49.7,3,49.5,3.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.5,0.1-1-0.2-1.5-0.5
		c-0.2-0.2-0.5-0.3-0.7-0.6c-0.4-0.6-0.2-1.4,0.1-2c0.1-0.2,0.2-0.4,0.3-0.5H46c0,0.4-0.3,0.8-0.5,1.1c-0.1,0.1-0.2,0.3-0.3,0.3
		c-0.1,0-0.2,0-0.3,0L45,1.5c-0.4-0.2-0.6-0.5-0.7-1c0-0.2,0-0.4,0-0.6h-0.4c0.1,0.7-0.3,1.5-1.1,1.4c-0.5-0.1-1.4-0.9-1.1-1.4h-3.9
		c0,0.4,0,0.8-0.2,1.1c-0.1,0.2-0.3,0.5-0.6,0.6v0c-0.3,0.2-0.6,0.3-0.9,0.4c-0.2,0-0.3,0-0.5,0C35.4,2,35.2,1.7,35,1.5
		c-0.2-0.4-0.2-0.9-0.1-1.3C35,0.1,35,0.1,35,0h-0.7c0,0.4-0.1,0.8-0.1,1.1c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.6,0.6-1,0.5
		c-0.4-0.1-0.8-0.3-1-0.7c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.5h-1.8c0,0.2,0,0.5-0.1,0.7
		c-0.2,0.6-0.8,1-1.4,1.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1.2-0.9c-0.2-0.4-0.5-0.8-0.4-1.2h-1.2c0.3,1.5-2.9,3.4-2.5,0.4
		c0-0.2,0.1-0.3,0.1-0.4h-1.2c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5c-0.3-0.1-0.5-0.2-0.8-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.2h-1.6c-0.3,0.4-0.7,0.5-1.2,0.1c0,0-0.1-0.1-0.1-0.1h-4c0.5,0.3,1,0.8,1.3,1.4
		c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6c-0.3,0.2-0.7,0.4-1.1,0.5c-0.5,0.1-1-0.2-1.5-0.5C8.4,3,8.2,2.8,8,2.6
		C7.6,2,7.9,1.2,8.1,0.5C8.2,0.3,8.3,0.2,8.4,0H7.7c0,0.4-0.3,0.8-0.5,1.1C7.1,1.3,7,1.4,6.9,1.5c-0.1,0-0.2,0-0.3,0l0.1,0.1
		C6.4,1.4,6.1,1,6,0.6c0-0.2,0-0.4,0-0.6H5.7c0.1,0.7-0.3,1.5-1.1,1.4C4,1.3,3.1,0.5,3.5,0H1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.4
		c0,0.2,0,0.5-0.1,0.7c0,0.2,0,0.3-0.1,0.5c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.3-0.6,0.5-1,0.5C0.3,2.4,0.1,2.4,0,2.3v1
		c0.1,0,0.1,0,0.2,0c0.6,0.2,1,0.9,0.9,1.5C1.1,4.9,1,5.1,1,5.2C0.9,5.3,0.8,5.3,0.6,5.3c-0.1,0-0.2,0-0.2-0.1C0.3,5.2,0.1,5.1,0,5
		c0,0,0,0,0,0v1.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.4,0.8,0.7
		c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8c-0.3,0-0.5,0-0.8-0.1C0.2,9.3,0.1,9.1,0,9v5.4
		c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2
		c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.6,0.6-1,0.5c-0.1,0-0.3-0.1-0.4-0.1v4.3c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,1v0.1
		c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.4,0.8,0.7C3,23.5,3,24,2.9,24.5
		c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8c-0.3,0-0.5,0.1-0.8-0.1c-0.2-0.1-0.3-0.3-0.4-0.4v4.3c0-0.1,0.1-0.2,0.2-0.2
		c0.2-0.2,0.7-0.3,0.7,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.3,0,0.5,0.2,0.6,0.5
		c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.6,0.6-1,0.5c-0.1,0-0.3-0.1-0.4-0.1v2.7
		c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.1-0.1,0.2-0.2,0.3C0.5,38.1,0.3,38.1,0,38c0,0,0,0,0,0v2C0,40,0.1,40,0.2,39.9c0.1-0.1,0.2-0.1,0.3-0.2
		c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.2,0.4,0.3c0.1,0.2,0,0.3,0,0.5
		c0,0.2,0,0.5-0.1,0.7c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
		c0,0,0-0.1-0.1-0.1v2.1c0.1,0,0.1,0,0.2,0c0.6,0.2,1,0.9,0.9,1.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0v1.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.5-0.7,0.9-0.7
		c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8
		c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.3-0.4v5.4c0.1-0.2,0.3-0.3,0.4-0.5C0.6,53,0.8,52.8,1,52.8c0.3,0,0.5,0.2,0.6,0.5
		c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.6,0.6-1,0.5c-0.1,0-0.3-0.1-0.4-0.1v4.3
		c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,1v0.1c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2
		c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.2,0.6,0.2,0.9c0.2,0.3,0.4,0.7,0.2,1.2c-0.2,0.7-0.9,1.3-1.6,1.3c-0.4,0-0.8-0.1-1-0.4
		c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.4-0.4v5.5c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5
		c0.1,0.3,0.1,0.6,0.1,0.8c0,0,0,0,0,0h1.8c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.5
		c0.2-0.2,0.5-0.2,0.6-0.1c0.3,0.2,0.4,0.6,0.5,1h0.4c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.4,0.6-0.5c0.5-0.1,0.8,0.4,0.8,0.9
		c0,0,0,0.1,0,0.1h0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.5-0.2,0.7-0.1c0.4,0,0.7,0.1,1,0.3h4c-0.2-0.3-0.2-0.8,0-1.2
		c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1-0.5,1.4-0.1c0.3,0.4,0.1,0.9,0,1.3c-0.1,0.3-0.3,0.7-0.5,1h1.6c0,0,0-0.1,0-0.1
		c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6
		c0.3,0.2,0.5,0.5,0.6,0.9c0,0.2,0,0.4-0.1,0.6c0,0,0,0-0.1,0.1h1.2c0.1-0.2,0.3-0.5,0.5-0.6c0.3-0.2,0.6-0.3,0.9-0.2
		c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.4,0.6h1.2c0,0,0-0.1,0-0.1c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6
		c0.4-0.2,0.9-0.4,1.4-0.2c0.5,0.2,0.7,0.7,0.8,1.2c0,0.2,0.1,0.5,0.1,0.7h1.8c0,0,0.1,0,0.1-0.1c0.2-0.1,0.5-0.2,0.7-0.3
		c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.1,0.8c0,0,0,0,0,0H35
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.4,0.5-0.8c0-0.2,0.2-0.5,0.4-0.5
		c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3v0c0.3,0.4,0.5,0.8,0.6,1.2c0.1,0.2,0.1,0.4,0.1,0.6h3.9c0-0.1,0.1-0.2,0.2-0.2
		c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.5-0.2,0.6-0.1c0.3,0.2,0.4,0.6,0.5,1h0.4c0-0.2,0.1-0.4,0.2-0.6
		c0.1-0.2,0.3-0.4,0.6-0.5c0.5-0.1,0.8,0.4,0.8,0.9c0,0,0,0.1,0,0.1h0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.5-0.2,0.7-0.1
		c0.4,0,0.7,0.1,1,0.3h4c-0.2-0.3-0.2-0.8,0-1.2c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1-0.5,1.4-0.1c0.3,0.4,0.1,0.9,0,1.3
		c-0.1,0.3-0.3,0.7-0.5,1h1.6c0,0,0-0.1,0-0.1c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3
		c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0,0.2,0,0.4-0.1,0.6c0,0,0,0-0.1,0.1h1.2
		c0.1-0.2,0.3-0.5,0.5-0.6c0.3-0.2,0.6-0.3,0.9-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.4,0.6h1.2c0,0,0-0.1,0-0.1
		c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6c0.4-0.2,0.9-0.4,1.4-0.2c0.5,0.2,0.7,0.7,0.8,1.2c0,0.2,0.1,0.5,0.1,0.7H70
		c0,0,0.1,0,0.1-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0,0,0.1-0.1,0.1-0.1v-5.5c0,0,0,0,0-0.1c-0.3-0.6-0.2-1.3,0-2v-6.3
		c-0.2-0.1-0.5-0.3-0.6-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.3
		c0,0,0.1-0.1,0.1-0.1v-5.4c0,0,0-0.1-0.1-0.1c-0.3-0.7-0.2-1.4,0.1-2.1v-5.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2v-6.3
		c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.3
		c0,0,0.1-0.1,0.1-0.1v-5.5c0,0,0,0,0-0.1c-0.3-0.6-0.2-1.3,0-2v-6.3c-0.2-0.1-0.5-0.3-0.6-0.6c-0.1-0.2-0.2-0.4-0.3-0.7
		c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.3c0,0,0.1-0.1,0.1-0.1V9c0,0,0-0.1-0.1-0.1
		c-0.3-0.7-0.2-1.4,0.1-2.1V2.3z M66.8,3.8C66.8,3,67.1,2.1,68,1.9c0.8-0.2,1.7,0.2,2.1,0.9c0.3,0.5,0.3,1,0.1,1.4h0
		c-0.3,0.4-0.7,0.8-1.2,1c-1.1,0.5-2.1,0.2-2.2-1h0C66.8,4.1,66.8,4,66.8,3.8z M69.6,24.7c-0.2,0.2-0.4,0.3-0.6,0.4
		c-0.3,0.1-0.7,0.2-1,0c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3
		c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7C69.9,23.7,70,24.3,69.6,24.7z
		 M70.2,27.1C70.2,27.1,70.2,27.1,70.2,27.1L70.2,27.1C70.2,27.1,70.2,27.1,70.2,27.1c0,0.2-0.2,0.5-0.4,0.6
		c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1c-0.2-0.2-0.3-0.5-0.2-0.7c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4
		c0.1,0,0.3,0,0.4,0.1C69.9,26.2,70.1,26.7,70.2,27.1z M68.2,30.8c0.1,0.5,0.1,1-0.1,1.4c-0.2,0.6-0.8,1-1.4,1.3
		c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6
		c0.4-0.2,0.9-0.4,1.4-0.2C67.9,29.8,68.1,30.3,68.2,30.8z M69.6,47.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.7,0.2-1,0
		c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1
		c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7C69.9,46.4,69.9,47,69.6,47.5z M70.2,49.8
		C70.2,49.8,70.2,49.8,70.2,49.8L70.2,49.8C70.2,49.8,70.2,49.8,70.2,49.8c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.5,0.2-0.8,0.2
		c-0.2,0-0.3,0-0.5-0.1c-0.2-0.2-0.3-0.5-0.2-0.7c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1
		C69.9,49,70.1,49.4,70.2,49.8z M68.1,53.5c0.1,0.5,0.1,1-0.1,1.4c-0.2,0.6-0.8,1-1.4,1.3c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6c0.4-0.2,0.9-0.4,1.4-0.2
		C67.8,52.5,68,53,68.1,53.5z M55.1,61.1c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.4,0.2,0.9,0.2,1.3c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.2-0.8-0.1L55.1,61.1z M50.1,63
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.3,0-0.7,0.3-0.8
		c0.3-0.1,0.6,0.1,0.8,0.3c0.3,0.3,0.4,0.7,0.2,1C50.5,63,50.3,63,50.1,63C50,63,50,62.9,50,62.9L50.1,63z M32,56
		c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.3,0.2-0.5,0.4-0.6s0.5-0.2,0.7-0.3c0.2-0.2,0.3-0.4,0.5-0.6
		c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2c0,0.3-0.1,0.6-0.2,0.8
		c-0.2,0.4-0.6,0.6-1,0.5C32.5,56.6,32.2,56.3,32,56z M31.7,60.9c-0.4,0.2-0.8,0.3-1.2,0.3c0,0-0.1,0-0.1,0c-0.3,0-0.7-0.1-0.7-0.3
		C29.6,59.5,33.2,60.2,31.7,60.9z M16.8,61.1c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.4,0.2,0.9,0.2,1.3c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.2-0.8-0.1L16.8,61.1z M11.8,63
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.3,0-0.7,0.3-0.8
		c0.3-0.1,0.6,0.1,0.8,0.3c0.3,0.3,0.4,0.7,0.2,1C12.2,63,12,63,11.8,63c-0.1-0.1-0.1-0.1-0.1-0.1L11.8,63z M2.1,34.9
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.3,0-0.6,0.1-0.8c0.1-0.3,0.3-0.5,0.5-0.7c0.1,0,0.1-0.1,0.2-0.1
		c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.3,0.1,0.7,0,1c-0.1,0.2-0.2,0.3-0.3,0.5C2.9,35,2.5,35,2.1,34.9z M2.9,36.5
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.5,0.2-0.6c0.2-0.1,0.5-0.1,0.7,0.1C3,36,3,36.1,3,36.1
		C3.1,36.2,3,36.4,2.9,36.5z M3.7,31.3c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.5-0.2,0.6-0.1
		c0.8,0.7,0.6,2.6-0.7,2.4C3.9,32.8,2.9,31.8,3.7,31.3z M3.7,16.3c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.1,0.2,0.3,0.2,0.4
		c1,1.9-0.3,2.3-1.5,1c-0.3-0.4-0.9-1-0.8-1.5C2.3,16.2,3.1,16,3.7,16.3z M2.8,14.7c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.1,0.5-0.2,0.6-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2,0c0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.2,0.7-0.4,0.9
		c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.5-0.2C2.7,15,2.7,14.8,2.8,14.7z M17.8,7.3c0.2-0.2,0.5-0.3,0.7-0.1
		c0.1,0.1,0.1,0.1,0.1,0.2C18.8,7.6,19,7.7,19,8c0,0.4-0.3,0.8-0.6,1c-0.2,0.1-0.4,0.1-0.5-0.1c-0.2-0.1-0.2-0.3-0.2-0.5
		C17.6,8,17.5,7.6,17.8,7.3z M19.4,12.1c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5c-0.3-0.1-0.5-0.2-0.8-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7
		C18.9,11.7,19.2,11.9,19.4,12.1z M19.4,8.9c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1
		c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7C21.5,8.9,21.4,9,21.3,9c-0.4,0.3-0.9,0.6-1.3,0.9c-0.1,0-0.1,0.1-0.2,0.1
		c0,0-0.1-0.1-0.1-0.1C19.5,9.6,19.3,9.3,19.4,8.9z M21.9,23.1c-0.4,0.4-1,0.6-1.5,0.8c-0.2,0-0.4,0.1-0.5,0
		c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8
		L21.9,23.1z M25.3,10.7c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5
		c0,0,0,0.1,0,0.1l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C25.4,10.5,25.3,10.6,25.3,10.7z M24.9,11.6
		c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1
		c-0.2,0-0.4-0.1-0.4-0.3c-0.4-0.1-1.3,0.1-0.8-0.8C23.7,11.7,24.4,11.5,24.9,11.6z M37.2,9c0.1,0.2,0.2,0.4,0.2,0.6
		c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0-0.1,0.1v0c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6-0.2-0.8-0.4
		c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1
		c0.2,0.1,0.3,0.3,0.4,0.5v0C37.1,8.8,37.2,8.9,37.2,9z M36.1,10.4c0.2,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5
		c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.3,0.1-0.4
		C35.7,10.3,35.9,10.4,36.1,10.4z M35.8,12.1c0.2,0.5-0.2,1.8-0.9,1.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.2-0.1-0.4-0.3-0.3-0.5C33.9,11.6,35.5,11,35.8,12.1z M56.1,7.3c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2
		c0.2,0.2,0.4,0.2,0.3,0.6c0,0.4-0.3,0.8-0.6,1c-0.2,0.1-0.4,0.1-0.5-0.1c-0.2-0.1-0.2-0.3-0.2-0.5C55.9,8,55.8,7.6,56.1,7.3z
		 M57.7,12.1c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0,0.2,0,0.4-0.1,0.6
		c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7C57.2,11.7,57.5,11.9,57.7,12.1z M57.7,8.9
		c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7
		C59.8,8.9,59.7,9,59.6,9c-0.4,0.3-0.9,0.6-1.3,0.9c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1C57.8,9.6,57.6,9.3,57.7,8.9z
		 M60.2,23.1c-0.4,0.4-1,0.6-1.5,0.8c-0.2,0-0.4,0.1-0.5,0c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7
		c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8L60.2,23.1z M63.6,10.7c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8
		c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2
		C63.7,10.5,63.6,10.6,63.6,10.7z M63.2,11.6c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3c-0.4-0.1-1.3,0.1-0.8-0.8C62,11.7,62.7,11.5,63.2,11.6z
		 M65.9,22.5c-0.3,0.2-0.8,0.1-1.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.4-0.3,0.9-0.4,1.4-0.3l-0.1,0
		C66.4,21.8,66.2,22.3,65.9,22.5z M65.5,20.1c-0.2,0.4-0.7,0.7-1.1,0.5c-0.4-0.2-0.5-0.7-0.4-1.1c0.1-0.4,0.3-0.8,0.3-1.2
		c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0C66.3,18,66.1,19,65.5,20.1z M66.5,37.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0
		c-0.2-0.1-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0,0.3,0.1C66.5,37.6,66.5,37.8,66.5,37.9L66.5,37.9z M66,35.7
		L66,35.7c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.4-0.7,0.7-1.1,0.5c-0.4-0.2-0.5-0.7-0.4-1.1c0-0.1,0.1-0.3,0.1-0.4h0
		c0.1-0.3,0.2-0.5,0.2-0.8c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0C65.9,34.6,66.1,35.1,66,35.7z M65.5,44.1
		c-0.2,0.4-0.7,0.7-1.1,0.5c-0.4-0.2-0.5-0.7-0.4-1.1c0.1-0.4,0.3-0.8,0.3-1.2c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0
		C66.3,42.1,66.2,43,65.5,44.1z M66.2,45.5c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0,0.4
		c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6c-0.1-0.3,0.1-0.7,0.3-1c0.2-0.3,0.5-0.5,0.8-0.7
		C65.9,45.6,66,45.5,66.2,45.5z M63.6,50.1c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6
		c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C63.7,49.9,63.6,50,63.6,50.1z
		 M63.4,47.6c-0.3,0.3-0.7,0.5-1.1,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6
		c0-0.2,0.1-0.4,0.2-0.6c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0.1,1.4,0.4c0.2,0.2,0.4,0.5,0.4,0.8C64,46.8,63.7,47.3,63.4,47.6z
		 M25.3,50.1c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1
		l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C25.4,49.9,25.3,50,25.3,50.1z M25.1,47.6c-0.3,0.3-0.7,0.5-1.1,0.7
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6c0-0.2,0.1-0.4,0.2-0.6c0.3-0.4,0.7-0.7,1.2-0.8
		c0.5-0.1,1,0.1,1.4,0.4c0.2,0.2,0.4,0.5,0.4,0.8C25.7,46.8,25.4,47.3,25.1,47.6z M6.4,39.1c0.5-0.1,0.8,0.4,0.8,0.9
		c0,0.5-0.3,0.9-0.5,1.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.2,0-0.3,0l0.1,0.1c-0.4-0.2-0.6-0.5-0.7-1c-0.1-0.4,0-0.8,0.3-1.2
		C6,39.4,6.2,39.2,6.4,39.1z M6,38.2c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.3,0.2,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c0,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1C6,38.4,6,38.3,6,38.2z M5.9,43.1
		c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.3,0,0.5,0.1,0.7,0.2c1.5,1.1-0.8,4.1-1.6,2.4c-0.1-0.2-0.1-0.5-0.1-0.7
		C5.7,43.7,5.8,43.4,5.9,43.1z M6.4,46.8c0.2-0.2,0.5-0.3,0.8-0.4c0.8-0.1,1.6-0.1,2.4,0.1c0.3,0.1,0.7,0.3,0.8,0.6
		c0,0.1,0,0.3-0.1,0.4c-0.2,0.4-0.6,0.6-1,0.7c-0.4,0.1-0.8,0.1-1.3,0.2c-0.3,0-0.5,0.1-0.8,0.1c-0.4,0-0.8-0.2-1-0.6
		C6.1,47.6,6.2,47.1,6.4,46.8z M7.1,50.5C7.2,50.2,7.6,50,8,50c0.7-0.1,1.4-0.2,2.1-0.2c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.4,0.3,0.6
		c0,0.6-0.4,1.1-0.9,1.3C9.2,52,8.7,52,8.1,51.9c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.3-0.4-0.5C7.1,51.1,7,50.8,7.1,50.5z
		 M10.4,45.2c-0.4,0.3-0.9,0.3-1.3,0.1c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.2-0.3,0.4-0.4
		c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7C10.7,45,10.6,45.1,10.4,45.2z M10.2,42.5
		c-0.5,0.1-1-0.2-1.5-0.5c-0.2-0.2-0.5-0.3-0.7-0.6c-0.4-0.6-0.2-1.4,0.1-2c0.1-0.3,0.2-0.6,0.5-0.7c0.2-0.1,0.5-0.2,0.7-0.1
		c1,0.1,1.8,0.8,2.4,1.7c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6C10.9,42.3,10.5,42.5,10.2,42.5z M6.7,33
		c-0.4-0.2-0.6-0.5-0.7-1c-0.1-0.4,0-0.8,0.3-1.2c0.1-0.2,0.3-0.4,0.6-0.5c0.5-0.1,0.8,0.4,0.8,0.9c0,0.5-0.3,0.9-0.5,1.3
		C7.1,32.8,7,32.9,6.9,33C6.8,33,6.7,33,6.7,33L6.7,33z M5.2,28c-0.6-0.9,1-1.1,1.5-0.9c0.2,0.1,0.4,0.2,0.5,0.4c0,0.1,0,0.2,0,0.3
		c-0.1,0.4-0.4,1.6-0.9,1.7C5.9,29.5,5.4,28.3,5.2,28z M7.7,29.1c0-0.3,0.1-0.6,0.2-0.9C8,28,8.1,27.8,8.3,27.7
		c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0.1-0.2,0.3-0.3,0.3c-0.4,0.3-0.9,0.3-1.3,0.1
		c0,0,0,0,0.1,0l-0.1,0c0,0,0,0,0,0C7.8,29.6,7.7,29.3,7.7,29.1z M21.4,31.4c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0
		c-0.8,0.4-1.6,0.7-2.4,0.5c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2
		c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9
		C21.6,31.1,21.5,31.3,21.4,31.4z M21.9,34.6c0,0.5-0.1,0.8-0.4,1.1h0c-0.6,0.8-1.8,0.9-2.2,0h0c-0.1-0.2-0.2-0.6-0.2-1
		C19.3,33.1,21.9,32.8,21.9,34.6z M13.1,29.5c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9
		c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1
		c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2C12.3,29,12.7,29.3,13.1,29.5z M12.1,25.8c0.1-0.4,0.6-0.5,0.9-0.4
		c0.4,0.1,0.6,0.5,0.7,0.8c0.1,0.4,0.2,0.7,0.3,1.1c0,0.2,0.1,0.4,0.1,0.6c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
		c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.3-0.3-0.7-0.3-1.1C12.1,26.3,12.1,26,12.1,25.8z M12.4,36.2
		c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.8,0.4-1.2,0.3
		c-0.3,0-0.6-0.1-0.8-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.5-0.2,0.8-0.1
		c0.3,0,0.5,0.2,0.8,0.2C11.2,36.5,11.8,36.1,12.4,36.2z M11.7,37.9c0.2-0.3,0.5-0.4,0.8-0.5c0.3-0.1,0.5-0.2,0.8-0.3
		c0.1,0,0.3-0.1,0.4,0c0.2,0,0.3,0.2,0.3,0.3c0.2,0.7-0.2,1.5-1,1.6C12.4,39.1,11.1,38.9,11.7,37.9z M13.6,35.4
		c-0.3,0.1-0.6,0.1-0.8,0c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.7,0.2-1.4,0.4-2c0.1-0.2,0.2-0.5,0.5-0.5
		c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8c0,0.1,0.1,0.3,0.1,0.4C14.4,34.7,14.1,35.2,13.6,35.4z
		 M14.4,31.6c-0.4-0.3-0.3-0.9-0.1-1.4c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1-0.5,1.4-0.1c0.3,0.4,0.1,0.9,0,1.3
		C15.9,31.3,15.2,32.4,14.4,31.6z M15.2,33.9c-0.2-0.5-0.1-1.2,0.3-1.5c1-0.6,2.9,0.9,2.7,2C17.8,36.2,15.6,35,15.2,33.9z M17,37.9
		c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.4,0.1-0.6,0c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.2-0.5-0.4-0.5-0.8
		c0-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.2,0,0.4,0,0.6,0.1c0.1,0.1,0.1,0.1,0.1,0.2C16.8,37.4,16.9,37.6,17,37.9z
		 M16.2,28.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0.1-1.1,0.4-1.5c1-1.3,2.6,1.5,0.3,2C16.6,28.5,16.3,28.4,16.2,28.2z M14.6,35.7
		C14.6,35.7,14.6,35.7,14.6,35.7L14.6,35.7c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.2c0,0.2-0.2,0.3-0.4,0.4c0,0-0.1,0-0.1,0
		C14.5,36.9,14.3,35.9,14.6,35.7z M15,39.5c1-0.6,2.9,0.9,2.7,2c-0.4,1.8-2.6,0.6-3-0.5C14.5,40.4,14.6,39.8,15,39.5z M17.6,37.3
		c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.4-0.3,0.6-0.2c0.3,0,0.5,0.2,0.7,0.4c0.1,0.1,0.1,0.2,0.1,0.3
		c0,0.1-0.1,0.1-0.1,0.1C18.2,37.2,17.9,37.3,17.6,37.3z M20,26.6c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1
		c-0.2-0.3-0.4-0.6-0.3-0.9c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6
		c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.2,0.2C20.9,26,20.5,26.3,20,26.6z M19,21.8c-0.1-0.2-0.1-0.4,0-0.6
		c0-0.2,0.1-0.3,0.2-0.4c0.3-0.3,0.8-0.1,1.2-0.2c0.5-0.1,0.9-0.5,1.3-0.3c0.3,0.1,0.4,0.6,0.3,0.9c-0.1,0.3-0.4,0.6-0.7,0.7
		c-0.4,0.2-0.8,0.3-1.2,0.3C19.5,22.2,19.1,22.1,19,21.8z M19,24.7c0,0.4-0.3,0.8-0.6,1l0.2-0.2c-0.4,0.2-0.8-0.2-0.8-0.6
		c0-0.4,0.4-0.8,0.8-0.8c0.1,0,0.3,0,0.4,0.1C19,24.3,19,24.5,19,24.7z M18,20.9c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.2-0.8-0.1
		l0,0.1c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6,0c0.1,0.1,0.1,0.1,0.1,0.2C17.9,20,18,20.4,18,20.9z
		 M17.5,17.8c-0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0.1-0.3,0-0.5-0.1c-0.4-0.2-0.6-0.7-0.6-1.1l0.1,0.3c0-0.4,0.6-0.7,1-0.5
		C17.6,16.9,17.7,17.4,17.5,17.8z M16.3,19.9c0,0.2,0,0.3-0.1,0.4c-0.2,0.3-0.6,0.2-0.9,0.2c-0.3,0-0.5-0.1-0.7-0.3
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.2-0.1c0.4,0,0.8-0.1,1.1,0.1
		c0.2,0.1,0.3,0.4,0.4,0.6C16.3,19,16.4,19.4,16.3,19.9z M14.3,14.1c0.1-0.3,0.3-0.6,0.6-0.6c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0.5,0.3,1,0.6,1.2,1.1C17.1,16.4,13.7,16.6,14.3,14.1z M15.9,21.9c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.5,0.3,1.1,0.2,1.6
		c-0.2,0.5-0.6,1-1,1.2c-0.3,0.1-0.7,0.2-1,0c-0.4-0.2-0.7-0.6-0.9-1.1c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.1,0-0.2,0.1-0.3
		c0.1-0.2,0.3-0.3,0.4-0.3C14.5,21.7,15.3,21.5,15.9,21.9z M14,20.3c-0.1,0.3-0.3,0.5-0.6,0.6C11.7,21.9,10,18,12,18
		c0.3,0,0.5,0.1,0.8,0.2c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.3,0.4,0.4,0.7C14.1,19.8,14.1,20.1,14,20.3z M11.4,22.1
		c0.3-0.1,0.6,0.1,0.8,0.3c0.3,0.3,0.4,0.7,0.2,1c-0.2,0.2-0.4,0.2-0.7,0.1c0,0-0.1,0-0.1,0l0.1,0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3l0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3C11,22.7,11.1,22.3,11.4,22.1z M11.5,25.1c0.2,0.5,0.2,1.1,0.2,1.7
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-0.8-0.4-1.2
		c0.1-0.5,0.3-0.9,0.4-1.3c0.1-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.1,0.6,0C11,24,11.3,24.6,11.5,25.1z M10.4,22.2
		c-0.3,0.4-1,0.5-1.5,0.5c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6
		c0.1-0.2,0.3-0.4,0.5-0.2l0,0.1c0.1-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.4,0.2,0.5,0.4C10.7,21.5,10.6,21.9,10.4,22.2z M8.7,20
		c-0.1,0.2-0.3,0.4-0.5,0.5c-2.2,1-2.9-4.8,0.1-2.7c0.4,0.3,0.6,0.7,0.6,1.2C8.9,19.3,8.8,19.7,8.7,20z M8.1,32
		c0.1-0.3,0.2-0.6,0.5-0.7c0.2-0.1,0.5-0.2,0.7-0.1c1,0.1,1.8,0.8,2.4,1.7c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6
		c-0.3,0.2-0.7,0.4-1.1,0.5c-0.5,0.1-1-0.2-1.5-0.5c-0.2-0.2-0.5-0.3-0.7-0.6C7.6,33.5,7.9,32.7,8.1,32z M10.8,45.5
		c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.4c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6
		c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8-0.1c-0.3-0.2-0.4-0.6-0.5-0.9
		C10.8,45.7,10.8,45.6,10.8,45.5z M11.9,47.6c0.2-0.3,0.6-0.5,0.9-0.5c0.2,0,0.3,0.1,0.5,0.2c0.7,0.6,0.2,1.3-0.5,1.6
		C11.9,49.5,11.4,48.5,11.9,47.6z M12.8,42.7c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.7,0.2-1.4,0.4-2
		c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8c0,0.1,0.1,0.3,0.1,0.4
		c0,0.5-0.3,1-0.8,1.1C13.4,42.8,13.1,42.8,12.8,42.7z M14.1,43.3c0.2-0.3,0.5-0.4,0.8-0.3c0.5,0.1,0.8,0.6,1,1.1
		c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1
		c-0.2,0-0.4-0.2-0.4-0.4C13.9,43.7,14,43.5,14.1,43.3z M14.3,46.9c0,0,0-0.1,0-0.1c0.2-0.6,0.8-1.1,1.5-1.1c1.8,0.1,0.9,3.5-0.6,3
		C14.5,48.3,14.1,47.6,14.3,46.9z M16.1,50.6C16.1,50.6,16.1,50.6,16.1,50.6c0-0.5,0.1-1.1,0.4-1.5c1-1.3,2.6,1.5,0.3,2
		c-0.3,0.1-0.6,0-0.7-0.2C16.1,50.8,16.1,50.7,16.1,50.6z M17.8,48.3c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.4-0.1-0.8,0.2-1.1
		c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2C18.8,47,19,47,19,47.4c0,0.4-0.3,0.8-0.6,1C18.2,48.5,18,48.4,17.8,48.3z
		 M18.4,45.7c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2
		c0.4,0.3,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9c0,0.2-0.1,0.3-0.2,0.4
		C19,45.9,18.7,45.8,18.4,45.7z M19.2,42.1c0.1-1.7,2.7-2,2.7-0.2C21.9,43.9,19.1,44.6,19.2,42.1z M19.4,48.3c0-0.2,0.2-0.4,0.3-0.6
		c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.4,0.3-0.9,0.6-1.3,0.9c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1C19.5,48.9,19.3,48.6,19.4,48.3z M20.4,46.6
		c-0.2,0-0.4,0.1-0.5,0c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1
		c0.3,0.1,0.4,0.5,0.2,0.8l0.1-0.1C21.5,46.2,21,46.5,20.4,46.6z M21.3,39.9c-0.2,0.1-0.4,0.1-0.6,0.2c-0.6,0.1-1.2,0.2-1.7,0.3
		c-0.3,0.1-0.8,0.1-1-0.2c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.6,0.1-1.2,0.4-1.7c0.4-0.4,0.9-0.6,1.5-0.7c0.5-0.1,1.1-0.2,1.6-0.5
		c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.4,0.3,0.5,0.5c0.2,0.7-0.1,1.6-0.5,2.2C21.8,39.5,21.6,39.7,21.3,39.9z M27.6,22.5
		c-0.3,0.2-0.8,0.1-1.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.4-0.3,0.9-0.4,1.4-0.3l-0.1,0
		C28.1,21.8,28,22.3,27.6,22.5z M26.7,23.7c0.2-0.3,0.5-0.5,0.8-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2
		c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6
		C26.3,24.3,26.5,24,26.7,23.7z M26.8,26.2c0.4-0.4,0.8-0.6,1.3-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5
		c0.1,0.2,0.1,0.5,0.2,0.7C29,26.6,29,26.8,29,27c0,0.4-0.4,0.7-0.8,0.9C27.8,28,27.4,28,27,28.1c-0.2,0-0.4,0.1-0.6,0
		c-0.2-0.1-0.2-0.3-0.2-0.5C26.2,27,26.4,26.5,26.8,26.2z M38,26.6c0.2,0.5,0.1,1.1-0.4,1.4c-0.2,0.1-0.4,0.2-0.6,0.2l0,0
		c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.6-0.6-0.7-1c-0.1-0.4,0-0.9,0.2-1.3c0.1-0.3,0.3-0.5,0.6-0.5c0.2,0,0.3,0.1,0.4,0.2
		c0,0,0.1,0,0.1,0.1v0C37.5,25.8,37.9,26.1,38,26.6z M39.3,12.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0.1-0.2,0.4-0.3,0.5-0.1
		c0,0,0.1,0.1,0.1,0.2c0.2,0.7,0.3,1.4,0.3,2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.5,0.2-0.7,0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.4-0.5,0.7-0.6C39.4,12.6,39.3,12.5,39.3,12.4z
		 M59.7,31.4c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5c-0.3-0.1-0.5-0.2-0.8-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3
		c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9C59.9,31.1,59.8,31.3,59.7,31.4z M60.2,34.6
		c0,0.5-0.1,0.8-0.4,1.1h0c-0.6,0.8-1.8,0.9-2.2,0h0c-0.1-0.2-0.2-0.6-0.2-1C57.6,33.1,60.2,32.8,60.2,34.6z M44.7,39.1
		c0.5-0.1,0.8,0.4,0.8,0.9c0,0.5-0.3,0.9-0.5,1.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.2,0-0.3,0l0.1,0.1c-0.4-0.2-0.6-0.5-0.7-1
		c-0.1-0.4,0-0.8,0.3-1.2C44.3,39.4,44.5,39.2,44.7,39.1z M44.3,38.2c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.2,0.6-0.1
		c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c0,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1
		C44.3,38.4,44.3,38.3,44.3,38.2z M44.2,43.1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.3,0,0.5,0.1,0.7,0.2
		c1.5,1.1-0.8,4.1-1.6,2.4C44.1,44.5,44,44.3,44,44C44,43.7,44.1,43.4,44.2,43.1z M45.9,36.8C45.9,36.8,45.8,36.8,45.9,36.8
		c-0.8,0.4-1.7,0.8-2.5,0.5c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.1-0.5,0-0.7h0
		c0.1-0.5,0.3-1.1,0.6-1.5c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6
		c0.2,0.2,0.4,0.4,0.5,0.7h0c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4-0.1,0.6C46.3,36.6,46.1,36.7,45.9,36.8z M42.5,41.4
		c-0.6-0.1-1.6-1.1-0.8-1.6c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.5-0.2,0.6-0.1C44,39.6,43.7,41.6,42.5,41.4z
		 M43.4,43.2c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7-0.1-0.8-0.4c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.3,0.3-0.5,0.5-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.5C43.5,42.8,43.5,43,43.4,43.2z
		 M38.5,42.6c0.6,0.2,1,0.9,0.9,1.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.3-0.3-0.4-0.8-0.4-1.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2C38.3,42.6,38.4,42.6,38.5,42.6z M38.5,40.4
		c0-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.3,0.2,0.7,0.1,1.1c0,0.1,0,0.1-0.1,0.2
		c-0.1,0.1-0.2,0.1-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1C38.5,40.9,38.4,40.7,38.5,40.4z M39.3,42.3c-0.6-0.9,1-1.1,1.5-0.9
		c0.2,0.1,0.4,0.2,0.5,0.4c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.4,1.6-0.9,1.7C40,43.8,39.5,42.6,39.3,42.3z M40.8,39.5
		c-0.3,0.1-0.7,0.1-1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.4-0.1-0.6c0-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.3,0.6-0.5
		c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.4,0.1,0.6c0,0.1-0.1,0.2-0.2,0.4
		C41.4,39.1,41.1,39.4,40.8,39.5z M42.3,28.3c0,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0,0.8c-0.1,0.3-0.5,0.4-0.8,0.3
		c-0.3,0-0.6-0.2-0.8-0.4c-0.4-0.2-0.7-0.5-1.1-0.7c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.4-0.2-0.9,0.1-1.3s0.7-0.6,1.1-0.6
		c0.3,0,0.5,0,0.8,0c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.3,0.4,0.4,0.7C42.3,27.8,42.2,28,42.3,28.3z M46.4,29.8L46.4,29.8
		C46.3,29.7,46.3,29.7,46.4,29.8c-0.3-0.2-0.4-0.4-0.4-0.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.1,0.6-0.1,0.8,0.1
		c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0.1-0.2,0.3-0.3,0.3C47.3,29.9,46.8,29.9,46.4,29.8
		C46.4,29.7,46.4,29.7,46.4,29.8z M46,31.4c0,0.5-0.3,0.9-0.5,1.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.2,0-0.3,0L45,33
		c-0.4-0.2-0.6-0.5-0.7-1c-0.1-0.4,0-0.8,0.3-1.2c0.1-0.2,0.3-0.4,0.6-0.5C45.6,30.4,46,30.9,46,31.4z M45.5,27.8
		c-0.1,0.4-0.4,1.6-0.9,1.7c-0.4,0-0.9-1.2-1.1-1.5c-0.6-0.9,1-1.1,1.5-0.9c0.2,0.1,0.4,0.2,0.5,0.4C45.5,27.6,45.5,27.7,45.5,27.8z
		 M43.5,30.5c0.8,0.7,0.6,2.6-0.7,2.4c-0.6-0.1-1.6-1.1-0.8-1.6c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.5
		C43,30.5,43.3,30.4,43.5,30.5z M41.5,33.7c-0.2,0.7-0.9,1.3-1.6,1.3c-1.1,0.1-1.5-1-1.4-1.9c0.1-1.3,0.9-1.9,2-1.2
		C41.1,32.3,41.7,32.9,41.5,33.7z M41.2,36.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.5,0.2-0.6
		c0.2-0.1,0.5-0.1,0.7,0.1c0,0,0.1,0.1,0.1,0.1C41.4,36.2,41.3,36.4,41.2,36.5z M46.3,34.1c-0.4-0.6-0.2-1.4,0.1-2
		c0.1-0.3,0.2-0.6,0.5-0.7c0.2-0.1,0.5-0.2,0.7-0.1c1,0.1,1.8,0.8,2.4,1.7c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6
		c-0.3,0.2-0.7,0.4-1.1,0.5c-0.5,0.1-1-0.2-1.5-0.5C46.7,34.5,46.5,34.3,46.3,34.1z M51.4,29.5c0.1,0.1,0.2,0.1,0.2,0.2
		c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0c-0.6-0.2-1.2-0.5-1.4-1
		c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2C50.6,29,51,29.3,51.4,29.5z
		 M50.4,25.8c0.1-0.4,0.6-0.5,0.9-0.4c0.4,0.1,0.6,0.5,0.7,0.8c0.1,0.4,0.2,0.7,0.3,1.1c0,0.2,0.1,0.4,0.1,0.6
		c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.3-0.3-0.7-0.3-1.1
		C50.4,26.3,50.4,26,50.4,25.8z M47.2,36.5c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0,0.5,0.2,0.8,0.2c0.6,0,1.3-0.4,1.9-0.3
		c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.8,0.4-1.2,0.3
		c-0.3,0-0.6-0.1-0.8-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3C47,36.9,47,36.6,47.2,36.5z M50,37.9
		c0.2-0.3,0.5-0.4,0.8-0.5c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0.3-0.1,0.4,0c0.2,0,0.3,0.2,0.3,0.3c0.2,0.7-0.2,1.5-1,1.6
		C50.7,39.1,49.4,38.9,50,37.9z M51.9,35.4c-0.3,0.1-0.6,0.1-0.8,0c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.1-0.4-0.1-0.5
		c0-0.7,0.2-1.4,0.4-2c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8
		c0,0.1,0.1,0.3,0.1,0.4C52.7,34.7,52.4,35.2,51.9,35.4z M52.7,31.6c-0.4-0.3-0.3-0.9-0.1-1.4c0.1-0.3,0.3-0.7,0.5-0.9
		c0.3-0.3,1-0.5,1.4-0.1c0.3,0.4,0.1,0.9,0,1.3C54.2,31.3,53.5,32.4,52.7,31.6z M53.5,33.9c-0.2-0.5-0.1-1.2,0.3-1.5
		c1-0.6,2.9,0.9,2.7,2C56.1,36.2,53.9,35,53.5,33.9z M55.3,37.9c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
		c-0.2,0-0.4,0.1-0.6,0c-0.2,0-0.4-0.2-0.6-0.3c-0.3-0.2-0.5-0.4-0.5-0.8c0-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4
		c0.2,0,0.4,0,0.6,0.1c0.1,0.1,0.1,0.1,0.1,0.2C55.1,37.4,55.2,37.6,55.3,37.9z M54.5,28.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
		c0-0.5,0.1-1.1,0.4-1.5c1-1.3,2.6,1.5,0.3,2C54.9,28.5,54.6,28.4,54.5,28.2z M52.9,35.7C52.9,35.7,52.9,35.7,52.9,35.7L52.9,35.7
		c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0.1,0,0.1,0,0.2
		c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.2c0,0.2-0.2,0.3-0.4,0.4c0,0-0.1,0-0.1,0C52.8,36.9,52.6,35.9,52.9,35.7z M53.3,39.5
		c1-0.6,2.9,0.9,2.7,2c-0.4,1.8-2.6,0.6-3-0.5C52.8,40.4,52.9,39.8,53.3,39.5z M55.9,37.3c-0.2,0-0.3-0.1-0.4-0.2
		c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.4-0.3,0.6-0.2c0.3,0,0.5,0.2,0.7,0.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.1,0.1
		C56.5,37.2,56.2,37.3,55.9,37.3z M58.3,26.6c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.4-0.6-0.3-0.9
		c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7
		c-0.1,0.1-0.1,0.1-0.2,0.2C59.2,26,58.8,26.3,58.3,26.6z M57.3,21.8c-0.1-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.4
		c0.3-0.3,0.8-0.1,1.2-0.2c0.5-0.1,0.9-0.5,1.3-0.3c0.3,0.1,0.4,0.6,0.3,0.9c-0.1,0.3-0.4,0.6-0.7,0.7c-0.4,0.2-0.8,0.3-1.2,0.3
		C57.8,22.2,57.4,22.1,57.3,21.8z M57.3,24.7c0,0.4-0.3,0.8-0.6,1l0.2-0.2c-0.4,0.2-0.8-0.2-0.8-0.6c0-0.4,0.4-0.8,0.8-0.8
		c0.1,0,0.3,0,0.4,0.1C57.3,24.3,57.3,24.5,57.3,24.7z M56.3,20.9c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.2-0.8-0.1l0,0.1
		c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6,0c0.1,0.1,0.1,0.1,0.1,0.2C56.2,20,56.3,20.4,56.3,20.9z
		 M55.8,17.8c-0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0.1-0.3,0-0.5-0.1c-0.4-0.2-0.6-0.7-0.6-1.1l0.1,0.3c0-0.4,0.6-0.7,1-0.5
		C55.9,16.9,56,17.4,55.8,17.8z M54.6,19.9c0,0.2,0,0.3-0.1,0.4c-0.2,0.3-0.6,0.2-0.9,0.2c-0.3,0-0.5-0.1-0.7-0.3
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.2-0.1c0.4,0,0.8-0.1,1.1,0.1
		c0.2,0.1,0.3,0.4,0.4,0.6C54.6,19,54.7,19.4,54.6,19.9z M52.6,14.1c0.1-0.3,0.3-0.6,0.6-0.6c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0.5,0.3,1,0.6,1.2,1.1C55.4,16.4,52,16.6,52.6,14.1z M54.1,21.9c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.5,0.3,1.1,0.2,1.6
		c-0.2,0.5-0.6,1-1,1.2c-0.3,0.1-0.7,0.2-1,0c-0.4-0.2-0.7-0.6-0.9-1.1c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.1,0-0.2,0.1-0.3
		c0.1-0.2,0.3-0.3,0.4-0.3C52.8,21.7,53.6,21.5,54.1,21.9z M52.3,20.3c-0.1,0.3-0.3,0.5-0.6,0.6c-1.8,1-3.5-2.8-1.4-2.8
		c0.3,0,0.5,0.1,0.8,0.2c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.3,0.4,0.4,0.7C52.3,19.8,52.4,20.1,52.3,20.3z M49.7,22.1
		c0.3-0.1,0.6,0.1,0.8,0.3c0.3,0.3,0.4,0.7,0.2,1c-0.2,0.2-0.4,0.2-0.7,0.1c0,0-0.1,0-0.1,0l0.1,0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3l0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3C49.3,22.7,49.4,22.3,49.7,22.1z M49.8,25.1c0.2,0.5,0.2,1.1,0.2,1.7
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-0.8-0.4-1.2
		c0.1-0.5,0.3-0.9,0.4-1.3c0.1-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.1,0.6,0C49.3,24,49.6,24.6,49.8,25.1z M48.7,22.2
		c-0.3,0.4-1,0.5-1.5,0.5c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6
		c0.1-0.2,0.3-0.4,0.5-0.2l0,0.1c0.1-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.4,0.2,0.5,0.4C49,21.5,48.9,21.9,48.7,22.2z M47,20
		c-0.1,0.2-0.3,0.4-0.5,0.5c-2.2,1-2.9-4.8,0.1-2.7c0.4,0.3,0.6,0.7,0.6,1.2C47.2,19.3,47.1,19.7,47,20z M45.1,21.8
		c0.4,0.2,0.6,0.6,0.7,1.1c0.1,0.4,0.1,0.9,0.1,1.3c0,0.5-0.1,1.2-0.5,1.5c-0.4,0.4-1,0.3-1.6,0.1c-0.5-0.1-1-0.4-1.3-0.8
		C42.1,24.5,42,23.7,42,23c0-0.2,0-0.5,0.2-0.7c0.3-0.5,0.9-0.7,1.5-0.7C44.2,21.5,44.7,21.5,45.1,21.8z M42.1,20.8
		c-1,1.2-1.8,0.6-2.5-0.6c-0.3-0.4-0.3-1,0.1-1.3c0.4-0.3,0.9-0.2,1.2,0.1c0.4,0.3,0.6,0.7,1,0.9c0.2,0.1,0.4,0.3,0.4,0.5
		C42.2,20.6,42.1,20.7,42.1,20.8z M38.7,16.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1
		c0.6,0,0.5,1.1,0.3,1.4c-0.2,0.3-0.9,1.2-1.2,0.5c0-0.1,0-0.3,0-0.5C38.5,17,38.6,16.7,38.7,16.4z M38.5,24.5
		c0.1-1.3,0.9-1.9,2-1.2c0.7,0.4,1.2,1,1,1.8c-0.2,0.7-0.9,1.3-1.6,1.3C38.8,26.6,38.5,25.5,38.5,24.5z M39.1,29.5
		c0.2,0.1,0.4,0.3,0.4,0.5c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0c-0.3-0.1-0.6-0.3-0.7-0.6c-0.1-0.3,0-0.7,0.2-0.9C38.7,29.4,38.9,29.4,39.1,29.5
		z M38.4,36.4c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.5,0.2-0.7,0.1c-0.2-0.1-0.4-0.2-0.4-0.3c0-0.1,0-0.3,0-0.4c0.1-0.2,0.1-0.3,0.2-0.5
		C38.2,36.6,38.3,36.5,38.4,36.4z M33.7,29.2c0.2-1,1.8-1.6,2.2-0.5c0.2,0.5-0.2,1.8-0.9,1.4c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.2-0.1-0.4-0.1-0.6-0.2C33.9,29.6,33.7,29.4,33.7,29.2z M33.6,28.1c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.3,0-0.9,0.4-1.1
		c0.4-0.2,0.8-0.2,1.2,0c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0,0.8-0.3,1C34.3,28,33.9,28.1,33.6,28.1z M37.7,30.9
		c0.2,0.5,0.2,1.2-0.1,1.7c-0.1,0.2-0.3,0.5-0.6,0.6v0c-0.3,0.2-0.6,0.3-0.9,0.4c-0.2,0-0.3,0-0.5,0c-0.3-0.1-0.5-0.3-0.6-0.6
		c-0.2-0.4-0.2-0.9-0.1-1.3c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.4,0.5-0.8
		c0-0.2,0.2-0.5,0.4-0.5c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3v0C37.4,30.1,37.6,30.5,37.7,30.9z M36.6,37.3
		c-0.3,0.5-1,0.7-1.5,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.4,0.3-0.5
		c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.8,0.2,1,0.5C36.8,36.7,36.8,37.1,36.6,37.3z M36.5,39.4c-0.2,0.2-0.6,0.1-0.9,0.1
		c-0.2,0-0.5,0-0.6-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.1-0.5,0.3-0.6c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.2,0.4,0.5C36.7,39.1,36.7,39.3,36.5,39.4z M34.9,33.6c0.1,0,0.2-0.1,0.3-0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0.8,0.5,1.1,0.8c0.1,0.3-0.1,0.7-0.4,0.8c-0.2,0.1-0.5,0.1-0.7,0.1c-0.7,0-1.4-0.2-2-0.4
		c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.3c0.2-0.3,0.4-0.5,0.7-0.6C34.3,33.8,34.6,33.7,34.9,33.6z M33.5,36.4
		c0.2,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.3-0.7-0.7c-0.1-0.3-0.1-0.7,0-1c0.1-0.1,0.1-0.2,0.2-0.3C33.2,36.4,33.4,36.4,33.5,36.4z
		 M33.1,39.7c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.2,0.4,0.3c0.1,0.2,0,0.3,0,0.5c0,0.2,0,0.5-0.1,0.7
		c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.2,0.1-0.3,0.3-0.4C32.9,39.9,33,39.8,33.1,39.7z M33.9,42.8c0.2-1,1.8-1.6,2.2-0.5c0.2,0.5-0.2,1.8-0.9,1.4
		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.1-0.6-0.2C34.1,43.2,33.9,43,33.9,42.8z M36.1,49.1c-0.1-0.1-0.2-0.2-0.2-0.4
		c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.3,0.4,0.5v0
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0-0.1,0.1v0
		c-0.1,0-0.2,0-0.2,0C36.6,49.5,36.3,49.3,36.1,49.1z M36.7,50.5c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.4,0.1
		c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.5,0c0.2,0.1,0.3,0.1,0.4,0.3
		S36.7,50.4,36.7,50.5z M37.2,46.7c0,0-0.1,0-0.1,0.1v0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.7-0.4-1.1
		c-0.1-0.2-0.1-0.5,0.1-0.7c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.6-0.3,1-0.4c0,0,0,0,0,0v0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2
		c0.1,0.3,0.2,0.7,0.3,1c0,0.3,0.1,0.5,0,0.8C37.7,46.2,37.5,46.5,37.2,46.7z M37.6,41.2c-0.1,0.2-0.3,0.2-0.5,0.3v0
		c-0.1,0-0.2,0-0.4,0c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.6-0.4c0.3,0,0.5,0,0.8,0
		c0,0,0.1,0,0.1,0v0c0.2,0,0.4-0.1,0.6,0c0.2,0.1,0.2,0.4,0.2,0.6C37.8,40.7,37.8,41,37.6,41.2z M36.2,22c-0.2,0.2-0.5,0.4-0.8,0.4
		c-0.3,0-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0-0.5c0.1-0.4,0.2-0.7,0.4-1c0.1-0.2,0.3-0.4,0.6-0.4
		c0.3,0,0.6,0.2,0.8,0.5c0.2,0.3,0.2,0.6,0.2,0.9C36.4,21.4,36.3,21.7,36.2,22z M34,20.4c-0.5,0.8-1.2-0.4-1.4-0.9
		c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,1.5-0.3,1.7,0C34.9,19.2,34.2,20.1,34,20.4z M32.9,22.8
		c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8
		c-0.3,0.6-1,0.7-1.6,0.8c-0.3,0-0.5,0.1-0.8-0.1c-0.2-0.1-0.3-0.3-0.4-0.5C32.2,24.6,32.4,23.6,32.9,22.8z M32.4,28.7
		c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1C31.1,28.6,32.2,28.3,32.4,28.7z
		 M30.6,36.7c-1.1,0.5-2.1,0.2-2.2-1l0,0c0-0.1,0-0.2,0-0.4c0.1-0.9,0.3-1.7,1.2-1.9c0.8-0.2,1.7,0.2,2.1,0.9c0.3,0.5,0.3,1,0.1,1.4
		h0C31.6,36.1,31.1,36.5,30.6,36.7z M31.7,38.5c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.2-0.5,0.3-0.8,0.3c-0.5,0-1-0.1-1.2-0.4
		c-0.2-0.2-0.2-0.5-0.2-0.8c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.5-0.2c0.3,0,0.6-0.1,0.9-0.2
		c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.3C31.7,37.9,31.7,38.2,31.7,38.5z M27.8,41
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.3
		c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4,0,0.6C28.3,40.7,28.1,40.9,27.8,41z M28.3,47
		c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6c-0.1-0.3,0.1-0.7,0.3-1c0.2-0.3,0.5-0.5,0.8-0.7
		c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7C28.4,46.7,28.4,46.8,28.3,47z M22.9,39.7
		c0.2-0.3,0.6-0.4,0.9-0.4c0.2,0,0.5,0.1,0.7,0.2c0.1,0.1,0.3,0.3,0.3,0.4C25.4,41.7,21.2,41.9,22.9,39.7z M25.1,43.3
		c-0.1,0.4-0.4,1.1-0.9,1.4c-0.6,0.3-1.1-0.3-1.1-1c0-0.3,0.1-0.6,0.2-0.8c0.1-0.1,0.1-0.3,0.2-0.4C24.7,40.9,25.6,41.8,25.1,43.3z
		 M23.1,36.3c0-0.2,0.1-0.4,0.1-0.6h0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c1-1.6,1.9-0.8,1.5,0.6h0c0,0.1,0,0.1-0.1,0.2
		c-0.1,0.4-0.4,1.1-0.9,1.4C23.6,37.6,23.1,37,23.1,36.3z M24.7,30c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4
		c0-0.2,0-0.4,0.1-0.6c0.2-0.3,0.5-0.4,0.8-0.3c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.1-0.3,0-0.5,0C25.1,30,24.9,30,24.7,30z M25.3,27.4c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8
		c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2
		C25.4,27.2,25.4,27.3,25.3,27.4z M25.7,36.1c0-0.1,0.1-0.3,0.1-0.4h0c0.1-0.3,0.2-0.5,0.2-0.8c0-0.2,0-0.4,0.2-0.5
		c0.1-0.1,0.3,0,0.4,0c0.9,0.2,1.1,0.7,1,1.3h0c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.4-0.7,0.7-1.1,0.5C25.8,37.1,25.7,36.6,25.7,36.1z
		 M28.2,37.9C28.1,38,28,38,27.9,38.1c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.1-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.3
		c0.1,0,0.2,0,0.3,0.1C28.2,37.6,28.2,37.8,28.2,37.9L28.2,37.9z M30,27c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4
		c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.5,0.2-0.8,0.2
		c-0.2,0-0.3,0-0.5-0.1C30,27.6,29.9,27.3,30,27z M31.7,21.6c-0.4,0.2-0.8,0.3-1.2,0.3c0,0-0.1,0-0.1,0c-0.3,0-0.7-0.1-0.7-0.3
		C29.6,20.2,33.2,20.8,31.7,21.6z M29.8,22.7c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0
		c0.2,0.1,0.3,0.4,0.4,0.7c0.1,0.6,0.2,1.2-0.2,1.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.7,0.2-1,0c-0.1-0.1-0.2-0.3-0.3-0.5
		c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2C29.7,22.9,29.7,22.8,29.8,22.7z M29.9,30.8c0.1,0.5,0.1,1-0.1,1.4
		c-0.2,0.6-0.8,1-1.4,1.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4c0-0.4,0.3-0.8,0.6-1
		c0.3-0.3,0.6-0.4,1-0.6c0.4-0.2,0.9-0.4,1.4-0.2C29.6,29.8,29.8,30.3,29.9,30.8z M26.2,21.5c0,0.3-0.5,0.6-0.8,0.5
		c-0.3,0.2-0.8,0.2-0.9-0.2c-0.1-0.3,0.1-0.5,0.3-0.7C25.2,20.7,26.3,20.5,26.2,21.5z M25,19.3c-0.1,0.4-0.4,1.1-0.9,1.4
		c-0.6,0.3-1.1-0.3-1.1-1c0-0.3,0.1-0.6,0.2-0.8c0.1-0.1,0.1-0.3,0.2-0.4C24.7,16.8,25.5,17.8,25,19.3z M23.8,22.5
		c0.5-0.1,1,0.1,1.4,0.4c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7c-0.1,0-0.2,0.1-0.3,0.1
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6c0-0.2,0.1-0.4,0.2-0.6C22.9,22.9,23.4,22.6,23.8,22.5z M23.9,30.7
		c0.2,0,0.4,0.1,0.6,0.2c1.7,1.4-2.6,4.4-2.2,1c0.1-0.4,0.3-0.8,0.7-1C23.3,30.7,23.6,30.6,23.9,30.7z M24.1,38.5
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.6-0.2,0.8,0c0.5,0.4,1.3,1.8,0.2,2
		C25.1,39.5,24.5,38.8,24.1,38.5z M25.7,43.5c0.1-0.4,0.3-0.8,0.3-1.2c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0
		c1.4,0.3,1.2,1.2,0.6,2.3c-0.2,0.4-0.7,0.7-1.1,0.5C25.8,44.4,25.7,43.9,25.7,43.5z M26.8,48.9c0.4-0.4,0.8-0.6,1.3-0.8
		c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.4,0.7-0.8,0.9
		c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5C26.1,49.7,26.4,49.2,26.8,48.9z M28.5,42.7
		c0.1-0.9,0.3-1.7,1.2-1.9c0.8-0.2,1.7,0.2,2.1,0.9c0.7,1.1-0.2,2-1.1,2.4C29.4,44.6,28.4,44.2,28.5,42.7z M32,33.2
		c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.2,0.3-0.4,0.5-0.6
		c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2c0,0.3-0.1,0.6-0.2,0.8
		c-0.2,0.4-0.6,0.6-1,0.5C32.6,33.9,32.2,33.6,32,33.2z M32.9,45.5c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2
		c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8c-0.3,0-0.5,0-0.8-0.1
		c-0.2-0.1-0.3-0.3-0.4-0.5C32.2,47.3,32.4,46.3,32.9,45.5z M33.3,49.4c0.4-0.2,0.8-0.2,1.2-0.1c0.1,0,0.3,0,0.4,0.1
		c0.1,0.1,0.1,0.1,0.1,0.2l0-0.1c0,0.4-0.2,0.7-0.5,1c-0.3,0.2-0.7,0.3-1,0.3c-0.2,0-0.5-0.1-0.6-0.3C32.7,50.1,32.9,49.6,33.3,49.4
		z M33.7,51.9c0.2-1,1.8-1.6,2.2-0.5c0.2,0.5-0.2,1.8-0.9,1.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
		C33.8,52.3,33.6,52.1,33.7,51.9z M34.6,56.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.2,0c0.3,0.1,0.6,0.2,0.9,0.4
		c0.2,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.6-0.5,0.6c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.7-0.4-1-0.8
		c-0.2-0.2-0.3-0.4-0.4-0.6C34.5,56.7,34.5,56.5,34.6,56.3z M36.2,55.9c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.5-0.1-1,0.1-1.5
		c0.2-0.5,0.5-0.9,0.6-1.4c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.2,0.4-0.3,0.6-0.3l0,0c0,0,0,0,0,0c0.2,0.1,0.3,0.3,0.4,0.5
		c0.2,0.5,0.5,1.1,0.5,1.7c0.1,0.6,0,1.2-0.4,1.7c-0.2,0.2-0.4,0.4-0.6,0.5v0C36.8,56,36.5,56,36.2,55.9z M38,47.3
		c0-0.4,0-0.7,0.3-1s0.6-0.4,0.9-0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.4,0.1,0.8-0.1,1.2c-0.2,0.3-0.7,0.5-1.1,0.3
		c-0.1-0.1-0.2-0.1-0.2-0.2C38.1,47.6,38,47.5,38,47.3z M38.6,52.6c0.1-0.3,0.4-0.5,0.7-0.6c0.1,0,0-0.1,0-0.2
		c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0.1-0.2,0.4-0.3,0.5-0.1c0,0,0.1,0.1,0.1,0.2c0.2,0.7,0.3,1.4,0.3,2c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.5,0.2-0.7,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
		C38.5,53.2,38.5,52.8,38.6,52.6z M39.4,50.3C39,50,39.1,49.4,39.3,49c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1-0.5,1.4-0.1
		c0.3,0.4,0.1,0.9,0,1.3C40.9,50,40.2,51.1,39.4,50.3z M40.8,50.9c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.4-0.4,0.6-0.4
		c0.2-0.1,0.5,0,0.7,0.2c0.8,1-0.5,3.3-1.6,2.3c-0.2-0.1-0.2-0.4-0.2-0.6C40.4,51.7,40.6,51.3,40.8,50.9z M41.1,54.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.5-0.2,0.6-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2,0c0.1,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.2,0.7-0.4,0.9c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.5-0.2C41,54.4,41,54.2,41.1,54.1z M42.1,47.8
		c-0.4-0.2-0.7-0.5-1.1-0.7c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.4-0.2-0.9,0.1-1.3s0.7-0.6,1.1-0.6c0.3,0,0.5,0,0.8,0
		c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3,0,0.6,0,0.8c0,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0,0.8
		c-0.1,0.3-0.5,0.4-0.8,0.3C42.6,48.1,42.4,48,42.1,47.8z M43.2,50.2c0-0.2,0.2-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.4,0.3,1,0,1.3c-0.3,0.3-0.7,0.4-1.1,0.1C43.2,51,43.1,50.6,43.2,50.2z
		 M44.6,48c-0.2-0.4-0.2-0.8,0.1-1.1c0.2-0.2,0.5-0.3,0.8-0.4c0.8-0.1,1.6-0.1,2.4,0.1c0.3,0.1,0.7,0.3,0.8,0.6c0,0.1,0,0.3-0.1,0.4
		c-0.2,0.4-0.6,0.6-1,0.7c-0.4,0.1-0.8,0.1-1.3,0.2c-0.3,0-0.5,0.1-0.8,0.1C45.2,48.6,44.8,48.3,44.6,48z M45.4,50.5
		c0.1-0.4,0.6-0.5,0.9-0.5c0.7-0.1,1.4-0.2,2.1-0.2c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.4,0.3,0.6c0,0.6-0.4,1.1-0.9,1.3
		C47.5,52,47,52,46.4,51.9c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.3-0.4-0.5C45.4,51.1,45.3,50.8,45.4,50.5z M48.7,45.2
		c-0.4,0.3-0.9,0.3-1.3,0.1c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.1,0.6-0.1,0.8,0.1
		c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7C49,45,48.9,45.1,48.7,45.2z M48.4,42.5c-0.5,0.1-1-0.2-1.5-0.5
		c-0.2-0.2-0.5-0.3-0.7-0.6c-0.4-0.6-0.2-1.4,0.1-2c0.1-0.3,0.2-0.6,0.5-0.7c0.2-0.1,0.5-0.2,0.7-0.1c1,0.1,1.8,0.8,2.4,1.7
		c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6C49.2,42.3,48.8,42.5,48.4,42.5z M49.1,45.5c0-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.4c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.4,0.4
		c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8-0.1c-0.3-0.2-0.4-0.6-0.5-0.9C49.1,45.7,49.1,45.6,49.1,45.5z M50.2,47.6
		c0.2-0.3,0.6-0.5,0.9-0.5c0.2,0,0.3,0.1,0.5,0.2c0.7,0.6,0.2,1.3-0.5,1.6C50.2,49.5,49.7,48.5,50.2,47.6z M51.1,42.7
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.7,0.2-1.4,0.4-2c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.2
		c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8c0,0.1,0.1,0.3,0.1,0.4c0,0.5-0.3,1-0.8,1.1C51.7,42.8,51.4,42.8,51.1,42.7z
		 M52.4,43.3c0.2-0.3,0.5-0.4,0.8-0.3c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.3,0-0.5,0
		c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4C52.2,43.7,52.3,43.5,52.4,43.3z M52.6,46.9
		c0,0,0-0.1,0-0.1c0.2-0.6,0.8-1.1,1.5-1.1c1.8,0.1,0.9,3.5-0.6,3C52.8,48.3,52.4,47.6,52.6,46.9z M54.4,50.6
		C54.4,50.6,54.4,50.6,54.4,50.6c0-0.5,0.1-1.1,0.4-1.5c1-1.3,2.6,1.5,0.3,2c-0.3,0.1-0.6,0-0.7-0.2C54.4,50.8,54.4,50.7,54.4,50.6z
		 M56.1,48.3c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.4-0.1-0.8,0.2-1.1c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2
		c0.2,0.2,0.4,0.2,0.3,0.6c0,0.4-0.3,0.8-0.6,1C56.5,48.5,56.3,48.4,56.1,48.3z M56.7,45.7c-0.6-0.2-1.2-0.5-1.4-1
		c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1.1,0.9
		c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9c0,0.2-0.1,0.3-0.2,0.4C57.3,45.9,57,45.8,56.7,45.7z M57.5,42.1
		c0.1-1.7,2.7-2,2.7-0.2C60.2,43.9,57.4,44.6,57.5,42.1z M57.7,48.3c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7
		c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.3-0.9,0.6-1.3,0.9
		c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1C57.8,48.9,57.6,48.6,57.7,48.3z M58.7,46.6c-0.2,0-0.4,0.1-0.5,0
		c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8l0.1-0.1
		C59.8,46.2,59.3,46.5,58.7,46.6z M59.6,39.9C59.5,40,59.2,40,59,40.1c-0.6,0.1-1.2,0.2-1.7,0.3c-0.3,0.1-0.8,0.1-1-0.2
		c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.6,0.1-1.2,0.4-1.7c0.4-0.4,0.9-0.6,1.5-0.7c0.5-0.1,1.1-0.2,1.6-0.5c0.2-0.1,0.3-0.2,0.5-0.2
		c0.3,0,0.4,0.3,0.5,0.5c0.2,0.7-0.1,1.6-0.5,2.2C60.1,39.5,59.9,39.7,59.6,39.9z M62.1,22.5c0.5-0.1,1,0.1,1.4,0.4
		c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.4-0.2
		c-0.5-0.4-1-0.9-0.9-1.6c0-0.2,0.1-0.4,0.2-0.6C61.2,22.9,61.7,22.6,62.1,22.5z M61.4,19.7c0-0.3,0.1-0.6,0.2-0.8
		c0.1-0.1,0.1-0.3,0.2-0.4c1.1-1.6,2-0.7,1.5,0.8c-0.1,0.4-0.4,1.1-0.9,1.4C61.8,21,61.4,20.3,61.4,19.7z M63.6,27.4
		c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C63.7,27.2,63.7,27.3,63.6,27.4z M62.2,30.7c0.2,0,0.4,0.1,0.6,0.2
		c1.7,1.4-2.6,4.4-2.2,1c0.1-0.4,0.3-0.8,0.7-1C61.6,30.7,61.9,30.6,62.2,30.7z M61.2,39.7c0.2-0.3,0.6-0.4,0.9-0.4
		c0.2,0,0.5,0.1,0.7,0.2c0.1,0.1,0.3,0.3,0.3,0.4C63.7,41.7,59.5,41.9,61.2,39.7z M63.4,43.3c-0.1,0.4-0.4,1.1-0.9,1.4
		c-0.6,0.3-1.1-0.3-1.1-1c0-0.3,0.1-0.6,0.2-0.8c0.1-0.1,0.1-0.3,0.2-0.4C63,40.9,63.9,41.8,63.4,43.3z M61.4,36.3
		c0-0.2,0.1-0.4,0.1-0.6h0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c1-1.6,1.9-0.8,1.5,0.6h0c0,0.1,0,0.1-0.1,0.2
		c-0.1,0.4-0.4,1.1-0.9,1.4C61.9,37.6,61.4,37,61.4,36.3z M62.4,38.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.6-0.2,0.8,0c0.5,0.4,1.3,1.8,0.2,2C63.4,39.5,62.8,38.8,62.4,38.5z M64.1,30
		c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0-0.4,0.1-0.6
		c0.2-0.3,0.5-0.4,0.8-0.3c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3C64.4,29.8,64.2,29.9,64.1,30z M63.6,22
		c-0.3,0.2-0.8,0.2-0.9-0.2c-0.1-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1.6-0.6,1.5,0.4C64.4,21.8,64,22,63.6,22z M62.2,14
		c0.2,0,0.4,0.1,0.6,0.2c1.7,1.4-2.6,4.4-2.2,1c0.1-0.4,0.3-0.8,0.7-1C61.6,14.1,61.9,14,62.2,14z M60.9,26.3
		c1.5,1.1-0.8,4.1-1.6,2.4c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
		C60.5,26,60.7,26.1,60.9,26.3z M57.5,18.1c0.1-1.7,2.7-2,2.7-0.2C60.2,19.9,57.4,20.5,57.5,18.1z M55.2,11.8
		c-0.3,0.1-0.6,0-0.7-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0.1-1.1,0.4-1.5C55.8,8.4,57.4,11.2,55.2,11.8z M53.5,9.2
		c-0.7-0.3-1.1-1-0.9-1.7c0,0,0-0.1,0-0.1c0.2-0.6,0.8-1.1,1.5-1.1C55.9,6.3,55,9.8,53.5,9.2z M53.3,10c0.3,0.1,0.4,0.4,0.5,0.7
		c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.8-0.9,1.5-1.7,1.6c-0.2,0-0.4,0.1-0.6,0c-0.4-0.2-0.5-0.7-0.5-1.2c0-0.3,0-0.5,0.1-0.7
		c0.1-0.2,0.3-0.3,0.5-0.5c0.3-0.2,0.5-0.4,0.8-0.6C52.6,10.1,52.9,9.8,53.3,10z M51.1,9.7c-0.9,0.4-1.4-0.6-0.9-1.4
		c0.2-0.3,0.6-0.5,0.9-0.5c0.2,0,0.3,0.1,0.5,0.2C52.3,8.7,51.8,9.4,51.1,9.7z M51.5,14.5c0.2,0.4,0.4,0.8,0.3,1.2
		c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.4-1,0.5c-0.3,0.2-0.7,0.3-1.1,0.1c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1c0-0.9,0.1-1.9,0.7-2.6c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.4-0.2
		c0.1,0,0.3,0,0.4,0C50.5,13.1,51.1,13.8,51.5,14.5z M49,11c0,0.6-0.4,1.1-0.9,1.3c-0.5,0.3-1.1,0.3-1.6,0.2c-0.2,0-0.3,0-0.5-0.1
		c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.6-0.1-0.8c0.1-0.4,0.6-0.5,0.9-0.5c0.7-0.1,1.4-0.2,2.1-0.2c0.1,0,0.2,0,0.3,0
		C48.9,10.5,49,10.8,49,11z M45.4,13.2c1.6,0.9,0.5,3.7-1.2,2.8C42.4,15,43.2,11.9,45.4,13.2z M41.4,18.5c-0.3-0.4-0.9-1-0.8-1.5
		c0-0.8,0.8-0.9,1.4-0.6c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.1,0.2,0.3,0.2,0.4C43.9,19.3,42.5,19.7,41.4,18.5z M42.5,14
		c-0.1,0.3-0.2,0.7-0.4,0.9c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.5-0.2c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.1,0.5-0.2,0.6-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2,0C42.6,13.8,42.5,13.9,42.5,14z M40.6,13.4
		c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.4,0.1-0.8,0.3-1.2c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.4-0.4,0.6-0.4c0.2-0.1,0.5,0,0.7,0.2
		C43,12.1,41.8,14.4,40.6,13.4z M41.1,9.9c-0.3,0.8-0.9,1.8-1.8,1.1c-0.4-0.3-0.3-0.9-0.1-1.4c0.1-0.3,0.3-0.7,0.5-0.9
		c0.3-0.3,1-0.5,1.4-0.1C41.5,9,41.3,9.5,41.1,9.9z M39.4,8.3c-0.2,0.3-0.7,0.5-1.1,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
		C38.1,8.2,38,8.1,38,8c0-0.4,0-0.7,0.3-1c0.2-0.3,0.6-0.4,0.9-0.2c0.1,0.1,0.2,0.2,0.3,0.4C39.7,7.5,39.6,8,39.4,8.3z M38.5,22.4
		c0,0.8-0.4,1.7-1.2,1.9c-0.1,0-0.2,0-0.2,0.1v0c0,0-0.1,0-0.1,0c-0.2,0-0.5-0.2-0.6-0.5c-0.1-0.2-0.1-0.5-0.1-0.8
		c0-0.5,0.2-1,0.4-1.5c0.1-0.4,0.3-0.8,0.4-1.2v0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0.1
		C38,20.8,38.5,21.6,38.5,22.4z M36.5,19.1c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.7-0.4-1-0.8c-0.2-0.2-0.3-0.4-0.4-0.6
		c-0.1-0.2-0.1-0.5,0.1-0.7c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.2,0c0.3,0.1,0.6,0.2,0.9,0.4c0.2,0.1,0.5,0.3,0.7,0.5
		c0.2,0.2,0.3,0.5,0.3,0.8C37,18.7,36.8,19,36.5,19.1z M34.6,11.1c-0.3,0.2-0.7,0.3-1,0.3c-0.2,0-0.5-0.1-0.6-0.3
		c-0.3-0.3,0-0.9,0.4-1.1c0.4-0.2,0.8-0.2,1.2-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0-0.1
		C35.1,10.5,34.9,10.9,34.6,11.1z M33,13.8c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5s0.1,0.6,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2
		c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.6,0.6-1,0.5c-0.4-0.1-0.8-0.3-1-0.7c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.3-0.3-0.3-0.6
		c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.3C32.7,14.3,32.8,14.1,33,13.8z M31.8,17.6c0.7,1.1-0.2,2-1.1,2.4
		c-1.3,0.5-2.3,0.1-2.2-1.4c0.1-0.9,0.3-1.7,1.2-1.9C30.4,16.6,31.3,17,31.8,17.6z M30,10.4c0-0.3,0.2-0.5,0.3-0.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6
		c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1C30,10.9,29.9,10.6,30,10.4z M31.3,8.1c-0.2,0.2-0.4,0.3-0.6,0.4
		c-0.3,0.1-0.7,0.2-1,0c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3
		C29.8,6,29.9,6,29.9,6c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7C31.6,7,31.6,7.7,31.3,8.1z M29.8,14.2
		c0.1,0.5,0.1,1-0.1,1.4c-0.2,0.6-0.8,1-1.4,1.3C28.2,17,28,17,27.7,17c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4
		c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6c0.4-0.2,1-0.4,1.4-0.2C29.5,13.1,29.8,13.7,29.8,14.2z M26.2,17.8c0.1-0.1,0.3,0,0.4,0
		c1.4,0.3,1.2,1.2,0.6,2.3c-0.2,0.4-0.7,0.7-1.1,0.5c-0.4-0.2-0.5-0.7-0.4-1.1c0.1-0.4,0.3-0.8,0.3-1.2C26,18.1,26.1,17.9,26.2,17.8
		z M23.9,14c0.2,0,0.4,0.1,0.6,0.2c1.7,1.4-2.6,4.4-2.2,1c0.1-0.4,0.3-0.8,0.7-1C23.3,14.1,23.6,14,23.9,14z M22.7,26.3
		c1.5,1.1-0.8,4.1-1.6,2.4c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
		C22.2,26,22.4,26.1,22.7,26.3z M19.2,18.1c0.1-1.7,2.7-2,2.7-0.2C21.9,19.9,19.1,20.5,19.2,18.1z M16.9,11.8
		c-0.3,0.1-0.6,0-0.7-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0.1-1.1,0.4-1.5C17.5,8.4,19.1,11.2,16.9,11.8z M15.2,9.2
		c-0.7-0.3-1.1-1-0.9-1.7c0,0,0-0.1,0-0.1c0.2-0.6,0.8-1.1,1.5-1.1C17.6,6.3,16.7,9.8,15.2,9.2z M15,10c0.3,0.1,0.4,0.4,0.5,0.7
		c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.8-0.9,1.5-1.7,1.6c-0.2,0-0.4,0.1-0.6,0c-0.4-0.2-0.5-0.7-0.5-1.2c0-0.3,0-0.5,0.1-0.7
		c0.1-0.2,0.3-0.3,0.5-0.5c0.3-0.2,0.5-0.4,0.8-0.6C14.3,10.1,14.6,9.8,15,10z M12.8,9.7c-0.9,0.4-1.4-0.6-0.9-1.4
		c0.2-0.3,0.6-0.5,0.9-0.5c0.2,0,0.3,0.1,0.5,0.2C14,8.7,13.5,9.4,12.8,9.7z M13.2,14.5c0.2,0.4,0.4,0.8,0.3,1.2
		c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.4-1,0.5c-0.3,0.2-0.7,0.3-1.1,0.1c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1c0-0.9,0.1-1.9,0.7-2.6c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.4-0.2
		c0.1,0,0.3,0,0.4,0C12.2,13.1,12.8,13.8,13.2,14.5z M10.7,11c0,0.6-0.4,1.1-0.9,1.3c-0.5,0.3-1.1,0.3-1.6,0.2c-0.2,0-0.3,0-0.5-0.1
		c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.6-0.1-0.8c0.1-0.4,0.6-0.5,0.9-0.5c0.7-0.1,1.4-0.2,2.1-0.2c0.1,0,0.2,0,0.3,0
		C10.6,10.5,10.7,10.8,10.7,11z M7.1,13.2c1.6,0.9,0.5,3.7-1.2,2.8C4.1,15,4.9,11.9,7.1,13.2z M5.3,21.6c0.5-0.1,1.1-0.1,1.5,0.2
		c0.4,0.2,0.6,0.6,0.7,1.1c0.1,0.4,0.1,0.9,0.1,1.3c0,0.5-0.1,1.2-0.5,1.5c-0.4,0.4-1,0.3-1.6,0.1c-0.5-0.1-1-0.4-1.3-0.8
		c-0.5-0.6-0.6-1.3-0.5-2.1c0-0.2,0-0.5,0.2-0.7C4.1,21.8,4.8,21.7,5.3,21.6z M3.8,35.7L3.8,35.7c0.1-0.5,0.3-1.1,0.6-1.5
		c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.4,0.4,0.5,0.7h0
		c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5
		c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4C3.8,36.2,3.8,35.9,3.8,35.7z M4.2,39.1
		c0.2-0.2,0.5-0.2,0.6-0.1c0.8,0.7,0.6,2.6-0.7,2.4c-0.6-0.1-1.6-1.1-0.8-1.6c0.1-0.1,0.2-0.1,0.4-0.2C3.9,39.4,4.1,39.2,4.2,39.1z
		 M5.1,43.2c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7-0.1-0.8-0.4c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.3,0.3-0.5,0.5-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.5C5.2,42.8,5.2,43,5.1,43.2z M3.4,45.1
		c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0.1,0.6,0.3c0.3,0.4,0.4,1,0.2,1.3c-0.4,0.5-1.1-0.1-1.5-0.3
		c-0.1-0.1-0.3-0.2-0.3-0.3C3.3,45.6,3.3,45.3,3.4,45.1z M3.7,47.6c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0
		c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
		c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.3-0.2-0.3-0.3C3.6,48.1,3.6,47.8,3.7,47.6z M4.9,50.2
		c0-0.2,0.2-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.4,0.3,1,0,1.3
		c-0.3,0.3-0.7,0.4-1.1,0.1C4.9,51,4.8,50.6,4.9,50.2z M7.1,52.5c1.6,0.9,0.5,3.7-1.2,2.8C4.1,54.4,4.9,51.2,7.1,52.5z M8.2,57.2
		c0.4,0.3,0.6,0.7,0.6,1.2c0,0.3,0,0.7-0.2,1c-0.1,0.2-0.3,0.4-0.5,0.5C5.9,60.8,5.2,55,8.2,57.2z M8.4,61.5c0-0.2,0.1-0.3,0.2-0.5
		c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.5-0.2l0,0.1c0.1-0.2,0.4-0.2,0.6-0.2s0.4,0.2,0.5,0.4c0.1,0.3,0.1,0.7-0.1,1
		C10.1,62,9.4,62,8.9,62c-0.1,0-0.2,0-0.3-0.1C8.4,61.9,8.3,61.7,8.4,61.5z M9.6,56.8c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1c0-0.9,0.1-1.9,0.7-2.6c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.4-0.2
		c0.1,0,0.3,0,0.4,0c0.8,0.2,1.4,0.9,1.8,1.7c0.2,0.4,0.4,0.8,0.3,1.2c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0.2-1,0.3
		c-0.3,0.1-0.7,0.4-1,0.5C10.4,56.9,10,57,9.6,56.8z M12,57.4c0.3,0,0.5,0.1,0.8,0.2c0.3,0.2,0.6,0.4,0.8,0.6
		c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.2,0.1,0.4,0,0.7c-0.1,0.3-0.3,0.5-0.6,0.6C11.7,61.2,10,57.4,12,57.4z M13.7,52.6
		c-0.2,0-0.4,0.1-0.6,0c-0.4-0.2-0.5-0.7-0.5-1.2c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.5c0.3-0.2,0.5-0.4,0.8-0.6
		c0.3-0.2,0.7-0.5,1-0.3c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.3,0.1,0.6,0,0.8C15.3,51.8,14.6,52.4,13.7,52.6z M14.3,53.4
		c0.1-0.3,0.3-0.6,0.6-0.6c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.5,0.3,1,0.6,1.2,1.1C17.1,55.7,13.7,55.9,14.3,53.4z M14.5,57.3
		c0,0,0.1,0,0.2-0.1c0.4,0,0.8-0.1,1.1,0.1c0.2,0.1,0.3,0.4,0.4,0.6c0.2,0.4,0.2,0.9,0.2,1.3c0,0.2,0,0.3-0.1,0.4
		c-0.2,0.3-0.6,0.2-0.9,0.2c-0.3,0-0.5-0.1-0.7-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.6-0.1-1.2-0.2-1.8C14.5,57.4,14.5,57.3,14.5,57.3z
		 M16.2,56.3l0.1,0.3c0-0.4,0.6-0.7,1-0.5c0.4,0.2,0.5,0.7,0.4,1.2c-0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0.1-0.3,0-0.5-0.1
		C16.4,57.2,16.2,56.7,16.2,56.3z M17.6,54.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2
		c0.2-0.3,0.5-0.7,0.8-0.7c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5C18.1,55,17.9,54.8,17.6,54.7z M19.2,57.5
		c0.1-1.7,2.7-2,2.7-0.2C21.9,59.3,19.1,59.9,19.2,57.5z M21.9,62.5c-0.4,0.4-1,0.6-1.5,0.8c-0.2,0-0.4,0.1-0.5,0
		c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8
		L21.9,62.5z M21,51.4c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
		c0.3,0,0.5,0.1,0.7,0.2C24.2,50.1,21.8,53.1,21,51.4z M22.4,54.6c0.1-0.4,0.3-0.8,0.7-1c0.3-0.2,0.6-0.3,0.9-0.2
		c0.2,0,0.4,0.1,0.6,0.2C26.2,55,21.9,58,22.4,54.6z M23.4,58.2c0.1-0.1,0.1-0.3,0.2-0.4c1.1-1.6,2-0.7,1.5,0.8
		c-0.1,0.4-0.4,1.1-0.9,1.4c-0.6,0.3-1.1-0.3-1.1-1C23.1,58.8,23.2,58.5,23.4,58.2z M24.7,52.7c-0.2,0-0.4-0.1-0.4-0.3
		c-0.4-0.1-1.3,0.1-0.8-0.8c0.3-0.5,1-0.7,1.5-0.6c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.1-0.3,0-0.5,0C25.1,52.7,24.9,52.7,24.7,52.7z M25.7,58.9c0.1-0.4,0.3-0.8,0.3-1.2c0-0.2,0-0.4,0.2-0.5
		c0.1-0.1,0.3,0,0.4,0c1.4,0.3,1.2,1.2,0.6,2.3c-0.2,0.4-0.7,0.7-1.1,0.5C25.8,59.8,25.6,59.3,25.7,58.9z M27.6,61.9
		c-0.3,0.2-0.8,0.1-1.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.4-0.3,0.9-0.4,1.4-0.3l-0.1,0
		C28.1,61.2,28,61.6,27.6,61.9z M26.7,63.1c0.2-0.3,0.5-0.5,0.8-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2
		c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6
		C26.3,63.7,26.5,63.4,26.7,63.1z M28.4,56.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4
		c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6c0.4-0.2,1-0.4,1.4-0.2c0.5,0.2,0.7,0.7,0.8,1.2c0.1,0.5,0.1,1-0.1,1.4
		C29.5,55.5,28.9,56,28.4,56.2z M29.6,47.8c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2
		c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7
		c0.1,0.6,0.2,1.2-0.2,1.6c-0.2,0.2-0.4,0.3-0.6,0.4C30.3,47.9,29.9,48,29.6,47.8z M30,49.7c0-0.3,0.2-0.5,0.3-0.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6
		c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1C30,50.3,29.9,50,30,49.7z M30.4,51.5c0.1-0.1,0.2-0.1,0.3-0.1
		c0.3-0.1,1.5-0.3,1.7,0c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9C30.3,51.8,30.3,51.6,30.4,51.5z M32.7,58.3
		c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,1.5-0.3,1.7,0c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9C32.6,58.7,32.6,58.4,32.7,58.3z
		 M34.4,60.5c0.1-0.4,0.2-0.7,0.4-1c0.1-0.2,0.3-0.4,0.6-0.4c0.3,0,0.6,0.2,0.8,0.5c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.3,0.8
		c-0.2,0.2-0.5,0.4-0.8,0.4c-0.3,0-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.2-0.3-0.4C34.3,60.8,34.3,60.6,34.4,60.5z M36.3,62.5
		c0-0.5,0.2-1,0.4-1.5c0.1-0.4,0.3-0.8,0.4-1.2v0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0.1c0.6,0.6,1,1.3,1.1,2.1
		c0,0.8-0.4,1.7-1.2,1.9c-0.1,0-0.2,0-0.2,0.1v0c0,0-0.1,0-0.1,0c-0.2,0-0.5-0.2-0.6-0.5C36.3,63,36.3,62.8,36.3,62.5z M38.5,57.1
		c0-0.1,0-0.3,0-0.5c0-0.3,0.1-0.6,0.2-0.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1
		c0.6,0,0.5,1.1,0.3,1.4C39.5,56.9,38.7,57.8,38.5,57.1z M38.5,63.9c0.1-1.3,0.9-1.9,2-1.2c0.7,0.4,1.2,1,1,1.8
		c-0.2,0.7-0.9,1.3-1.6,1.3C38.8,65.9,38.5,64.8,38.5,63.9z M39.5,59.6c-0.3-0.4-0.3-1,0.1-1.3c0.4-0.3,0.9-0.2,1.2,0.1
		s0.6,0.7,1,0.9c0.2,0.1,0.4,0.3,0.4,0.5c0,0.1-0.1,0.3-0.2,0.4C41.1,61.4,40.3,60.8,39.5,59.6z M41.4,57.8c-0.3-0.4-0.9-1-0.8-1.5
		c0-0.8,0.8-0.9,1.4-0.6c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.1,0.2,0.3,0.2,0.4C43.9,58.7,42.5,59.1,41.4,57.8z M45.4,52.5
		c1.6,0.9,0.5,3.7-1.2,2.8C42.4,54.4,43.2,51.2,45.4,52.5z M46.5,57.2c0.4,0.3,0.6,0.7,0.6,1.2c0,0.3,0,0.7-0.2,1
		c-0.1,0.2-0.3,0.4-0.5,0.5C44.2,60.8,43.5,55,46.5,57.2z M46.7,61.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6
		c0.1-0.2,0.3-0.4,0.5-0.2l0,0.1c0.1-0.2,0.4-0.2,0.6-0.2s0.4,0.2,0.5,0.4c0.1,0.3,0.1,0.7-0.1,1c-0.3,0.4-1,0.5-1.5,0.5
		c-0.1,0-0.2,0-0.3-0.1C46.7,61.9,46.6,61.7,46.7,61.5z M47.9,56.8c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.4-0.1-0.7
		c0,0,0-0.1,0-0.1c0-0.9,0.1-1.9,0.7-2.6c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0
		c0.8,0.2,1.4,0.9,1.8,1.7c0.2,0.4,0.4,0.8,0.3,1.2c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.4-1,0.5
		C48.7,56.9,48.3,57,47.9,56.8z M50.3,57.4c0.3,0,0.5,0.1,0.8,0.2c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.3,0.4,0.4,0.7
		c0.1,0.2,0.1,0.4,0,0.7c-0.1,0.3-0.3,0.5-0.6,0.6C50,61.2,48.3,57.4,50.3,57.4z M52,52.6c-0.2,0-0.4,0.1-0.6,0
		c-0.4-0.2-0.5-0.7-0.5-1.2c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.5c0.3-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.5,1-0.3
		c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.3,0.1,0.6,0,0.8C53.6,51.8,52.9,52.4,52,52.6z M52.6,53.4c0.1-0.3,0.3-0.6,0.6-0.6
		c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.5,0.3,1,0.6,1.2,1.1C55.4,55.7,52,55.9,52.6,53.4z M52.8,57.3c0,0,0.1,0,0.2-0.1
		c0.4,0,0.8-0.1,1.1,0.1c0.2,0.1,0.3,0.4,0.4,0.6c0.2,0.4,0.2,0.9,0.2,1.3c0,0.2,0,0.3-0.1,0.4c-0.2,0.3-0.6,0.2-0.9,0.2
		c-0.3,0-0.5-0.1-0.7-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.6-0.1-1.2-0.2-1.8C52.8,57.4,52.8,57.3,52.8,57.3z M54.5,56.3l0.1,0.3
		c0-0.4,0.6-0.7,1-0.5c0.4,0.2,0.5,0.7,0.4,1.2c-0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0.1-0.3,0-0.5-0.1C54.7,57.2,54.5,56.7,54.5,56.3z
		 M55.9,54.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.8,0.2-1.6,0.6-2.2c0.2-0.3,0.5-0.7,0.8-0.7
		c0.3-0.1,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0,0.2,0,0.4-0.1,0.6
		c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-0.8,0.4-1.6,0.7-2.4,0.5C56.4,55,56.2,54.8,55.9,54.7z M57.5,57.5
		c0.1-1.7,2.7-2,2.7-0.2C60.2,59.3,57.4,59.9,57.5,57.5z M60.2,62.5c-0.4,0.4-1,0.6-1.5,0.8c-0.2,0-0.4,0.1-0.5,0
		c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8
		L60.2,62.5z M59.3,51.4c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
		c0.3,0,0.5,0.1,0.7,0.2C62.4,50.1,60.1,53.1,59.3,51.4z M60.7,54.6c0.1-0.4,0.3-0.8,0.7-1c0.3-0.2,0.6-0.3,0.9-0.2
		c0.2,0,0.4,0.1,0.6,0.2C64.5,55,60.2,58,60.7,54.6z M61.7,58.2c0.1-0.1,0.1-0.3,0.2-0.4c1.1-1.6,2-0.7,1.5,0.8
		c-0.1,0.4-0.4,1.1-0.9,1.4c-0.6,0.3-1.1-0.3-1.1-1C61.4,58.8,61.5,58.5,61.7,58.2z M63,52.7c-0.2,0-0.4-0.1-0.4-0.3
		c-0.4-0.1-1.3,0.1-0.8-0.8c0.3-0.5,1-0.7,1.5-0.6c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.1-0.3,0-0.5,0C63.4,52.7,63.2,52.7,63,52.7z M64,58.9c0.1-0.4,0.3-0.8,0.3-1.2c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0
		c1.4,0.3,1.2,1.2,0.6,2.3c-0.2,0.4-0.7,0.7-1.1,0.5C64.1,59.8,63.9,59.3,64,58.9z M65.9,61.9c-0.3,0.2-0.8,0.1-1.1-0.2
		c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.4-0.3,0.9-0.4,1.4-0.3l-0.1,0C66.4,61.2,66.2,61.6,65.9,61.9z M65,63.1
		c0.2-0.3,0.5-0.5,0.8-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0,0.4
		c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6C64.6,63.7,64.8,63.4,65,63.1z M67.3,49.7
		c0,0.4-0.4,0.7-0.8,0.9c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3
		s0.8-0.6,1.3-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.2,0.7C67.2,49.3,67.3,49.5,67.3,49.7z M66.1,41
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.3
		c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4,0,0.6C66.6,40.7,66.4,40.9,66.1,41z M67.3,27c0,0.4-0.4,0.7-0.8,0.9
		c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3c0.4-0.4,0.8-0.6,1.3-0.8
		c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.2,0.7C67.3,26.6,67.3,26.8,67.3,27z M66.7,24.2
		c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6c-0.1-0.3,0.1-0.7,0.3-1c0.2-0.3,0.5-0.5,0.8-0.7
		c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7C66.7,24,66.7,24.1,66.7,24.2z M66.7,16.9
		C66.5,17,66.2,17,66,17c-0.5,0-0.9-0.5-1.2-0.9c-0.3-0.4-0.5-0.9-0.4-1.4c0-0.4,0.3-0.8,0.6-1c0.3-0.3,0.6-0.4,1-0.6
		c0.4-0.2,0.9-0.4,1.4-0.2c0.5,0.2,0.7,0.7,0.8,1.2c0.1,0.5,0.1,1-0.1,1.4C67.8,16.2,67.2,16.6,66.7,16.9z M67.9,8.4
		c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3C68.1,6,68.2,6,68.2,6
		c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7c0.1,0.6,0.2,1.2-0.2,1.6c-0.2,0.2-0.4,0.3-0.6,0.4
		C68.6,8.6,68.2,8.6,67.9,8.4z M68.3,10.4c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1
		c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1
		C68.3,10.9,68.2,10.6,68.3,10.4z M67,9.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.4,0.7-0.8,0.9
		c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3c0.4-0.4,0.8-0.6,1.3-0.8
		c0.1-0.1,0.2-0.1,0.3-0.1C66.8,8.6,66.9,8.9,67,9.1z M66.2,6.1c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7
		c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.5,0.5-0.7,0.8c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6c-0.1-0.3,0.1-0.7,0.3-1
		c0.2-0.3,0.5-0.5,0.8-0.7C65.9,6.3,66,6.2,66.2,6.1z M64,4.2c0.1-0.4,0.3-0.8,0.3-1.2c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0
		c1.4,0.3,1.2,1.2,0.6,2.3c-0.2,0.4-0.7,0.7-1.1,0.5C64.1,5.1,64,4.6,64,4.2z M61.7,3.5c0.1-0.1,0.1-0.3,0.2-0.4
		c1.1-1.6,2-0.7,1.5,0.8c-0.1,0.4-0.4,1.1-0.9,1.4c-0.6,0.3-1.1-0.3-1.1-1C61.4,4.1,61.5,3.8,61.7,3.5z M60.9,6.6
		c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0.1,1.4,0.4c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6C60.7,7,60.8,6.8,60.9,6.6z M60.9,9.6
		c1.5,1.1-0.8,4.1-1.6,2.4c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
		C60.5,9.4,60.7,9.5,60.9,9.6z M58.7,7.2c-0.2,0-0.4,0.1-0.5,0c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2
		c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.4,0.5,0.2,0.8l0.1-0.1C59.8,6.8,59.3,7.1,58.7,7.2z M60.2,3.1
		c0,0.5-0.1,0.8-0.4,1.1h0c-0.6,0.8-1.8,0.9-2.2,0h0c-0.1-0.2-0.2-0.6-0.2-1C57.6,1.6,60.2,1.3,60.2,3.1z M53.8,0.9
		c1-0.6,2.9,0.9,2.7,2c0,0.2-0.1,0.4-0.2,0.6c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.2C57,4,57.4,4.3,57.7,4.6
		c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0
		c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8c0-0.2,0.1-0.5,0.2-0.7c-0.8,0-1.7-0.7-2-1.5C53.3,1.8,53.4,1.2,53.8,0.9z
		 M53.2,3.6c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1
		c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0-0.4,0.1-0.6C52.6,3.7,52.9,3.5,53.2,3.6z M50.7,3.1
		c0-0.7,0.2-1.4,0.4-2c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8
		c0,0.1,0.1,0.3,0.1,0.4c0,0.5-0.3,1-0.8,1.1c-0.3,0.1-0.6,0.1-0.8,0c-0.1,0-0.2-0.1-0.3-0.2C50.7,3.5,50.7,3.3,50.7,3.1z M49.1,6.1
		c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.4c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6
		c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8-0.1c-0.3-0.2-0.4-0.6-0.5-0.9
		C49.1,6.4,49.1,6.2,49.1,6.1z M47.2,4.4c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.5,0.7
		C49,5,49.1,5.2,49,5.5c-0.1,0.1-0.2,0.3-0.3,0.3c-0.4,0.3-0.9,0.3-1.3,0.1C47.2,5.8,47,5.5,47,5.3C47,5,47.1,4.6,47.2,4.4z M48,7.2
		c0.3,0.1,0.7,0.3,0.8,0.6c0,0.1,0,0.3-0.1,0.4c-0.2,0.4-0.6,0.6-1,0.7c-0.4,0.1-0.8,0.1-1.3,0.2c-0.3,0-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.2-1-0.6c-0.2-0.4-0.2-0.8,0.1-1.1c0.2-0.2,0.5-0.3,0.8-0.4C46.4,7,47.2,7,48,7.2z M44.2,3.7c0.1-0.2,0.2-0.4,0.4-0.6
		c0.1-0.1,0.3-0.2,0.5-0.3c0.3,0,0.5,0.1,0.7,0.2c1.5,1.1-0.8,4.1-1.6,2.4C44.1,5.2,44,4.9,44,4.7C44,4.3,44.1,4,44.2,3.7z
		 M44.1,10.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.4,0.3,1,0,1.3c-0.3,0.3-0.7,0.4-1.1,0.1c-0.3-0.2-0.4-0.6-0.3-1
		c0-0.2,0.2-0.4,0.3-0.6C43.7,10.1,43.9,10.1,44.1,10.1z M41.9,3.3c0.1-0.3,0.3-0.5,0.5-0.6c0.1-0.1,0.1-0.1,0.2-0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.4,0,0.6c-0.1,0.3-0.4,0.6-0.7,0.7
		c-0.3,0.1-0.7-0.1-0.8-0.4C41.8,3.9,41.8,3.6,41.9,3.3z M42.4,5.3c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3,0,0.6,0,0.8
		c0,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0,0.8c-0.1,0.3-0.5,0.4-0.8,0.3c-0.3,0-0.6-0.2-0.8-0.4C41.8,8.2,41.4,8,41,7.8
		c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.4-0.2-0.9,0.1-1.3c0.3-0.4,0.7-0.6,1.1-0.6C41.8,5.2,42.1,5.3,42.4,5.3z M40.6,4.4
		c0.2-0.1,0.5-0.1,0.7,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
		C40.3,4.8,40.3,4.5,40.6,4.4z M38.5,1.6c0.1-1.3,0.9-1.9,2-1.2c0.7,0.4,1.2,1,1,1.8c-0.2,0.7-0.9,1.3-1.6,1.3
		C38.8,3.7,38.5,2.6,38.5,1.6z M38,3.8c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.2,0c0.6,0.2,1,0.9,0.9,1.5
		c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2C38,4.8,37.9,4.2,38,3.8z
		 M38.1,14.3c0.1,0.6,0,1.2-0.4,1.7c-0.2,0.2-0.4,0.4-0.6,0.5v0c-0.3,0.1-0.6,0.2-0.9,0c-0.3-0.1-0.5-0.3-0.6-0.6
		c-0.2-0.5-0.1-1,0.1-1.5c0.2-0.5,0.5-0.9,0.6-1.4c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.2,0.4-0.3,0.6-0.3v0c0,0,0,0,0,0
		c0.2,0.1,0.3,0.3,0.4,0.5C37.8,13.2,38,13.7,38.1,14.3z M37.1,4.5C37.1,4.5,37.1,4.5,37.1,4.5L37.1,4.5c0.1,0,0.2-0.1,0.3,0
		c0.1,0,0.2,0.1,0.2,0.2c0.1,0.3,0.2,0.7,0.3,1c0,0.3,0.1,0.5,0,0.8c-0.1,0.3-0.3,0.7-0.6,0.8c0,0-0.1,0-0.1,0.1v0
		c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.2-0.1-0.5,0.1-0.7C35.9,5,36,4.9,36.1,4.9
		C36.4,4.8,36.7,4.6,37.1,4.5z M33.9,3.5c0.2-1,1.8-1.6,2.2-0.5c0.2,0.5-0.2,1.8-0.9,1.4c-0.1-0.1-0.2-0.2-0.3-0.2
		C34.7,4,34.5,4,34.3,3.9C34.1,3.9,33.9,3.7,33.9,3.5z M32.9,6.2c0.2-0.3,0.5-0.7,0.9-0.7c0.2,0,0.5,0.1,0.7,0.2
		c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8c-0.3,0-0.5,0-0.8-0.1
		c-0.2-0.1-0.3-0.3-0.4-0.5C32.2,8,32.4,7,32.9,6.2z M32.4,12.1c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9
		c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1C31,12,32.2,11.7,32.4,12.1z M28.5,3.8c0.1-0.9,0.3-1.7,1.2-1.9
		c0.8-0.2,1.7,0.2,2.1,0.9c0.3,0.5,0.3,1,0.1,1.4h0c-0.3,0.4-0.7,0.8-1.2,1c-1.1,0.5-2.1,0.2-2.2-1l0,0C28.5,4.1,28.5,4,28.5,3.8z
		 M28.7,9.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.4,0.7-0.8,0.9c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0
		c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3c0.4-0.4,0.8-0.6,1.3-0.8c0.1-0.1,0.2-0.1,0.3-0.1C28.6,8.6,28.6,8.9,28.7,9.1z
		 M27.9,6.1c0.2,0,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.5,0.5-0.7,0.8
		c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7-0.3-0.7-0.6c-0.1-0.3,0.1-0.7,0.3-1c0.2-0.3,0.5-0.5,0.8-0.7C27.6,6.3,27.7,6.2,27.9,6.1z
		 M25.7,4.2C25.8,3.8,26,3.4,26,3c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.3,0,0.4,0c0.9,0.2,1.1,0.7,1,1.3c0,0.2,0,0.3,0,0.5h0
		c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.4-0.7,0.7-1.1,0.5c-0.4-0.2-0.5-0.7-0.4-1.1c0,0,0,0,0,0C25.7,4.5,25.7,4.3,25.7,4.2z M22.6,6.6
		c0.3-0.4,0.6-0.7,1.1-0.8c-0.4-0.1-0.6-0.5-0.6-1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.8
		c0.1-0.1,0.1-0.3,0.2-0.4c0.9-1.4,1.7-0.9,1.6,0.3c0,0.2,0,0.5-0.1,0.8h0c0,0.1,0,0.1-0.1,0.2c-0.1,0.4-0.4,1.1-0.9,1.4
		c0,0,0,0,0,0c0.4,0,0.7,0.2,1,0.4c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7C24,8.9,23.9,9,23.8,9
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6C22.4,7,22.5,6.8,22.6,6.6z M22.6,9.6c1.5,1.1-0.8,4.1-1.6,2.4
		c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3C22.2,9.4,22.4,9.5,22.6,9.6z
		 M20.4,7.2c-0.2,0-0.4,0.1-0.5,0c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.3,0.6-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1
		c0.3,0.1,0.4,0.5,0.2,0.8l0.1-0.1C21.5,6.8,21,7.1,20.4,7.2z M21.9,3.1c0,0.5-0.1,0.8-0.4,1.1h0c-0.6,0.8-1.8,0.9-2.2,0h0
		c-0.1-0.2-0.2-0.6-0.2-1C19.3,1.6,21.9,1.3,21.9,3.1z M15.5,0.9c1-0.6,2.9,0.9,2.7,2c0,0.2-0.1,0.4-0.2,0.6c0,0,0.1,0,0.1,0
		c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.2,0.9
		c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8C17,4.3,17,4,17.2,3.8
		c-0.8,0-1.7-0.7-2-1.5C15,1.8,15.1,1.2,15.5,0.9z M14.9,3.6c0.5,0.1,0.8,0.6,1,1.1C16,4.8,16,4.9,16,5c0,0.2-0.1,0.3-0.3,0.4
		c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0-0.4,0.1-0.6
		C14.3,3.7,14.6,3.5,14.9,3.6z M12.4,3.1c0-0.7,0.2-1.4,0.4-2c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.2
		c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.2,0,0.5,0.1,0.8c0,0.1,0.1,0.3,0.1,0.4c0,0.5-0.3,1-0.8,1.1c-0.3,0.1-0.6,0.1-0.8,0
		c-0.1,0-0.2-0.1-0.3-0.2C12.4,3.5,12.4,3.3,12.4,3.1z M10.8,6.1c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.4
		c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6C13.2,6.8,13,7,12.8,7.1c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8-0.1
		c-0.3-0.2-0.4-0.6-0.5-0.9C10.8,6.4,10.8,6.2,10.8,6.1z M8.9,4.4C9,4.2,9.1,4,9.3,3.9c0.3-0.1,0.6-0.1,0.8,0.1
		c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0.1-0.2,0.3-0.3,0.3C10,6.1,9.5,6.1,9.1,5.9C8.9,5.8,8.7,5.5,8.7,5.3
		C8.7,5,8.8,4.6,8.9,4.4z M9.7,7.2c0.3,0.1,0.7,0.3,0.8,0.6c0,0.1,0,0.3-0.1,0.4c-0.2,0.4-0.6,0.6-1,0.7C8.9,9.1,8.5,9.1,8.1,9.1
		c-0.3,0-0.5,0.1-0.8,0.1c-0.4,0-0.8-0.2-1-0.6C6.1,8.3,6.2,7.8,6.4,7.5C6.7,7.2,7,7.2,7.3,7.1C8.1,7,8.9,7,9.7,7.2z M5.9,3.7
		C6,3.5,6.1,3.3,6.3,3.1c0.1-0.1,0.3-0.2,0.5-0.3C7,2.8,7.3,2.9,7.5,3C9,4.1,6.7,7.1,5.9,5.4C5.8,5.2,5.7,4.9,5.7,4.7
		C5.7,4.3,5.8,4,5.9,3.7z M5.8,10.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.4,0.3,1,0,1.3c-0.3,0.3-0.7,0.4-1.1,0.1
		c-0.3-0.2-0.4-0.6-0.3-1c0-0.2,0.2-0.4,0.3-0.6C5.4,10.1,5.6,10.1,5.8,10.1z M3.6,3.3c0.1-0.3,0.3-0.5,0.5-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.4,0,0.6
		C5,4.2,4.8,4.4,4.4,4.5C4.1,4.6,3.7,4.4,3.6,4.1C3.5,3.9,3.5,3.6,3.6,3.3z M3.8,5.3c0.2-0.1,0.7-0.1,0.9,0C5,5.4,5.1,5.7,5.1,6
		c0,0.3,0,0.6,0,0.8c0,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0,0.8C5.3,8.7,4.9,8.9,4.6,8.8c-0.3,0-0.5-0.2-0.7-0.3
		C3.7,8.4,3.7,8.3,3.6,8.1C3.3,7.6,3.2,7,3.2,6.5c0-0.2,0-0.5,0.1-0.7C3.4,5.6,3.6,5.4,3.8,5.3z M2.3,4.9c-0.4,0-0.9-1.2-1.1-1.5
		c-0.6-0.9,1-1.1,1.5-0.9C3,2.6,3.2,2.7,3.2,2.9c0,0.1,0,0.2,0,0.3C3.1,3.6,2.8,4.9,2.3,4.9z M2.7,11.3C2.8,11.1,3,11,3.3,10.9
		c0.2-0.1,0.5,0,0.7,0.2c0.8,1-0.5,3.3-1.6,2.3c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.4,0.1-0.8,0.3-1.2C2.5,11.5,2.6,11.4,2.7,11.3z
		 M0.5,12.2c-0.4-0.3-0.3-0.9-0.1-1.4c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1-0.5,1.4-0.1c0.3,0.4,0.1,0.9,0,1.3C2,11.9,1.3,13,0.5,12.2
		z M1.2,20.3c-0.3-0.4-0.3-1,0.1-1.3c0.4-0.3,0.9-0.2,1.2,0.1c0.4,0.3,0.6,0.7,1,0.9c0.2,0.1,0.4,0.3,0.4,0.5c0,0.1-0.1,0.3-0.2,0.4
		C2.8,22.1,2,21.4,1.2,20.3z M1.6,29.3c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.4-0.2-0.9,0.1-1.3c0.3-0.4,0.7-0.6,1.1-0.6
		c0.3,0,0.5,0,0.8,0c0.2,0,0.5-0.1,0.7,0C3.9,26.9,4,27.2,4,27.5c0,0.3,0,0.6,0,0.8c0,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0,0.8
		c-0.1,0.3-0.5,0.4-0.8,0.3c-0.3,0-0.6-0.2-0.8-0.4C2.3,29.7,2,29.5,1.6,29.3z M1.7,38.8c-0.1-0.2-0.2-0.4-0.1-0.6
		c0-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.3,0.3,0.5
		C3.9,37.8,4,38,4,38.2c0,0.1-0.1,0.2-0.2,0.4c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.1-0.7,0.1-1-0.1C1.8,39,1.7,38.9,1.7,38.8z M2,43.7
		c-0.4,0-0.9-1.2-1.1-1.5c-0.6-0.9,1-1.1,1.5-0.9c0.2,0.1,0.4,0.2,0.5,0.4c0,0.1,0,0.2,0,0.3C2.8,42.4,2.5,43.7,2,43.7z M2.5,50.9
		c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.4-0.4,0.6-0.4c0.2-0.1,0.5,0,0.7,0.2c0.8,1-0.5,3.3-1.6,2.3c-0.2-0.1-0.2-0.4-0.2-0.6
		C2.1,51.7,2.3,51.3,2.5,50.9z M4.2,53.4C4.1,53.7,4,54,3.8,54.3c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.5-0.2
		c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.5-0.2,0.6-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2,0
		C4.3,53.2,4.2,53.3,4.2,53.4z M0.9,50.6c-0.2-0.2-0.1-0.4-0.1-0.7c0.1-0.2,0.1-0.3,0.3-0.5c0.2-0.2,0.5-0.2,0.7,0
		c0.2,0.2,0.1,0.4,0,0.6C1.7,50.4,1.3,51,0.9,50.6z M1.4,59.7c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5
		c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,1.5-0.3,1.7,0C2.3,58.5,1.6,59.5,1.4,59.7z M3.1,57.8c-0.3-0.4-0.9-1-0.8-1.5
		c0-0.8,0.8-0.9,1.4-0.6c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.1,0.2,0.3,0.2,0.4C5.6,58.7,4.2,59.1,3.1,57.8z M4.3,69.3
		c-0.1,0.3-0.5,0.4-0.8,0.3c-0.3,0-0.6-0.2-0.8-0.4c-0.4-0.2-0.7-0.5-1.1-0.7C1.4,68.5,1.1,68.3,1,68c-0.2-0.4-0.2-0.9,0.1-1.3
		c0.3-0.4,0.7-0.6,1.1-0.6c0.3,0,0.5,0,0.8,0c0.2,0,0.5-0.1,0.7,0C3.9,66.3,4,66.6,4,66.8c0,0.3,0,0.6,0,0.8c0,0.3,0.2,0.5,0.3,0.8
		C4.4,68.8,4.5,69.1,4.3,69.3z M7.2,67.1c-0.1,0.4-0.4,1.6-0.9,1.7c-0.4,0-0.9-1.2-1.1-1.5c-0.6-0.9,1-1.1,1.5-0.9
		c0.2,0.1,0.4,0.2,0.5,0.4C7.2,66.9,7.2,67,7.2,67.1z M7.6,63.6c0,0.5-0.1,1.2-0.5,1.5c-0.4,0.4-1,0.3-1.6,0.1
		c-0.5-0.1-1-0.4-1.3-0.8c-0.5-0.6-0.6-1.3-0.5-2.1c0-0.2,0-0.5,0.2-0.7C4.1,61.2,4.8,61,5.3,61c0.5-0.1,1.1-0.1,1.5,0.2
		c0.4,0.2,0.6,0.6,0.7,1.1C7.6,62.7,7.6,63.1,7.6,63.6z M9.7,68.6c-0.1,0.1-0.2,0.3-0.3,0.3c-0.4,0.3-0.9,0.3-1.3,0.1c0,0,0,0,0.1,0
		l-0.1,0c0,0,0,0,0,0c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.2-0.3,0.4-0.4C8.5,67,8.8,67,9.1,67.2
		c0.2,0.2,0.4,0.4,0.5,0.7C9.7,68.1,9.8,68.4,9.7,68.6z M10.2,66.5c-0.2,0-0.4-0.1-0.6-0.2C9.2,66,9.1,65.5,9.2,65
		c0.1-0.5,0.3-0.9,0.4-1.3c0.1-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.1,0.6,0c0.5,0.3,0.8,0.8,1,1.3c0.2,0.5,0.2,1.1,0.2,1.7
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.4,0.2-0.6,0.1C10.7,66.6,10.4,66.5,10.2,66.5z M13.3,69.4c0,0.3-0.1,0.6-0.2,0.9
		c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1
		c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0.1,0.2,0.2C13.3,69.2,13.3,69.3,13.3,69.4z
		 M14.1,67.3c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.3-0.3-0.3-0.7-0.3-1.1c0-0.2-0.1-0.5,0-0.7c0.1-0.4,0.6-0.5,0.9-0.4c0.4,0.1,0.6,0.5,0.7,0.8c0.1,0.4,0.2,0.7,0.3,1.1
		C14.1,66.9,14.2,67.1,14.1,67.3z M15.4,64.5c-0.3,0.1-0.7,0.2-1,0c-0.4-0.2-0.7-0.6-0.9-1.1c-0.1-0.5-0.1-0.9-0.1-1.4
		c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.4-0.3c0.6-0.3,1.4-0.5,1.9-0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.5,0.3,1.1,0.2,1.6
		C16.3,63.9,15.9,64.3,15.4,64.5z M16.9,67.8c-0.3,0.1-0.6,0-0.7-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0.1-1.1,0.4-1.5
		C17.5,64.4,19.1,67.2,16.9,67.8z M18.4,65l0.2-0.2c-0.4,0.2-0.8-0.2-0.8-0.6c0-0.4,0.4-0.8,0.8-0.8c0.1,0,0.3,0,0.4,0.1
		C19,63.7,19,63.9,19,64C19,64.4,18.7,64.8,18.4,65z M19,61.1c-0.1-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.4
		c0.3-0.3,0.8-0.1,1.2-0.2c0.5-0.1,0.9-0.5,1.3-0.3c0.3,0.1,0.4,0.6,0.3,0.9c-0.1,0.3-0.4,0.6-0.7,0.7c-0.4,0.2-0.8,0.3-1.2,0.3
		C19.5,61.5,19.1,61.5,19,61.1z M20,65.9C20,66,19.9,66,19.8,66c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.4-0.6-0.3-0.9
		c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.1,0.5,0,0.7
		c-0.1,0.1-0.1,0.1-0.2,0.2C20.9,65.3,20.5,65.6,20,65.9z M21,68c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1
		c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.3,0,0.5,0.1,0.7,0.2C24.2,66.7,21.8,69.7,21,68z M23.8,65
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6c0-0.2,0.1-0.4,0.2-0.6c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0.1,1.4,0.4
		c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7C24,64.9,23.9,65,23.8,65z M25.3,66.7
		c-0.2,0.2-0.6,0.3-0.8,0.1c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C25.4,66.5,25.4,66.6,25.3,66.7z M25.8,69.3c-0.2,0.1-0.3,0-0.5,0
		c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0-0.4,0.1-0.6c0.2-0.3,0.5-0.4,0.8-0.3
		c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3C26.1,69.2,25.9,69.3,25.8,69.3z M25.3,61.4c-0.3,0.2-0.8,0.2-0.9-0.2
		c-0.1-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1.6-0.6,1.5,0.4C26.1,61.1,25.7,61.4,25.3,61.4z M29,66.3c0,0.4-0.4,0.7-0.8,0.9
		c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3c0.4-0.4,0.8-0.6,1.3-0.8
		c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.2,0.7C29,66,29,66.1,29,66.3z M28.4,58.1
		c0.1-0.9,0.3-1.7,1.2-1.9c0.8-0.2,1.7,0.2,2.1,0.9c0.7,1.1-0.2,2-1.1,2.4C29.4,60,28.3,59.5,28.4,58.1z M29.6,64.4
		c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3C29.8,62,29.9,62,30,62
		c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7c0.1,0.6,0.2,1.2-0.2,1.6c-0.2,0.2-0.4,0.3-0.6,0.4
		C30.3,64.6,29.9,64.6,29.6,64.4z M30,66.4c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1
		c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1
		C30,66.9,29.9,66.6,30,66.4z M31.7,69.6c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1
		c0.3-0.1,1.5-0.3,1.7,0C32.6,68.4,31.9,69.3,31.7,69.6z M32.5,64.8c-0.4-0.9-0.1-1.9,0.4-2.7c0.2-0.3,0.5-0.7,0.9-0.7
		c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.4,0.2,1,0.2,1.5c0,0.3,0,0.5-0.2,0.8c-0.3,0.6-1,0.7-1.6,0.8
		c-0.3,0-0.5,0-0.8-0.1C32.7,65.3,32.6,65.1,32.5,64.8z M33.6,67.4c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.3,0-0.9,0.4-1.1
		c0.4-0.2,0.8-0.2,1.2,0c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0,0.8-0.3,1C34.3,67.4,33.9,67.5,33.6,67.4z M34.9,69.4
		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.3-0.5c0.2-1,1.8-1.6,2.2-0.5C36,68.6,35.7,69.9,34.9,69.4z
		 M37.7,67.4c-0.2,0.1-0.4,0.2-0.6,0.2l0,0c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.6-0.6-0.7-1c-0.1-0.4,0-0.9,0.2-1.3
		c0.1-0.3,0.3-0.5,0.6-0.5c0.2,0,0.3,0.1,0.4,0.2c0,0,0.1,0,0.1,0.1v0c0.4,0.3,0.8,0.6,0.9,1.1C38.2,66.5,38.1,67.1,37.7,67.4z
		 M40.2,70.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0c-0.3-0.1-0.6-0.3-0.7-0.6c-0.1-0.3,0-0.7,0.2-0.9
		c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.3,0.4,0.5c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1
		c0.1,0,0.1,0.1,0.1,0.2C40.3,70.2,40.3,70.3,40.2,70.3z M42.6,69.3c-0.1,0.3-0.5,0.4-0.8,0.3c-0.3,0-0.6-0.2-0.8-0.4
		c-0.4-0.2-0.7-0.5-1.1-0.7c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.4-0.2-0.9,0.1-1.3s0.7-0.6,1.1-0.6c0.3,0,0.5,0,0.8,0
		c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.3,0.4,0.4,0.7c0,0.3,0,0.6,0,0.8c0,0.3,0.2,0.5,0.3,0.8C42.7,68.8,42.8,69.1,42.6,69.3z M45.5,67.1
		c-0.1,0.4-0.4,1.6-0.9,1.7c-0.4,0-0.9-1.2-1.1-1.5c-0.6-0.9,1-1.1,1.5-0.9c0.2,0.1,0.4,0.2,0.5,0.4C45.5,66.9,45.5,67,45.5,67.1z
		 M45.9,63.6c0,0.5-0.1,1.2-0.5,1.5c-0.4,0.4-1,0.3-1.6,0.1c-0.5-0.1-1-0.4-1.3-0.8c-0.5-0.6-0.6-1.3-0.5-2.1c0-0.2,0-0.5,0.2-0.7
		c0.3-0.5,0.9-0.7,1.5-0.7c0.5-0.1,1.1-0.1,1.5,0.2c0.4,0.2,0.6,0.6,0.7,1.1C45.9,62.7,45.9,63.1,45.9,63.6z M48,68.6
		c-0.1,0.1-0.2,0.3-0.3,0.3c-0.4,0.3-0.9,0.3-1.3,0.1c0,0,0,0,0.1,0l-0.1,0c0,0,0,0,0,0c-0.2-0.1-0.3-0.4-0.4-0.7
		c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.5,0.7C48,68.1,48.1,68.4,48,68.6z
		 M48.5,66.5c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-0.8-0.4-1.2c0.1-0.5,0.3-0.9,0.4-1.3c0.1-0.2,0.1-0.5,0.3-0.6
		c0.2-0.1,0.4-0.1,0.6,0c0.5,0.3,0.8,0.8,1,1.3c0.2,0.5,0.2,1.1,0.2,1.7c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.4,0.2-0.6,0.1
		C49,66.6,48.7,66.5,48.5,66.5z M51.5,69.4c0,0.3-0.1,0.6-0.2,0.9c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.2-0.6,0.1-0.8,0
		c-0.6-0.2-1.2-0.5-1.4-1c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.4,0.2-0.8,0.5-1c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.1,0.3,0.2
		c0.4,0.3,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0.1,0.2,0.2C51.6,69.2,51.6,69.3,51.5,69.4z M52.4,67.3c0,0.2-0.2,0.4-0.4,0.4
		c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.3-0.3-0.7-0.3-1.1c0-0.2-0.1-0.5,0-0.7
		c0.1-0.4,0.6-0.5,0.9-0.4c0.4,0.1,0.6,0.5,0.7,0.8c0.1,0.4,0.2,0.7,0.3,1.1C52.4,66.9,52.5,67.1,52.4,67.3z M53.7,64.5
		c-0.3,0.1-0.7,0.2-1,0c-0.4-0.2-0.7-0.6-0.9-1.1c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.4-0.3
		c0.6-0.3,1.4-0.5,1.9-0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.5,0.3,1.1,0.2,1.6C54.6,63.9,54.2,64.3,53.7,64.5z M55.2,67.8
		c-0.3,0.1-0.6,0-0.7-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0.1-1.1,0.4-1.5C55.8,64.4,57.4,67.2,55.2,67.8z M56.7,65l0.2-0.2
		c-0.4,0.2-0.8-0.2-0.8-0.6c0-0.4,0.4-0.8,0.8-0.8c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4C57.3,64.4,57,64.8,56.7,65z
		 M57.3,61.1c-0.1-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.4c0.3-0.3,0.8-0.1,1.2-0.2c0.5-0.1,0.9-0.5,1.3-0.3
		c0.3,0.1,0.4,0.6,0.3,0.9c-0.1,0.3-0.4,0.6-0.7,0.7c-0.4,0.2-0.8,0.3-1.2,0.3C57.8,61.5,57.4,61.5,57.3,61.1z M58.3,65.9
		c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.4-0.6-0.3-0.9c0-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.6-0.6,1-0.7
		c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.2,0.2C59.2,65.3,58.8,65.6,58.3,65.9z
		 M59.3,68c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.7,0.2-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.3,0,0.5,0.1,0.7,0.2
		C62.5,66.7,60.1,69.7,59.3,68z M62.1,65c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.4-1-0.9-0.9-1.6c0-0.2,0.1-0.4,0.2-0.6
		c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0.1,1.4,0.4c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.7
		C62.3,64.9,62.2,65,62.1,65z M63.6,66.7C63.4,67,63,67,62.8,66.8c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.7,0.7-0.6
		c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0.1,0,0.1l0-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l0-0.2C63.7,66.5,63.7,66.6,63.6,66.7z
		 M64.1,69.3c-0.2,0.1-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3l0.1-0.1c-0.2,0-0.4-0.2-0.4-0.4s0-0.4,0.1-0.6
		c0.2-0.3,0.5-0.4,0.8-0.3c0.5,0.1,0.8,0.6,1,1.1c0,0.1,0.1,0.2,0.1,0.3C64.4,69.2,64.2,69.3,64.1,69.3z M63.6,61.4
		c-0.3,0.2-0.8,0.2-0.9-0.2c-0.1-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1.6-0.6,1.5,0.4C64.4,61.1,64,61.4,63.6,61.4z M67.3,66.3
		c0,0.4-0.4,0.7-0.8,0.9c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.5,0.3-1,0.6-1.3
		c0.4-0.4,0.8-0.6,1.3-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.2,0.7C67.3,66,67.3,66.1,67.3,66.3z
		 M67.9,64.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1
		c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.3,0.4,0.4,0.7c0.1,0.6,0.2,1.2-0.2,1.6c-0.2,0.2-0.4,0.3-0.6,0.4
		C68.6,64.6,68.2,64.6,67.9,64.4z M68.3,66.4c0-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.3,0,0.4,0.1
		c0.4,0.2,0.6,0.6,0.7,1.1c0,0,0,0,0-0.1l0,0.1c0,0,0,0,0,0c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1
		C68.3,66.9,68.2,66.6,68.3,66.4z M70.7,68.1c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5
		c0.1-0.1,0.2-0.1,0.3-0.1C69.4,68,70.5,67.7,70.7,68.1z M70,60.9c-0.4,0.2-0.8,0.3-1.2,0.3c0,0-0.1,0-0.1,0c-0.3,0-0.7-0.1-0.7-0.3
		C67.9,59.5,71.5,60.2,70,60.9z M70.1,57c0.7,1.1-0.2,2-1.1,2.4c-1.3,0.5-2.3,0.1-2.2-1.4c0.1-0.9,0.3-1.7,1.2-1.9
		C68.7,55.9,69.6,56.3,70.1,57z M70.7,51.4c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5
		c0.1-0.1,0.2-0.1,0.3-0.1C69.3,51.3,70.5,51.1,70.7,51.4z M68.9,44.1c-1.3,0.5-2.3,0.1-2.2-1.4c0.1-0.9,0.3-1.7,1.2-1.9
		c0.8-0.2,1.7,0.2,2.1,0.9C70.8,42.7,69.9,43.7,68.9,44.1z M67.4,38.7c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.1,0.3-0.1,0.5-0.2c0.3,0,0.6-0.1,0.9-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.3
		c0.1,0.3,0.1,0.7,0.1,1c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.2-0.5,0.3-0.8,0.3c-0.5,0-1-0.1-1.2-0.4C67.4,39.3,67.4,39,67.4,38.7z
		 M70.1,34.3c0.3,0.5,0.3,1,0.1,1.4h0c-0.3,0.4-0.7,0.8-1.2,1c-1.1,0.5-2.1,0.2-2.2-1h0c0-0.1,0-0.2,0-0.4c0.1-0.9,0.3-1.7,1.2-1.9
		C68.8,33.2,69.6,33.6,70.1,34.3z M70.7,28.7c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9c0-0.2,0-0.4,0.1-0.5
		c0.1-0.1,0.2-0.1,0.3-0.1C69.4,28.6,70.5,28.3,70.7,28.7z M70,21.6c-0.4,0.2-0.8,0.3-1.2,0.3c0,0-0.1,0-0.1,0
		c-0.3,0-0.7-0.1-0.7-0.3C67.9,20.2,71.5,20.8,70,21.6z M70.1,17.6c0.7,1.1-0.2,2-1.1,2.4c-1.3,0.5-2.3,0.1-2.2-1.4
		c0.1-0.9,0.3-1.7,1.2-1.9C68.7,16.6,69.6,17,70.1,17.6z M70.7,12.1c0.2,0.3-0.6,1.2-0.7,1.5c-0.5,0.8-1.2-0.4-1.4-0.9
		c0-0.2,0-0.4,0.1-0.5C68.8,12,68.9,12,69,12C69.3,12,70.5,11.7,70.7,12.1z"/>
        </g>

    );
}
