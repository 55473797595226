import React, {Component} from 'react';
import './Header.scss';
import {doLogout} from "../../actions/user.actions";
import {connect} from 'react-redux';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from 'react-confirm-alert';
import { faDoorClosed} from "@fortawesome/free-solid-svg-icons";


class Header extends Component
{

    askForLogout()
    {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-confirm-ui'>
                        <h2 className="mb-2">Sei sicuro di voler uscire?</h2>
                        <p>Questa operazione comporta la perdita di tutti i dati non salvati!</p>

                        <div className="mt-4">
                            <button className="btn btn-outline-primary" onClick={onClose}> No, rimango collegato</button>

                            <button className="btn btn-outline-danger ml-4" onClick={() => {this.props.doLogout(); onClose(); }}>
                                <FontAwesomeIcon icon={faDoorClosed} color={"#f44336"} /> Si, esco
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }



    render()
    {
        const {user} = this.props;


        return (
            <nav className="navbar navbar-expand navbar-light topbar static-top">

                <ul className="navbar-nav ml-auto">


                    {/*<li className="nav-item dropdown no-arrow d-sm-none">*/}
                    {/*    /!* eslint-disable-next-line*!/*/}
                    {/*    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"*/}
                    {/*       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                    {/*        <i className="fas fa-search fa-fw"></i>*/}
                    {/*    </a>*/}
                    {/*    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"*/}
                    {/*         aria-labelledby="searchDropdown">*/}
                    {/*        <form className="form-inline mr-auto w-100 navbar-search">*/}
                    {/*            <div className="input-group">*/}
                    {/*                <input type="text" className="form-control bg-light border-0 small"*/}
                    {/*                       placeholder="Search for..." aria-label="Search"*/}
                    {/*                       aria-describedby="basic-addon2"/>*/}
                    {/*                <div className="input-group-append">*/}
                    {/*                    <button className="btn btn-primary" type="button">*/}
                    {/*                        <i className="fas fa-search fa-sm"></i>*/}
                    {/*                    </button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*</li>*/}


                    {/*<div className="topbar-divider d-none d-sm-block"></div>*/}

                    <li className="nav-item dropdown no-arrow">
                        {/* eslint-disable-next-line*/}
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faUser} color={"#333871"} />
                            <span className="mr-2 d-none d-lg-inline text-dark small">&nbsp;{user ? user.nome : '-'}</span>
                            <div className="img-profile rounded-circle bg-light"/>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

                            {/*<a className="dropdown-item" href="/profilo">*/}
                            {/*    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> Profilo*/}
                            {/*</a>*/}

                            {/*<div className="dropdown-divider"></div>*/}


                            <button className="dropdown-item"  data-toggle="modal" data-target="#logoutModal" onClick={() => this.askForLogout()}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                <FontAwesomeIcon icon={faSignOutAlt} /> Esci
                            </button>

                        </div>
                    </li>

                </ul>

            </nav>
        );
    }
}


export default connect(state => ({user: state.user}), {doLogout})(Header);
