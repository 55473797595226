import React, {Component} from 'react';

export class Profilo extends Component
{

    render() {
        return (
            <div>
                <h1 id="tabelLabel">Profilo</h1>
                <p>Cambia passowrd ecc</p>
            </div>
        );
    }
}
