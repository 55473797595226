import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { AdminLayout } from './layouts/AdminLayout/AdminLayout';
import  Home from './containers/Home/Home';
import Settings from './containers/Settings/Settings';
import { Profilo } from './containers/Profilo/Profilo';
import Pazienti from './containers/Pazienti/Pazienti';
import PazienteDettaglio from './containers/Pazienti/PazienteDettaglio';
import { LayoutLogin } from "./layouts/LayoutLogin/LayoutLogin";
import CreaVisita from "./containers/Visita/CreaVisita";
import ReportVisita from "./containers/Visita/ReportVisita";
import Login from "./containers/Login/Login";
import Personale from "./containers/Personale/Personale";
import Istanza from "./containers/Login/Istanza";
import { connect } from 'react-redux';
import './custom.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ResetPassword from "./containers/Login/ResetPassword";
import Onboarding from "./containers/Login/Onboarding";
import Assistenza from "./containers/Assistenza/Assistenza";


class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        AOS.init({
            duration: 2000
        })
    }

    _renderAdmin() {
        return (
            <AdminLayout>
                <Switch>
                    <Route path='/pazienti/:id/crea-visita/:step' component={CreaVisita} />
                    <Route path='/pazienti/:id' component={PazienteDettaglio} />
                    <Route path='/visita/:id' component={ReportVisita} />
                    <Route path='/pazienti' component={Pazienti} />
                    <Route path='/personale' component={Personale} />
                    <Route path='/settings' component={Settings} />
                    <Route path='/assistenza' component={Assistenza} />
                    <Route path='/profilo' component={Profilo} />
                    <Route exact path='/' component={Home} />
                </Switch>
            </AdminLayout>
        );
    }


    _renderLogin() {
        return (
            <LayoutLogin>
                <Switch>
                    <Route exact path='/' component={Login} />
                    <Route exact path='/crea-account' component={Onboarding} />
                    <Route exact path='/reset-password' component={ResetPassword} />
                    <Route path="*" render={() => (<Redirect to="/" />)} />
                </Switch>
            </LayoutLogin>
        );
    };


    _renderSelezioneIstanza() {
        return (
            <LayoutLogin>
                <Switch>
                    <Route path='/' component={Istanza} />
                </Switch>
            </LayoutLogin>
        );
    };


    render() {
        const { user, current_istanza } = this.props;

        return user && user.accessToken ? current_istanza ?
            this._renderAdmin() : this._renderSelezioneIstanza() : this._renderLogin();
    }
}


export default connect(state => ({ user: state.user, current_istanza: state.current_istanza }), {})(App);
