import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Pazienti.scss';
import { createPaziente, editPaziente, deletePaziente } from '../../actions/istanza.actions';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';


class PazientiForm extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            indirizzo: '',
            birthDate: '',
            phone: '',
            gender: '',
            ultima_anamnesi: [],
            visite: [],
            action: ''
        };

        // preserve the initial state in a new object
        this.baseState = this.state
    }


    async componentDidMount()
    {
        const { paziente, mode } = this.props;

        if (paziente) {
            this.setState({
                ...this.state,
                id: paziente.id,
                firstName: paziente.firstName,
                lastName: paziente.lastName,
                email: paziente.email,
                indirizzo: paziente.indirizzo,
                gender: paziente.gender,
                phone: paziente.phone,
                birthDate: moment(paziente.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                fiscalCode: paziente.fiscalCode,
                action: this.props.mode
            });
        }

        if (mode === 'create') {
            this.setState(this.baseState);
        }
    }




    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };




    handleChangegender = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }




    handleSubmit = async (e) => {
        e.preventDefault();
        const paziente = {
            id: this.state.id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            indirizzo: this.state.indirizzo,
            gender: this.state.gender,
            phone: this.state.phone,
            birthDate: this.state.birthDate,
            fiscalCode: this.state.fiscalCode
        }

        switch (this.state.action)
        {
            case 'create':
                this.props.createPaziente(paziente);
                break;

            case 'edit':
                this.props.editPaziente(paziente);
                break;

            case 'delete':
                await this.props.deletePaziente(paziente);
                window.location.href = "/";
                break;

            default:
                break;
        }

        this.props.closeModal();
        this.setState(this.baseState);
    };



    render() {
        const { firstName, lastName, email, phone, birthDate, indirizzo, fiscalCode, gender } = this.state;
        const { mode } = this.props;
        let bottoni_action = null;

        if (mode === 'create')
        {
            bottoni_action = (
                <div className="row">
                    <div className="col-12 text-right">
                        <button className="btn btn-primary mt-3" type="submit" onClick={() => this.setState({ action: 'create' })}>Aggiungi</button>
                    </div>
                </div>
            );
        }
        else if (mode === 'edit')
        {
            bottoni_action = (
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <button className="btn btn-outline-secondary-delete mt-3" type="submit" onClick={() => this.setState({ action: 'delete' })}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    <div className="col-12 col-lg-6 text-right">
                        <button className="btn btn-primary mt-3" type="submit" onClick={() => this.setState({ action: 'edit' })}>Modifica</button>
                    </div>
                </div>
            );
        }


        return (
            <div className="">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-12 bg-white">
                            <div className="row">

                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="firstName">Nome <span className="icon-required">*</span></label>
                                    <input type="text" className="form-control" id="firstName" name="firstName" placeholder="inserisci nome" value={firstName} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="lastName">Cognome <span className="icon-required">*</span></label>
                                    <input type="text" className="form-control" id="lastName" name="lastName" placeholder="inserisci cognome" value={lastName} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="birthDate">Data di Nascita <span className="icon-required">*</span></label>
                                    <input type="date" placeholder={"dd/mm/yyyy"} className="form-control" id="birthDate" name="birthDate" value={birthDate} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="gender">Sesso <span className="icon-required">*</span></label>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="d-flex align-items-center">
                                                <label className="form-check-label mr-3" htmlFor="gender_m">
                                                <input type="radio" className="form-control" id="gender_m" name="gender" checked={gender === "M"} value="M" onChange={this.handleChangegender} /> Maschio
                                            </label>
                                            <label className="form-check-label" htmlFor="gender_f˘">
                                                <input type="radio" className="form-control" id="gender_f" name="gender" checked={gender === "F"} value="F" onChange={this.handleChangegender} /> Femmina
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="fiscalCode">Codice Fiscale <span className="icon-required">*</span></label>
                                    <input type="text" className="form-control" id="fiscalCode" name="fiscalCode" placeholder="inserisci codice fiscale" value={fiscalCode} onChange={this.handleChange} required />
                                </div>


                                <div className="form-group mb-4 col-6">
                                    <label htmlFor="phone">Telefono</label>
                                    <input type="text" className="form-control" id="phone" name="phone" placeholder="inserisci telefono" value={phone} onChange={this.handleChange}  />
                                </div>


                                <div className="form-group mb-4 col-12">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="inserisci email paziente" value={email} onChange={this.handleChange} />
                                </div>


                                <div className="form-group mb-4 col-12">
                                    <label htmlFor="indirizzo">Indirizzo</label>
                                    <input type="text" className="form-control" id="indirizzo" name="indirizzo" placeholder="inserisci indirizzo" value={indirizzo} onChange={this.handleChange}  />
                                </div>

                            </div>
                        </div>
                    </div>


                    {bottoni_action}
                </form>
            </div>
        );
    }
}


export default connect(null, { createPaziente, editPaziente, deletePaziente })(PazientiForm);
