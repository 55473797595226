import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListaPersonale, getOptionsLicenze, getOptionsRuoli } from "../../actions/istanza.actions";
import PersonaleForm from '../Personale/PersonaleForm';
import Licenze from '../Personale/Licenze';
import AModal from '../../components/Modal/Modal';
import './Personale.scss';


class Personale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modale_create_persona: false,
            mode: 'create',
            persona: null,
            listaLicenze: [],
            listaRuoli: [],
        };

        this._toggleModal = this._toggleModal.bind(this);
    }


    async componentDidMount() {
        this.props.getListaPersonale();

        let listaLicenze = await this.props.getOptionsLicenze();
        let listaRuoli = await getOptionsRuoli();

        this.setState({ listaLicenze, listaRuoli })
    }



    _renderPersona(pId) {
        const { personale } = this.props;
        let persona = personale.data[pId];

        return persona && (
            <tr onClick={() => this.setState({ show_modale_create_persona: true, mode: 'edit', persona: persona })} key={persona.id}>
                <td>{persona.id}</td>
                <td>
                    <h5>{persona.firstName} {persona.lastName}</h5>
                    <div className="font-weight-light">
                        {persona.email}
                    </div>
                </td>
                <td>
                    <p>{persona.role ? persona.role.label : '-'}</p>
                </td>
                <td className="licenze">
                    {persona && persona.licenses && persona.licenses.map((l, i) => {
                        return (
                            <div className="licenze-item text-center m-1 px-1 bg-primary" key={i}>
                                <small className="text-secondary text-white p-2">{l.type} - <span className="text-uppercase">{l.label.substr(-5)}</span></small>
                            </div>
                        )
                    })}
                </td>
                <td className="td-actions ">
                    <div className="d-flex align-items-center h-100 td-hover">
                        <img src={"/arrow-dx.svg"} width={32} className="mr-4" alt={"Dettaglio"}/>
                    </div>
                </td>
            </tr>
        );
    }


    _toggleModal() {
        this.componentDidMount();
        const { show_modale_create_persona } = this.state;
        this.setState({ show_modale_create_persona: !show_modale_create_persona });
    }


    render() {
        const { personale } = this.props;
        const { show_modale_create_persona } = this.state;


        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h1>Gestione Personale</h1>
                        <p>Da questa sezione puoi gestire le persone che hanno accesso a questo studio</p>
                    </div>

                    <div className="col-12 col-md-4 text-right">
                        <button className={"btn btn-outline-primary"} onClick={() => this.setState({ show_modale_create_persona: true, mode: 'create' })}>+ AGGIUNGI PERSONA</button>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <table className="table my-5 py-5 bg-white sd-table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nominativo</th>
                                    <th scope="col">Ruolo</th>
                                    <th scope="col">Licenze associate</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {personale && personale.order && personale.order.map(pId => this._renderPersona(pId))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.listaLicenze && personale && personale.order && personale.order.length > 0 && <Licenze licenze={this.state.listaLicenze}/>}

                {show_modale_create_persona ? (
                    <AModal isOpen={show_modale_create_persona} toggle={() => this._toggleModal()} title={this.state.mode === 'create' ? 'Nuova persona' : 'Modifica persona'} className={"vh-100 v-center modal-dialog-center sd-modal"}>
                        <PersonaleForm
                            closeModal={this._toggleModal}
                            mode={this.state.mode}
                            persona={this.state.persona}
                            listaLicenze={this.state.listaLicenze.filter(l => l.userId === 0)}
                            listaRuoli={this.state.listaRuoli} />
                    </AModal>
                ) : null}

            </div>
        );
    }
}


export default connect(state => ({ personale: state.personale }), { getListaPersonale, getOptionsLicenze, getOptionsRuoli })(Personale);
