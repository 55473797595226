export default {
    select: {
        control: styles => ({
            ...styles,
            backgroundColor: '#F5F6FA',
            borderColor: 'transparent',
            borderRadius: '0',
            color: '#1A1F5F',
            cursor: 'pointer',
            boxShadow: "none",
            '&:hover, &:focus, &:active': {
                borderColor: 'transparent',
                boxShadow: "none"
            }
        }),
        option: styles => {
            return {
                ...styles,
                backgroundColor: '#F5F6FA',
                color: '#1A1F5F',
                cursor: 'pointer',
            };
        },
        multiValue: styles => {
            return {
                ...styles,
                borderRadius: '30px',
                backgroundColor: '#08d2b2',
            };
        },
        multiValueLabel: styles => ({
            ...styles,
            color: 'white',
        }),
        multiValueRemove: styles => ({
            ...styles,
            color: 'white',
            ':hover': {
                color: 'white',
            },
        })
    }
}
