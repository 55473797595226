import React, {Component} from 'react';
import {connect} from 'react-redux';
import {doLogout} from "../../actions/user.actions";
import {setCurrentIstanza} from "../../actions/istanza.actions";
import './Login.scss';


class Istanza extends Component {

    constructor(props) {
        super(props);
        this.state = {istanza: null};

        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleInputChange = this._handleInputChange.bind(this);
    }


    _handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }


    async _handleSubmit(e){
        e.preventDefault();
        await this.props.setCurrentIstanza(this.state.istanza, this.props.user.accessToken);
    }


    render()
    {
        const {user} = this.props;
        const {istanze} = user;

        return (
            <div className="container container-login">
                <div className="row justify-content-center v-center vh-100">
                    <div className="col-xl-6 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="pcard" data-aos='fade-in' data-aos-delay="200">

                                            <div><h1 className="h4 mb-4 text-bold">Seleziona studio</h1></div>

                                            <form className="istanza" onSubmit={this._handleSubmit}>

                                                {istanze && istanze.map((ist, i) => (
                                                    <div className="form-check mb-2 text-uppercase" key={ist.id}>
                                                        <input required
                                                               className="form-check-input"
                                                               type="radio"
                                                               onChange={this._handleInputChange}
                                                               id={`istanza-${ist.id}`}
                                                               name="istanza"
                                                               value={ist.id} />

                                                        <label className="form-check-label" htmlFor={`istanza-${ist.id}`}>{ist.nome}</label>
                                                    </div>
                                                ))}

                                                <div className="text-right pt-4">
                                                    <button type={"submit"} className="btn text-uppercase text-white btn-primary btn-user d-inline-block btn-block col-md-5">
                                                        CONTINUA
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-0" data-aos='fade-in' data-aos-delay="1000">
                            {/* eslint-disable-next-line*/}
                            <a className="small" onClick={() => this.props.doLogout()}>Logout</a>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


export default connect(state => ({user: state.user, current_istanza: state.current_istanza}), {doLogout, setCurrentIstanza})(Istanza);
