import React, {Component} from 'react';
import ReactToPrint from 'react-to-print';
import Report from "./Report";
import {connect} from 'react-redux';
import {doCollapse} from "../../actions/user.actions";
import { faPrint, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class ReportVisita extends Component {

    componentDidMount() {
        this.props.doCollapse(false);
    }

    render() {
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <span className={"text-dark d-flex align-items-center pointer"} onClick={() => this.props.history.goBack()}>
                            <FontAwesomeIcon icon={faAngleLeft} size="1x" color={"#7c7c7c"} /> <span className={"ml-2"}>Torna a dettaglio paziente</span>
                        </span>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 d-flex">
                        <h1>Report Visita</h1>
                    </div>

                    <div className="col-md-6 text-right">
                        <ReactToPrint
                            trigger={() => <div className="pointer text-dark"> <FontAwesomeIcon icon={faPrint} color={"#7c7c7c"} />  Stampa Report</div>} content={() => this.componentRef}
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div ref={el => this.componentRef = el}>
                            <Report id_visita={this.props.match.params.id} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


export default connect(state => ({}), {doCollapse})(ReportVisita);
