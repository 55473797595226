import React, {Component} from 'react';


export class InputTextReadonly extends Component
{
    render()
    {
        let {options} = this.props;

        return (
           <div>
               {options.map(p => (
                   <div className="mb-3 type-impianto">
                       <h6>{p.label}</h6>
                       <p dangerouslySetInnerHTML={{__html: p.value}}></p>
                   </div>
               ))}
           </div>
        );
    }
}
