import React, {Component} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {doResetPassword, doReset} from "../../actions/user.actions";
import './Login.scss';


class ResetPassword extends Component {
    static displayName = ResetPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            password_check: "",
            password: ""
        };

        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }


    _handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }


    _handleSubmit(e){
        e.preventDefault();
        const {password} = this.state;
        let params = queryString.parse(this.props.location.search);
        const {token, email} = params;
        this.props.doResetPassword(token, password, email);
    }


    render() {
        const {password_check, password} = this.state;
        const {error, message} = this.props;

        return (
            <div className="container container-login">
                <div className="row justify-content-center v-center vh-100">
                    <div className="col-xl-6 col-lg-12 col-md-9">

                        <div className={"text-center"} data-aos='fade-up'>
                            <a href="/"><img src={"/logo-test.png"} width={250} alt={"Logo"}/></a>
                        </div>

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="pcard" data-aos='fade-in' data-aos-delay="500">

                                            <div>
                                                <h1 className="h4 mb-4 text-bold">Reset password</h1>
                                            </div>

                                            <form className="user" onSubmit={this._handleSubmit}>
                                                <div className="form-group">
                                                    <label className={"text-uppercase"}>Nuova password</label>
                                                    <input type="password"
                                                           name="password"
                                                           value={password}
                                                           required
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Inserisci la nuova password"/>
                                                </div>


                                                <div className="form-group">
                                                    <input type="password"
                                                           name="password_check"
                                                           value={password_check}
                                                           required
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Conferma la nuova password"/>
                                                </div>

                                                {error && (<div className={"py-1 text-danger"}>{error}</div>)}
                                                {message && (<div className={"py-1 text-success"}>{message}</div>)}


                                                <div className="text-right pt-4">
                                                    <button type={"submit"} className="btn text-uppercase text-white btn-primary btn-user d-inline-block btn-block col-md-5">
                                                        Conferma
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-0">
                            {/* eslint-disable-next-line*/}
                            <a className="small" href="/" onClick={() => this.props.doReset()}>Accedi</a>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


export default connect(state => ({error: state.error, message: state.message}), {doResetPassword, doReset})(ResetPassword);
