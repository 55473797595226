import React, {Component} from 'react';
import {connect} from 'react-redux';
import {doLogin, doRecovery, doReset} from "../../actions/user.actions";
import './Login.scss';
import { faEye} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? {
            recovery: false,
            show_password: false,
            username: "admin@sd.com",
            password: "StrongPassword123!"
        }: {
            recovery: false,
            show_password: false,
            username: "",
            password: ""
        };

        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }


    _toggleRecovery(){
        const {recovery} = this.state;
        this.setState({recovery: !recovery, password: ""});
        this.props.doReset();
    }


    _handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }


    _handleSubmit(e){
        e.preventDefault();
        const {recovery, username, password} = this.state;
        recovery ? this.props.doRecovery(username) : this.props.doLogin(username, password);
    }


    render() {
        const {recovery, username, password, show_password} = this.state;
        const {error, message} = this.props;

        return (
            <div className="container container-login">
                <div className="row justify-content-center v-center vh-100">
                    <div className="col-xl-6 col-lg-12 col-md-9">

                        <div className={"text-center"} data-aos='fade-up'>
                            <img src={"/logo-test.png"} width={250} alt={"Logo"}/>
                        </div>

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="pcard" data-aos='fade-in' data-aos-delay="500">

                                            <div>
                                                <h1 className="h4 mb-4 text-bold">{recovery ? "Password dimenticata" : "Accedi"}</h1>
                                            </div>

                                            <form className="user" onSubmit={this._handleSubmit}>
                                                <div className="form-group">
                                                    <label className={"text-uppercase"}>Email</label>
                                                    <input type="email"
                                                           name="username"
                                                           value={username}
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           aria-describedby="Inserisci email"
                                                           placeholder="Inserisci email"/>
                                                </div>

                                                {!recovery && (
                                                    <div className="form-group">
                                                        <label className={"text-uppercase"}>Password</label>
                                                        <input type={show_password ? 'text' : 'password'}
                                                               name="password"
                                                               value={password}
                                                               onChange={this._handleInputChange}
                                                               className={`form-control form-control-user ${error ? "error" : ""}`}
                                                               placeholder="Inserisci password"/>

                                                           <div className="link link-eye" onClick={() => this.setState({show_password: !show_password})}>
                                                               <FontAwesomeIcon icon={faEye} color={show_password ? "#1a1f5f" : "#7c7c7c"} />
                                                           </div>
                                                    </div>
                                                )}


                                                {error && (<div className={"py-1 text-danger"}>{error}</div>)}
                                                {message && (<div className={"py-1 text-success"}>{message}</div>)}


                                                <div className="text-right pt-4">
                                                    <button type={"submit"} className="btn text-uppercase text-white btn-primary btn-user d-inline-block btn-block col-md-5">
                                                        {recovery ? "Recupera" : "Accedi"}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-0">
                            {/* eslint-disable-next-line*/}
                            <a className="small" href="#" onClick={() => this._toggleRecovery()}> {recovery ? "Indietro" : "Password dimenticata?"}</a>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


export default connect(state => ({error: state.error, message: state.message, user: state.user}), {doLogin, doRecovery, doReset})(Login);
