import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import './NavMenu.scss';
import { faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {doCollapse} from "../../actions/user.actions";
import Loading from "../Loading/Loading";


class NavMenu extends Component {

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
       this.props.doCollapse(!this.props.collapsed);
    }


    render() {
        let {user, collapsed, loading} = this.props;
        let company = user.istanze && user.istanze.length > 0 ? user.istanze[0] : {};


        return (
            <ul className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion " + (collapsed ? 'toggled' : '')} id="accordionSidebar">

                <div className="sidebar-brand d-flex align-items-center justify-content-center flex-column">
                    <div className="logo-box"><img src={company ? company.logo : ''} className={"img-fluid"} alt={"Logo"}/></div>
                    <h6 className={"mt-3 text-secondary"}>{company ? company.nome : "PerioMapp"}</h6>
                </div>
                
                <li className="nav-item">
                    <NavLink to={"/personale"} className="nav-link collapsed">
                        <img src="/personale.svg" alt="Personale"/>
                        <span>Personale</span>
                    </NavLink>
                </li>


                <li className="nav-item">
                    <NavLink to={"/pazienti"} activeClassName="active" className="nav-link collapsed">
                        <img src="/pazienti.svg" alt="Pazienti"/>
                        <span>Pazienti</span>
                    </NavLink>
                </li>


                <li className="nav-item">
                    <NavLink to={"/settings"} activeClassName="active" className="nav-link collapsed">
                        <img src="/impostazioni.svg" alt="Impostazioni"/>
                        <span>Impostazioni</span>
                    </NavLink>
                </li>


                <li className="nav-item">
                    <NavLink to={"/assistenza"} activeClassName="active" className="nav-link collapsed">
                        <img src="/assistenza.svg" alt="Assistenza"/>
                        <span>Assistenza</span>
                    </NavLink>
                </li>



                <div className="text-center d-none d-md-inline btn-collapse mt-5 mx-auto pt-5">
                    <div className="border-0" id="sidebarToggle" onClick={() => this.toggleNavbar()}>
                        <FontAwesomeIcon icon={collapsed ? faAngleRight : faAngleLeft}  color={"#1A1F5F"}/>
                    </div>
                </div>

                <Loading loading={loading}/>

            </ul>
        );
    }
}


export default withRouter(connect(state => ({
    user: state.user,
    loading: state.loading,
    collapsed: state.collapsed
}), {
    doCollapse
})(NavMenu));
