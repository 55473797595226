import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import './PazienteDettaglio.scss';
import {getDettaglioPaziente, createVisitaDraft, getListaPazienti, deleteVisitaDraft, editVisita, deleteVisita} from "../../actions/istanza.actions";
import AModal from '../../components/Modal/Modal';
import { faTrash, faPrint, faEdit, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PazienteForm from "./PazientiForm";
import {ActionDropdown} from "../../components/Visita/ActionDropdown";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class PazienteDettaglio extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            show_modale_visita: false,
            show_modale_edit_paziente: false,
            tipo_visita: "periodontogramma",
            modo_visita: "nuova",
            note_visita: "",
            id_visita_rivalutazione: ""
        };

        this._createVisita = this._createVisita.bind(this);
        this._handleInputChange = this._handleInputChange.bind(this);
    }


    async componentDidMount() {
        const {id} = this.props.match.params;
        const {pazienti} = this.props;
        if(!pazienti || !pazienti.data) {
            await this.props.getListaPazienti()
        }

        await this.props.getDettaglioPaziente(id);
    }



    _renderVisita(v, paziente){

        let notes = v.notes ? JSON.parse(v.notes) : {};

        return (
            <tr key={v.id}>
                <td>{v.id}</td>
                <td className={"text-uppercase"}>
                    <div className={"label-visita"}>{v.type}</div>
                    <div className={"small mt-1"}>{moment(v.dateTime).format('DD/MM/YYYY')}</div>
                    <div className={"small text-capitalize"}>Dott. {v.doctor ? v.doctor.name : "-"}</div>
                </td>
                <td>
                    <div className={`stato-visita stato-visita-${v.state ? v.state.toLowerCase() : ""}`}>{v.state ? v.state : "-"}</div>
                </td>
                <td>
                    {notes && (
                        <div className="small d-flex">
                            <div className='mr-3'>Bop: <span className="text-bold">{notes.sanguinamento}</span></div>
                            <div className='mr-3'>Pla: <span className="text-bold">{notes.placca}</span></div>
                            <div className='mr-3'>Ppd: <span className="text-bold">{notes.PPD}</span></div>
                            <div>Cal: <span className="text-bold">{notes.CAL}</span></div>
                        </div>
                    )}
                </td>
                <td className="text-right no-border">
                    <div className="d-flex justify-content-end align-items-center">
                        <button className={"btn btn-sm btn-default"} onClick={() => this.props.history.push(`/visita/${v.id}`)}><FontAwesomeIcon icon={faPrint} color={"#7c7c7c"} /> Report</button>

                        <ActionDropdown direction={"left"}>
                            <span className={"action-drop-item"} onClick={() => this._editVisita(paziente, v.id)}>
                                <FontAwesomeIcon icon={faEdit} color={"#7c7c7c"} /> Modifica vista
                            </span>

                            <span className={"action-drop-item text-danger"} onClick={() => this._deleteVisita(paziente, v.id)}>
                                <FontAwesomeIcon icon={faTrash} color={"#f44336"} /> Cancella visita
                            </span>
                        </ActionDropdown>
                    </div>
                </td>
            </tr>
        );
    }



    async _createVisita(e) {
        let {tipo_visita, modo_visita, note_visita, id_visita_rivalutazione} = this.state;
        const {pazienti} = this.props;
        const {id} = this.props.match.params;
        let paziente = pazienti.data[id];

        e.preventDefault();
        await this.props.createVisitaDraft(paziente.id, tipo_visita, modo_visita, note_visita, id_visita_rivalutazione);
        this.setState({show_modale_visita: false});
        this.props.history.push(`/pazienti/${paziente.id}/crea-visita/0`);
    }


    async _editVisita(paziente, id_visita) {
        await this.props.editVisita(paziente.id, id_visita);
        this.props.history.push(`/pazienti/${paziente.id}/crea-visita/0`);
    }


    _deleteVisita(paziente, id_visita) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-confirm-ui'>
                        <h2 className="mb-2">Sei sicuro di continuare?</h2>
                        <p>Questa operazione comporta la perdita di tutti i dati inseriti e può essere annullata!</p>

                        <div className="mt-4">
                            <button className="btn btn-outline-primary" onClick={onClose}>Annulla</button>

                            <button className="btn btn-outline-danger ml-4" onClick={() => {this.props.deleteVisita(paziente.id, id_visita); onClose(); }}>
                                <FontAwesomeIcon icon={faTrash} color={"#f44336"} /> Ho capito, cancella
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }



    _handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }


    render()
    {
        let {show_modale_visita, show_modale_edit_paziente, tipo_visita, modo_visita, note_visita, id_visita_rivalutazione} = this.state;
        const {pazienti} = this.props;
        const {id} = this.props.match.params;
        let paziente = pazienti.data[id];


        if(paziente) {
            return (
                <div key={paziente.id}>


                    <div className="row mb-3">
                        <div className="col">
                            <Link to={"/pazienti"} className={"text-dark d-flex align-items-center"}>
                                <FontAwesomeIcon icon={faAngleLeft} size="1x" color={"#7c7c7c"} /> <span className={"ml-2"}>Torna a Lista pazienti</span>
                            </Link>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <h1>Scheda paziente</h1>
                        </div>

                        <div className="col-md-6 text-right">
                            <span onClick={() => this.setState({show_modale_edit_paziente: true})} className={"mb-3 pointer text-dark"}><FontAwesomeIcon icon={faEdit} color={"#7c7c7c"} /> Modifica anagrafica</span>
                        </div>
                    </div>


                    <div className="row mt-5 justify-content-between">

                        <div className="col card-paziente-cnt mr-5">
                            <h6>DATI ANAGRAFICI</h6>

                            <div className="card-paziente card-paziente-anagrafica bg-white p-3">
                                <h3>{paziente.firstName} {paziente.lastName}  <span className={"badge badge-pill badge-success"}>{paziente.gender ? paziente.gender.substr(0, 1) : '-'}</span></h3>
                                <hr/>
                                <h5><span>CF</span> {paziente.fiscalCode}</h5>
                                <h5><span>NATO</span> {paziente.birthDate}</h5>
                                <h5><span>TEL</span> {paziente.phone} </h5>
                                <h5><span>MAIL</span> {paziente.email}</h5>
                            </div>
                        </div>


                        <div className="col card-paziente-cnt ml-5">
                            <h6>ULTIMA SITUAZIONE SANITARIA</h6>

                            <div className="card-paziente bg-white p-3">
                                {paziente.lastAnamnesi ? paziente.lastAnamnesi.map(item => (
                                    <h5 className={"d-flex justify-content-between"} key={item.key}>
                                        <span>{item.key}</span>
                                        {item.value.map((v,i) => <span key={i.toString()} className={"badge badge-pill badge-success text-uppercase"}>{v}</span>)}
                                    </h5>
                                )) : null}
                            </div>
                        </div>
                    </div>



                    <section className="row mt-5 pt-4">
                        <div className="col-8">
                            <h2>Elenco visite</h2>
                        </div>

                        <div className="col-4 text-right">
                            <button onClick={() => this.setState({show_modale_visita: true})} className={"btn btn-outline-primary ml-3"}>+ NUOVA VISITA</button>
                        </div>
                    </section>



                    <section className="row">
                        <div className="col-12">
                            <table className="table py-5 mt-3 bg-white sd-table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tipo visita</th>
                                    <th scope="col">Stato</th>
                                    <th scope="col">Statistiche</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {/*{visita_draft && this._renderVisitaDraft(visita_draft, paziente)}*/}
                                    {paziente.visits ? paziente.visits.map(v => this._renderVisita(v, paziente)) : []}
                                </tbody>
                            </table>
                        </div>
                    </section>



                    {show_modale_visita ? (
                        <AModal isOpen={show_modale_visita} toggle={() => this.setState({show_modale_visita: !show_modale_visita})} title={`Nuova visita`} className={"vh-100 v-center modal-dialog-center sd-modal"}>

                            <form onSubmit={this._createVisita}>
                                <div className="form-group">
                                    <label>TIPO DI VISITA</label>
                                    <select className="form-control" name={"tipo_visita"} value={tipo_visita} onChange={this._handleInputChange}>
                                        <option value={"visita_periodontogramma"}>Visita Periodontogramma</option>
                                        {/*<option value={"visita_carie"}>Visita Carie</option>*/}
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label>MODALITA'</label>
                                    <select className="form-control" name={"modo_visita"} value={modo_visita} onChange={this._handleInputChange}>
                                        <option value={"nuova"}>Nuova visita</option>
                                        <option value={"rivalutazione"}>Rivalutazione</option>
                                    </select>
                                </div>


                                {modo_visita && modo_visita === "rivalutazione" && <div className="form-group">
                                        <label>VISITA RIFERIMENTO</label>
                                        <select className="form-control" name={"id_visita_rivalutazione"} value={id_visita_rivalutazione} onChange={this._handleInputChange}>
                                            <option value={-1}>Seleziona una visita.. </option>}
                                            {paziente.visits ? paziente.visits.filter(v => v.state === "Completed").map(v => (
                                                <option value={v.id}>{v.type} (#{v.id}) {moment(v.dateTime).format('DD/MM/YYYY')} </option>)) : []}
                                        </select>
                                    </div>
                                }


                                <div className="form-group" >
                                    <label>NOTE</label>
                                    <textarea className="form-control" name={"note_visita"} value={note_visita} onChange={this._handleInputChange}/>
                                </div>

                                <div className="mt-5 text-right">
                                    <button type="submit" className={"btn btn-primary"}>CREA VISITA</button>
                                </div>
                            </form>
                        </AModal>
                    ) : null}




                    {show_modale_edit_paziente ? (
                        <AModal isOpen={show_modale_edit_paziente} toggle={() => this.setState({show_modale_edit_paziente: false})} title={'Modifica paziente'} className={"vh-100 v-center modal-dialog-center sd-modal"}>
                            <PazienteForm paziente={paziente} closeModal={() => this.setState({show_modale_edit_paziente: false})} mode={"edit"} />
                        </AModal>
                    ) : null}


                </div>
            );
        } else {
            return (<div>caricamento</div>)
        }

    }
}


export default connect(state => ({pazienti: state.pazienti, visite_drafts: state.visite_drafts}), {getDettaglioPaziente, createVisitaDraft, getListaPazienti, deleteVisitaDraft, editVisita, deleteVisita})(PazienteDettaglio);
