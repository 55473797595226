import React, {Component} from 'react';
import { connect } from 'react-redux';
import Periodontogramma from "../../components/Visita/Periodontogramma";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import Questions from "../../components/Visita/Questions";
import {doCollapse} from "../../actions/user.actions";
import {saveVisita, setMargineEnne, setBiotipo} from "../../actions/istanza.actions";
import {AverageBar} from "../../components/Visita/AverageBar";
import { faArrowAltCircleRight, faArrowAltCircleLeft, faTooth, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {InputDropdown} from "../../components/Visita/InputDropdown";


class CreaVisita extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            paziente: {},
            activeTab: 0,
            margine_preferito: 0
        }
    }

    componentDidMount() {
        // Get Flusso visita
        const {id, step} = this.props.match.params;

        this.setState({
            paziente: this.props.pazienti.data ? this.props.pazienti.data[id] : 0,
            activeTab: parseInt(step)
        });
        this.props.doCollapse(true);
    }


    _checkActiveClass(index,){
        let {activeTab} = this.state;
        return index <= activeTab ? 'active' : '';
    }


    async _toggleActive(i){
        let {visite_drafts} = this.props;
        const {id} = this.props.match.params;
        let visita = visite_drafts[id];

        if(i < visita.steps.length)
        {
            // fine, cambio stato
            if(i === visita.steps.length -1){
                visita = {...visita, state: "Completed"};
            }

            await this.props.saveVisita(visita);
            this.setState({activeTab: i});
            this.props.history.push(`/pazienti/${visita.patientId}/crea-visita/${i}`)
        }
    }


    async _saveVisit(){
        let {visite_drafts} = this.props;
        const {id} = this.props.match.params;
        let visita = visite_drafts[id];

        await this.props.saveVisita(visita);
    }


    _componentInjector(step)
    {
        const {activeTab} = this.state;
        const {id} = this.props.match.params;

        switch (step.tipo)
        {
            case 'type_anamnesi':
            case 'type_additional_fields':
                return (<Questions {...step} id_paziente={id} current_step={activeTab} />);

            case 'type_periodontogramma':
                return (<Periodontogramma {...step} arcate={step.arcate} id_paziente={id} current_step={activeTab}/>);

            case 'type_diagnosi':
            case 'type_prognosi':
            case 'type_trattamento':
                return (<Questions row {...step} id_paziente={id} current_step={activeTab} />);

            case 'type_report':
                return this._renderLast()


            default: return (<div>Tipologia {step.tipo} non valida</div>);
        }
    }



    _renderLast(){
        let {visite_drafts} = this.props;
        const {id} = this.props.match.params;
        let visita = visite_drafts[id];

        return (
            <div className={"row bg-white"}>

                <div className="col-12 col-lg-6 p-5 align-items-center justify-content-center d-flex">
                    <img src={"/check.png"} className={"img-fluid"} alt="Dettagli"/>
                    <h2 className="pt-3">Visita completata</h2>
                </div>

                <div className="col-12 col-lg-6 align-items-center d-flex">

                    <div className="row">
                        <div className="col-12">
                            <a href={`/visita/${visita.id}`} className={"btn btn-primary nav-link"}>Genera Report PDF</a>
                            <div className={"my-3 text-center"}>oppure</div>
                            <a href={"/pazienti"} className={"btn btn-outline-secondary nav-link"}>Esci</a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }



    render()
    {
        let {paziente, activeTab, margine_preferito} = this.state;
        let {visite_drafts} = this.props;
        const {id} = this.props.match.params;
        let visita = visite_drafts[id];

        if(!visita || !paziente) return (<div>Caricamento</div>);

        return (
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col-5">
                        <h2 className={"pl-0"}>Visita {visita.tipologia}</h2>
                    </div>

                    <div className="col-5">
                        <h2 className={"font-weight-light"}>Paziente: {paziente.firstName} {paziente.lastName}</h2>
                    </div>

                    {/*<div className="col-2 text-right">*/}
                    {/*    {visita && visita.steps && activeTab < visita.steps.length -1 && <button onClick={() => this._toggleActive(++activeTab)} className={"btn btn-primary"}>AVANTI</button>}*/}
                    {/*</div>*/}
                </div>


                <div className="row mt-2">
                    <div className="col-12 p-0">
                        <Nav tabs>
                            {visita && visita.steps && visita.steps.map((step, i) => (
                                <NavItem key={i}>
                                    <NavLink className={this._checkActiveClass(i)} onClick={() => i <= activeTab ? this._toggleActive(i) : () => {} }>
                                        <span className={"circle mr-2"}>{i}</span> {step.titolo}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <TabContent activeTab={activeTab} className={"mt-5"}>
                            {visita && visita.steps && visita.steps.map((step, i) => (
                                <TabPane tabId={i} key={step.id}>
                                    <Row className={"pl-3"}>
                                        <Col sm="12">
                                            {activeTab >= 1 && activeTab < visita.steps.length -1 && visita.steps.length > 1 && <AverageBar {...visita.steps[1].informazioni}/>}
                                            {this._componentInjector(step)}
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                </div>


                <div className="row mb-4 pb-4 toolbar-visita-fixed">
                    <div className="col-6">
                        {visita && visita.steps && activeTab === 1 && (
                            <span>
                                <input type="number" className="input-margin" value={margine_preferito} onChange={e => this.setState({margine_preferito: parseInt(e.target.value)})}/>
                                <button onClick={() => this.props.setMargineEnne(id, activeTab, margine_preferito)} className="btn btn-secondary text-capitalize mr-2 text-dark"><FontAwesomeIcon icon={faTooth} color={"#343a40"}/> Set margine</button>

                                <InputDropdown
                                    name={"biotipo"}
                                    className="selector-biotipo"
                                    placeholder={"Seleziona Biotipo"}
                                    selected={visita.steps[1].campi && visita.steps[1].campi.length > 0 ? visita.steps[1].campi[0].answers[0] : null}
                                    onChange={answerObj => this.props.setBiotipo(id, activeTab, answerObj)}
                                    options={visita.steps[1].campi && visita.steps[1].campi.length > 0 ? visita.steps[1].campi[0].options : null} />
                            </span>
                        )}
                    </div>

                    <div className="col-6 text-right">
                        {visita && visita.steps && activeTab > 0 && activeTab < visita.steps.length -1 && (
                            <button className="btn btn-secondary text-capitalize mr-2 text-dark" onClick={() => this._toggleActive(--activeTab)}><FontAwesomeIcon icon={faArrowAltCircleLeft} color={"#343a40"}/> Indietro</button>
                        )}


                        <button className="btn btn-secondary text-capitalize text-dark" onClick={() => this._saveVisit()}>
                            Salva <FontAwesomeIcon icon={faSave} color={"#343a40"}/>
                        </button>


                        {visita && visita.steps && activeTab < visita.steps.length -1 && (
                            <button className="btn btn-primary text-capitalize ml-2" onClick={() => this._toggleActive(++activeTab)}>Avanti <FontAwesomeIcon icon={faArrowAltCircleRight} color={"#fff"}/></button>
                        )}
                    </div>
                </div>

            </div>
        );
    }
}


export default connect(state => ({visite_drafts: state.visite_drafts, pazienti: state.pazienti}), {doCollapse, saveVisita, setMargineEnne, setBiotipo})(CreaVisita);
