import React, {Component} from 'react';


export default class CircleSvg extends Component
{
    render()
    {
        const {x, y, v} = this.props;

        switch (v)
        {
            // Triangolo aperto
            case 1: return (
                <g transform={`translate(${x}, ${y})`} stroke="#D0021B" stroke-width="2">
                    <line x1="-1.39859564e-16" y1="20.7336306" x2="10.0779437" y2="0.5" id="Line" {...this.props}></line>
                    <line x1="10.5779437" y1="0" x2="21.0190499" y2="20.7336306" id="Line-2" {...this.props}></line>
                </g>
            );

            // Triangolo bordo
            case 2: return (
                <g transform={`translate(${x}, ${y})`}>
                    <polygon cx={x} cy={y} stroke="#D0021B" stroke-width="2" fill="transparent" points="12 3 22 23 2 23" {...this.props}/>
                </g>
            );

            // Triangolo pieno
            case 3: return (
                <g transform={`translate(${x}, ${y})`}>
                    <polygon stroke="#D0021B" stroke-width="2" fill="#D0021B" points="12 3 22 23 2 23" {...this.props}/>
                </g>
            );


            default: return null;
        }
    }
}
