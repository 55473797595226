import React, {Component} from 'react';
import { connect } from 'react-redux';
import {InputRadio} from "../../components/Visita/InputRadio";
import {getReportSettings, saveReportSettings, setReportToggle} from "../../actions/istanza.actions";


class Settings extends Component
{
    componentDidMount() {
        this.props.getReportSettings();
    }


    handleAnswers(a, option_id, module_id){
        this.props.setReportToggle(module_id, option_id, a);
    }


    render() {
        const {settings} = this.props;

        return (
            <div className={"container-fluid mb-5"}>
                <div className="row">
                    <div className="col-12">

                        <h1 id="tabelLabel">Impostazioni</h1>

                        {settings && settings.length && settings.map((mod, i) => (

                            <div className={"mt-4 pt-5 bg-white pl-5 pr-5 pb-4"} key={i.toString()}>
                                <h3>Preferenze report {mod.name}</h3>
                                <p className="mb-3">Da questa sezione puoi personalizzare il report di {mod.name}</p>

                                {mod.reportSteps.map((s) => {
                                    return (
                                        <div className={"row row-settings-single my-4 pl-3 pr-3"} key={s.stepId}>
                                            <div className={"col-12 col-lg-6 csx"}>
                                                <h5>{s.name}</h5>
                                                <p className={"medium"}>Mostrare blocco {s.name} nel report della visita?</p>
                                            </div>

                                            <div className={"col-12 col-lg-6"}>
                                                <InputRadio actions={s.options} selected={s.answers} onChange={a => this.handleAnswers(a, s.stepId, mod.licenseId)}/>
                                            </div>
                                        </div>
                                    )})}

                                <div className={"row row-settings-single mt-5 mb-4 pl-3 pr-3"}>
                                    <div className={"col-12 col-lg-6"}>
                                        <button className={"btn btn-primary"} onClick={() => this.props.saveReportSettings()}> Salva impostazioni</button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        );
    }
}


export default connect(state => ({ settings: state.settings }), { getReportSettings, saveReportSettings, setReportToggle })(Settings);
