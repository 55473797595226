import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dente from "./Dente";
import {SquareInt, SquareFlag} from "../Arcata/Square";
import {InputRadio} from "../Visita/InputRadio";
import _ from 'lodash';
import {setCurrentDente} from "../../actions/istanza.actions";

const data_stato_dente = [
    {id: 0, label: "Presente", value: 0},
    {id: 1, label: "Assente", value: 1},
    {id: 2, label: "Impianto", value: 2},
];


const data_motivo_perdita = [
    {id: 0, label: "No", value: 0},
    {id: 1, label: "Si", value: 1},
];


class DentePanel extends Component
{

    constructor(props){
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.handleKeyDown);
    }


    _handleChange(data) {
        const {dente, updateDente} = this.props;
        updateDente({...dente, ...data});
    }


    _goLeft(){
        const {current_dente, setCurrentDente} = this.props;
        setCurrentDente({...current_dente, dente_index: current_dente.dente_index - 1})
    }


    _goRight(){
        const {current_dente, setCurrentDente} = this.props;
        setCurrentDente({...current_dente, dente_index: current_dente.dente_index + 1})
    }


    handleKeyDown(e)
    {
        if (e.keyCode === 37) {
            this._goLeft()
        } else if (e.keyCode === 39) {
            this._goRight()
        }
    }


    render()
    {
        const {dente, current_dente, tipo_arcata} = this.props;
        let d = {
            ..._.omit(dente, ["lato_interno", "lato_esterno"]),
            ...dente[current_dente.lato_dente],
            lato: current_dente.lato_dente
        };

        return d ? (
            <div key={d.id}>

                {/* Stato dente */}
                <div className="section">
                    <h5>Stato dente</h5>
                    <InputRadio
                        actions={data_stato_dente}
                        selected={[_.find(data_stato_dente, s => s.value === dente.stato_dente)]}
                        onChange={a =>
                            a.value === 1 ?  //se assente reset dente
                                this._handleChange({
                                    stato_dente: a.value,
                                    mobilita: 0,
                                    lato_interno: {
                                        ...dente.lato_interno,
                                        sanguinamento: [0, 0, 0],
                                        placca: [false, false, false],
                                        margine:  [0, 0, 0],
                                        profondita:  [0, 0, 0],
                                        forcazione: [...dente.lato_interno.forcazione.map(a => 0)]
                                    },
                                    lato_esterno: {
                                        ...dente.lato_esterno,
                                        sanguinamento: [0, 0, 0],
                                        placca: [false, false, false],
                                        margine:  [0, 0, 0],
                                        profondita:  [0, 0, 0],
                                        forcazione: [...dente.lato_esterno.forcazione.map(a => 0)]
                                    }

                                }) :
                                // Altrimenti update stato
                                this._handleChange({stato_dente: a.value})
                        }/>
                </div>


                {/* Motivo perdita */}
                {dente.stato_dente === 1 && (
                    <div className="section mt-4">
                        <h5>Perso per parodontite?</h5>
                        <InputRadio
                            actions={data_motivo_perdita}
                            selected={[_.find(data_motivo_perdita, s => s.value === (dente.motivo_perdita ? dente.motivo_perdita : 0))]}
                            onChange={a => this._handleChange({motivo_perdita: a.value})}/>
                    </div>
                )}



                {/* Valori Dente */}
                {dente.stato_dente !== 1 && (
                    <div className={"row mt-5 pb-4"}>

                        <div className="col-4">
                            <Dente {...d} arcata={tipo_arcata}/>
                        </div>


                        <div className="col-8 modale-dente-tabella">

                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex"}>
                                        <input type="number" min="0" max="3" value={dente.mobilita} className={"text-center w100"}
                                               onChange={e => this._handleChange({mobilita: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 3 ? 3 : parseInt(e.target.value)})} />
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Mobilità</label>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex flex-row justify-content-center"}>
                                        {d.sanguinamento.map((s, i) => <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                            <SquareInt key={i} colors={["#ededed", "#e91e63", "#ff8500"]} className={"box-square-lg"} val={s} onClick={e =>
                                                this._handleChange({
                                                    [current_dente.lato_dente]: {...dente[current_dente.lato_dente], sanguinamento: d.sanguinamento.map((x,j) => j === i ? ++x%3 : x)}
                                                })
                                            }/>
                                        </div>)}
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Sanguinamento / Suppurazione</label>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex flex-row justify-content-center"}>
                                        {d.placca.map((s, i) => <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                            <SquareFlag color="#03a9f4" className={"box-square-lg"} flag={s} onClick={e =>
                                                this._handleChange({
                                                    [current_dente.lato_dente]: {...dente[current_dente.lato_dente], placca: d.placca.map((x,j) => j === i ? !x : x)}
                                                })
                                            }/>
                                        </div>)}
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Placca</label>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex flex-row justify-content-center margine-fix"}>
                                        <div className={"d-flex w-100"}>{d.margine.map((p, i) => <input type="number" min="-20" max="7" className={"w-100 text-center"} value={p.toString()} onChange={e => {
                                            let m = [...d.margine];
                                            let x = parseInt(e.target.value) < -20 ? -20 : parseInt(e.target.value) > 7 ? 7 : parseInt(e.target.value);
                                            m[i] = x;
                                            this._handleChange({
                                                    [current_dente.lato_dente]: {...dente[current_dente.lato_dente], margine: m}}
                                                )}
                                        }
                                        />)}</div>
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Margine gengivale</label>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex flex-row justify-content-center"}>
                                        <div className={"d-flex"}>{d.profondita.map((p, i) => <input type="number" className={`w-100 text-center ${p >= 4 && p <= 5 ? 'text-warning' : p > 5 ? 'text-danger' : 'text-dark'}`} value={p.toString()} onChange={e => {
                                            let p = [...d.profondita];
                                            p[i] = parseInt(e.target.value);
                                            this._handleChange({
                                                [current_dente.lato_dente]: {...dente[current_dente.lato_dente], profondita: p}}
                                            )}
                                        }
                                        />)}</div>
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Profondità Sondaggio</label>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-7">
                                    <div className={"d-flex flex-row justify-content-center"}>
                                        {d.forcazione && d.forcazione.length > 0 ? d.forcazione.map((s, i) => <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                            <SquareInt key={i} colors={["#ededed", "#5d5d5d", "#815b5b", "#090909"]} className={"box-square-lg"} val={s} onClick={e =>
                                                this._handleChange({
                                                    [current_dente.lato_dente]: {...dente[current_dente.lato_dente], forcazione: d.forcazione.map((x,j) => j === i ? ++x%4 : x)}
                                                })
                                            }/>
                                        </div>) :
                                            <div>-</div>}
                                    </div>
                                </div>

                                <div className="col-5 text-left">
                                    <label>Forcazione</label>
                                </div>
                            </div>

                        </div>
                    </div>
                )}



                {/* Stato impianto */}
                {dente.stato_dente === 2 && (
                    <div className="section mt-5 pt-5">
                        {dente.impianto.campi.map(campo => {

                            if(!campo.hide ||  !_.isEmpty(_.find(dente.impianto.campi, c => c.answers && c.answers.length > 0 && c.answers[0].showFields[0] === campo.id)))
                            {
                                return (
                                    <div className="mb-3 pr-5">
                                        <h5>{campo.description}</h5>
                                        <InputRadio
                                            actions={campo.options}
                                            selected={campo.answers && campo.answers.length > 0 ? [_.find(campo.options, s => s.value === (campo.answers ? campo.answers[0].value : 0))] : null}
                                            onChange={a => this._handleChange({
                                                impianto: {
                                                    ...dente.impianto,
                                                    campi: [
                                                        ..._.map(dente.impianto.campi, (c, i) => c.id === campo.id ? {
                                                            ...c,
                                                            answers: _.filter(campo.options, o => o.value === a.value)
                                                        } : c),
                                                    ]
                                                }
                                            })}
                                        />

                                        {campo.id === 1002 && <a href="/stamp.png" target="_blank"><img alt="Radiografia" className="radiografia w-30 mt-3 img-flud" src="/stamp.png"/></a>}
                                    </div>
                                )
                            } else {
                                return (<div/>)
                            }
                        })}
                        
                    </div>
                )}


                {/* Navigazione */}
                <div className={"row"}>
                    <div className={"col-6 offset-6 text-right mt-5 pt-5 denti-nav"}>
                        <img alt="" src="/next.svg" width={32} className="flip" onClick={() => this._goLeft()}/>
                        <img alt="" src="/next.svg" width={32} onClick={() => this._goRight()}/>
                    </div>
                </div>

            </div>

        ) : (
            <div>Caricamento</div>
        );
    }
}


export default connect(state => ({}), {setCurrentDente})(DentePanel);
