import React, { Component } from 'react';
import './Dente.scss';
import CircleSvg from "./CircleSvg";
import ArrowSvg from "./ArrowSvg";
import Path from "./Path";
import PathArea from "./PathArea";
import pattern from "./pattern";

const empty = [[0,0], [1,0], [2,0]];


export default class Dente extends Component {
    constructor(props) {
        super(props);
        this.dente = React.createRef()
    }


    _renderDente(islayerOsso) {
        const { margine, profondita, forcazione, base_dente, mobilita, dente_prev, dente_next, offsets } = this.props;

        const posizionetop = 120;
        const spessoreGengiva = 12;
        const posizioneOssoTop = posizionetop - spessoreGengiva;
        const margine_line = margine.map((p, i) => [i, base_dente[i] - p]);
        const profondita_line = profondita.map((p, i) => [i, base_dente[i] - margine[i] + p]);

        // Margini denti prev e next
        const dente_prev_margine_line = dente_prev ? dente_prev.margine.map((p, i) => [i, dente_prev.base_dente[i] - p]) : null;
        const dente_next_margine_line = dente_next ? dente_next.margine.map((p, i) => [i, dente_next.base_dente[i] - p]) : null;

        const dente_prev_profondita_line = dente_prev ? dente_prev.profondita.map((p, i) => [i, dente_prev.base_dente[i] - dente_prev.margine[i] + p]) : null;
        const dente_next_profondita_line = dente_next ? dente_next.profondita.map((p, i) => [i, dente_next.base_dente[i] - dente_next.margine[i] + p]) : null;



        return islayerOsso ? (
            <svg className="zi-1 noop">
                <Path x={70}
                      y={posizioneOssoTop}
                      profondita={profondita_line}
                      fill={"url(#pattern-chevron)"}
                      strokeLineJoin="round"
                      stroke="#d8ab7b"
                      offsets={offsets}
                      dente_prev_margine_line={dente_prev_margine_line ? dente_prev_margine_line : empty}
                      dente_next_margine_line={dente_next_margine_line ? dente_next_margine_line : empty}
                      dente_prev_profondita_line={dente_prev_profondita_line ? dente_prev_profondita_line : empty}
                      dente_next_profondita_line={dente_next_profondita_line ? dente_next_profondita_line : empty}
                       />
            </svg>
        ) : (
            <svg className="zi-3 noop">
                {/* Linee sfondo */}
                {/*{_.times(19, i => <Line x={0} y={env.distanza_linee_dente * (i + 1)} data={[[0,0], [1,0], [2,0]]} stroke={"rgba(200,225,230,0.61)"}/>)}*/}

                <PathArea x={70}
                      y={posizionetop}
                      yOsso={posizioneOssoTop}
                      margine={margine_line}
                      profondita={profondita_line}
                      // fill={"rgba(238,120,134,0.53)"}
                      fill="url(#grad1)"
                      strokeLineJoin="round"
                      strokeLinecap="round"
                      strokeWidth="1"
                      stroke="#c98884"
                      offsets={offsets}
                      dente_prev_margine_line={dente_prev_margine_line ? dente_prev_margine_line : empty}
                      dente_next_margine_line={dente_next_margine_line ? dente_next_margine_line : empty}
                      dente_prev_profondita_line={dente_prev_profondita_line ? dente_prev_profondita_line : empty}
                      dente_next_profondita_line={dente_next_profondita_line ? dente_next_profondita_line : empty}
                      />


                {/* Forcazioni */}
                {forcazione.map((f, i) => (
                    f > 0 && <CircleSvg x={forcazione.length === 1 ? 25 : i === 0 ? 10 : i * 35} y={55} v={f} />
                ))}

                {/* Indicatore mobilita */}
                {mobilita > 0 && <ArrowSvg x={10} y={150} v={mobilita} />}
            </svg>
        );
    }



    render() {
        const { id, lato, numero_dente, stato_dente, arcata } = this.props;

        return (
            <div className={`single-dente-image p-0 fix-dente-h position-relative overflow-hidden ${arcata}`} key={id} ref={this.dente}>

                <svg>
                    <defs>
                        <pattern id="pattern-chevron" x="0" y="0" width="70.9px" height="70.9px" viewBox="0 0 70.9 70.9" patternUnits="userSpaceOnUse" >
                            {pattern()}
                        </pattern>

                        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" className="danim-start" />
                            <stop offset="75%" className="danim-stop" />
                        </linearGradient>
                    </defs>
                </svg>

                {this._renderDente(true)}
                {stato_dente === 2 && (<img className="zi-2 position-relative" src={`/denti/${lato}/${numero_dente}i.png`} alt={`Dente ${numero_dente}`} />)}
                {stato_dente === 0 && (<img className="zi-2 position-relative" src={`/denti/${lato}/${numero_dente}.png`} alt={`Dente ${numero_dente}`} />)}
                {this._renderDente(false)}
            </div>
        );
    }
}
