import React, {Component} from 'react';
import './AdminLayout.scss';
import NavMenu from "../../components/navigation/NavMenu";
import Header from "../../components/header/Header";
import {Footer} from "../../components/footer/Footer";


export class AdminLayout extends Component
{
    render() {
        return (
            <div id="wrapper">
                <NavMenu/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header/>
                        <div className="pt-3 scrollable-content position-relative">
                            {this.props.children}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}
