import React, {Component} from 'react';
import Select from 'react-select';
import CssJs from "../../style/cssjs";


export class InputDropdownVisit extends Component
{
    state = {
        itemSelected: null
    };


    componentDidMount() {
        const {selected} = this.props;
        this.setState({itemSelected: selected});
    }


    _handleChangeSelect = item => {
        this.setState({itemSelected: item});
        this.props.onChange(item);
    };


    render()
    {
        const {options, name, isMulti} = this.props;
        const {itemSelected} = this.state;

        return (
           <div className={"w100 dropdown-visita"}>
               <Select
                   name={name}
                   value={itemSelected}
                   onChange={this._handleChangeSelect}
                   options={options}
                   styles={CssJs.select}
                   isMulti={isMulti}
               />

               {itemSelected && itemSelected.value &&
                   <a target="_blank" href={`/visita/${itemSelected.value}`} rel="noopener noreferrer">
                       Dettagli
                   </a>
               }
           </div>
        );
    }
}
