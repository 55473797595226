import React, {Component} from 'react';
import './Footer.scss';
import {Link} from 'react-router-dom';


export class Footer extends Component
{
    render()
    {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col pl-5">
                            <span>Copyright &copy; DAGMA S.R.L.</span>
                        </div>

                        <div className="col pr-5 text-right">
                            <a href="https://www.periomapp.com/privacy-policy/" rel="noopener noreferrer" target="_blank" className="mr-3 text-dark">Norme sulla privacy</a>
                            <Link to={"/assistenza"}>Assistenza</Link>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
