let getBaseUrl = () => {
   if(window.location.port === "5000") return {base_url: "http://127.0.0.1:5000", env: "development"};
   if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") return {base_url: "https://127.0.0.1", env: "development"};
   if(window.location.hostname === "periodontogramma.helix.g2k.it") return {base_url: "https://periodontogramma.helix.g2k.it", env: "staging"};
   if(window.location.hostname === "app.periomapp.com") return {base_url: "https://app.periomapp.com", env: "production"};

   console.error("Hostname corrente non configurato");
}


export default {
    base_url:  getBaseUrl().base_url,
    environment: getBaseUrl().env,
    distanza_linee_dente: 7,
    link_manuale: "https://www.periomapp.com/wp-content/uploads/2020/11/manuale-uso.pdf",
    link_chat: "https://tawk.to/chat/5fa91c100a68960861bd135f/default",
    dsn: "https://a6999dc2c80746ac91d8e944353bc103@o263675.ingest.sentry.io/5601386",
    version: "1.2.0"
}
