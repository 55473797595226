import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import CssJs from '../../style/cssjs';
import './Personale.scss';
import { createPersona, editPersona, deletePersona } from '../../actions/istanza.actions';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class PersonaleForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            email: '',
            role: [],
            licenses: [],
            action: ''
        };

        // preserve the initial state in a new object
        this.baseState = this.state
    }

    async componentDidMount() {
        const { persona, mode } = this.props;

        if (persona) {
            this.setState({
                id: persona.id,
                email: persona.email,
                role: persona.role,
                licenses: persona.licenses,
                action: this.props.mode
            })
        }
        if (mode === 'create') {
            this.setState(this.baseState);
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };

    handleChangeSelectRuolo = role => {
        this.setState({role});
    };

    handleChangeSelectlicenses = licenses => {
        this.setState({licenses: licenses});
    };


    handleSubmit = (e) => {
        e.preventDefault();

        const persona = {
            id: this.state.id,
            email: this.state.email,
            role: this.state.role,
            licenses: this.state.licenses,
        };

        switch (this.state.action)
        {
            case 'create':
                this.props.createPersona(persona);
                break;
            case 'edit':
                this.props.editPersona(persona);
                break;
            case 'delete':
                this.props.deletePersona(persona);
                break;
            default:
                break;
        }

        this.props.closeModal();
        this.setState(this.baseState);
    };


    render() {
        const { email, role, licenses } = this.state;
        const { mode, listaRuoli, listaLicenze } = this.props;
        let bottoni_action = null;

        if (mode === 'create')
        {
            bottoni_action = (
                <div className="row">
                    <div className="col-12 text-right">
                        <button className="btn btn-primary mt-3" type="submit" onClick={() => this.setState({ action: 'create' })}>Aggiungi</button>
                    </div>
                </div>
            );
        }
        else if (mode === 'edit')
        {
            bottoni_action = (
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <button className="btn btn-outline-secondary-delete mt-3" type="submit" onClick={() => this.setState({ action: 'delete' })}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    <div className="col-12 col-lg-6 text-right">
                        <button className="btn btn-primary mt-3" type="submit" onClick={() => this.setState({ action: 'edit' })}>Modifica</button>
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-12 bg-white">
                            <div className="row">

                                <div className="form-group mb-4 col-12">
                                    <label htmlFor="email">Email <span className="icon-required">*</span></label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="inserisci email" value={email} onChange={this.handleChange} required />
                                </div>
                                <div className="form-group mb-4 col-12">
                                    <label htmlFor="role">Ruolo <span className="icon-required">*</span></label>
                                    <Select
                                        name="role"
                                        id="role"
                                        value={role}
                                        onChange={this.handleChangeSelectRuolo}
                                        options={listaRuoli}
                                        styles={CssJs.select}
                                        defaultValue={"Seleziona role"}
                                    />
                                </div>
                                <div className="form-group mb-4 col-12">
                                    <label htmlFor="licenses">Licenze assegnate</label>
                                    <Select
                                        name="licenses"
                                        id="licenses"
                                        value={licenses}
                                        onChange={this.handleChangeSelectlicenses}
                                        options={listaLicenze}
                                        styles={CssJs.select}
                                        isMulti
                                        defaultValue={"Seleziona licenza"}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {bottoni_action}
                </form>
            </div>
        );
    }
}


export default connect(state => ({}), { createPersona, editPersona, deletePersona })(PersonaleForm);
