import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getVisitaDettaglio, getDitta} from "../../actions/istanza.actions";
import Periodontogramma from "../../components/Visita/Periodontogramma";
import _ from 'lodash';
import moment from 'moment';


class Report extends Component {
    state = {
        visita: null,
        ditta: null
    }

    async componentDidMount() {
        const {id_visita, pazienti, getVisitaDettaglio, getDitta} = this.props;

        let visita = await getVisitaDettaglio(id_visita);
        let patientId = visita.data.patientId;
        let ditta = await getDitta();
        let paziente = pazienti.data[patientId];

        this.setState({
            visita: visita.data,
            ditta: ditta.data,
            paziente
        })
    }


    _renderQuestion(step){
        return (
            <div className="pl-3 pr-3 pb-2">
                {step.campi.map(c => c.answers && c.answers.length > 0 && (
                    <div className="row my-3">
                        <div className="col-6 d-flex flex-column justify-content-center">
                            <h5>{c.titolo}</h5>
                            <p className="font-italic">{c.description}</p>
                        </div>

                        <div className="col-6 pr-4 align-items-center d-flex">
                            <p>{c.tipologia === 'number' ?
                                c.answers.map(a => (
                                    <span className="chip">{a.value}</span>
                                )) : (
                                c.answers.map(a => (
                                    <span className="chip">{a.label && a.label !== "Value" ? a.label: a.value}</span>
                                ))
                             )}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }


    _renderPrognosi(step){
        return (
            <div className="pl-3 pr-3 pb-2">
                {step.campi.map(c => c.tipologia !== "dropdownStatus" && (
                    <div className="row my-3">
                        <div className="col-12">
                            <h5 dangerouslySetInnerHTML={{__html: c.titolo}}></h5>
                            <h6>{c.description}</h6>
                        </div>

                        <div className="col-12">
                            {c.answers && c.answers.length > 0 ?
                                c.answers.map(o => <div dangerouslySetInnerHTML={{ __html: c.tipologia === 'checkbox' ? o.label : o.value}}/>) :
                                c.options.map(o => <div dangerouslySetInnerHTML={{ __html: c.tipologia === 'checkbox' ? o.label : o.value}}/>)
                            }
                        </div>
                    </div>
                ))}
            </div>
        );
    }


    _componentInjector(step)
    {
        const {visita} = this.state;
    
        switch (step.tipo)
        {
            case 'type_periodontogramma':
                return (<Periodontogramma {...step} arcate={step.arcate} current_step={1} id_paziente={visita.patientId} />);

            case 'type_anamnesi':
            case 'type_additional_fields':
                return this._renderQuestion(step)

            case 'type_diagnosi':
            case 'type_prognosi':
            case 'type_trattamento':
                return this._renderPrognosi(step)

            default: return (<div/>);
        }
    }



    render() {
        const {visita, ditta, paziente} = this.state;

        return (
            <div className={"container container-print-report"}>

                <div className="row my-3 bg-white paper sub-page privacy-page">
                    <div className="col-12 pl-5 pr-5 pb-5 pt-0 cc">
                        <img src={ditta ? ditta.logo : ''} alt="Logo"/>
                        <h1 className="mt-4 mb-2">{ditta ? ditta.name : ''}</h1>
                    </div>
                </div>

                <div className="row my-3 bg-white paper">
                    <div className="col-12 pl-5 pr-5 pb-5 pt-0">

                        {/* INTESTAZIONE */}
                        <div className="row border bb my-5">
                            <div className="col-6 p-3">
                                <div id="logo-area">
                                    <img src={ditta ? ditta.logo : ''} alt="Logo"/>
                                </div>

                                <h3 className="mt-4 mb-2">{ditta ? ditta.name : ''}</h3>
                                <p>{ditta && ditta.address ? ditta.address.address : ''}</p>
                                <p>{ditta && ditta.address ? `${ditta.address.city} - ${ditta.address.cap} (${ditta.address.province})` : ''}</p>
                                <p>
                                    {ditta && ditta.iva ? `PIVA ${ditta.iva}` : ''}
                                    {ditta && ditta.fiscalCode ? `- CF ${ditta.fiscalCode}` : ''}
                                </p>
                                <p>{ditta && ditta.phone ? ditta.phone : ''} - {ditta && ditta.email ? ditta.email : ''}</p>
                            </div>


                            <div className="col-6 border p-0">
                                <div className="bg-light-gray p-3 mt-0 pt-2">
                                    <h5>Visita {visita ? visita.tipologia : ''}</h5>
                                    <p>Data {visita ? moment(visita.data).format("DD/MM/YYYY") : ''}</p>
                                    <p>{visita ? visita.doctor : ''} - Rif. #{visita ? visita.id : ''}</p>
                                </div>

                                <div className="p-3 pt-2">
                                    <h5 className="my-2">Paziente</h5>
                                    <p>{paziente ? `${paziente.firstName} ${paziente.lastName}` : ''}</p>
                                    <p>{paziente ? paziente.fiscalCode : ''}</p>
                                    <p>{paziente ? paziente.email : ''}</p>
                                    <p>{paziente ? paziente.phone : ''}</p>
                                </div>
                            </div>
                        </div>


                        {/* STEPS */}
                        {visita && visita.steps &&
                            _.filter(visita.steps, s =>
                                s.tipo !== "type_report" &&
                                _.find(this.props.settings[0].reportSteps, r => r.stepId === s.id).answers[0].value === "1"
                            ).map((step, i) => (

                            <div className={`row border my-5 sub-page`}>
                                <div className="col-12 border bb p-0">
                                    <h3 className="mb-2 bg-light-gray p-3">{step.titolo}</h3>
                                    {this._componentInjector(step)}
                                </div>
                            </div>

                        ))}


                    </div>
                </div>


            </div>
        );
    }
}


export default connect(state => ({pazienti: state.pazienti, settings: state.settings}), {getDitta, getVisitaDettaglio})(Report);
