import React, {Component} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


export class InputTextArea extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };

        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }



    setTextArea(){
        const {option} = this.props;

        if(option && option.value) {
            const contentBlock = htmlToDraft(option.value);
            // const contentBlock = htmlToDraft("<p style='color:red'>Hey this <strong>editor</strong> rocks 😀</p>");

            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({editorState: EditorState.createWithContent(contentState)});
            }
        }
    }


    componentDidMount() {
        this.setTextArea();
    }


    componentDidUpdate(prevProps) {
        const {option} = this.props;
        if (prevProps.option !== option) {
            this.setTextArea();
        }
    }


    onEditorStateChange(editorState){
        const {option, onChange} = this.props;

        this.setState({editorState}, () => {
            let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            //console.log("UPDATE", html);
            onChange({...option, value: html});
        });
    }


    render()
    {
        let {editorState} = this.state;

        return (
            <Editor
                editorState={editorState}
                ref="draftRef"
                onEditorStateChange={this.onEditorStateChange} />
            )
    }
}
