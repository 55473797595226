import React, { Component } from 'react';
import env from '../../env';
import _ from 'lodash';


export default class Path extends Component {
    static defaultProps = { multiplierX: 40, multiplierY: env.distanza_linee_dente };


    render() {
        const { x, y, profondita, multiplierY, dente_next_profondita_line, dente_prev_profondita_line, offsets} = this.props;

        let y1_pro = y - (profondita[0][1] * multiplierY);
        let y2_pro = y - (profondita[1][1] * multiplierY);
        let y3_pro = y - (profondita[2][1] * multiplierY);

        let y3_pro_pre = y - (dente_prev_profondita_line[2][1] * multiplierY);   // osso-3 del dente prima
        let y1_pro_next = y - (dente_next_profondita_line[0][1] * multiplierY); // osso-1 del dente dopo


        let getXStimata = ((x, y, key) => {
            let shift = _.find(offsets, s => y >= s.min && y <= s.max);
            return shift ? x - shift[key] : x;
        });



        return (
            <g>
                <path d={`
                        M ${x} ${(y3_pro + y1_pro_next)/2}
                        L ${getXStimata((3*x)/4, y3_pro, 'right')} ${y3_pro}
                        L ${getXStimata((2*x)/4, y2_pro, 'center')} ${y2_pro}
                        L ${getXStimata(x/4, y1_pro, 'left')} ${y1_pro}
                        L 0 ${(y1_pro + y3_pro_pre)/2}
                        L 0 0
                        L ${x} 0 
                        Z`}
                      {...this.props}
                />




                {/* SOLO PER TARARE DENTI */}
                {window.location.href.indexOf("debugLines=true") >= 0 && (<g>
                    <line x1={x/4} y1="0" x2={x/4} y2={y} stroke={'red'}/>
                    <line x1={(x/4)-5} y1="0" x2={(x/4)-5} y2={y} stroke={'cyan'}/>
                    <line x1={(x/4)-10} y1="0" x2={(x/4)-10} y2={y} stroke={'yellow'}/>


                    <line x1={((2*x)/4)-5} y1="0" x2={((2*x)/4)-5} y2={y} stroke={'cyan'}/>
                    <line x1={(2*x)/4} y1="0" x2={(2*x)/4} y2={y} stroke={'red'}/>
                    <line x1={((2*x)/4)+5} y1="0" x2={((2*x)/4)+5} y2={y} stroke={'cyan'}/>


                    <line x1={(3*x)/4} y1="0" x2={(3*x)/4} y2={y} stroke={'red'}/>
                    <line x1={((3*x)/4)+5} y1="0" x2={((3*x)/4)+5} y2={y} stroke={'cyan'}/>
                    <line x1={((3*x)/4)+10} y1="0" x2={((3*x)/4)+10} y2={y} stroke={'yellow'}/>

                    <line x1={0} y1="22" x2={x} y2='22' stroke={'blue'}/>
                    <line x1={0} y1="44" x2={x} y2='44' stroke={'blue'}/>
                    <line x1={0} y1="66" x2={x} y2='66' stroke={'blue'}/>
                    <line x1={0} y1="88" x2={x} y2='88' stroke={'blue'}/>
                    <line x1={0} y1="110" x2={x} y2='110' stroke={'green'} strokeWidth={2}/>
                </g>)}
            </g>


            // <g>
            //     <path d={`
            //                 M ${x} ${(y3_pro + y1_pro_next)/2}
            //                 L ${(3 * x) / 4} ${y3_pro}
            //                 L ${(2 * x) / 4} ${y2_pro}
            //                 L ${x/4} ${y1_pro}
            //                 L 0 ${(y1_pro + y3_pro_pre)/2}
            //                 L 0 0
            //                 L ${x} 0
            //                 Z`}
            //           {...this.props}
            //     />
            // </g>
        )

    }
}
