import React, {Component} from 'react';
import Dente from "../Dente/Dente";
import _ from 'lodash';
import './Arcata.scss';
import {SquareFlag, SquareInt, SquareText} from "./Square";
import {connect} from 'react-redux';


class Arcata extends Component
{
    _prepareDente(dente)
    {
        return {
            ..._.omit(dente, ["lato_interno", "lato_esterno"]),
            ...dente.lato_esterno,
            dente_id: dente.id,
            lato: "lato_esterno"
        };
    }

    render() {
        const {titolo, denti, tipologia, onClickDente, arcata_index} = this.props;

        return (
            <div className={"bg-white p-4 mb-4"}>
                <div className={"container-arcata pl-3 pt-2"}>

                {/* Titolo */}
                <div className="row">
                    <h4 className={"col mb-3"}>{titolo}</h4>
                </div>


                {/* Superiore */}
                <div className={"denti-container row my-3"}>
                    <div className={"col denti-labels"}>
                        <div>Numero dente</div>
                        <div>Mobilita</div>
                        <div>Sanguinamento</div>
                        <div>Placca</div>
                        <div>Margine Geng.</div>
                        <div>Prof. D. Sond.</div>
                        <h6 className={"fix-dente-h v-center"}>VESTIBOLARE</h6>
                    </div>

                    {denti.map((dente, i) => {

                        let d = this._prepareDente(dente);

                        let dente_prev = i > 0 ? this._prepareDente(denti[i - 1]) : null;
                        let dente_next = i + 1 < denti.length ? this._prepareDente(denti[i + 1]) : null;

                        return (
                            <div className={`col single-dente dente-nr-${dente.numero_dente} p-0 ${i === (denti.length / 2) -1 ? 'mr-4' : ''}`} key={d.id} onClick={() => onClickDente(d.lato, i, arcata_index)}>
                                <div className={"text-bold"}>{d.numero_dente}</div>
                                <div>{d.mobilita}</div>
                                <div>{d.sanguinamento.map(s => <SquareInt colors={["#ededed", "#e91e63", "#ff8500"]} val={s}/>)}</div>
                                <div>{d.placca.map(s => <SquareFlag color="#03a9f4" flag={s}/>)}</div>
                                <div>{d.margine.map(p => <SquareText text={p.toString()}/>)}</div>
                                <div>{d.profondita.map(p => <SquareText text={p.toString()} className={`${p >= 4 && p <= 5 ? 'text-warning' : p > 5 ? 'text-danger' : 'text-dark'}`}/>)}</div>

                                <Dente
                                    {...d}
                                    key={i}
                                    arcata={tipologia}
                                    dente_prev={dente_prev}
                                    dente_next={dente_next} />
                            </div>
                        )
                    })}
                </div>




                {/* Interno */}
                <div className={"denti-container row my-3"}>
                    <div className={"col denti-labels"}>
                        <h6 className={"fix-dente-h v-center"}>{tipologia && tipologia === "arcata_superiore" ? "PALATALE" : "LINGUALE"}</h6>
                        <div>Sanguinamento</div>
                        <div>Placca</div>
                        <div>Margine Geng.</div>
                        <div>Prof. D. Sond.</div>
                    </div>

                    {denti.map((dente, i) => {
                        let d = {
                            ..._.omit(dente, ["lato_interno", "lato_esterno"]),
                            ...dente.lato_interno,
                            dente_id: dente.id,
                            lato: "lato_interno"
                        };

                        return (
                            <div className={`col single-dente dente-nr-${dente.numero_dente} p-0 ${i === (denti.length / 2) -1 ? 'mr-4' : ''}`} key={d.id} onClick={() => onClickDente(d.lato, i, arcata_index)}>
                                <Dente {...d} arcata={tipologia} />

                                <div>{d.sanguinamento.map(s => <SquareInt colors={["#ededed", "#e91e63", "#ff8500"]} val={s}/>)}</div>
                                <div>{d.placca.map(s => <SquareFlag color="#03a9f4" flag={s}/>)}</div>
                                <div>{d.margine.map(p => <SquareText text={p.toString()}/>)}</div>
                                <div>{d.profondita.map(p => <SquareText text={p.toString()} className={`${p >= 4 && p <= 5 ? 'text-warning' : p > 5 ? 'text-danger' : 'text-dark'}`}/>)}</div>
                            </div>
                        )
                    })}
                </div>

              </div>
            </div>
        );
    }
}



export default connect(state => ({}), {})(Arcata);

