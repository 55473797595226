import React, {Component} from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import _ from 'lodash';


export class InputCheck extends Component
{
    state = {
        itemSelected: []
    };


    componentDidMount() {
        const {selected} = this.props;
        let k = _.map(selected, s => s.id);
        this.setState({itemSelected: k});
    }


    _handleSelection = (selction) => {
        let {itemSelected} = this.state;
        const index = itemSelected.indexOf(selction);
        index < 0 ? itemSelected.push(selction) : itemSelected.splice(index, 1);
        this.setState({itemSelected: [...itemSelected]}, () => this.props.onChange(_.map(this.state.itemSelected, selectedItemVal => _.find(this.props.actions, a => a.id === selectedItemVal))));
    };


    render()
    {
        const {actions, color} = this.props;
        const {itemSelected} = this.state;

        return (
            <ButtonGroup className={"col pr-0 d-flex justify-content-end align-items-start btn-group-radio"}>
                {actions.map((act, i) => (
                    <Button
                        color={color}
                        key={act.id.toString()}
                        active={itemSelected.includes(act.id)}
                        onClick={() => this._handleSelection(act.id)}>{act.label}</Button>
                ))}
            </ButtonGroup>
        );
    }
}
