import React, {Component} from 'react';


export default class ArrowSvg extends Component
{
    render()
    {
        const {x, y, v} = this.props;

        switch (v)
        {
            // Triangolo aperto
            case 1: return (
                <g transform={`translate(${x}, ${y})`} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g fill="#8bc34a" fill-rule="nonzero">
                        <polygon id="Path" points="43.2290576 3.9065445 36.5320244 0.039877836 36.5320244 5.33289703 13.3942408 5.33289703 13.3942408 0.039877836 6.69729494 3.90636998 0 7.77294939 6.69729494 11.6393543 13.3942408 15.5060209 13.3942408 10.213089 36.5320244 10.213089 36.5320244 15.5060209 43.2290576 11.6395288 49.9263525 7.77294939"></polygon>
                    </g>
                </g>
            );

            // Triangolo bordo
            case 2: return (
                <g transform={`translate(${x}, ${y})`} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g fill="#ff9800" fill-rule="nonzero">
                        <polygon id="Path" points="43.2290576 3.9065445 36.5320244 0.039877836 36.5320244 5.33289703 13.3942408 5.33289703 13.3942408 0.039877836 6.69729494 3.90636998 0 7.77294939 6.69729494 11.6393543 13.3942408 15.5060209 13.3942408 10.213089 36.5320244 10.213089 36.5320244 15.5060209 43.2290576 11.6395288 49.9263525 7.77294939"></polygon>
                    </g>
                </g>
            );

            // Triangolo pieno
            case 3: return (
                <g transform={`translate(${x}, ${y})`} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g fill="#f44336" fill-rule="nonzero">
                        <polygon id="Path" points="43.2290576 3.9065445 36.5320244 0.039877836 36.5320244 5.33289703 13.3942408 5.33289703 13.3942408 0.039877836 6.69729494 3.90636998 0 7.77294939 6.69729494 11.6393543 13.3942408 15.5060209 13.3942408 10.213089 36.5320244 10.213089 36.5320244 15.5060209 43.2290576 11.6395288 49.9263525 7.77294939"></polygon>
                    </g>
                </g>
            );


            default: return null;
        }
    }
}
