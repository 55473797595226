import {
    DO_LOGIN,
    DO_RECOVERY,
    GET_PAZIENTI,
    DELTE_VISITA_DRAFT,
    SET_DENTE,
    DO_RESET,
    DO_LOGOUT,
    DO_LOGOUT_REASON,
    SET_ISTANZA,
    DELETE_VISITA,
    GET_DETTAGLIO_PAZIENTE,
    SET_VISITA_DRAFT,
    GET_PERSONALE,
    CREATE_PERSONA,
    GET_LICENZE,
    DELETE_PERSONA,
    CREATE_PAZIENTE,
    DELETE_PAZIENTE,
    DO_COLLAPSE,
    SAVE_VISITA,
    SET_RISPOSTE_ANAMNESI,
    UPDATE_DENTE,
    TOGGLE_RELATED_QUESTIONS,
    UPDATE_PAZIENTE,
    UPDATE_PERSONA,
    GET_REPORT_SETTINGS,
    SAVE_REPORT_SETTINGS,
    SET_REPORT_TOGGLE,
    DO_RESET_PASSWORD,
    DO_REFRESH_TOKEN,
    SET_MARGINE_ENNE,
    SET_BIOTIPO, DISMISS_ONBOARDING, LOADING
} from "../actions/types";

import _ from 'lodash';


export default (state = {}, action) => {

    switch (action.type) {
        case DO_LOGIN:
            return { ...state, user: action.user, error: action.error };

        case DO_REFRESH_TOKEN:
            return { ...state, user: {...state.user, ...action.user}, error: action.error };

        case DO_RESET_PASSWORD:
            return { ...state, error: action.error, message: action.message};

        case DO_LOGOUT_REASON:
            return {error: action.error};

        case DISMISS_ONBOARDING:
            return {...state, user: {...state.user, onboarding: false}}

        case DO_LOGOUT:
            return {};

        case DO_RECOVERY:
            return { ...state, user: null, message: action.message };

        case GET_PAZIENTI:
            return { ...state, pazienti: action.pazienti };

        case SET_DENTE:
            return { ...state, current_dente: action.data };

        case DO_RESET:
            return { ...state, message: "", error: "" };

        case SET_ISTANZA:
            return { ...state, current_istanza: action.current_istanza, user: { ...state.user, ...action.user } };

        case GET_DETTAGLIO_PAZIENTE:
            return { ...state, pazienti: {data: { ...state.pazienti.data, [action.paziente.id]: {...state.pazienti.data[action.paziente.id], ...action.paziente}}, order: state.pazienti.order} };

        case SET_VISITA_DRAFT:
            return { ...state, visite_drafts: { [action.data.id_paziente]: { ...action.data } } };

        case UPDATE_DENTE:
            return {
                ...state,
                visite_drafts: {
                    ...state.visite_drafts,
                    [action.data.id_paziente]: {
                        ...state.visite_drafts[action.data.id_paziente],
                        steps: state.visite_drafts[action.data.id_paziente].steps.map(
                            (step, i) => i === action.data.current_step ? {
                                ...step,
                                arcate: step.arcate.map(
                                    (arcata, i) => i === action.data.arcata_index ? {
                                        ...arcata,
                                        denti: arcata.denti.map(
                                            (dente, i) => dente.id === action.data.dente_updated.id ? {
                                                ...dente,
                                                ...action.data.dente_updated

                                            } : dente)

                                    } : arcata)
                            } : step
                        )
                    }
                }
            };



        case SET_MARGINE_ENNE:
            return {
                ...state,
                visite_drafts: {
                    ...state.visite_drafts,
                    [action.data.id_paziente]: {
                        ...state.visite_drafts[action.data.id_paziente],
                        steps: state.visite_drafts[action.data.id_paziente].steps.map(
                            (step, i) => i === action.data.current_step ? {
                                ...step,
                                arcate: step.arcate.map(
                                    (arcata, i) => {
                                        return {
                                            ...arcata,
                                            denti: arcata.denti.map(
                                                (dente, i) => {
                                                    return {
                                                        ...dente,
                                                        lato_interno: {...dente.lato_interno, margine: [action.data.valore_margine, action.data.valore_margine, action.data.valore_margine]},
                                                        lato_esterno: {...dente.lato_esterno, margine: [action.data.valore_margine, action.data.valore_margine, action.data.valore_margine]},
                                                    }
                                                })
                                        }
                                    })
                            } : step
                        )
                    }
                }
            };




        case SET_BIOTIPO:
            return {
                ...state,
                visite_drafts: {
                    ...state.visite_drafts,
                    [action.data.id_paziente]: {
                        ...state.visite_drafts[action.data.id_paziente],
                        steps: state.visite_drafts[action.data.id_paziente].steps.map(
                            (step, i) => i === action.data.current_step ? {
                                ...step,
                                campi: step.campi.map(
                                    (campo, i) => {
                                        return {
                                            ...campo,
                                            answers: [action.data.objAnswer]
                                        }
                                    })
                            } : step
                        )
                    }
                }
            };


        case SAVE_VISITA:
            return { ...state, visite_drafts: { [action.data.patientId]: { ...action.data } } };

        case SET_RISPOSTE_ANAMNESI:
            return {
                ...state,
                visite_drafts: {
                    ...state.visite_drafts,
                    [action.data.id_paziente]: {
                        ...state.visite_drafts[action.data.id_paziente],
                        steps: state.visite_drafts[action.data.id_paziente].steps.map(
                            (step, i) => i === action.data.current_step ? {
                                ...step,
                                campi: step.campi.map(
                                    (domanda) => domanda.id === action.data.id_domanda ? {
                                        ...domanda,
                                        answers: _.isArray(action.data.risposta) ? action.data.risposta : [action.data.risposta]

                                    } : domanda)
                            } : step
                        )
                    }
                }
            };


        case TOGGLE_RELATED_QUESTIONS:
            return {
                ...state,
                visite_drafts: {
                    ...state.visite_drafts,
                    [action.data.id_paziente]: {
                        ...state.visite_drafts[action.data.id_paziente],
                        steps: state.visite_drafts[action.data.id_paziente].steps.map(
                            (step, i) => i === action.data.current_step ? {
                                ...step,
                                campi: step.campi.map(
                                    (domanda) => domanda.id === action.data.id_domanda ? {
                                        ...domanda,
                                        hide: action.data.hide

                                    } : domanda)
                            } : step
                        )
                    }
                }
            };


        case GET_PERSONALE:
            return { ...state, personale: action.personale };

        case CREATE_PERSONA:
            return { ...state, personale: {...state.personale, data: {...state.personale.data, [action.persona.id] : action.persona}, order: [...state.personale.order, action.persona.id] }};

        case UPDATE_PERSONA:
            return {...state, error: "", personale: {...state.personale, data: {...state.personale.data, ...{ [action.id_persona]: {...state.personale.data[action.id_persona], ...action.data} }}}};

        case DELETE_PERSONA:
            return {...state, error: "", personale: {data: {..._.omit(state.personale.data, action.id_persona)}, order: [..._.remove(state.personale.order, id => id !== action.id_persona)]}};

        case CREATE_PAZIENTE:
            return { ...state, pazienti: {...state.pazienti, data: {...state.pazienti.data, [action.paziente.id] : action.paziente}, order: [...state.pazienti.order, action.paziente.id] }};

        case UPDATE_PAZIENTE:
            return {...state, error: "", pazienti: {data: {...state.pazienti.data, ...{ [action.id_paziente]: action.data }}}};

        case DELETE_PAZIENTE:
            return {...state, error: "", pazienti: {data: {...state.pazienti.data, ...{ [action.id_paziente]: {} }}}};

        case GET_LICENZE:
            return { ...state, licenze: action.licenze };

        case DO_COLLAPSE:
            return { ...state, collapsed: action.collapsed };

        case GET_REPORT_SETTINGS:
            return { ...state, settings: [...action.data]};

        case SAVE_REPORT_SETTINGS:
            return { ...state, settings: [...action.data]};

        case SET_REPORT_TOGGLE:
            return { ...state, settings: state.settings.map(mod => mod.licenseId === action.module_id ? {
                    ...mod,
                    reportSteps: mod.reportSteps.map(s => s.stepId === action.option_id ? {
                        ...s,
                        answers: [action.data]
                    } : s)
                } : mod)};


        case DELTE_VISITA_DRAFT:
            return {...state, visite_drafts: {..._.remove(state.visite_drafts, id => id !== action.user_id)}};


        case DELETE_VISITA:
            return {...state, error: "", pazienti: {
                order: [...state.pazienti.order],
                data: {
                    ...state.pazienti.data,
                    ...{ [action.id_paziente]: {
                        ...state.pazienti.data[action.id_paziente],
                        visits: _.remove(state.pazienti.data[action.id_paziente].visits, v => v.id !== action.id_visita)
                } }}}
            };


        case LOADING:
            return {...state, loading: action.isLoading}


        default:
            return state
    }
}
