import React, {Component} from 'react';
import './Modal.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


export default class AModal extends Component
{
    state = {
        isOpen: false
    };

    componentDidMount() {
        this.setState({isOpen: this.props.isOpen});
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
        this.props.toggle();
    }

    render() {
        const {isOpen} = this.state;
        const {className, title} = this.props;

        return (
            <Modal isOpen={isOpen} toggle={() => this.toggle()} className={className}>
                <ModalHeader tag="h4" toggle={() => this.toggle()}>{title}</ModalHeader>
                <ModalBody>{this.props.children}</ModalBody>
                {/*<ModalFooter>{actions}</ModalFooter>*/}
            </Modal>
        );
    }
}
