import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListaPazienti, getReportSettings } from "../../actions/istanza.actions";
import './PazienteDettaglio.scss';
import PazienteForm from '../Pazienti/PazientiForm';
import AModal from '../../components/Modal/Modal';
import moment from 'moment';
import {doRefreshToken} from "../../actions/user.actions";


class Pazienti extends Component {
    state = {
        show_modale_create_paziente: false,
        mode: 'create',
        paziente: null
    };


    async componentDidMount() {
        await this.props.getListaPazienti();
        await this.props.getReportSettings();
        //await this.props.doRefreshToken();
    }


    _renderPaziente(pId) {
        const { pazienti } = this.props;
        let paziente = pazienti.data[pId];

        return (
            <tr onClick={() => this.props.history.push(`/pazienti/${paziente.id}`)} key={paziente.id.toString()}>
                <td>{paziente.id}</td>
                <td>
                    <h5>{paziente.lastName} {paziente.firstName}</h5>
                    <div className="font-weight-light">{paziente.lastVisit ? "Ultima visita: " + moment(paziente.lastVisit).format('DD/MM/YYYY')  : 'Nessuna visita'}</div>
                </td>
                <td className="td-actions text-right">
                    <div className="d-flex align-items-center h-100 td-hover">
                        <img src={"/arrow-dx.svg"} width={32} className="mr-4" alt={"Dettaglio"}/>
                    </div>
                </td>
            </tr>
        );
    }


    _toggleModal = () => {
        const { show_modale_create_paziente } = this.state;
        this.setState({ show_modale_create_paziente: !show_modale_create_paziente });
    };


    _handleSearch(s){
        if(s === "" || s.length >= 3){
            this.props.getListaPazienti(s);
        }
    }

    render() {
        const { pazienti } = this.props;
        const { show_modale_create_paziente } = this.state;

        return pazienti ? (
            <div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h1>Gestione Pazienti</h1>
                        <p>Da questa sezione puoi gestire le anagrafiche dei pazienti</p>
                        <input type="text" placeholder={"Ricerca paziente"} className={"input-search"} onChange={e => this._handleSearch(e.target.value) }/>
                    </div>

                    <div className="col-12 col-md-4 text-right">
                    <button className={"btn btn-outline-primary"} onClick={() => this.setState({ show_modale_create_paziente: true, mode: 'create' })}>+ AGGIUNGI PAZIENTE</button>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <table className="table my-5 py-5 bg-white sd-table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nominativo</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pazienti && pazienti.order && pazienti.order.map(pId => this._renderPaziente(pId))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {show_modale_create_paziente ? (
                    <AModal isOpen={show_modale_create_paziente} toggle={() => this._toggleModal()} title={this.state.mode === 'create' ? 'Nuovo paziente' : 'Modifica paziente'} className={"vh-100 v-center modal-dialog-center sd-modal"}>
                        <PazienteForm closeModal={this._toggleModal} mode={"create"} />
                    </AModal>
                ) : null}

            </div>
        ) : (<div>Caricamento</div>);
    }
}


export default connect(state => ({pazienti: state.pazienti }), { getListaPazienti, doRefreshToken, getReportSettings })(Pazienti);
