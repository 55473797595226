export const DO_LOGIN = 'APP/DO_LOGIN';
export const DO_LOGOUT = 'APP/DO_LOGOUT';
export const DO_LOGOUT_REASON = 'APP/DO_LOGOUT_REASON';
export const DO_REFRESH_TOKEN = 'APP/DO_REFRESH_TOKEN';
export const DO_RECOVERY = 'APP/DO_RECOVERY';
export const DO_RESET_PASSWORD = 'APP/DO_RESET_PASSWORD';
export const GET_PAZIENTI = 'APP/GET_PAZIENTI';
export const SET_DENTE = 'APP/SET_DENTE';
export const UPDATE_DENTE = 'APP/UPDATE_DENTE';
export const DO_RESET = 'APP/DO_RESET';
export const SET_ISTANZA = 'APP/SET_ISTANZA';
export const GET_DETTAGLIO_PAZIENTE = 'APP/GET_DETTAGLIO_PAZIENTE';
export const SET_VISITA_DRAFT = 'APP/SET_VISITA_DRAFT';
export const DELTE_VISITA_DRAFT = 'APP/DELTE_VISITA_DRAFT';
export const SAVE_VISITA = 'APP/SAVE_VISITA';
export const DELETE_VISITA = 'APP/DELETE_VISITA';
export const SET_RISPOSTE_ANAMNESI = 'APP/SET_RISPOSTE_ANAMNESI';
export const TOGGLE_RELATED_QUESTIONS = 'APP/TOGGLE_RELATED_QUESTIONS';
export const GET_PERSONALE = 'APP/GET_PERSONALE';
export const CREATE_PERSONA = 'APP/CREATE_PERSONA';
export const DELETE_PERSONA = 'APP/DELETE_PERSONA';
export const UPDATE_PERSONA = 'APP/UPDATE_PERSONA';
export const CREATE_PAZIENTE = 'APP/CREATE_PAZIENTE';
export const UPDATE_PAZIENTE = 'APP/UPDATE_PAZIENTE';
export const DELETE_PAZIENTE = 'APP/DELETE_PAZIENTE';
export const GET_LICENZE = 'APP/GET_LICENZE';
export const DO_COLLAPSE = 'APP/DO_COLLAPSE';
export const GET_REPORT_SETTINGS = 'APP/GET_REPORT_SETTINGS';
export const SAVE_REPORT_SETTINGS = 'APP/SAVE_REPORT_SETTINGS';
export const SET_REPORT_TOGGLE = 'APP/SET_REPORT_TOGGLE';
export const SET_MARGINE_ENNE = 'APP/SET_MARGINE_ENNE';
export const SET_BIOTIPO = 'APP/SET_BIOTIPO';
export const DISMISS_ONBOARDING = 'APP/DISMISS_ONBOARDING';
export const LOADING = 'APP/LOADING';
export const GET_DITTA = 'APP/GET_DITTA';
export const GET_DETTAGLIO_VISITA = 'APP/GET_DETTAGLIO_VISITA';
