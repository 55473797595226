import {createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers/reducer';
import {persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});


const s = {
    user: {},
    pazienti: [],
    personale: [],
    licenze: [],
    error: "",
    current_istanza: "",
    current_dente: null,
    message: "",
    visite_drafts: {},
    collapsed: false,
    settings: [],
    loading: false
};


const c = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState=s)
{
    const st = createStore(persistReducer({key: 'mtt-root', storage}, reducer), initialState, c(applyMiddleware(thunk), sentryReduxEnhancer));
    const pst = persistStore(st);
    return {st, pst};
}
