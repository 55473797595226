import React, {Component} from 'react';
import { connect } from 'react-redux';
import Arcata from "../Arcata/Arcata";
import AModal from '../Modal/Modal';
import {setCurrentDente} from "../../actions/istanza.actions";
import DentePanel from "../Dente/DentePanel";
import {updateDente} from '../../actions/istanza.actions';


class Periodontogramma extends Component
{
    constructor(props) {
        super(props);
        this._handleClickDente = this._handleClickDente.bind(this);
        this._handleUpdateDente = this._handleUpdateDente.bind(this);
    }


    _handleClickDente(lato_dente, dente_index, arcata_index) {
        const {current_step, id_paziente} = this.props;
        this.props.setCurrentDente({id_paziente, current_step, arcata_index, dente_index, lato_dente});
    }


    _handleUpdateDente(dente_updated) {
        //console.log(dente_updated);
        this.props.updateDente(dente_updated);
    }


    render()
    {
        let {arcate, current_dente, id_paziente, current_step, visite_drafts} = this.props;
        const dente = current_dente && visite_drafts && visite_drafts[id_paziente].steps[current_step].arcate ?
            visite_drafts[id_paziente].steps[current_step].arcate[current_dente.arcata_index].denti[current_dente.dente_index] : null;

        if(arcate) {
            return (
                <div className={"container-fluid px-0"}>
                    <div className="row">
                        <div className="col-12">
                            {arcate.map((ar, i) =>
                                <Arcata {...ar} arcata_index={i} onClickDente={this._handleClickDente} />
                            )}
                        </div>
                    </div>


                    {dente ? (
                        <AModal
                            isOpen={dente !== null}
                            toggle={() => this.props.setCurrentDente(null)}
                            title={`Dente ${dente.numero_dente} - ${current_dente.arcata_index === 0 ? 
                                                                    current_dente.lato_dente === "lato_interno" ? 'Palatale' : 'Vestibolare' 
                                                                : current_dente.lato_dente === 'lato_interno' ? 'Linguale' : 'Vestibolare'}`}
                            className={"md modal-dente"}>

                            <DentePanel
                                dente={dente}
                                current_dente={current_dente}
                                updateDente={this._handleUpdateDente}
                                tipo_arcata={current_dente.arcata_index === 0 ? "arcata_superiore" : "arcata_inferiore"} />

                        </AModal>
                    ) : null}

                </div>
            );
        }
        else {
            return (<div>Caricamento</div>);
        }
    }
}


export default connect(state => ({current_dente: state.current_dente, visite_drafts: state.visite_drafts}), {setCurrentDente, updateDente})(Periodontogramma);
