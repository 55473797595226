import React, { Component } from 'react';


export default class Loading extends Component {


    render()
    {
        const {loading} = this.props;

       return loading ? (
           <div>
               <div className="loading-container">
                   <img alt={"Loading"} src="/loading.gif"/>
               </div>
           </div>

       ) :  (<div/>)
    }
}
