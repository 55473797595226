import React, {Component} from 'react';
import './Arcata.scss';


export class SquareText extends Component
{
    render()
    {
       const {text, className, onClick} = this.props;
       const backgroundStyle = {backgroundColor: 'white'};

       return(
           <div className={`d-inline-block box-square ${className}`} style={backgroundStyle} onClick={onClick}>{text}</div>
       );
    }
}



export class SquareFlag extends Component
{
    render()
    {
        const {flag, color, className, onClick} = this.props;
        const backgroundStyle = flag ? {backgroundColor: color}: {backgroundColor: '#ededed'};

        return(
            <div className={`d-inline-block box-square ${className}`} style={backgroundStyle} onClick={onClick}>
                {flag}
            </div>
        );
    }
}


export class SquareInt extends Component
{
    render()
    {
        const {val, colors, className, onClick} = this.props;
        const backgroundStyle = {backgroundColor: colors[val]};

        return(
            <div className={`d-inline-block box-square ${className}`} style={backgroundStyle} onClick={onClick}></div>
        );
    }
}

