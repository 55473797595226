import React, {Component} from 'react';
import { connect } from 'react-redux';
import { faLifeRing, faBookReader } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import env from '../../env';


class Assistenza extends Component
{

    _openPoup()
    {
        window.open(env.link_chat, 'targetWindow', 'toolbar=0,status=0,width=450,height=550');
        return false;
    }

    render() {

        return (
            <div className={"container-fluid mb-5 container-assistenza"}>

                <div className="row">
                    <div className="col-12 col-md-10">
                        <h1>Come possiamo aiutarti?</h1>
                        <p className="mt-3">
                            Siamo qui per rispondere a qualsiasi tuo dubbio o domanda.
                        </p>
                    </div>
                </div>



                <div className="row mt-5">
                    <div className="col-md-1 bg-white d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faBookReader} size={"3x"} color={"#476f82"} />
                    </div>

                    <div className="col-md-6 p-4 bg-white">
                        <h3>Manuale d'uso</h3>
                        <p className="mt-3">Consulta il manuale d'uso per troavare rapidamente le risposte ai tuoi dubbi.</p>

                        <ul>
                            <li><a href={env.link_manuale} target="_blank" rel="noopener noreferrer">Scarica il manuale (PDF)</a> </li>
                        </ul>
                    </div>
                </div>



                <div className="row mt-3">
                    <div className="col-md-1 bg-white d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faLifeRing} size={"3x"} color={"#476f82"} />
                    </div>

                    <div className="col-md-6 p-4 bg-white">
                        <h3>Assistenza</h3>
                        <p className="mt-3">Se hai ancora bisogno di supporto puoi contattarci<br/> attraverso uno di questi metodi:</p>

                        <ul>
                            <li><span className="text-bold">Whatsapp:</span> +39 375 5452169</li>
                            <li><span className="text-bold">Email:</span> info@periomapp.com</li>
                            <li><span className="text-bold">Chat:</span> Puoi scriverci tramite <span className="link" onClick={() => this._openPoup()}>live chat</span></li>
                        </ul>
                    </div>
                </div>


            </div>
        );
    }
}


export default connect(state => ({ }), { })(Assistenza);
