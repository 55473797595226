import React, {Component} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export class ActionDropdown extends Component
{
    state = {
        dropdownOpen: false
    };


    _toggle() {
        const {dropdownOpen} = this.state;
        this.setState({dropdownOpen: !dropdownOpen});
    }


    render()
    {
        const {dropdownOpen} = this.state;

        return (
            <Dropdown isOpen={dropdownOpen} toggle={() => this._toggle()} {...this.props}>
                <DropdownToggle>
                    <FontAwesomeIcon icon={faEllipsisV} color={"#7c7c7c"} />
                </DropdownToggle>

                <DropdownMenu>
                    {this.props.children.map((elem, i) => {
                        return (
                            <div key={i.toString()}>
                                <DropdownItem divider={elem.props.divider}/>
                                <DropdownItem>{elem}</DropdownItem>
                            </div>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
