import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import {faKeyboard, faQuestionCircle, faStar} from '@fortawesome/free-solid-svg-icons';
import {dismissOnboarding} from "../../actions/istanza.actions";


class Home extends Component {
    static displayName = Home.name;


    doDismissOnboarding(){
        this.props.dismissOnboarding();
    }


    render() {
        const {user} = this.props;

        return user.onboarding ? (
            <div className={"container"} data-aos="fade-in">
                <h1 className="pb-2">Benvenuto su <strong>PerioMapp</strong></h1>
                <h5>Di seguito puoi trovare alcune indicazioni per utilizzare al meglio Periomapp!</h5>


               <div className="row mt-5 align-items-center bg-white px-3 py-4 mb-3" >
                   <div className="col-12 col-lg-1">
                       <FontAwesomeIcon icon={faChrome} size="4x" color="#476f82" />
                   </div>

                   <div className="col-12 col-lg-11">
                       <h3 className="mb-1">BROWSER</h3>
                       <p>Per una migliore esperienza utilizza il browser Chrome!<br/>
                       Se non lo hai, <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/intl/it_it/chrome/">scaricalo ora</a>, è gratis!</p>
                   </div>
               </div>



                <div className="row align-items-center bg-white px-3 py-4 mb-3">
                    <div className="col-12 col-lg-1">
                        <FontAwesomeIcon icon={faStar} size="4x" color="#476f82"/>
                    </div>

                    <div className="col-12 col-lg-11">
                        <h3 className="mb-1">ACCESSO</h3>
                        <p>Per comodità, salva nei preferiti il link di accesso alla piattaforma Periomapp.<br/>
                            Se non sai come fare, qui puoi trovare la <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/answer/188842?hl=it">guida passo passo</a></p>
                    </div>
                </div>



                <div className="row align-items-center bg-white px-3 py-4 mb-3">
                    <div className="col-12 col-lg-1">
                        <FontAwesomeIcon icon={faKeyboard} size="4x" color="#476f82"/>
                    </div>

                    <div className="col-12 col-lg-11">
                        <h3 className="mb-1">INSERIMENTO DATI</h3>
                        <p>Nel periodontogramma, per spostarti da un dente all’altro utilizza le frecce del tastierino numerico (← | →);<br/>
                        Per un inserimento più rapido dei valori, puoi utilizzare le frecce ( ↑ | ↓ ) come alternativa ai tasti numerici della tastiera;<br/>
                        Per spostarti da un valore all’altro all’interno del dente, utilizza il tasto TAB. </p>
                    </div>
                </div>



                <div className="row align-items-center bg-white px-3 py-4 mb-3">
                    <div className="col-12 col-lg-1">
                        <FontAwesomeIcon icon={faQuestionCircle} size="4x" color="#476f82" />
                    </div>

                    <div className="col-12 col-lg-11">
                        <h3 className="mb-1">TUTORIAL</h3>
                        <p>Consulta la nuova sezione Assistenza & Tutorial.<br/>
                        Troverai tutte le guide che ti servono!</p>
                    </div>
                </div>

                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a href="#"
                   className={"btn btn-primary my-4"}
                   onClick={() => this.doDismissOnboarding()}>
                    Non mostrare più</a>
            </div>
        ) : (
            <Redirect to="/pazienti" />
        );
    }
}



export default connect(state => ({
    user: state.user
}), {
    dismissOnboarding
})(Home);
