import React, {Component} from 'react';
import { connect } from 'react-redux';
import {InputRadio} from "./InputRadio";
import {InputCheck} from "./InputCheck";
import {InputNumber} from "./InputNumber";
import {InputDropdown} from "./InputDropdown";
import {InputTextArea} from "./InputTextArea";
import {setRisposteAnamnesi} from "../../actions/istanza.actions";
import {InputTextReadonly} from "./InputTextReadonly";
import {GlicemiaLevel} from "./GlicemiaLevel";
import {InputDropdownVisit} from "./InputDropdownVisit";


class Questions extends Component
{
    _renderInputType(domanda) {

        switch (domanda.tipologia)
        {
            case 'radio': return (<InputRadio actions={domanda.options} selected={domanda.answers} onChange={a => this.handleAnswers(a, domanda.id)}/>);
            case 'checkbox': return (<InputCheck actions={domanda.options} selected={domanda.answers} onChange={a => this.handleAnswers(a, domanda.id)}/>);
            case 'dropdownStatus': return (<InputDropdown options={domanda.optionGroups} menuPlacement={"top"} selected={domanda.answers} isMulti={false} onChange={a => this.handleAnswers(a, domanda.id)}/>);
            case 'dropdown': return (<InputDropdown options={domanda.optionGroups} selected={domanda.answers} isMulti={true} onChange={a => this.handleAnswers(a, domanda.id)}/>);
            case 'visitsPreview': return (<InputDropdownVisit options={domanda.optionGroups} selected={domanda.answers} isMulti={false} onChange={a => this.handleAnswers(a, domanda.id)}/>);
            case 'number': return (<InputNumber options={domanda.options} selected={domanda.answers} onChange={a => this.handleAnswers(a, domanda.id)} />);
            case 'glycemiaLevel': return (<GlicemiaLevel options={domanda.options} selected={domanda.answers} onChange={a => this.handleAnswers(a, domanda.id)} />);
            case 'text': return (<InputTextArea option={domanda.options ? domanda.options[0] : null} selected={domanda.answers} onChange={a => this.handleAnswers(a, domanda.id)} />);
            case 'information': return (<InputTextReadonly options={domanda.options} />);
            default: return (<div>Tipo {domanda.tipologia} non implementato</div>);
        }
    }


    handleAnswers(itemSelected, id_domanda)
    {
        let {id_paziente, current_step} = this.props;
        this.props.setRisposteAnamnesi(id_paziente, current_step, id_domanda, itemSelected);
    }


    _renderDomanda() {
        let {campi} = this.props;

        return (
            <form className={"container-questions"}>

                {campi && campi.map((d, i) => !d.hide && (
                    <div className="row domanda p-3 mb-3" key={i.toString()}>
                        <div className="col-5">
                            <h5>{d.titolo}</h5>
                            <p>{d.description}</p>
                        </div>

                        <div id={`d-id-${d.id}`} className="col-7 d-flex justify-content-end align-items-center">
                            {this._renderInputType(d)}
                        </div>
                    </div>
                ))}

            </form>
        );
    }


    _renderResults() {
        let {campi} = this.props;

        return (
            <form className={"container-questions container-results container-fluid"}>

                {campi && campi.map((d, i) => !d.hide && (
                    <div className="row domanda px-4 py-5 mb-4" key={i.toString()}>
                        <div className="col-12 mb-4">
                            <h2 dangerouslySetInnerHTML={{__html: d.titolo}}></h2>
                            <h3 className="pt-2">{d.description}</h3>
                        </div>

                        <div className="col-12 mt-3">
                            {this._renderInputType(d)}
                        </div>
                    </div>
                ))}

            </form>
        );
    }


    render()
    {
        let {row} = this.props;
        return row ? this._renderResults() : this._renderDomanda();
    }
}


export default connect(state => ({}), {setRisposteAnamnesi})(Questions);
