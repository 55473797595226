import React, {Component} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {doConfirmUser} from "../../actions/user.actions";
import './Login.scss';
import {Redirect} from 'react-router-dom';


class Onboarding extends Component {
    static displayName = Onboarding.name;

    constructor(props) {
        super(props);
        this.state = {
            password_check: "",
            password: "",
            nome: "",
            cognome: "",
        };

        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }


    _handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }


    _handleSubmit(e){
        e.preventDefault();
        const {password, nome, cognome} = this.state;
        let params = queryString.parse(this.props.location.search);
        const {token, email} = params;
        this.props.doConfirmUser(token, password, email, nome, cognome);
    }


    render() {
        const {password_check, password, nome, cognome} = this.state;
        const {error, message} = this.props;

        return (
            <div className="container container-login">
                <div className="row justify-content-center v-center vh-100">
                    <div className="col-lg-12">

                        <div className={"text-center"} data-aos='fade-up'>
                            <a href="/"><img src={"/logo-test.png"} width={250} alt={"Logo"}/></a>
                        </div>

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-12 col-md-6 py-4 px-5">
                                        <h2 className="my-2">Benvenuto su Periomapp!</h2>
                                        {/*<h5 className="my-1">Hai ricevuto un invito di collaborazione</h5>*/}
                                        <p className="my-3">Dopo aver inserito i tuoi dati potrai utilizzare tutte le funzionalità di Periomapp</p>
                                    </div>


                                    <div className="col-12 col-md-6">
                                        <div className="pcard" data-aos='fade-in' data-aos-delay="500">

                                            <div className="pb-2">
                                                <h1 className="h4 mb-4 text-bold">Attiva il tuo account</h1>
                                            </div>

                                            <form className="user row" onSubmit={this._handleSubmit}>

                                                <div className="form-group col-12 col-md-6">
                                                    <label>Nome</label>
                                                    <input type="text"
                                                           name="nome"
                                                           value={nome}
                                                           required
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Il tuo nome"/>
                                                </div>

                                                <div className="form-group col-12 col-md-6">
                                                    <label>Cognome</label>
                                                    <input type="text"
                                                           name="cognome"
                                                           value={cognome}
                                                           required
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Il tuo cognome"/>
                                                </div>


                                                <div className="form-group col-12 col-md-6">
                                                    <label>Password</label>
                                                    <input type="password"
                                                           name="password"
                                                           pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                           title="La password deve contenere almeno 8 caratteri, un carattere speciale, un numero e una lettera maiuscola"
                                                           value={password}
                                                           required
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Crea una password"/>
                                                </div>


                                                <div className="form-group col-12 col-md-6">
                                                    <label>Ripeti password</label>
                                                    <input type="password"
                                                           name="password_check"
                                                           pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                           title="La password deve contenere almeno 8 caratteri, un carattere speciale, un numero e una lettera maiuscola"
                                                           required
                                                           value={password_check}
                                                           onChange={this._handleInputChange}
                                                           className={`form-control form-control-user ${error ? "error" : ""}`}
                                                           placeholder="Ripeti la password"/>
                                                </div>

                                                {error && (<div className={"py-1 text-danger col-12"}>{error}</div>)}
                                                {/*{message && (<div className={"py-1 text-success col-12"}>{message}</div>)}*/}
                                                {message && <Redirect to="/" />}


                                                <div className="text-right pt-4 col-12">
                                                    <button type={"submit"} className="btn text-uppercase text-white btn-primary btn-user d-inline-block btn-block col-md-5">
                                                        Conferma
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-0" data-aos='fade-in' data-aos-delay="1000">
                            {/* eslint-disable-next-line*/}
                            <a className="small" href="/">Esci</a>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


export default connect(state => ({error: state.error, message: state.message}), {doConfirmUser})(Onboarding);
