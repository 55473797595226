import React, {Component} from 'react';


export class AverageBar extends Component
{
    render()
    {
        const {sanguinamento, placca, ppd, cal, avg_cal, avg_ppd} = this.props;

        return (
            <div className="container-fluid px-0">
                <div className="row average-bar p-2 mb-3 m-0">
                    <div className="col">Max PPD: <span>{ppd}</span>mm</div>
                    <div className="col">Avg PPD <span>{parseFloat(avg_ppd).toFixed(2)}</span>mm </div>

                    <div className="col">Max CAL: <span>{cal}</span>mm</div>
                    <div className="col">Avg CAL <span>{parseFloat(avg_cal).toFixed(2)}</span>mm </div>

                    <div className="col"><span>{placca}%</span> Placca</div>
                    <div className="col"><span>{sanguinamento}%</span> Sang. al sondaggio</div>
                </div>
            </div>
        );
    }
}
