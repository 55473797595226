import React, {Component} from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import _ from 'lodash';


export class InputRadio extends Component
{
    state = {
        itemSelected: null
    };

    componentDidMount() {
        const {selected, actions} = this.props;
        let k = _.findIndex(actions, a => selected && selected.length > 0 && a.id === selected[0].id);
        this.setState({itemSelected: k});
    }


    _handleSelection = (selected, index) => {
        this.setState({itemSelected: index});
        this.props.onChange(this.props.actions[index]);
    };


    render()
    {
        const {actions, color} = this.props;
        const {itemSelected} = this.state;

        return (
            <ButtonGroup>
                 {actions.map((act, i) => (
                    <Button
                        color={color}
                        onClick={() => this._handleSelection(act, i)}
                        active={itemSelected === i}>{act.label}</Button>
                ))}
            </ButtonGroup>
        );
    }
}
