import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, withRouter} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import {unregister} from './registerServiceWorker';
// import register from './registerServiceWorker';
import {Provider} from 'react-redux'
import configureStore from './store';
import env from './env';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from 'history';

const {st, pst} = configureStore();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const MAPP = withRouter(App);
const history = createBrowserHistory();


if(env.environment === "production" || env.environment === "staging") {
    Sentry.init({
        dsn: env.dsn,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing({routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),})],
        tracesSampleRate: 0.5,
        release: "oralmapp@" + env.version,
        environment: env.environment
    });
}


ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>

        <Provider store={st}>
            <PersistGate loading={null} persistor={pst}>
                <BrowserRouter basename={baseUrl}>
                    <MAPP/>
                </BrowserRouter>
            </PersistGate>
        </Provider>

    </Sentry.ErrorBoundary>,

    rootElement);


unregister();
// register();
