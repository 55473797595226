import React, {Component} from 'react';


export class GlicemiaLevel extends Component
{
    state = {
        value: 0
    };


    componentDidMount() {
        const {selected} = this.props;
        this.setState({value: selected && selected.length > 0 ? selected[0].value : 0});
    }


    handleChange(v){
        const {options} = this.props;

        this.setState({value: v});
        this.props.onChange({...options[0], value: v});
    }


    render()
    {
        let {value} = this.state;

        return (
           <div className="d-flex">
               {value > 6 ? <p className="levelgly text-danger">Non controllato</p> : <p className="levelgly text-success">Controllato</p>}
               <div className={"input-number-sub"} onClick={() => value-1 >= 0 ? this.handleChange(--value) : 0}>-</div>
               <input type='number' className={"text-center input-number"} min="0" value={value} onChange={e => this.handleChange(e.target.value)}/>
               <div className={"input-number-add"} onClick={() => this.handleChange(++value)}>+</div>
           </div>
        );
    }
}
