import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListaLicenze } from "../../actions/istanza.actions";
import moment from 'moment';


class Licenze extends Component {

    componentDidMount() {
        this.props.getListaLicenze();
    }


    _renderLicenza(l, j) {
        return (
            <div className="row mb-2" key={j}>
                <div className="col-4"></div>
                <div className="col-3"><p>{l.label}</p></div>
                <div className="col-2"><small>{l.scadenza}</small></div>
                <div className="col-3"><p>{l.uso ? l.uso : '-'}</p></div>
            </div>
        );
    }



    render() {
        const { licenze, personale } = this.props;

        return licenze && personale ? (
            <div className="mb-5">

                <div className="row mb-3">
                    <div className="col-12">
                        <h3>Riepilogo Licenze</h3>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <table className="table py-5 bg-white sd-table">
                            <thead>
                                <tr>
                                    <th scope="col">Modulo</th>
                                    <th scope="col">Numero Licenza</th>
                                    <th scope="col">Assegnata a</th>
                                </tr>
                            </thead>

                            <tbody>

                                {licenze && licenze.map((licenza, i) => (
                                    <tr key={i}>
                                        <td className={"licence-type"}>{licenza.type}</td>
                                        <td className={"serial-number"}>
                                            <span>{licenza.label}</span>
                                            <div className="pt-2">Scadenza: {moment(licenza.expirationDate).format('DD/MM/YYYY')}</div>
                                        </td>
                                        <td>{licenza.userId && personale.data[licenza.userId] ?
                                                `${personale.data[licenza.userId].firstName} ${personale.data[licenza.userId].lastName}`
                                            :  '-'}</td>
                                    </tr>
                                 ))}

                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        ) : (<div>Caricamento</div>);
    }
}


export default connect(state => ({ licenze: state.licenze, personale: state.personale }), { getListaLicenze })(Licenze);
